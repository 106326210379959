import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { setInitiatorReviewNeeded } from 'shared/apis';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useOfferCreationNavigationUtils } from 'hooks/offers/useOfferCreationNavigationUtils';

/**
 * Checkbox to set if initiator review is needed.
 * False for review is needed, true for review not needed.
 */
export const InitiatorReviewCheckbox = () => {
  const { offer, setOffer, offerDataForAnalytics } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();
  const { showInitiatorReviewCheckBox } = useOfferCreationNavigationUtils();

  const { mutate: setInitiatorReviewNeededMutation, isPending } = useMutation({
    mutationFn: setInitiatorReviewNeeded,
    mutationKey: 'setInitiatorReviewNeeded',
    onSuccess: (data) => {
      setOffer(data);
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const onCheckboxChange = useCallback(() => {
    const payload = {
      offerId: offer._id,
      initiatorReviewNeeded: !!!offer.internalApproval.initiatorReviewNeeded,
      dataLayer: offerDataForAnalytics,
    };

    setInitiatorReviewNeededMutation(payload);
  }, [offer, setInitiatorReviewNeededMutation, offerDataForAnalytics]);

  // dont show the checkbox if the offer is not in the correct stage and state
  if (!showInitiatorReviewCheckBox) {
    return null;
  }

  // dont show the checkbox if the offer is not in the correct stage and state
  if (!showInitiatorReviewCheckBox) {
    return null;
  }

  // dont show the checkbox if the offer is not in the correct stage and state
  if (!showInitiatorReviewCheckBox) {
    return null;
  }

  return (
    <Form.Group className="mt-3 flex justify-between">
      <Form.Check.Input
        type="checkbox"
        disabled={isPending}
        onChange={onCheckboxChange}
        // if an offer has initiatorReviewNeeded set to false, the checkbox should be checked
        checked={!!!offer.internalApproval.initiatorReviewNeeded}
        className="inline relative"
      />
      <Form.Check.Label> {t('screens.LimitationsAndContacts.form.contacts.labels.needInitiatorApproval')} </Form.Check.Label>
    </Form.Group>
  );
};
