export const bankAccountProperties = {
  bank: 'bank',
  branchNumber: 'branchNumber',
  accountNumber: 'accountNumber',
  isCashAccount: 'isCashAccount',
  isSharesAccount: 'isSharesAccount',
};

export const bankAccountTypes = {
  DEBIT_AND_CREDIT_ACCOUNT: 'DEBIT_AND_CREDIT_ACCOUNT',
  cashAccount: 'cashAccount',
  sharesAccount: 'sharesAccount',
};
