import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const CopyCashToSharesInput = ({ index }) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();

  const handleLine = (line) => {
    const { cash_account_bank, cash_account_branch, cash_account_number } = line;
    return {
      ...line,
      shares_account_bank: cash_account_bank,
      shares_account_branch: cash_account_branch,
      shares_account_number: cash_account_number,
    };
  };

  const handleClick = () => {
    let table = watch('table');

    if (index || index === 0) {
      table[index] = handleLine(table[index]);
    } else {
      table = table.map(handleLine);
    }

    /* if no index, copy all lines */
    setValue('table', table, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  const tooltipText =
    index || index === 0
      ? t('components.Offers.DividedAccountsInputTable.CopyCashToSharesInput.tooltip')
      : t('components.Offers.DividedAccountsInputTable.CopyCashToSharesInput.tooltipAll');

  return (
    <td className="flex justify-content-center align-items-center">
      <Tooltip
        Content={tooltipText}
        Trigger={
          <Button variant="link" className="link-success">
            <FontAwesomeIcon onClick={handleClick} icon={faArrowCircleLeft} />
          </Button>
        }
      />
    </td>
  );
};
