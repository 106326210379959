import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils, useSendOfferMutation } from 'hooks/offers';

export const useSendingForm = () => {
  const { offer, setOnProceed, setIsLoadingFormSubmit, offerDataLayer } = useContext(OfferCreationContext);
  const { isOfferComplete, allowSendingWithMissingSignatures } = useOfferStateUtils({ offer });
  const { mutate: sendOfferMutation, isPending: sendOfferPending, Modal: OfferSendingSuccessModal } = useSendOfferMutation({ offer });
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onSubmit = useCallback(() => {
    if (isOfferComplete) {
      navigate('/user/offers/manage');
      return;
    }

    if (allowSendingWithMissingSignatures) {
      setShowConfirmModal(true);
      return;
    }

    const payload = {
      offerId: offer._id,
      dataLayer: offerDataLayer,
    };

    sendOfferMutation(payload);
  }, [isOfferComplete, navigate, sendOfferMutation, allowSendingWithMissingSignatures, offer._id, offerDataLayer]);

  const onMissingSignaturesConfirm = () => {
    const payload = {
      offerId: offer._id,
      allowMissingSignatures: true,
      dataLayer: offerDataLayer,
    };

    sendOfferMutation(payload);

    setShowConfirmModal(false);
  };

  useEffect(() => {
    setOnProceed(() => onSubmit);
  }, [setOnProceed, onSubmit]);

  useEffect(() => {
    setIsLoadingFormSubmit(sendOfferPending);
  }, [sendOfferPending, setIsLoadingFormSubmit]);

  return {
    showConfirmModal,
    setShowConfirmModal,
    onMissingSignaturesConfirm,
    OfferSendingSuccessModal,
  };
};
