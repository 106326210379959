import React, { createContext, useEffect, useState } from 'react';

import { customLocalStorage } from 'shared/customLocalStorage';

import i18n from '../i18n';

export const LanguageContext = createContext(null);

export const LanguageContextProvider = ({ children }) => {
  const lastSessionLanguage = customLocalStorage.getLanguage();
  const [language, setLanguage] = useState(lastSessionLanguage || 'he');
  const [direction, setDirection] = useState(language === 'he' ? 'rtl' : 'ltr');

  useEffect(() => {
    customLocalStorage.setLanguage(language);
    document.getElementsByTagName('html')[0].setAttribute('lang', language);
    i18n.changeLanguage(language);

    const direction = language === 'he' ? 'rtl' : 'ltr';
    setDirection(direction);
  }, [language]);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.direction = direction;
    document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  }, [direction]);

  const contextValue = {
    language,
    setLanguage,
    direction,
    setDirection,
  };

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};
