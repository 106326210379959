import some from 'lodash/some';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { bankAccountTypes } from 'shared/const/bankAccounts';
import { hashBankAccount } from 'shared/utils/offers';

import { useBankAccountOptions, useScopeAndAccountsErrors } from 'hooks/offers';

export const UnitedAccountsSelection = () => {
  const { setShowAddAccountModal, lastAddedBankAccount } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext();
  const { cashOptions, sharesOptions, noValuePlaceHolder } = useBankAccountOptions();
  const {
    setError: setCashAccountError,
    clearError: clearCashAccountError,
    errorToPresent: cashAccountError,
    errorDisplay: cashAccountErrorDisplay,
  } = useScopeAndAccountsErrors(bankAccountTypes.cashAccount);
  const {
    setError: setSharesAccountError,
    clearError: clearSharesAccountError,
    errorToPresent: sharesAccountError,
    errorDisplay: sharesAccountErrorDisplay,
  } = useScopeAndAccountsErrors(bankAccountTypes.sharesAccount);
  const [cashAccountTouched, setCashAccountTouched] = useState(false);
  const [sharesAccountTouched, setSharesAccountTouched] = useState(false);
  const isNoAccountSelected = useCallback((currentValue) => [noValuePlaceHolder, undefined].includes(currentValue), [noValuePlaceHolder]);

  /* CONVERT BANK ACCOUNTS OPTIONS FROM HOOK TO JSX */
  const generateJSXOptions = useCallback(
    (accountsFromHook, currentValue) => {
      if (!accountsFromHook?.length) return null;
      const noAccountSelected = isNoAccountSelected(currentValue);
      const result = (
        <>
          {accountsFromHook.map((item, index) => {
            return (
              <option key={index} value={item.value} disabled={!!item.disabled} selected={index === 0 && noAccountSelected}>
                {item.label}
              </option>
            );
          })}
        </>
      );
      return result;
    },
    [isNoAccountSelected],
  );

  /* SET NEW (ADDED) BANK ACCOUNTS AS SELECTED */
  useEffect(() => {
    if (!lastAddedBankAccount?.bank) return;
    const bankAccountHash = hashBankAccount(lastAddedBankAccount);
    const { isCashAccount, isSharesAccount } = lastAddedBankAccount;
    if (isCashAccount) {
      setValue(bankAccountTypes.cashAccount, bankAccountHash);
    }
    if (isSharesAccount) {
      setValue(bankAccountTypes.sharesAccount, bankAccountHash);
    }
  }, [lastAddedBankAccount, cashOptions, sharesOptions, setValue]);

  /* VALIDATE REQUIRED */
  const currentCashAccountHash = watch(bankAccountTypes.cashAccount) || undefined;
  const currentSharesAccountHash = watch(bankAccountTypes.sharesAccount) || undefined;
  const noAccountErrorMsg = t('components.UnitedAccountsSelection.errors.noAccountSelected'); // add to dependencies list of the hooks below and make sure it works the same

  useEffect(() => {
    isNoAccountSelected(currentSharesAccountHash) ? setSharesAccountError(noAccountErrorMsg) : clearSharesAccountError(noAccountErrorMsg);
  }, [currentSharesAccountHash, setSharesAccountError, clearSharesAccountError, isNoAccountSelected, noAccountErrorMsg]);

  useEffect(() => {
    isNoAccountSelected(currentCashAccountHash) ? setCashAccountError(noAccountErrorMsg) : clearCashAccountError(noAccountErrorMsg);
  }, [currentCashAccountHash, setCashAccountError, clearCashAccountError, isNoAccountSelected, noAccountErrorMsg]);

  const [cashJSXOptions, sharesJSXOptions] = useMemo(() => {
    return [generateJSXOptions(cashOptions, currentCashAccountHash), generateJSXOptions(sharesOptions, currentSharesAccountHash)];
  }, [cashOptions, currentCashAccountHash, currentSharesAccountHash, sharesOptions, generateJSXOptions]);

  const CashAccountSelect = useCallback(() => {
    return (
      <Form.Group id="selectCashAccount" className="mb-3">
        <Form.Label htmlFor="selectCashAccount">{t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.cashAccount')}</Form.Label>
        <Form.Select
          isInvalid={cashAccountError && cashAccountTouched}
          {...register(bankAccountTypes.cashAccount)}
          onBlur={() => setCashAccountTouched(true)}
          // id="selectCashAccount"
        >
          {cashJSXOptions}
        </Form.Select>
        {cashAccountTouched && cashAccountErrorDisplay}
      </Form.Group>
    );
  }, [cashAccountError, cashAccountErrorDisplay, cashAccountTouched, cashJSXOptions, register, t]);

  const SharesAccountSelect = useCallback(() => {
    return (
      <Form.Group id="selectSharesAccount" className="mb-4">
        <Form.Label htmlFor="selectSharesAccount">{t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.sharesAccount')}</Form.Label>
        <Form.Select
          isInvalid={sharesAccountError && sharesAccountTouched}
          {...register(bankAccountTypes.sharesAccount)}
          onBlur={() => setSharesAccountTouched(true)}
          id="selectSharesAccount"
        >
          {sharesJSXOptions}
        </Form.Select>
        {sharesAccountTouched && sharesAccountErrorDisplay}
      </Form.Group>
    );
  }, [sharesJSXOptions, register, sharesAccountError, sharesAccountErrorDisplay, sharesAccountTouched, t]);

  /* cash/shares accounts first option is always "no account selected" (noValuePlaceHolder) */
  const hasCashAccounts = some(cashOptions) && cashOptions.length > 1;
  const hasSharesAccounts = some(sharesOptions) && sharesOptions.length > 1;

  const noAccountText = useMemo(() => {
    if (!hasCashAccounts && !hasSharesAccounts) {
      return t('components.UnitedAccountsSelection.noAccounts');
    }

    if (!hasCashAccounts) {
      return t('components.UnitedAccountsSelection.noCashAccounts');
    }

    if (!hasSharesAccounts) {
      return t('components.UnitedAccountsSelection.noSharesAccounts');
    }

    return null;
  }, [hasCashAccounts, hasSharesAccounts, t]);

  /* JSX */
  return (
    <>
      {hasCashAccounts && <CashAccountSelect />}
      {hasSharesAccounts && <SharesAccountSelect />}
      {noAccountText && <p className="text-danger mb-3">{noAccountText}</p>}
      <Form.Group id="addNewAccount">
        <Button
          variant="success"
          onClick={() => {
            setShowAddAccountModal(true);
          }}
        >
          {t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.addAccount')} +
        </Button>
      </Form.Group>
    </>
  );
};
