import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { getAuctionById, initiateOffer } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import AuctionTitle from 'components/Offers/AuctionTitle/AuctionTitle';
import Box from 'components/UI/Box';

const NewOfferCompanySelection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auctionId } = useParams();
  const { setOffer } = useContext(OfferCreationContext);
  const { activeContracts, activeContractsWithOfferCreationPermission } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);

  /** validate params */
  useEffect(() => {
    if (!auctionId) {
      addAlert({
        title: t('screens.ChooseCompanyForNewOffer.errors.auctionIdNotProvided.title'),
        description: t('screens.ChooseCompanyForNewOffer.errors.auctionIdNotProvided.description'),
        type: ALERT_TYPES.ERROR,
      });
      navigate('/');
      return;
    }
    if (!activeContracts?.length) {
      addAlert({
        title: t('screens.ChooseCompanyForNewOffer.errors.noActiveContracts.title'),
        description: t('screens.ChooseCompanyForNewOffer.errors.noActiveContracts.description'),
        type: ALERT_TYPES.ERROR,
      });
      navigate('/');
      return;
    }
    if (!activeContractsWithOfferCreationPermission?.length) {
      addAlert({
        title: t('screens.ChooseCompanyForNewOffer.errors.noActiveContractsForOfferCreation.title'),
        description: t('screens.ChooseCompanyForNewOffer.errors.noActiveContractsForOfferCreation.description'),
        type: ALERT_TYPES.ERROR,
      });
      navigate('/');
      return;
    }
  }, [auctionId, activeContracts, activeContractsWithOfferCreationPermission, addAlert, navigate, t]);

  /* server side communication */
  const {
    isLoading: isLoadingAuction,
    data: auction,
    error: getAuctionByIdError,
  } = useQuery({
    queryKey: ['getAuctionById', auctionId],
    queryFn: () => getAuctionById(auctionId),
    enabled: !!auctionId,
  });

  useEffect(() => {
    if (getAuctionByIdError) {
      addAlert({
        title: t('screens.ChooseCompanyForNewOffer.errors.auctionNotFound.title'),
        description: t('screens.ChooseCompanyForNewOffer.errors.auctionNotFound.description'),
        getAuctionByIdError,
        type: ALERT_TYPES.ERROR,
      });
      navigate('/');
    }
  }, [getAuctionByIdError, addAlert, navigate, t]);

  const { mutate: initiateOfferWithCompany, isPending: offerInitiationIsLoading } = useMutation({
    mutationKey: 'initiateOffer',
    mutationFn: initiateOffer,
    onSuccess: (data) => {
      setOffer(data);
      navigate(`/user/offers/create/order-scope-and-accounts/${data._id}`);
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const submit = (companyId) => {
    const payload = {
      companyId,
      auctionId: auctionId,
    };
    initiateOfferWithCompany(payload);
  };

  if (!activeContractsWithOfferCreationPermission?.length) {
    return null;
  }

  /* JSX */
  if (offerInitiationIsLoading || isLoadingAuction || !auction) {
    return <Spinner animation="border" />;
  } else {
    return (
      <div id="PageContainer" className="h-full flex flex-col items-center">
        <div id="ContentContainer" className="flex flex-col justify-between items-center w-[1400px]">
          <AuctionTitle auctionId={auctionId} />
          <Box bodyClassName="items-center py-5">
            <h2 className="font-medium">{t('screens.ChooseCompanyForNewOffer.header')}</h2>
            <div className="h-full w-[1400px] flex flex-row justify-content-center mt-8 flex-wrap gap-3	">
              {activeContractsWithOfferCreationPermission.map((roleInCompany) => {
                return (
                  <Button
                    key={roleInCompany.companyId}
                    variant="primary"
                    className="w-[200px]"
                    size="lg"
                    onClick={() => {
                      submit(roleInCompany.companyId);
                    }}
                  >
                    {roleInCompany.companyName}
                  </Button>
                );
              })}
            </div>
          </Box>
        </div>
      </div>
    );
  }
};

export default NewOfferCompanySelection;
