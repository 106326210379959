import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { Button, Form, Form as FromInputs, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { addBankAccountTroughOfferFlow } from 'shared/apis';
import { bankAccountTypes } from 'shared/const/bankAccounts';
import { israelBanks } from 'shared/const/israelBanks';
import { schema } from 'shared/schemas/addCompanyBankAccount';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const AddAccountForm = ({ onHide }) => {
  const { t } = useTranslation();
  const { offer, setOffer, setLastAddedBankAccount } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);

  //FORM INITIALIZATION
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      bank: '',
      branchNumber: null,
      accountNumber: null,
      accountType: bankAccountTypes.DEBIT_AND_CREDIT_ACCOUNT,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    clearErrors,
    reset,
    control,
    setError,
    setValue,
  } = methods;

  const { mutate: addBankAccountTroughOfferMutation } = useMutation({
    mutationKey: 'addBankAccountTroughOffer',
    mutationFn: addBankAccountTroughOfferFlow,
    onSuccess: (data, variables) => {
      const { bankAccount } = variables;
      setOffer(data);
      setLastAddedBankAccount(bankAccount);
      onHide();
      reset();
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  // handle submit
  const onSubmit = async (data) => {
    const isCashAccount = data.accountType === bankAccountTypes.DEBIT_AND_CREDIT_ACCOUNT || data.accountType === bankAccountTypes.cashAccount;
    const isSharesAccount = data.accountType === bankAccountTypes.DEBIT_AND_CREDIT_ACCOUNT || data.accountType === bankAccountTypes.sharesAccount;
    const expectedPayload = {
      offerId: offer._id,
      bankAccount: {
        bank: data.bank,
        branchNumber: data.branchNumber ? Number(data.branchNumber) : null,
        accountNumber: data.accountNumber || null,
        isCashAccount,
        isSharesAccount,
      },
    };
    addBankAccountTroughOfferMutation(expectedPayload);
  };

  const handleClose = useCallback(() => {
    clearErrors();
    onHide();
  }, [clearErrors, onHide]);

  return (
    <Form className="flex flex-col">
      <Modal.Body className="px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <div>
            <Form.Group className="mb-4">
              <Form.Label>{t('components.AddAccountModal.labels.bank')}</Form.Label>
              <Controller
                name="bank"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      list="banks"
                      {...field}
                      className={`form-control ${errors.bank ? 'is-invalid' : ''}`}
                      onBlur={() => {
                        const bankName = field.value;
                        const bankCode = Object.keys(israelBanks).find((key) => israelBanks[key].includes(bankName));
                        if (bankCode) {
                          setValue('bank', bankCode);
                          clearErrors('bank');
                        } else {
                          setError('bank', { message: t('components.AddAccountModal.errors.bank') });
                        }
                        field.onChange(bankCode);
                      }}
                      placeholder={t('components.AddAccountModal.labels.bankPlaceholder')}
                    />

                    <datalist id="banks">
                      {Object.keys(israelBanks).map((bank) => (
                        <option key={bank} value={bank}>
                          {israelBanks[bank]}
                        </option>
                      ))}
                    </datalist>

                    <ErrorMessage
                      errors={errors}
                      name="bank"
                      render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                    />
                  </>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>{t('components.AddAccountModal.labels.branchNumber')}</Form.Label>
              <Form.Control type="number" {...register('branchNumber')} isInvalid={errors.branchNumber} />
              <ErrorMessage
                errors={errors}
                name="branchNumber"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-4">
              <Form.Label>{t('components.AddAccountModal.labels.accountNumber')}</Form.Label>
              <Form.Control type="string" {...register('accountNumber')} isInvalid={errors.accountNumber} />
              <ErrorMessage
                errors={errors}
                name="accountNumber"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>{t('components.AddAccountModal.labels.accountType')}</Form.Label>
              <FromInputs.Select {...register('accountType')} isInvalid={errors.accountType} defaultValue={bankAccountTypes.DEBIT_AND_CREDIT_ACCOUNT}>
                <option value={bankAccountTypes.DEBIT_AND_CREDIT_ACCOUNT}>{t('components.AddAccountModal.accountTypes.both')}</option>
                <option value={bankAccountTypes.cashAccount}>{t('components.AddAccountModal.accountTypes.debit')}</option>
                <option value={bankAccountTypes.sharesAccount}>{t('components.AddAccountModal.accountTypes.credit')}</option>
              </FromInputs.Select>
              <ErrorMessage
                errors={errors}
                name="accountType"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {/* we use onClick instead of type='submit' Because this form is displayed in a component that is in the context of another form*/}
        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
          {t('components.AddAccountModal.submit')}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t('components.AddAccountModal.cancel')}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export const AddAccountModal = () => {
  const { t } = useTranslation();

  const { showAddAccountModal, setShowAddAccountModal } = useContext(OfferCreationContext);

  const onHide = () => {
    setShowAddAccountModal(false);
  };

  return (
    <Modal show={showAddAccountModal} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('components.AddAccountModal.title')}</Modal.Title>
      </Modal.Header>
      <AddAccountForm onHide={onHide} />
    </Modal>
  );
};
