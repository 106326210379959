import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/UI/ModalConfirm';

import * as S from './styles';

const UnderwriterForm = ({ onSubmit, isLoading, isError, show, onClose }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const logo = watch('logo');

  const { fields, append, remove } = useFieldArray({
    name: 'allowedHostnames',
    control,
  });

  const modalContent = (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('screens.underwriters.table.modals.editUnderwriter.name')}</Form.Label>
        <Form.Control {...register('name')} isInvalid={!!errors?.name} />
        <ErrorMessage errors={errors} name="name" render={({ message }) => <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('screens.underwriters.table.modals.editUnderwriter.phone')}</Form.Label>
        <Form.Control {...register('phone')} isInvalid={!!errors?.phone} />
        <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('screens.underwriters.table.modals.editUnderwriter.email')}</Form.Label>
        <Form.Control {...register('email')} isInvalid={!!errors?.email} />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('screens.underwriters.table.modals.editUnderwriter.logo')}</Form.Label>
        <Form.Control {...register('logo')} isInvalid={!!errors?.email} />
        {logo && <img src={logo} alt="Logo preview" className="w-full" />}
        <ErrorMessage errors={errors} name="logo" render={({ message }) => <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>} />
      </Form.Group>

      <S.Section>
        {fields.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === fields.length - 1;

          return (
            <div className="d-flex align-items-start gap-2" key={index}>
              <Form.Group controlId={`allowedHostnames.${index}.url`} className="w-100">
                {isFirst && <Form.Label>{t('screens.underwriters.table.modals.editUnderwriter.allowedHostnames')}</Form.Label>}
                <Form.Control {...register(`allowedHostnames.${index}.url`)} isInvalid={errors.allowedHostnames?.[index]?.url} />
                <ErrorMessage
                  errors={errors}
                  name={`allowedHostnames.${index}.url`}
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>

              <S.RowToggle
                onClick={(e) => {
                  e.preventDefault();
                  if (isLast) {
                    append({ url: '' });
                  } else {
                    remove(index);
                  }
                }}
                variant={isLast ? 'info' : 'danger'}
                isFirst={isFirst}
                hasRowError={errors.allowedHostnames?.[index]?.url}
              >
                <FontAwesomeIcon icon={isLast ? faPlus : faTrash} />
              </S.RowToggle>
            </div>
          );
        })}
      </S.Section>
    </>
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalConfirm
          show={show}
          onClose={onClose}
          title={t('screens.underwriters.table.modals.editUnderwriter.title')}
          content={modalContent}
          variant="warning"
          proceedLabel={t('screens.underwriters.table.modals.editUnderwriter.proceed')}
          onProceed={handleSubmit(onSubmit)}
          isLoading={isLoading}
          isError={isError}
        />
      </Form>
    </>
  );
};

export default UnderwriterForm;
