import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { bankAccountTypes } from 'shared/const/bankAccounts';
import { hashBankAccount } from 'shared/utils/offers';

export const useBankAccountOptions = () => {
  const { offer } = useContext(OfferCreationContext);
  const { t } = useTranslation();

  const filterBankAccountsByType = useCallback(
    (accountType) => {
      const { bankAccounts } = offer?.initiation?.investingCompany || [];
      const result = bankAccounts.filter((account) => {
        if (accountType === bankAccountTypes.cashAccount) {
          return account.isCashAccount;
        }
        if (accountType === bankAccountTypes.sharesAccount) {
          return account.isSharesAccount;
        }
        return true;
      });
      return result;
    },
    [offer],
  );

  const noValuePlaceHolder = useMemo(() => `-- ${t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.pleaseSelect')} --`, [t]);

  const buildBankAccountsOptions = useCallback(
    (accountTypeAsFieldName) => {
      const accountsByType = filterBankAccountsByType(accountTypeAsFieldName);
      const options = accountsByType.map((item) => {
        let label = '';
        if (!item.branchNumber) {
          label = ` ${t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.bank')} ${item.bank}, ${t(
            'screens.OrderScopeAndAccounts.form.bankAccounts.labels.account',
          )} ${item.accountNumber}`;
        } else {
          label = ` ${t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.bank')} ${item.bank}, ${t(
            'screens.OrderScopeAndAccounts.form.bankAccounts.labels.branch',
          )} ${item.branchNumber}, ${t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.account')} ${
            item.accountNumber ? item.accountNumber : t('screens.OrderScopeAndAccounts.form.bankAccounts.labels.noAccountNumber')
          }`;
        }
        return {
          value: hashBankAccount(item),
          label,
        };
      });

      /** add empty option */
      options.unshift({
        value: undefined,
        label: noValuePlaceHolder,
        disabled: true,
      });

      return options;
    },
    [filterBankAccountsByType, t, noValuePlaceHolder],
  );

  const cashOptions = useMemo(() => {
    const cashOptions = buildBankAccountsOptions(bankAccountTypes.cashAccount);
    return cashOptions;
  }, [buildBankAccountsOptions]);

  const sharesOptions = useMemo(() => {
    const sharesOptions = buildBankAccountsOptions(bankAccountTypes.sharesAccount);
    return sharesOptions;
  }, [buildBankAccountsOptions]);

  return {
    cashOptions,
    sharesOptions,
    noValuePlaceHolder,
  };
};
