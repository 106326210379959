const { isProduction, isStaging, isDevelopment } = (() => {
  if (window && window.location) {
    console.log('window.location.hostname', window.location.hostname);
    const isProduction =
      window.location.hostname.includes('online.smartbull') ||
      window.location.hostname.includes('sb-online-prod') ||
      window.location.hostname.includes('test-in-prod');
    const isDevelopment = window.location.hostname.includes('localhost');
    const isStaging = !isProduction && !isDevelopment;

    return {
      isProduction,
      isStaging,
      isDevelopment,
    };
  } else {
    console.error('no window nor window.location...');
    return {
      isProduction: false,
      isStaging: false,
      isDevelopment: true,
    };
  }
})();

if (isStaging) console.log('staging env');
if (isDevelopment) console.log('development env');
if (isProduction) console.log('Welcome to SmartBull Online');

export { isDevelopment, isStaging, isProduction };
