import isNumber from 'lodash/isNumber';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

export const BidInputController = ({ fieldName, isEditingMode, disable, format, ...props }) => {
  const { watch, control } = useFormContext();
  const watchFieldName = watch(fieldName);

  const formattedValue = useMemo(() => {
    return watchFieldName && isNumber(+watchFieldName) ? format(+watchFieldName) : '';
  }, [watchFieldName, format]);

  const handleBlur = (onBlur, onChange) => {
    if (isEditingMode && watchFieldName && isNumber(+watchFieldName)) {
      onChange(+watchFieldName);
    }

    onBlur();
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, onBlur } }) => (
        <Form.Control
          /* since this is actually a number input, but we want to show the formatted value, we need to use a text input */
          /* so for editing mode we set the type to number, and for viewing mode we set the type to text */
          /* then we use the formatted value for viewing mode, and the actual value for editing mode */
          disabled={disable}
          type={isEditingMode ? 'number' : 'text'}
          value={isEditingMode ? watchFieldName : formattedValue}
          onChange={(event) => onChange(event.target.value)}
          onBlur={() => handleBlur(onBlur, onChange)}
          autoFocus
          {...props}
          dir="ltr"
          style={{ textAlign: 'right' }}
        />
      )}
    />
  );
};
