import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RelatedDistributerList = () => {
  const { t } = useTranslation();

  //JSX
  return (
    <div>
      <p>{t('screens.company.labels.relatedDistributors')}:</p>

      <Button variant="primary" size="sm" className="ml-2" onClick={() => alert('not implemented yet')}>
        {t('screens.company.actions.addRelatedDistributors')}
      </Button>
    </div>
  );
};

export default RelatedDistributerList;
