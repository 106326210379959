import { useCallback, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import { contractRoles } from 'shared/const/contractRoles';

import { useToggleCompanyActivation } from './useToggleCompanyActivation';

export const ViewModeCompanyTitle = ({ className, ...props }) => {
  const { t } = useTranslation();
  const { toggleMode, companyDetails, role } = useContext(CompanyDetailsContext);
  const { isSBAdmin } = useContext(AuthContext);
  const { ToggleActivationModal, setShowConfirmModal } = useToggleCompanyActivation();

  const isCompanyActive = !companyDetails.isDeactivated;

  const Actions = useCallback(() => {
    const isUserAllowedToEdit = role === contractRoles.COMPANY_ADMIN || isSBAdmin;

    if (!isUserAllowedToEdit) {
      return null;
    }

    return (
      <div className="flex gap-2">
        <Button variant="primary" onClick={toggleMode}>
          {t('screens.company.actions.edit')}
        </Button>
        {isSBAdmin && (
          <Button variant={isCompanyActive ? 'danger' : 'warning'} onClick={() => setShowConfirmModal(true)}>
            {isCompanyActive ? t('screens.company.actions.deactivate') : t('screens.company.actions.reactivate')}
          </Button>
        )}
      </div>
    );
  }, [role, setShowConfirmModal, isSBAdmin, isCompanyActive, toggleMode, t]);

  return (
    <div className={`flex justify-between ${className}`} {...props}>
      <h1>{companyDetails.name}</h1>
      <Actions />
      {isSBAdmin && <ToggleActivationModal />}
    </div>
  );
};
