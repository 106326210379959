export const columnNames = {
  account_name: 'account_name',
  cash_account_bank: 'cash_account_bank',
  cash_account_branch: 'cash_account_branch',
  cash_account_number: 'cash_account_number',
  shares_account_bank: 'shares_account_bank',
  shares_account_branch: 'shares_account_branch',
  shares_account_number: 'shares_account_number',
  amount: 'amount',
  limit: 'limit',
  maxPercentageFromAuction: 'maxPercentageFromAuction',
};

export const columnAliases = {
  [columnNames.account_name]: ['חברה', 'שם מלא', 'שם הקרן', 'שם חשבון', 'שם החשבון', 'שם'],
  [columnNames.cash_account_bank]: ['בנק לחיוב כספי', 'שם בנק', "מס' בנק/חבר בורסה לחיוב", "מס' בנק לחיוב", 'בנק לחיוב'],
  [columnNames.cash_account_branch]: ['סניף', 'סניף בנק', 'סניף לחיוב כספי', 'סניף לחיוב'],
  [columnNames.cash_account_number]: ['חשבון לחיוב כספי', 'מספר חשבון', "מס' ח-ן", 'חשבון', "מס' חשבון לחיוב", 'חשבון לחיוב'],
  [columnNames.shares_account_bank]: ['בנק לזיכוי', 'בנק להעברת ניירות ערך', "מס' בנק/חבר בורסה לזיכוי", 'בנק להעברת נ"יע', 'חבר בורסה לזיכוי'],
  [columnNames.shares_account_branch]: ['סניף לזיכוי', 'סניף להעברת ניירות ערך', 'סניף להעברת ני"ע'],
  [columnNames.shares_account_number]: ['חשבון לזיכוי', 'חשבון להעברת ניירות ערך', "מס' חשבון לזיכוי", 'חשבון להעברת ני"ע'],
  [columnNames.amount]: ['יחידות', 'ערך נקוב בהזמנה', 'סה"כ כמות', "כמות יח'", 'כמות ביחידות', 'כמות מקורית', 'כמות', 'כמות יחידות', 'כמות יח׳'],
  [columnNames.limit]: ['מחיר', 'ריבית', 'לימיט', 'מחיר היחידה', 'ריבית היחידה'],
  [columnNames.maxPercentageFromAuction]: ['אחוז מקסימלי להזמנה', 'אחוז מקסימלי', 'אחוז מקסימלי להזמנה על פי סכום ההזמנה', 'מגבלה', 'מגבלת פיצול'],
};

export const orderedDividedAccountsTableHeaders = [
  columnNames.account_name,
  columnNames.limit,
  columnNames.amount,
  columnNames.cash_account_bank,
  columnNames.cash_account_branch,
  columnNames.cash_account_number,
  columnNames.shares_account_bank,
  columnNames.shares_account_branch,
  columnNames.shares_account_number,
  columnNames.maxPercentageFromAuction,
];
