import { useLayoutEffect, useState } from 'react';

export const useWindowSize = ({ ref } = {}) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (ref) {
        setWindowSize({
          width: ref?.current?.offsetWidth,
          height: ref?.current?.offsetHeight,
        });
      } else {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]); // Empty array ensures that effect is only run on mount

  const isMobile = windowSize.width < 768;
  const isTablet = windowSize.width >= 768 && windowSize.width < 992;
  const isDesktop = windowSize.width >= 992;
  const isLargeDesktop = windowSize.width >= 1200;

  return { windowSize, isMobile, isTablet, isDesktop, isLargeDesktop };
};
