import React from 'react';

import { InitiatorReviewCheckbox } from '../../InitiatorReviewCheckbox';

import { PrevButton } from './PrevButton';
import { ProceedButton } from './ProceedButton';

export const NavbarActionsContainer = () => {
  const ActionButtons = () => {
    return (
      <div className="flex gap-3 max-h-[47px]">
        <PrevButton />
        <ProceedButton />
      </div>
    );
  };

  return (
    <div>
      <ActionButtons />
      <InitiatorReviewCheckbox />
    </div>
  );
};
