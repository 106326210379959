import { auctionTypes } from 'shared/const/auctions';
import $ from 'shared/utils/safeNumbers';

const isModuloValid = (inputValue, gap) => {
  const moduloValue = $.modulo(inputValue, gap);
  return moduloValue === 0;
};

const checkPriceGap = (inputValue, minPrice, priceGap) => {
  const valueForCheck = $.subtract(inputValue, minPrice);
  const valueForCheckAbs = Math.abs(valueForCheck);
  return isModuloValid(valueForCheckAbs, priceGap);
};

const checkInterestGap = (inputValue, maxInterest, interestGap) => {
  const valueForCheck = maxInterest ? $.subtract(maxInterest, inputValue) : inputValue;
  const valueForCheckAbs = Math.abs(valueForCheck);
  return isModuloValid(valueForCheckAbs, interestGap);
};

export const checkGap = (inputValue, gap, auctionType, { maxInterest, minPrice }) => {
  switch (auctionType) {
    case auctionTypes.PRICE:
      return checkPriceGap(inputValue, minPrice, gap);
    case auctionTypes.INTEREST:
    case auctionTypes.GAP:
      return checkInterestGap(inputValue, maxInterest, gap);
    default:
      throw new Error('invalid auction type: ' + auctionType);
  }
};
