import isNumber from 'lodash/isNumber';

export const cashIsraelBanks = {
  '4 - יהב': '4 - יהב',
  '10 - לאומי': '10 - לאומי',
  '11 - דיסקונט': '11 - דיסקונט',
  '12 - פועלים': '12 - פועלים',
  '13 - איגוד': '13 - איגוד',
  '14 - אוצר החייל': '14 - אוצר החייל',
  '17 - מרכנתיל דיסקונט': '17 - מרכנתיל דיסקונט',
  'וואן זירו הבנק הדיגיטלי - 18': 'וואן זירו הבנק הדיגיטלי - 18',
  '20 - מזרחי טפחות': '20 - מזרחי טפחות',
  '26 - יובנק': '26 - יובנק',
  '31 - בינלאומי': '31 - בינלאומי',
  '46 - מסד': '46 - מסד',
  'מיטב דש - 51': 'מיטב דש - 51',
  '54 - ירושלים': '54 - ירושלים',
  '68 - דקסיה': '68 - דקסיה',
};

export const sharesIsraelBanks = {
  '1131 - אגוד (חבר בורסה)': '1131 - אגוד (חבר בורסה)',
  'מרכנתיל דיסקונט (חבר בורסה) - 1173': 'מרכנתיל דיסקונט (חבר בורסה) - 1173',
  '4120 - בנק מזרחי טפחות בע"מ - נוסטרו': '4120 - בנק מזרחי טפחות בע"מ - נוסטרו',
  '5850 - בנק יהב לעובדי המדינה בע"מ': '5850 - בנק יהב לעובדי המדינה בע"מ',
  '1023 - בנק מסד בע"מ': '1023 - בנק מסד בע"מ',
  'דיסקונט (חבר בורסה) - 1115': 'דיסקונט (חבר בורסה) - 1115',
  'מזרחי טפחות (חבר בורסה) - 1206': 'מזרחי טפחות (חבר בורסה) - 1206',
  'הפועלים (חבר בורסה) - 1123': 'הפועלים (חבר בורסה) - 1123',
  'לאומי (חבר בורסה) - 1107': 'לאומי (חבר בורסה) - 1107',
  'הבנק הבינלאומי הראשון (חבר בורסה) - 1313': 'הבנק הבינלאומי הראשון (חבר בורסה) - 1313',
  'ירושלים (חבר בורסה) (ירושלים שוקי הון) - 2296': 'ירושלים (חבר בורסה) (ירושלים שוקי הון) - 2296',
  'יהב (חבר בורסה) - 5785': 'יהב (חבר בורסה) - 5785',
  'שרותי בורסה והשקעות בישראל אי.בי.אי. (חבר בורסה) - 2303': 'שרותי בורסה והשקעות בישראל אי.בי.אי. (חבר בורסה) - 2303',
  'אקסלנס נשואה שירותי בורסה (חבר בורסה) - 2361': 'אקסלנס נשואה שירותי בורסה (חבר בורסה) - 2361',
  'פועלים סהר (חבר בורסה) - 2379': 'פועלים סהר (חבר בורסה) - 2379',
  'מיטב דש טרייד (חבר בורסה) - 5018': 'מיטב דש טרייד (חבר בורסה) - 5018',
  'פסגות ניירות ערך (חבר בורסה) - 2288': 'פסגות ניירות ערך (חבר בורסה) - 2288',
  'CitiBank (חבר בורסה) - 2550': 'CitiBank (חבר בורסה) - 2550',
  'HSBC (חבר בורסה) - 5611': 'HSBC (חבר בורסה) - 5611',
  'UBS (חבר בורסה) - 5315': 'UBS (חבר בורסה) - 5315',
  'דויטשה בנק (חבר בורסה) - 5520': 'דויטשה בנק (חבר בורסה) - 5520',
};

export const israelBanks = {
  ...cashIsraelBanks,
  ...sharesIsraelBanks,
};

const bankNumberToBank = Object.entries(israelBanks).reduce((map, [key, value]) => {
  const number = key.match(/\d+/)[0];
  map[number] = value;
  return map;
}, {});

export function normalizeBankName(name) {
  name = name || '';
  let baseName;

  if (isNumber(name) || isFinite(name)) {
    // Directly use the number to find the bank name
    return bankNumberToBank[name.toString().trim()] || '';
  } else {
    baseName = name
      .replace(/[\s\d_,.()-]/g, '') // Remove whitespaces, numbers and specific characters
      .replace(/^בנק/g, '') // Remove prefix 'בנק'
      .replace(/^ה/g, '') // Remove prefix 'ה'
      .replace(/בע$/g, '') // Remove suffix 'בע'
      .replace(/בעמ$/g, ''); // Remove suffix 'בע״מ'
  }

  // Map specific bank names
  switch (baseName) {
    // Add cases as needed for specific bank name mappings
    // Example:
    case 'יהב':
      return bankNumberToBank['4'];
    case 'לאומי':
      return bankNumberToBank['10'];
    case 'דיסקונט':
      return bankNumberToBank['11'];
    case 'פועלים':
      return bankNumberToBank['12'];
    case 'איגוד':
      return bankNumberToBank['13'];
    case 'אוצר החייל':
      return bankNumberToBank['14'];
    case 'מרכנתיל דיסקונט':
      return bankNumberToBank['17'];
    case 'וואן זירו הבנק הדיגיטלי':
      return bankNumberToBank['18'];
    case 'מזרחי טפחות':
    case 'מזרחי':
      return bankNumberToBank['20'];
    case 'יובנק':
      return bankNumberToBank['26'];
    case 'בינלאומי':
      return bankNumberToBank['31'];
    case 'ערבי ישראלי':
      return bankNumberToBank['34'];
    case 'מסד':
      return bankNumberToBank['46'];
    case 'קופת העובד הלאומי':
      return bankNumberToBank['48'];
    case 'פועלי אגודת ישראל':
      return bankNumberToBank['52'];
    case 'ירושלים':
      return bankNumberToBank['54'];
    case 'שב"א':
      return bankNumberToBank['59'];
    case 'חסך קופת חסכון לחינוך':
      return bankNumberToBank['65'];
    case 'קהיר-עומאן':
      return bankNumberToBank['66'];
    case 'ערב איסמליק':
      return bankNumberToBank['73'];
    case 'בריטיש אוף מידל איסט':
      return bankNumberToBank['74'];
    case 'לאומי למשכנתאות בע"מ':
      return bankNumberToBank['77'];
    case 'ישראל דיסקונט':
      return bankNumberToBank['83'];
    case 'יוניון':
      return bankNumberToBank['83'];
    case 'האוזינג':
      return bankNumberToBank['84'];
    case 'דיסקונט למשכנתאות':
      return bankNumberToBank['90'];
    case 'שרותי בורסה והשקעות בישראל אי.בי.אי.':
      return bankNumberToBank['2303'];
    case 'אקסלנס נשואה שירותי בורסה':
      return bankNumberToBank['2361'];
    case 'פועלים סהר':
      return bankNumberToBank['2379'];
    case 'מיטב דש טרייד':
      return bankNumberToBank['5018'];
    case 'פסגות ניירות ערך':
      return bankNumberToBank['2288'];
    case 'דויטשה':
      return bankNumberToBank['5520'];
    default:
      console.log('WARN unknown bank name', name);
      return '';
  }
}
