import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext } from 'context/PublicOfferingsFilters';

import { getPublicOfferingFilterOptions } from 'shared/apis';

export const PublicOfferingFilter = ({ filterKey, staticOptions = null }) => {
  const { getFilterValue, setFilterValue, removeFilter } = useContext(PublicOfferingsFiltersContext);
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const value = getFilterValue(filterKey) || '';

  const placeHolderValue = '';
  const placeHolderOption = useMemo(
    () => ({
      label: `${t(`screens.publicOfferings.filtersLabels.${filterKey}`)}`,
      value: placeHolderValue,
    }),
    [filterKey, t],
  );

  const buildSelectOptions = useCallback(
    (options) => {
      const selectOptions = options.map((option) => {
        return {
          label: option,
          value: option,
        };
      });
      selectOptions.unshift(placeHolderOption);
      return selectOptions;
    },
    [placeHolderOption],
  );

  const { mutate: fetchOptions, isPending: isLoadingOptions } = useMutation({
    mutationKey: 'getPublicOfferingFilterOptions',
    mutationFn: async () => getPublicOfferingFilterOptions(filterKey),
    onSuccess: (data) => {
      const fetchedOptions = data;
      if (!fetchedOptions || fetchedOptions.length === 0) {
        return;
      }

      const selectOptions = buildSelectOptions(fetchedOptions);
      setOptions(selectOptions);
    },
    onError: (error) => {
      console.error(error);
      setOptions([{ label: t('common.error'), value: '' }]);
    },
  });

  useEffect(() => {
    if (!options.length || isLoadingOptions) {
      return;
    }

    const valueInOptions = !!options.find((opt) => opt.value === value);
    if (value && !valueInOptions) {
      removeFilter(filterKey);
    }
  }, [options, value, removeFilter, isLoadingOptions, filterKey]);

  useEffect(() => {
    if (staticOptions && staticOptions.length > 0) {
      setOptions([placeHolderOption, ...staticOptions]);
      return;
    }

    fetchOptions();
  }, [setOptions, staticOptions, fetchOptions, placeHolderOption]);

  const onchange = (e) => {
    const newValue = e.target.value;

    if (newValue === placeHolderValue) {
      removeFilter(filterKey);
      return;
    }

    setFilterValue(filterKey, newValue);
  };

  const optionsToRender = useMemo(() => {
    return isLoadingOptions ? [{ label: t('common.loading'), value: '' }] : options;
  }, [isLoadingOptions, options, t]);

  // select component with label
  return (
    <Form.Group>
      <Form.Label> {t(`screens.publicOfferings.filtersLabels.${filterKey}`)} </Form.Label>
      <Form.Select value={value} onChange={onchange}>
        {optionsToRender.map((option, idx) => {
          return (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};
