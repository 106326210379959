import Confetti from 'react-confetti';

export const DollarsConfettiShow = () => {
  const drawDollarBill = (ctx) => {
    const width = 40;
    const height = 20;

    // Draw the green rectangle
    ctx.fillStyle = '#86A98E';
    ctx.fillRect(0, 0, width, height);

    // Draw dark green border
    ctx.fillStyle = '#426E71';
    ctx.fillRect(0, 0, width, 2);
    ctx.fillRect(0, 0, 2, height);
    ctx.fillRect(width - 2, 0, 2, height);
    ctx.fillRect(0, height - 2, width, 2);

    // Draw the green circle in the middle
    const circleRadius = Math.min(width, height) / 4;
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, circleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = '#447146';
    ctx.fill();
    ctx.closePath();

    // Draw the '$' symbol in the middle of the circle
    ctx.font = '10px Arial';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('$', width / 2, height / 2);
  };

  return <Confetti id={'confettiCanvas'} run={true} recycle={true} numberOfPieces={10} drawShape={drawDollarBill} />;
};
