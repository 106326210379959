import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { contractRoles } from 'shared/const/contractRoles';

import { useAccreditedInvestorCertAlertContent } from './useAccreditedInvestorCertAlertContent';

export const AccreditedInvestorCertAlert = ({ contract }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const { alertHeader, alertText } = useAccreditedInvestorCertAlertContent({ contract });

  const { role } = contract;
  const isCompanyAdmin = role === contractRoles.COMPANY_ADMIN;

  if (!show) {
    return null;
  }

  return (
    <Alert variant="warning">
      <Alert.Heading>{alertHeader}</Alert.Heading>
      <div className="d-flex justify-content-between flex-row align-content-center">
        <p>{alertText}</p>

        <div className="d-flex flex-row gap-2">
          {isCompanyAdmin && (
            <Button className="btn btn-success" onClick={() => navigate(`/user/companies/${contract.companyId}`)} size="sm">
              {t('components.ContractRow.button.toCompanyPage')}
            </Button>
          )}
          <Button className="btn btn-danger" onClick={() => setShow(false)} size="sm">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
    </Alert>
  );
};
