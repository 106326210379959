export const ALERT_TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  WARNING: 'WARNING',
};

export const ALERT_TYPES_VARIANTS_MAP = {
  ERROR: 'danger',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  default: 'info',
};
