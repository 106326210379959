import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

import { AccountsStructures } from 'shared/const/accountsStructures';

export const calcTotalPctOfAuctionFromDivAccts = (offer) => {
  if (!offer) return 0;

  if (!offer.bankAccounts.chosenType === AccountsStructures.DIVIDED) {
    return 0;
  }

  const totalPct = sumBy(offer.bankAccounts.dividedAccounting, 'maxPercentageFromAuction');
  return totalPct;
};

export const someDivAccountsWithMaxPctFromAuction = (offer) => {
  if (!offer) return false;

  if (offer.bankAccounts.chosenType !== AccountsStructures.DIVIDED) {
    return false;
  }

  if (!some(offer.bankAccounts.dividedAccounting, 'maxPercentageFromAuction')) {
    return false;
  }

  return true;
};
