import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const UpdaterInfo = ({ updatedAt, user }) => {
  const { t } = useTranslation();

  if (!updatedAt || !user) {
    return null;
  }

  const TooltipTrigger = <FontAwesomeIcon icon={faInfoCircle} className="h-4 w-4" />;
  const TooltipContent = (
    <div>
      <div>
        {t('common.updatedBy')} {user.firstName} {user.lastName}
      </div>
      <div>
        {t('common.at')} {dayjs(updatedAt).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
  );

  return (
    <span className="text-muted">
      <Tooltip Content={TooltipContent} Trigger={TooltipTrigger} />
    </span>
  );
};
