import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import { toggleCompanyActivationBySbAdmin } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';

import ModalConfirm from 'components/UI/ModalConfirm';

export const useToggleCompanyActivation = () => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const queryClient = useQueryClient();
  const { companyDetails } = useContext(CompanyDetailsContext);
  const { _id: companyId, isDeactivated } = companyDetails;
  const { addAlert } = useContext(AlertContentContext);

  const { mutate, isPending } = useMutation({
    mutationKey: 'toggleCompanyActivation',
    mutationFn: () => toggleCompanyActivationBySbAdmin(companyId),
    onSuccess: () => {
      queryClient.invalidateQueries(['getCompanyDetails', companyId]);
      queryClient.invalidateQueries(['getAllCompaniesTable']);
      queryClient.invalidateQueries(['companiesCount']);
      setShowConfirmModal(false);
      addAlert({
        title: t('general.success'),
        description: isDeactivated
          ? t('components.companyDetails.companyTitle.activateCompany.success')
          : t('components.companyDetails.companyTitle.deactivateCompany.success'),
        type: ALERT_TYPES.SUCCESS,
      });
    },
    onError: (err) => {
      addAlert({
        title: isDeactivated
          ? t('components.companyDetails.companyTitle.activateCompany.error.title')
          : t('components.companyDetails.companyTitle.deactivateCompany.error.title'),
        description: isDeactivated
          ? t('components.companyDetails.companyTitle.activateCompany.error.description')
          : t('components.companyDetails.companyTitle.deactivateCompany.error.description'),
        err,
        type: ALERT_TYPES.ERROR,
      });

      console.error('Error toggling company activation', err);
      setShowConfirmModal(false);
    },
  });

  const onClose = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const ToggleActivationModal = useCallback(
    () => (
      <ModalConfirm
        show={showConfirmModal}
        onProceed={mutate}
        onClose={onClose}
        title={
          isDeactivated
            ? t('components.companyDetails.companyTitle.activateCompany.title')
            : t('components.companyDetails.companyTitle.deactivateCompany.title')
        }
        content={
          isDeactivated
            ? t('components.companyDetails.companyTitle.activateCompany.content')
            : t('components.companyDetails.companyTitle.deactivateCompany.content')
        }
        variant={isDeactivated ? 'warning' : 'danger'}
        proceed={
          isDeactivated
            ? t('components.companyDetails.companyTitle.activateCompany.proceed')
            : t('components.companyDetails.companyTitle.deactivateCompany.proceed')
        }
        isLoading={isPending}
      />
    ),
    [showConfirmModal, mutate, onClose, isPending, isDeactivated, t],
  );

  return { ToggleActivationModal, showConfirmModal, setShowConfirmModal };
};
