import { Container } from 'react-bootstrap';

import { MissingSignaturesConfirmModal } from 'components/Offers/AllowMissingSignatures/AllowMissingSignatures';
import SignatoriesTrackingBoard from 'components/Offers/SignatoriesTrackingBoard';
import { ViewAndSignSummary } from 'components/Offers/ViewAndSignSummary/ViewAndSignSummary';

import { useSendingForm } from './useSendingForm';

export const Sending = () => {
  const { showConfirmModal, setShowConfirmModal, onMissingSignaturesConfirm, OfferSendingSuccessModal } = useSendingForm();

  return (
    <>
      <Container className="flex flex-col md:flex-row items-start gap-2 md:!gap-4 pb-[130px]">
        <SignatoriesTrackingBoard />
        <ViewAndSignSummary />
      </Container>
      <MissingSignaturesConfirmModal show={showConfirmModal} setShow={setShowConfirmModal} onProceed={onMissingSignaturesConfirm} />

      <OfferSendingSuccessModal />
    </>
  );
};
