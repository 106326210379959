import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BankSelectInput } from './BankSelectInput';

export const BankAccountRow = ({ index, remove }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <tr key={index}>
      <td>
        <BankSelectInput name={`bankAccounts.${index}.bank`} />
      </td>
      <td>
        <Form.Control type="number" {...register(`bankAccounts.${index}.branchNumber`)} isInvalid={errors?.bankAccounts?.[index]?.branchNumber} />
        <ErrorMessage
          errors={errors}
          name={`bankAccounts.${index}.branchNumber`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Control type="string" {...register(`bankAccounts.${index}.accountNumber`)} isInvalid={errors?.bankAccounts?.[index]?.accountNumber} />
        <ErrorMessage
          errors={errors}
          name={`bankAccounts.${index}.accountNumber`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Check type="checkbox" {...register(`bankAccounts.${index}.isCashAccount`)} />
      </td>
      <td>
        <Form.Check type="checkbox" {...register(`bankAccounts.${index}.isSharesAccount`)} />
      </td>
      <td>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            remove(index);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};
