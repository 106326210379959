import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAllCompaniesTable } from 'shared/apis';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

const CompaniesTable = ({ isActive }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TableQuery
      queryKey={['getAllCompaniesTable', !isActive]}
      queryFn={getAllCompaniesTable}
      Columns={Columns({ t })}
      columnTPrefix={'screens.companies.table.columns.'}
      defaultSort={{ name: sortDirections.ASC }}
      actions={[
        {
          type: 'button',
          label: t('screens.companies.actions.create'),
          variant: 'success',
          onClick: () => {
            navigate('/admin/companies/new');
          },
        },
      ]}
      withExport={true}
      queryParams={{ isDeactivated: !isActive }}
    />
  );
};

export default CompaniesTable;
