import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { createUnderwriter } from 'shared/apis';
import { schema } from 'shared/schemas/underwriter';

import UnderwriterForm from 'components/Admin/UnderwritersTable/Form';

const NewRHF = ({ show, onClose }) => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateCreateUnderwriter,
    isPending: isCreateUnderwriterLoading,
    isError: isCreateUnderwriterError,
  } = useMutation({
    mutationFn: createUnderwriter,
    onSuccess: (data) => {
      queryClient.invalidateQueries('getAllUnderwritersTable');
      alert('Secret key: ' + data);
    },
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      allowedHostnames: [{ url: '' }],
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    let { name, allowedHostnames, phone, email, logo } = data;
    allowedHostnames = allowedHostnames.map((hostname) => hostname.url);

    const expectedPayload = {
      name,
      allowedHostnames,
      phone,
      email,
      logo,
    };

    mutateCreateUnderwriter(expectedPayload);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <UnderwriterForm onSubmit={onSubmit} show={show} onClose={onClose} isLoading={isCreateUnderwriterLoading} isError={isCreateUnderwriterError} />
    </FormProvider>
  );
};

export default NewRHF;
