import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { getNotifications, getUnreadNotificationsCount, markAllNotificationsAsRead, markNotificationAsRead } from 'shared/apis';

import { AuthContext } from './Auth';

const LIMIT = 10;

export const NotificationContext = createContext(null);

export const NotificationContextProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);
  const { isLoggedIn, isTokenLoadedToContext, signedCurrentAgreementVersion } = useContext(AuthContext);

  const enableNotifications = useMemo(
    () => isTokenLoadedToContext && isLoggedIn && !!signedCurrentAgreementVersion,
    [isTokenLoadedToContext, isLoggedIn, signedCurrentAgreementVersion],
  );

  const {
    data: notifications,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 0 }) => getNotifications({ pageParam, limit: LIMIT }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    enabled: enableNotifications,
  });

  const { data: unreadNotificationsCount } = useQuery({
    queryKey: ['unreadNotificationsCount'],
    queryFn: getUnreadNotificationsCount,
    initialData: 0,
    enabled: enableNotifications,
  });

  const setNotifications = useCallback(
    (notifications) => {
      queryClient.setQueryData(['notifications'], notifications);
    },
    [queryClient],
  );

  const setUnreadNotificationsCount = useCallback(
    (unreadNotificationsCount) => {
      queryClient.setQueryData(['unreadNotificationsCount'], unreadNotificationsCount);
    },
    [queryClient],
  );

  const addNotification = useCallback(
    (notification) => {
      let { pages } = notifications;

      if (!pages) {
        pages = [];
      }

      // Prepend the new notification to the first page
      pages[0].unshift(notification);

      // Iterate through the pages and adjust the notifications
      for (let i = 0; i < pages.length; i++) {
        // Check if the current page exceeds the limit
        if (pages[i].length > LIMIT) {
          // Take the last notification from the current page
          const lastNotification = pages[i].pop();

          // If the next page exists, add the notification to the next page
          // Else, create a new page with the notification
          if (pages[i + 1]) {
            pages[i + 1].unshift(lastNotification);
          } else {
            pages.push([lastNotification]);
            break; // No further shifting is needed
          }
        }
      }

      // Return the modified query data
      setNotifications({ ...notifications, pages });
      setUnreadNotificationsCount(unreadNotificationsCount + 1);
    },
    [setNotifications, notifications, setUnreadNotificationsCount, unreadNotificationsCount],
  );

  const { mutate: markAsRead } = useMutation({
    mutationKey: 'markAsRead',
    mutationFn: markNotificationAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
    },
    enabled: enableNotifications,
  });

  const { mutate: markAllAsRead } = useMutation({
    mutationKey: 'markAllAsRead',
    mutationFn: markAllNotificationsAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
    },
    enabled: enableNotifications,
  });

  const contextValue = {
    notifications,
    addNotification,
    markAllAsRead,
    markAsRead,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
    unreadNotificationsCount,
    showNotificationsDropdown,
    setShowNotificationsDropdown,
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
