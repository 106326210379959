import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { approveRejectMultipleContractsBySbAdmin } from 'shared/apis';
import { contractActionTypes } from 'shared/const/contractActionTypes';
import { detailedStatuses } from 'shared/const/detailedStatuses';
import { sortDirections } from 'shared/const/sortDirections';

import ModalConfirm from 'components/UI/ModalConfirm';
import { TableQuery } from 'components/UI/TableQuery';

import { pendingColumns as Columns } from './columns';

const PendingTable = ({ queryKey, queryFn, onBehalfOf, detailedStatus }) => {
  const { isSBAdmin, userId } = useContext(AuthContext);
  const { userId: userIdFromParams, companyId } = useParams();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [status, setStatus] = useState(null);

  const shouldShowActions = () => {
    if (isSBAdmin) {
      return true;
    }

    const isPendingOnCompany = detailedStatus === detailedStatuses.PENDING_ON_COMPANY;
    const isPendingOnEmployee = detailedStatus === detailedStatuses.PENDING_ON_EMPLOYEE;

    // if user details page, show true for pending on user if user is the same as the logged in user
    if (userId && userId === userIdFromParams) return isPendingOnEmployee;

    // if company details page, show true for pending on company (already only accessible to company admins)
    if (companyId) return isPendingOnCompany;

    return false;
  };

  const handleInvalidateQueries = useCallback(
    async (queryKey) => {
      const queries = queryClient.getQueriesData();
      for (const query of queries) {
        await new Promise((resolve) => setTimeout(resolve, 50));
        const isQueryKeyArray = Array.isArray(query[0]);
        const queryFnName = isQueryKeyArray ? query[0][0] : query[0];
        const queriesNeedToBeInvalidated = ['contractsLookup', 'getUserRoles', 'getCompanyUsersTable', 'getUserDetails', queryKey];
        if (queriesNeedToBeInvalidated.includes(queryFnName)) {
          queryClient.invalidateQueries(query.queryKey);
        }
      }
    },
    [queryClient],
  );

  const { mutate, isPending } = useMutation({
    mutationKey: 'approveRejectMultipleContractsBySbAdmin',
    mutationFn: approveRejectMultipleContractsBySbAdmin,
    onSuccess: () => {
      handleInvalidateQueries(queryKey);
      setSelected([]);
      setShowConfirmModal(false);
    },
    onError: () => {
      NotificationManager.error(t('screens.contracts.notifications.error'));
    },
  });

  const onClose = () => {
    setShowConfirmModal(false);
    setStatus(null);
  };

  const onProceed = () => {
    mutate({ contracts: selected, status, onBehalfOf });
  };

  const actions = shouldShowActions()
    ? [
        {
          type: 'button',
          variant: 'success',
          label: t('screens.contracts.actions.approve'),
          disabled: selected.length === 0,
          onClick: (data) => {
            setStatus(contractActionTypes.APPROVE);
            setShowConfirmModal(true);
          },
        },
        {
          type: 'button',
          label: t('screens.contracts.actions.reject'),
          variant: 'danger',
          disabled: selected.length === 0,
          onClick: (data) => {
            setStatus(contractActionTypes.REJECT);
            setShowConfirmModal(true);
          },
        },
      ]
    : [];

  const modalContent = useMemo(() => {
    if (isPending) return <Spinner animation="border" />;

    const question = [
      t('screens.contracts.modals.areYouSure'),
      status === contractActionTypes.APPROVE ? t('screens.contracts.modals.approve') : t('screens.contracts.modals.reject'),
      selected?.length > 1 ? t('screens.contracts.modals.these') : t('screens.contracts.modals.this'),
      selected?.length > 1 ? t('screens.contracts.modals.contracts') : t('screens.contracts.modals.contract'),
      '?',
    ].join(' ');

    const note = t('screens.contracts.modals.note');

    return (
      <div>
        <p>{question}</p>
        <p>{note}</p>
      </div>
    );
  }, [status, selected, t, isPending]);

  return (
    <React.Fragment>
      <TableQuery
        queryKey={queryKey}
        queryFn={queryFn}
        Columns={Columns}
        columnTPrefix={'screens.contracts.table.columns.'}
        defaultSort={{ 'employee.firstName': sortDirections.ASC }}
        withSelection={shouldShowActions()}
        selected={selected}
        setSelected={setSelected}
        actions={actions}
        filters={{ detailedStatus }}
        withExport={true}
      />

      <ModalConfirm
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        onProceed={onProceed}
        onClose={onClose}
        title={status === contractActionTypes.APPROVE ? t('screens.contracts.titles.approve') : t('screens.contracts.titles.reject')}
        content={modalContent}
        variant={status === contractActionTypes.APPROVE ? 'success' : 'danger'}
        proceed={status === contractActionTypes.APPROVE ? t('screens.contracts.actions.approve') : t('screens.contracts.actions.reject')}
      />
    </React.Fragment>
  );
};

export default PendingTable;
