import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/context/company-details';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { getCompanyDetails, getCompanyUsersTable, getEmploymentState } from 'shared/apis';
import { companyViewModes } from 'shared/const/companyViewModes';
import { contractStatues } from 'shared/const/contractStatues';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const CompanyDetailsContext = createContext(null);

export const CompanyDetailsContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { companyId } = useParams();
  const [mode, setMode] = useState(companyViewModes.VIEW);
  const [role, setRole] = useState(null);
  const [enableGetEmploymentState, setEnableGetEmploymentState] = useState(false);
  const [activeContracts, setActiveContracts] = useState([]);
  const [serverError, setServerError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddAssociatesModal, setShowAddAssociatesModal] = useState(false);
  const { addAlert } = useContext(AlertContentContext);

  const handleError = useCallback(
    (error) => {
      const errorName = getErrorNameFromErrorResponse(error);
      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.COMPANY_NOT_FOUND:
          errorAlertContent = {
            title: t('context.companyDetails.errors.companyNotFound.title'),
            description: t('context.companyDetails.errors.companyNotFound.description'),
          };
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(error);
          break;
      }

      addAlert(errorAlertContent);
      navigate('/');
    },
    [addAlert, navigate, t],
  );

  const {
    data: companyDetails,
    isLoading: companyDetailsIsLoading,
    error: companyDetailsError,
  } = useQuery({
    queryKey: ['getCompanyDetails', companyId],
    queryFn: () => getCompanyDetails(companyId),
    retry: false,
    enabled: !!companyId,
  });

  const {
    data: companyUsers,
    isLoading: companyUsersIsLoading,
    error: companyUsersError,
  } = useQuery({
    queryKey: ['getCompanyUsersTable'],
    queryFn: () => getCompanyUsersTable({}, companyId),
    retry: false,
    enabled: !!companyId,
  });

  useEffect(() => {
    if (companyDetailsError) {
      handleError(companyDetailsError);
    }

    if (companyUsersError) {
      handleError(companyUsersError);
    }
  }, [companyDetailsError, handleError, companyUsersError]);

  const {
    isLoading: employmentStateIsLoading,
    error: employmentStateError,
    data: employmentState,
  } = useQuery({
    queryKey: ['getEmploymentState'],
    queryFn: () => getEmploymentState(),
    placeholderData: keepPreviousData,
    enabled: enableGetEmploymentState,
  });

  useEffect(() => {
    if (employmentState) {
      setActiveContracts(employmentState?.[contractStatues.ACTIVE] || []);
      const activeContract = employmentState?.[contractStatues.ACTIVE]?.find((contract) => contract.companyId === companyId);
      if (activeContract) {
        setRole(activeContract.role);
        return;
      }
      if (!employmentState?.[contractStatues.ACTIVE]?.length) return;
      const firstActiveContract = employmentState?.[contractStatues.ACTIVE]?.[0];
      navigate(`/user/companies/${firstActiveContract.companyId}`);
      setRole(employmentState?.[contractStatues.ACTIVE]?.[0]?.role);
    }
  }, [companyId, employmentState, navigate]);

  const toggleMode = () => {
    setMode(mode === companyViewModes.VIEW ? companyViewModes.EDIT : companyViewModes.VIEW);
  };

  const contextValue = {
    companyId,
    mode,
    setMode,
    toggleMode,
    companyDetails,
    companyDetailsIsLoading,
    companyDetailsError,
    companyUsers,
    companyUsersIsLoading,
    companyUsersError,
    // employee/companyAdmin mode:
    enableGetEmploymentState,
    setEnableGetEmploymentState,
    role,
    setRole,
    activeContracts,
    setActiveContracts,
    employmentStateIsLoading,
    employmentStateError,
    serverError,
    setServerError,
    showModal,
    setShowModal,
    showAddAssociatesModal,
    setShowAddAssociatesModal,
  };

  return <CompanyDetailsContext.Provider value={contextValue}>{children}</CompanyDetailsContext.Provider>;
};
