import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { PUBLIC_OFFERINGS_FILTERS } from 'components/publicOfferingsFilters/consts';

const UrlStateConfig = {
  [PUBLIC_OFFERINGS_FILTERS.ISSUER]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.LINKAGE]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.SECTOR]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.COVENANT]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER]: StringParam,
  [PUBLIC_OFFERINGS_FILTERS.MIN_DURATION]: NumberParam,
  [PUBLIC_OFFERINGS_FILTERS.MAX_DURATION]: NumberParam,
  [PUBLIC_OFFERINGS_FILTERS.MIN_RATING]: NumberParam,
  [PUBLIC_OFFERINGS_FILTERS.MAX_RATING]: NumberParam,
  [PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE]: StringParam,
};

export const usePublicOfferingsFilters = () => {
  const useIssuerFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.ISSUER, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.ISSUER]);
  const useLinkageFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.LINKAGE, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.LINKAGE]);
  const useSectorFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.SECTOR, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.SECTOR]);
  const useCovenantFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.COVENANT, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.COVENANT]);
  const useOfferingTypeFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE]);
  const useAuctionTypeFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE]);
  const useLeadUnderwriterFilter = () =>
    useQueryParam(PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER]);
  const useMinDurationFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.MIN_DURATION, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.MIN_DURATION]);
  const useMaxDurationFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.MAX_DURATION, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.MAX_DURATION]);
  const useMinRatingFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.MIN_RATING, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.MIN_RATING]);
  const useMaxRatingFilter = () => useQueryParam(PUBLIC_OFFERINGS_FILTERS.MAX_RATING, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.MAX_RATING]);
  const useMosadiAuctionDateFilter = () =>
    useQueryParam(PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE, UrlStateConfig[PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE]);

  return {
    useIssuerFilter,
    useLinkageFilter,
    useSectorFilter,
    useCovenantFilter,
    useOfferingTypeFilter,
    useAuctionTypeFilter,
    useLeadUnderwriterFilter,
    useMinDurationFilter,
    useMaxDurationFilter,
    useMinRatingFilter,
    useMaxRatingFilter,
    useMosadiAuctionDateFilter,
  };
};
