import { ErrorMessage } from '@hookform/error-message';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import ControlledPhoneInput from 'components/UI/Form/ControlledPhoneInput';

const UserIdentityForm = () => {
  const { isSBAdmin } = useContext(AuthContext);

  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group controlId="personalId">
        <Form.Label>{t('screens.user.labels.personalId')}</Form.Label>
        <Form.Control type="text" {...register('personalId')} isInvalid={errors.personalId} disabled={!isSBAdmin} />
        <ErrorMessage
          errors={errors}
          name="personalId"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>{t('screens.user.labels.email')}</Form.Label>
        <Form.Control type="text" {...register('email')} isInvalid={errors.email} />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group controlId="mobile">
        <Form.Label>{t('screens.user.labels.mobile')}</Form.Label>
        <ControlledPhoneInput control={control} name={'mobile'} errors={errors} />
        <ErrorMessage
          errors={errors}
          name={'mobile'}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
    </>
  );
};

export default UserIdentityForm;
