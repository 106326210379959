import { sortDirections } from 'shared/const/sortDirections';

import { getSortKey } from './utils';

export const ColumnSortSpan = ({ column, sortBy }) => {
  if (column.noSort) return null;

  const sortKey = getSortKey(column);
  const currentSort = sortBy[sortKey];

  if (!currentSort) return null;

  switch (currentSort) {
    case sortDirections.ASC:
      return <span> &#8593;</span>;
    case sortDirections.DESC:
      return <span> &#8595;</span>;
    default:
      return null;
  }
};
