import _ from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

const AuctionResultsEmails = () => {
  const { t } = useTranslation();
  const { companyDetails } = useContext(CompanyDetailsContext);

  const emailsForAuctionResults = companyDetails?.defaultEmailsForAuctionResults || [];

  if (!_.some(emailsForAuctionResults)) {
    return <p>{t('screens.company.labels.emptyEmailsForAuctionResults')}</p>;
  }

  return (
    <div className="mt-3">
      <p>{t('screens.company.labels.emailsForAuctionResults')}:</p>
      {emailsForAuctionResults.join(', ')}
    </div>
  );
};

export default AuctionResultsEmails;
