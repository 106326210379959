import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AlertContentContext } from 'context/Alert';

import adminRoutes from 'routers/admin';
import userRoutes from 'routers/user';

import { Authentication } from 'screens/Auth';
import { QuickAuth } from 'screens/Auth/QuickAuth/QuickAuth';
import LandingScreen from 'screens/Landing';
import NotFoundScreen from 'screens/NotFound';
import RefusedAgreementScreen from 'screens/RefusedAgreement';
import TosScreen from 'screens/Tos';
import PrivacyPolicy from 'screens/Tos/PrivacyPolicy';
import TermsOfService from 'screens/Tos/TermsOfService';

import SignUpScreen from 'components/Auth/SignUp';
import AlertModal from 'components/UI/Alert/AlertModal';
import Layout from 'components/UI/Layout/Layout';
import TitleHandler from 'components/UI/TitleHandler';
import UnprotectedRoute from 'components/UI/UnprotectedRoute';
import { VisualEffectsContainer } from 'components/UI/VisualEffectsContainer/VisualEffectsContainer';

const MainBrowserRouter = () => {
  const { activeAlertContent, activeVisualEffect } = useContext(AlertContentContext);

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <TitleHandler />
        <Layout>
          <Routes>
            <Route
              index
              element={
                <UnprotectedRoute>
                  <LandingScreen />
                </UnprotectedRoute>
              }
            />
            <Route path="authentication" element={<Authentication />} />
            <Route path="quick-login" element={<QuickAuth />} />
            <Route
              path="sign-up"
              element={
                <UnprotectedRoute>
                  <SignUpScreen />
                </UnprotectedRoute>
              }
            />
            <Route path="refused-agreement" element={<RefusedAgreementScreen />} />
            <Route path="terms-of-service" element={<TosScreen Document={TermsOfService} />} />
            <Route path="privacy-policy" element={<TosScreen Document={PrivacyPolicy} />} />

            {adminRoutes}
            {userRoutes}

            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Layout>
        {activeAlertContent && <AlertModal />}
        {activeVisualEffect && <VisualEffectsContainer />}
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default MainBrowserRouter;
