import numbro from 'numbro';
import { useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { auctionTypes } from 'shared/const/auctions';
import { getLocalizedAuctionType } from 'shared/utils/auctions';

const formatBidLimit = (limit, type) => {
  switch (type) {
    case auctionTypes.INTEREST:
    case auctionTypes.GAP:
      return numbro(limit).formatCurrency({
        thousandSeparated: true,
        currencySymbol: '%',
        currencyPosition: 'postfix',
      });
    case auctionTypes.PRICE:
    default:
      return numbro(limit).format('0,0.0$');
  }
};

export const ValueWarning = ({ config: { show }, handleCancel, data, handleConfirm }) => {
  const { t } = useTranslation();
  const { type, hasAboveThresholdBids, hasUnderLimitBids, highValueBids, lowValueBids } = data;

  const renderBids = useCallback(
    (bids) => {
      return bids?.map((bid, index) => {
        return (
          <li key={index}>
            {getLocalizedAuctionType(type, t)} {formatBidLimit(bid.limit, type)} - {numbro(bid.value).format('0.0a$')}
          </li>
        );
      });
    },
    [type, t],
  );

  const warningType = useMemo(() => {
    if (hasAboveThresholdBids && hasUnderLimitBids) {
      return 'HighAndLowValueWarning';
    } else if (hasAboveThresholdBids) {
      return 'HighValueWarning';
    } else {
      return 'LowValueWarning';
    }
  }, [hasAboveThresholdBids, hasUnderLimitBids]);

  const generateWarning = useCallback(
    (bids, tPrefix) => {
      return bids?.length > 0 ? (
        <>
          <p>{t(`components.ValueWarning.${tPrefix}.message`)}</p>
          <ul className="list-disc list-inside">{renderBids(bids)}</ul>
        </>
      ) : null;
    },
    [renderBids, t],
  );

  if (!show) {
    return null;
  }

  const highValueWarning = generateWarning(highValueBids, 'HighValueWarning');
  const lowValueWarning = generateWarning(lowValueBids, 'LowValueWarning');

  return (
    <Modal show={show} onHide={handleCancel} size="lg" centered dialogClassName="justify-center" contentClassName="w-auto">
      <Modal.Header closeButton>
        <Modal.Title className="text-red-700 font-bold">{t(`components.ValueWarning.${warningType}.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-red-700">
        {highValueWarning}
        {lowValueWarning}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCancel}>
          {t('components.ValueWarning.cancel')}
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          {t('components.ValueWarning.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
