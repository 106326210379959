import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccountsStructures } from 'shared/const/accountsStructures';

import { AccountsStructureSelector } from 'components/Offers/AccountsStructureSelector';
import { AddAccountModal } from 'components/Offers/AddBankAccountModal';
import { DividedAccountsInputTable } from 'components/Offers/DividedAccountsInputTable/DividedAccountsInputTable';
import { UnitedAccountsSelection } from 'components/Offers/UnitedAccountsSelection';
import Box from 'components/UI/Box';

const OfferAccountsInput = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <Box className="" title={t('screens.OrderScopeAndAccounts.form.bankAccounts.header')}>
      <AccountsStructureSelector />

      <Form.Group id="AccountsInputs" className="flex-1">
        {watch('accountsStructure') === AccountsStructures.UNITED && <UnitedAccountsSelection />}
        {watch('accountsStructure') === AccountsStructures.DIVIDED && <DividedAccountsInputTable />}
      </Form.Group>

      <AddAccountModal />
    </Box>
  );
};

export default OfferAccountsInput;
