import { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { UserDetailsContext } from 'context/UserDetails';

import Box from 'components/UI/Box';
import { UserContractsTable } from 'components/UserDetails/ViewMode/Contracts';
import UserIdentifyingInfo from 'components/UserDetails/ViewMode/Info';
import UserAdditionalInfo from 'components/UserDetails/ViewMode/Settings';
import UserRoles from 'components/UserDetails/ViewMode/UserRolesTable';

export const ViewMode = () => {
  const { t } = useTranslation();
  const { isSBAdmin } = useContext(AuthContext);
  const { isEditingSelf } = useContext(UserDetailsContext);

  const UserInfo = useMemo(() => {
    const result = (
      <Row className="mt-4">
        <Col md={6}>
          <Box title={t('screens.user.titles.identifyingInfo')}>
            <UserIdentifyingInfo />
          </Box>
        </Col>
        <Col md={6}>
          <Box title={t('screens.user.titles.additionalInfo')}>
            <UserAdditionalInfo />
          </Box>
        </Col>
      </Row>
    );
    return result;
  }, [t]);

  const UserContracts = useMemo(() => {
    const result = (
      <>
        <Row className="mt-5">
          <Col>
            <Box title={t('screens.user.titles.UserRoles')}>
              <UserRoles />
            </Box>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Box title={t('screens.user.titles.JoinRequests')}>
              <UserContractsTable />
            </Box>
          </Col>
        </Row>
      </>
    );
    return result;
  }, [t]);

  if (isSBAdmin && isEditingSelf) {
    return <>{UserInfo}</>;
  } else {
    return (
      <>
        {UserInfo}
        {UserContracts}
      </>
    );
  }
};
