import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import UserTable from 'components/Admin/UsersTable/table';

const UsersScreen = () => {
  const { t } = useTranslation();
  const [userCount, setUserCount] = useState(0);

  return (
    <Container className="mb-5 mt-4">
      <h1>
        {t('screens.users.titles.usersTable')} ({userCount})
      </h1>
      <UserTable setUserCount={setUserCount} userCount={userCount} />
    </Container>
  );
};

export default UsersScreen;
