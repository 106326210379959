import { Button, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

import { useEmployeeEmail } from 'components/CompanyDetails/CompanyForm/useEmployeeEmail';

import { EmployeesRow } from './EmployeesRow';

export const EmployeesExt = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  useEmployeeEmail(fields);

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>{t('screens.company.labels.email')}</th>
            <th>{t('screens.company.labels.firstName')}</th>
            <th>{t('screens.company.labels.lastName')}</th>
            <th>{t('screens.company.labels.mobile')}</th>
            <th>{t('screens.company.labels.id')}</th>
            <th>{t('screens.company.labels.role')}</th>
            <th>{t('screens.company.labels.mandatorySignatory')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <EmployeesRow key={item.id} index={index} remove={remove} />
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-center">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            append({
              firstName: '',
              lastName: '',
              email: '',
              mobile: '',
              id: '',
              role: contractRoles.EMPLOYEE,
            });
          }}
        >
          {t('screens.company.buttons.addUser')}
        </Button>
      </div>
    </>
  );
};
