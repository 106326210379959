import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccreditedInvestorCertSelector } from 'components/CompanyDetails/CompanyForm/AccreditedInvestorCertSelector';
import { InvestorTypeSelector } from 'components/CompanyDetails/CompanyForm/InvestorTypeSelector';

export const GeneralInfo = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group controlId="companyName">
        <Form.Label>{t('screens.company.labels.companyName')}</Form.Label>
        <Form.Control type="text" {...register('companyName')} isInvalid={errors.companyName} />
        <ErrorMessage
          errors={errors}
          name="companyName"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group controlId="bnNumber">
        <Form.Label>{t('screens.company.labels.bnNumber')}</Form.Label>
        <Form.Control type="text" {...register('bnNumber')} isInvalid={errors.bnNumber} />
        <ErrorMessage
          errors={errors}
          name="bnNumber"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group controlId="domain">
        <Form.Label>{t('screens.company.labels.domain')}</Form.Label>
        <Form.Control type="text" {...register('domain')} isInvalid={errors.domain} />
        <ErrorMessage
          errors={errors}
          name="domain"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group controlId="investorType">
        <Form.Label>{t('screens.company.labels.investorType')}</Form.Label>
        <InvestorTypeSelector fieldPath="investorType" />
        <ErrorMessage
          errors={errors}
          name="investorType"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <AccreditedInvestorCertSelector fieldPath="accreditedInvestorCert" />
    </>
  );
};
