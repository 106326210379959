import React from 'react';
import { Route } from 'react-router-dom';

import { systemRoles } from 'shared/const/systemRoles';

import { CompanyScreenWithContext as CompanyDetailsScreen } from 'screens/User/CompanyDetails';
import DashboardScreen from 'screens/User/Dashboard';
import { NotificationsScreen } from 'screens/User/Notifications';
import { PublicOfferingsScreen } from 'screens/User/PublicOfferings';
import { UpcomingOfferingsScreen } from 'screens/User/UpcomingOfferings';
import UserDetailsScreen from 'screens/User/UserDetails';

import ProtectedRoute from 'components/UI/ProtectedRoute';

import offersRoutes from './offers';

const userRoutes = (
  <Route path="user">
    <Route
      index
      element={
        <ProtectedRoute allowedRoles={[systemRoles.USER]}>
          <DashboardScreen />
        </ProtectedRoute>
      }
    />

    <Route path="companies">
      <Route
        index
        element={
          <ProtectedRoute allowedRoles={[systemRoles.USER]}>
            <CompanyDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path=":companyId"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.USER]}>
            <CompanyDetailsScreen />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="details">
      <Route
        index
        element={
          // both user and admin can see and edit their own details (users will go through TFA before updating their details)
          <ProtectedRoute allowedRoles={[systemRoles.USER, systemRoles.SB_ADMIN]}>
            <UserDetailsScreen />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route
      path="notifications"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.USER]}>
          <NotificationsScreen />
        </ProtectedRoute>
      }
    />
    <Route
      path="public-offerings"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.USER]}>
          <PublicOfferingsScreen />
        </ProtectedRoute>
      }
    />
    <Route
      path="upcoming-offerings"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.USER]}>
          <UpcomingOfferingsScreen />
        </ProtectedRoute>
      }
    />

    {offersRoutes}
  </Route>
);
export default userRoutes;
