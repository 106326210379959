import numbro from 'numbro';

import { auctionTypes } from 'shared/const/auctions';
import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';

export const formatBidValue = (value, bidType, offer) => {
  const auctionType = offer?.initiation?.auctionDetails?.type;
  const isPriceAuction = auctionType === auctionTypes.PRICE;
  switch (bidType) {
    case BID_INPUT_FIELDS.LIMIT:
      return isPriceAuction
        ? numbro(value).format('0,0$')
        : numbro(value).formatCurrency({
            thousandSeparated: true,
            currencySymbol: '%',
            currencyPosition: 'postfix',
          });
    case BID_INPUT_FIELDS.AMOUNT:
      return numbro(value).format({
        thousandSeparated: true,
        mantissa: 0,
      });
    default:
      return value;
  }
};
