import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { israelBanks } from 'shared/const/israelBanks';

import { useDividedAccountsInputWrapper, useScopeAndAccountsErrors } from 'hooks/offers';

import './style.css';

export const BankNameInput = ({ index, name }) => {
  const fieldName = useMemo(() => `table.${index}.${name}`, [index, name]);
  const { setError, clearError } = useScopeAndAccountsErrors(fieldName);
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const watchFieldName = watch(fieldName);
  const { control } = useFormContext();

  useEffect(() => {
    /* trick watch update to overcome react-hook-form bug */
    /* watch is not updated when the value is an object and and inner value is changed */
    /* stringifying the object and saving it in a different field accomplishes the needed update */
    const tableStringified = JSON.stringify(watch('table'));
    setValue('tableStr', tableStringified);
  }, [watch, setValue, watchFieldName]);

  /* validate value */
  useEffect(() => {
    const requiredErrorMsg = t('components.DividedAccountsInputTable.table.errors.required');
    const haveValue = watchFieldName?.length;
    haveValue ? clearError(requiredErrorMsg) : setError(requiredErrorMsg);

    const notInVBankListErrorMsg = t('components.DividedAccountsInputTable.table.errors.notInBankList');
    const inVBankList = Object.values(israelBanks).includes(watchFieldName);
    inVBankList ? clearError(notInVBankListErrorMsg) : setError(notInVBankListErrorMsg);
  }, [watchFieldName, setError, clearError, t]);

  const controllerProps = useMemo(() => {
    const result = {
      name: fieldName,
      control: control,
      render: ({ field }) => (
        <>
          <input list={`banks_${name}`} {...field} className={`form-control p-0`} />

          <datalist id={`banks_${name}`}>
            {Object.keys(israelBanks).map((bank) => (
              <option key={bank} value={bank}>
                {israelBanks[bank]}
              </option>
            ))}
          </datalist>
        </>
      ),
    };
    return result;
  }, [fieldName, control, name]);

  const controller = useMemo(() => <Controller {...controllerProps} />, [controllerProps]);

  return useDividedAccountsInputWrapper({ controller, fieldName });
};
