export const BID_INPUT_FIELDS = {
  LIMIT: 'limit',
  AMOUNT: 'amount',
};

export const BID_INPUT_RESULTS_FIELDS = {
  LIMIT: 'limit',
  REQUESTED_AMOUNT: 'requestedAmount',
  RECEIVED_AMOUNT: 'receivedAmount',
};
