import { ErrorMessage } from '@hookform/error-message';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useErrorRenderer = (errors) => {
  const { t } = useTranslation();

  const errorRender = useCallback(
    ({ message }, prefix) => {
      return (
        <p className="text-red-700">
          {prefix ? prefix + ' : ' : ''}
          {t(message)}
        </p>
      );
    },
    [t],
  );

  const errorDisplay = useCallback(
    (name) => {
      return <ErrorMessage errors={errors} name={name} render={errorRender} />;
    },
    [errors, errorRender],
  );

  return { errorDisplay };
};
