import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BankAccountColumns = [
  {
    Header: 'Bank',
    accessor: 'bank',
  },
  {
    Header: 'branchNumber',
    accessor: 'branchNumber',
  },
  {
    Header: 'accountNumber',
    accessor: 'accountNumber',
  },
  {
    Header: 'isCashAccount',
    accessor: 'isCashAccount',
    Cell: (row) => {
      const icon = row?.isCashAccount ? faCheck : faTimes;
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={icon} />
        </div>
      );
    },
  },
  {
    Header: 'isSharesAccount',
    accessor: 'isSharesAccount',
    Cell: (row) => {
      const icon = row?.isSharesAccount ? faCheck : faTimes;
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={icon} />
        </div>
      );
    },
  },
];

export default BankAccountColumns;
