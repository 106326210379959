import { ERROR_CODES } from 'errors/components/companies/users-table';
import React, { useContext } from 'react';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { getCompanyBankAccountsTable } from 'shared/apis';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

export const BankAccountsTable = () => {
  const { companyId } = useContext(CompanyDetailsContext);

  if (!companyId) return null;

  const getErrorAlertContent = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    switch (errorName) {
      case ERROR_CODES.COMPANY_NOT_FOUND:
        return {
          title: 'components.CompanyDetails.UsersTable.errors.companyNotFound.title',
          description: 'components.CompanyDetails.UsersTable.errors.companyNotFound.description',
        };
      default:
        return null;
    }
  };

  return (
    <TableQuery
      queryKey={'getCompanyBankAccountsTable'}
      filters={{ companyId }}
      queryFn={(options) => getCompanyBankAccountsTable(options, companyId)}
      getErrorAlertContent={getErrorAlertContent}
      Columns={Columns}
      columnTPrefix={'screens.company.table.columns.'}
      withSearch={false}
      withPagination={false}
      withSort={false}
    />
  );
};
