import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required(),
  allowedHostnames: yup
    .array()
    .of(
      yup.object().shape({
        url: yup.string().required(),
      }),
    )
    .required(),
  phone: yup.string().notRequired(),
  email: yup.string().notRequired(),
});
