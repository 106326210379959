Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'align-left';
var width = 21;
var height = 23;
var aliases = [];
var unicode = 'f036';
var svgPathData =
  'M4.75 10.5654H6.75V12.5654H4.75V10.5654ZM18.75 4.56543V18.5654C18.75 19.6754 17.86 20.5654 16.75 20.5654H2.75C2.21957 20.5654 1.71086 20.3547 1.33579 19.9796C0.960714 19.6046 0.75 19.0959 0.75 18.5654V4.56543C0.75 3.46543 1.65 2.56543 2.75 2.56543H3.75V0.56543H5.75V2.56543H13.75V0.56543H15.75V2.56543H16.75C17.2804 2.56543 17.7891 2.77614 18.1642 3.15122C18.5393 3.52629 18.75 4.035 18.75 4.56543ZM2.75 6.56543H16.75V4.56543H2.75V6.56543ZM16.75 18.5654V8.56543H2.75V18.5654H16.75ZM12.75 12.5654V10.5654H14.75V12.5654H12.75ZM8.75 12.5654V10.5654H10.75V12.5654H8.75ZM4.75 14.5654H6.75V16.5654H4.75V14.5654ZM12.75 16.5654V14.5654H14.75V16.5654H12.75ZM8.75 16.5654V14.5654H10.75V16.5654H8.75Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faCalendarMonthOutline = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
