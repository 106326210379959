import { ErrorMessage } from '@hookform/error-message';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { israelBanks } from 'shared/const/israelBanks';

export const BankSelectInput = ({ name }) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useFormContext();

  const handleBlur = (field) => {
    const bankName = field.value;
    const bankCode = Object.keys(israelBanks).find((key) => israelBanks[key].includes(bankName));
    if (bankCode) {
      setValue('bank', bankCode);
      clearErrors('bank');
    } else {
      setError('bank', { message: t('components.AddAccountModal.errors.bank') });
    }
    field.onChange(bankCode);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <input
              list="banks"
              {...field}
              className={`form-control ${errors.bank ? 'is-invalid' : ''}`}
              onBlur={() => handleBlur(field)}
              placeholder={t('components.AddAccountModal.labels.bankPlaceholder')}
            />

            <datalist id="banks">
              {Object.keys(israelBanks).map((bank) => (
                <option key={bank} value={bank}>
                  {israelBanks[bank]}
                </option>
              ))}
            </datalist>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
            />
          </>
        )}
      />
    </>
  );
};
