import { useQuery } from '@tanstack/react-query';

import { getCompaniesCount, getPendingContractsCount, getUsersCount } from 'shared/apis';

export const useAdminStats = ({ enabled, withUsersCount = true, withCompaniesCount = true, withPendingContractsCount = true }) => {
  const {
    data: usersCount,
    isLoading: usersCountLoading,
    isError: usersCountError,
  } = useQuery({
    queryKey: ['usersCount'],
    queryFn: getUsersCount,
    enabled: enabled && withUsersCount,
  });

  const {
    data: companiesCount,
    isLoading: companiesCountLoading,
    isError: companiesCountError,
  } = useQuery({
    queryKey: ['companiesCount'],
    queryFn: getCompaniesCount,
    enabled: enabled && withCompaniesCount,
  });

  const {
    data: pendingContractsCount,
    isLoading: pendingContractsCountLoading,
    isError: pendingContractsCountError,
  } = useQuery({
    queryKey: ['pendingContractsCount'],
    queryFn: getPendingContractsCount,
    enabled: enabled && withPendingContractsCount,
  });

  if (!enabled) return {};

  return {
    usersCount,
    usersCountLoading,
    usersCountError,
    companiesCount,
    companiesCountLoading,
    companiesCountError,
    pendingContractsCount,
    pendingContractsCountLoading,
    pendingContractsCountError,
  };
};
