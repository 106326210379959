import styled from 'styled-components';

export const PhoneFieldWrapper = styled.div`
  direction: ltr;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: ${(props) => (props.disabled ? '#e9ecef' : '#fff')};

  &.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus-visible {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }
  }

  .PhoneInputInput {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .PhoneInputCountrySelect:disabled {
    opacity: 0 !important;
  }
`;
