import React from 'react';

import { detailedStatuses } from 'shared/const/detailedStatuses';
import { onBehalfOfs } from 'shared/const/onBehalfOfs';

import PendingTable from 'components/Admin/Contracts/PendingTable';

const PendingOnCompanyTable = ({ queryFn }) => {
  return (
    <PendingTable
      queryFn={queryFn}
      queryKey={'contractsLookup'}
      onBehalfOf={onBehalfOfs.COMPANY}
      detailedStatus={detailedStatuses.PENDING_ON_COMPANY}
    />
  );
};

export default PendingOnCompanyTable;
