import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

// eyal_todo: is this really needed? can't we just use the Button component from react-bootstrap?

const BtnWrapper = styled.div`
  button {
    background: ${(props) => (props.primary ? '#63a103' : 'none')};
    font-weight: 700;
    color: ${(props) => (props.primary ? '#ffffff' : '#63a103')};
    border: ${(props) => (props.primary ? '1px solid #639910' : '1px solid #d7d7d7')};
    &:hover {
      background: none !important;
      color: #63a103;
      border: 1px solid #639910;
    }
    &:active {
      background: none !important;
      color: #63a103;
      border: 1px solid #639910;
    }
    &:focus {
      background-color: #63a103;
      border-color: #63a103;
      box-shadow: 0 0 0 0.25rem rgb(199 247 141);
    }
    &:disabled {
      background-color: #63a103;
      border-color: #63a103;
    }
  }
`;
const StyleButton = ({ title, fn, type, size, primary, comp, disable }) => {
  return (
    <BtnWrapper className="d-grid" primary={primary}>
      <Button type={type} variant="primary" size={size ? size : 'lg'} disabled={disable} onClick={fn}>
        {comp} {title}{' '}
      </Button>
    </BtnWrapper>
  );
};

export default StyleButton;
