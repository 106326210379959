import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { finishQuickLogin } from 'shared/apis';
import { mobileOnlySchema } from 'shared/schemas/pinCodeValidation';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const SmsPinCodeModal = ({ show, redirectUrl, encryptedEmailAndSmsPinCode, onClose, resendPinCode, isResending }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContentContext);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(mobileOnlySchema),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = methods;

  //update staging

  const finishQuickLoginErrorHandling = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      switch (errorName) {
        case 'WRONG_SMS_CODE':
          setError('smsPinCode', { type: 'manual', message: t('components.PinCodesValidationModal.errors.WRONG_SMS_CODE') });
          break;
        default:
          addAlert(errorAlertBuilder.bug(error));
      }
    },
    [setError, addAlert, t],
  );

  const { mutate: finishQuickLoginMutation } = useMutation({
    mutationKey: 'finishQuickLogin',
    mutationFn: finishQuickLogin,
    onSuccess: () => {
      navigate(redirectUrl);
    },
    onError: (error) => {
      finishQuickLoginErrorHandling(error);
    },
  });

  const onSubmitSmsPinCode = (data) => {
    const { smsPinCode } = data;
    finishQuickLoginMutation({ encryptedEmailAndSmsPinCode, smsPinCode });
  };

  return (
    <>
      <Modal show={show} onHide={onClose} className="d-flex align-items-center" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('components.PinCodesValidationModal.title')}</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit(onSubmitSmsPinCode)} noValidate className="mt-2 max-w-[480px]">
          <Modal.Body>
            <Form.Group className="mb-4">
              <Form.Label>{t('components.PinCodesValidationModal.labels.smsPinCode')}</Form.Label>
              <Form.Control {...register('smsPinCode')} required isInvalid={errors.smsPinCode} style={{ direction: 'ltr' }} pattern="\d*" />
              <div className="d-flex align-items-center gap-1 text-muted-more">
                {t('components.PinCodesValidationModal.labels.didntRecieveSmsPinCode')}
                <Button variant="link" onClick={resendPinCode} tabIndex={4}>
                  {isResending ? <Spinner animation="border" /> : t('components.PinCodesValidationModal.links.sendAgain')}
                </Button>
              </div>
              <ErrorMessage
                errors={errors}
                name="smsPinCode"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-between">
            <Button variant="primary" type="submit">
              {/* {isLoginIn && <Spinner animation="border" />} */}
              {t('screens.authentication.actions.joinOrSignIn')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
