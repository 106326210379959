import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

export const localizeRole = (t, role) => {
  switch (role) {
    case contractRoles.SIGNATORY:
      return t('screens.company.labels.signatory');
    case contractRoles.EMPLOYEE:
      return t('screens.company.labels.employee');
    case contractRoles.COMPANY_ADMIN:
      return t('screens.company.labels.admin');
    default:
      return role;
  }
};

export const LocalizedRole = ({ role }) => {
  const { t } = useTranslation();
  return localizeRole(t, role);
};
