import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { ALERT_TYPES } from 'shared/const/alerts';
import { offerCreationRoutesForEachScreen, offerCreationRoutesOrderedArray } from 'shared/const/offerCreationStages';
import { isRouteAllowed } from 'shared/utils/isRouteAllowed';

import Stepper from 'components/UI/Stepper/Stepper';

export const OfferCreationSteps = ({ offer }) => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname.split('/')[4];
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();

  const showBlockedStageError = () => {
    addAlert({
      title: t('components.offerCreationNavigation.errors.offerNotReadyForStage.title'),
      description: t('components.offerCreationNavigation.errors.canofferNotReadyForStagenotReselectCompany.message'),
      type: ALERT_TYPES.ERROR,
    });
  };

  const handleSpecificStageClick = (route) => {
    const path = `/user/offers/create/${route}/${offer._id}`;
    if (route === offerCreationRoutesForEachScreen.COMPANY_SELECTION) {
      addAlert({
        title: t('components.offerCreationNavigation.errors.cannotReselectCompany.title'),
        description: t('components.offerCreationNavigation.errors.cannotReselectCompany.message'),
      });
    } else {
      navigate(path);
    }
  };

  const steps = offerCreationRoutesOrderedArray.map((route) => {
    const allowed = isRouteAllowed(offer, route);
    return {
      label: t(`components.OfferCreationNavigation.labels.${route}`),
      active: currentRoute === route,
      onClick: () => (allowed ? handleSpecificStageClick(route) : showBlockedStageError(route)),
      disabled: !allowed,
    };
  });

  return <Stepper steps={steps} />;
};
