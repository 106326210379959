import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { investorTypes } from 'shared/const/investorTypes';

import Selector from '../../../UI/Form/Selector/Selector';

export const InvestorTypeSelector = ({ fieldPath }) => {
  const { t } = useTranslation();

  const buildInvestorTypeOptions = useCallback(() => {
    return Object.values(investorTypes).map((investorType) => {
      return {
        value: investorType,
        label: t(`components.selectors.investorType.${investorType}`),
      };
    });
  }, [t]);

  const options = buildInvestorTypeOptions();

  return <Selector fieldPath={fieldPath} options={options} />;
};
