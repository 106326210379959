import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { AccreditedInvestorCertLi } from 'components/CompanyDetails/ViewMode/CompanyInfo/AccreditedInvestorCertLi';

export const CompanyInfo = () => {
  const { companyDetails, companyDetailsIsLoading, companyDetailsError } = useContext(CompanyDetailsContext);

  const { t } = useTranslation();

  if (companyDetailsIsLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (companyDetailsError) {
    /* error will be handled by the context */
    return null;
  }

  if (!companyDetails) {
    return (
      <div className="alert alert-danger" role="alert">
        {t('screens.company.errors.noCompany')}
      </div>
    );
  }

  return (
    <ul className="list-unstyled">
      <li className="my-[1rem]">
        {t('screens.company.labels.companyName')}: <span className="text-muted">{companyDetails?.name}</span>
      </li>
      <li className="my-[1rem]">
        {t('screens.company.labels.companyId')}: <span className="text-muted">{companyDetails?.bnNumber}</span>
      </li>
      <li className="my-[1rem]">
        {t('screens.company.labels.domain')}: <span className="text-muted">{companyDetails?.domain}</span>
      </li>
      <li className="my-[1rem]">
        {t('screens.company.labels.investorType')}:{' '}
        <span className="text-muted">{t(`components.selectors.investorType.${companyDetails?.investorType}`)}</span>
      </li>
      <AccreditedInvestorCertLi />
    </ul>
  );
};
