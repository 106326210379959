import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';
import { employeeSchema } from 'shared/schemas/company';

import ModalConfirm from 'components/UI/ModalConfirm';

import { NewEmployeeForm } from './NewEmployeeForm';

export const NewEmployeeFormModal = ({ append, getUsers, showEmployeeFormModal, setShowEmployeeFormModal }) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      personalId: '',
      role: contractRoles.EMPLOYEE,
    },
    resolver: yupResolver(employeeSchema),
  });

  const { t } = useTranslation();
  const { reset, trigger, getValues, setError } = methods;

  const validateUser = async () => {
    const isValid = await trigger(null, { shouldFocus: true });

    if (!isValid) {
      return false;
    }

    const users = getUsers();
    const duplicatedUser = users.find((user) => user.email === getValues().email);
    if (duplicatedUser) {
      setError('email', {
        type: 'manual',
        message: 'screens.company.form.errors.users.isUnique',
      });
      return false;
    }

    return true;
  };

  const onProceed = async () => {
    const isValid = await validateUser();
    if (!isValid) {
      return;
    }

    append(getValues());
    reset();
    setShowEmployeeFormModal(false);
  };

  return (
    <FormProvider {...methods}>
      <ModalConfirm
        show={showEmployeeFormModal}
        onClose={() => setShowEmployeeFormModal(false)}
        title={t('screens.company.labels.newUser')}
        content={<NewEmployeeForm />}
        proceed={t('screens.company.buttons.addUser')}
        onProceed={onProceed}
        variant="primary"
      />
    </FormProvider>
  );
};
