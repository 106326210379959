import { detailedStatuses } from 'shared/const/detailedStatuses';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import { rejectedColumns as Columns } from './columns';

const RejectedTable = ({ queryFn }) => {
  return (
    <TableQuery
      queryKey={'contractsLookup'}
      queryFn={queryFn}
      Columns={Columns}
      columnTPrefix={'screens.contracts.table.columns.'}
      defaultSort={{ 'employee.firstName': sortDirections.ASC }}
      filters={{ detailedStatus: detailedStatuses.REJECTED }}
      withExport={true}
    />
  );
};

export default RejectedTable;
