import { useTranslation } from 'react-i18next';

import { DividedAccountsFileErrorsModal } from '../DividedAccountsFileErrorsModal/DividedAccountsFileErrorsModal';

import { ExcelUploadControls } from './ExcelUploadControls/ExcelUploadControls';
import { SavedAccountsControls } from './SavedAccountsControls/SavedAccountsControls';

export const DividedAccountsUploader = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between align-items-center mb-3">
      <p className="text-sm">{t('components.dividedAccountsUploader.subHeader')}</p>
      <div className="flex gap-3">
        <SavedAccountsControls />
        <ExcelUploadControls />
      </div>

      <DividedAccountsFileErrorsModal />
    </div>
  );
};
