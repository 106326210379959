import * as yup from 'yup';

import { bankAccountProperties, bankAccountTypes } from 'shared/const/bankAccounts';
import { cashIsraelBanks, israelBanks } from 'shared/const/israelBanks';

const t = (param) => param; // for translation plugin

// Combined list of banks
const bankList = Object.keys(israelBanks);

export const baseSchema = yup.object().shape({
  [bankAccountProperties.bank]: yup
    .string()
    .oneOf(bankList, t('components.addBankAccountModal.errors.invalidBank'))
    .required(t('components.addBankAccountModal.errors.bank')),

  [bankAccountProperties.branchNumber]: yup.string().when(bankAccountProperties.bank, {
    is: (bank) => !!cashIsraelBanks[bank],
    then: (schema) =>
      schema
        .required(t('components.addBankAccountModal.errors.requiredBranchNumber'))
        .matches(/^\d+$/, t('components.addBankAccountModal.errors.invalidBranchNumber')),
    otherwise: (schema) => schema.nullable().matches(/^\d*$/, t('components.addBankAccountModal.errors.invalidBranchNumber')),
  }),
  [bankAccountProperties.accountNumber]: yup.string().matches(/^[0-9-./\s]*$/, t('components.addBankAccountModal.errors.invalidAccountNumber')),
});

export const schema = yup.object().shape({
  ...baseSchema.fields,
  accountType: yup.string().oneOf(Object.values(bankAccountTypes)).required(),
});
