import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { getUsersDataForCompanyForm } from 'shared/apis';

import { useDebounce } from 'hooks/useDebounce';

const emailSchema = yup.string().email().required();

export const useEmployeeEmail = (defaultState) => {
  // keeps track of the email value of each employee
  // on change of any of them, after debouncing, fetch the user's data from the database
  // if the user exists, populate the rest of the fields
  const { watch, getValues, setValue } = useFormContext();
  const watchRes = watch();

  const [previouslyFetchedEmails, setPreviouslyFetchedEmails] = useState(defaultState?.map((user) => user.email) || []);
  const [emailsToFetch, setEmailsToFetch] = useState([]);
  const [formerEmailState, setFormerEmailState] = useState([]);
  const debouncedEmailState = useDebounce(formerEmailState, 1000);

  const { data } = useQuery({
    queryKey: ['getUsersDataForCompanyForm', emailsToFetch],
    queryFn: () => getUsersDataForCompanyForm(emailsToFetch),
    enabled: some(emailsToFetch),
  });

  useEffect(() => {
    if (data) {
      data?.forEach((user) => {
        if (user) {
          const { firstName, lastName, email, mobile, personalId } = user;

          // find the index of the user in the form
          const index = getValues().users.findIndex((user) => {
            // user's emails are saved in lowercase
            return user.email.toLowerCase() === email;
          });

          // update the form with the user's data
          setValue(`users[${index}]`, { firstName, lastName, email, mobile, personalId, role: 'EMPLOYEE' });
        }
      });

      setEmailsToFetch([]);
      setPreviouslyFetchedEmails([...previouslyFetchedEmails, ...emailsToFetch]);
    }
  }, [data, emailsToFetch, previouslyFetchedEmails, getValues, setValue]);

  useEffect(() => {
    const currFormEmails = watchRes?.users?.map((user) => user.email);

    if (!isEqual(currFormEmails, formerEmailState)) {
      setFormerEmailState(currFormEmails);
    }
  }, [watchRes, getValues, formerEmailState]);

  useEffect(() => {
    if (debouncedEmailState?.length > 0) {
      // check which emails are new
      const newEmails = debouncedEmailState
        .filter((email) => !previouslyFetchedEmails.includes(email))
        // check if the email is valid
        .filter((email) => emailSchema.isValidSync(email));

      if (some(newEmails)) {
        setEmailsToFetch(newEmails);
      }
    }
  }, [debouncedEmailState, previouslyFetchedEmails]);
};
