import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils } from 'hooks/offers';

const ScrollToSignAlert = () => {
  const { userId } = useContext(AuthContext);
  const { offer } = useContext(OfferCreationContext);
  const [userSigned, setUserSigned] = useState(false);
  const { t } = useTranslation();
  const { userHasSigned } = useOfferStateUtils({ offer });

  useEffect(() => {
    if (!offer) return;

    setUserSigned(userHasSigned);
  }, [offer, userId, userHasSigned]);

  if (userSigned) return null;

  return (
    <a href={`#${userId}`} className="w-100">
      <Alert variant="primary" className="text-center !text-decoration-none">
        <span className="text-link">
          {t('components.ScrollToSignAlert.scroll')} <FontAwesomeIcon icon={faArrowDown} />
        </span>
      </Alert>
    </a>
  );
};

export default ScrollToSignAlert;
