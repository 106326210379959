import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { reportWebVitals } from 'reportWebVitals';

import { AlertContentContextProvider } from 'context/Alert';
import { AuthContextProvider } from 'context/Auth';
import { LanguageContextProvider } from 'context/Language';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/tailwind-build.css';

import { App } from './App';
import './i18n';
import './shared/utils/numbro.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AlertContentContextProvider>
        <AuthContextProvider>
          <LanguageContextProvider>
            <App />
          </LanguageContextProvider>
        </AuthContextProvider>
      </AlertContentContextProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
