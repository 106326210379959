import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { LocalizedRole } from 'components/common/LocalizedRole';

const Columns = [
  {
    Header: 'companyName',
    accessor: 'companyName',
    Cell: (row) => {
      return <Link to={`/admin/companies/${row._id}`}>{row.companyName}</Link>;
    },
  },
  {
    Header: 'bnNumber',
    accessor: 'bnNumber',
  },
  {
    Header: 'role',
    accessor: 'role',
    noSort: true,
    Cell: (row) => {
      return <LocalizedRole role={row?.role} />;
    },
  },
  {
    Header: 'isMandatorySignatory',
    accessor: 'isMandatorySignatory',
    noSort: true,
    Cell: (row) => {
      return row?.isMandatorySignatory ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />;
    },
  },
];

export default Columns;
