import { useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { calculateLimits } from 'shared/utils/offers';

import { useScopeAndAccountsErrors } from 'hooks/offers';

import { checkGap } from '../utils/gaps';

export const useBidInputValidation = ({ fieldName, inputType }) => {
  const { watch } = useFormContext();
  const { offer } = useContext(OfferCreationContext);
  const { type, biddingLimitations, unitStructures } = offer?.initiation.auctionDetails || {};
  const { priceGap, minUnitsPerOrder } = biddingLimitations || {};
  const { minPrice, maxInterest } = useMemo(() => calculateLimits(biddingLimitations, unitStructures), [biddingLimitations, unitStructures]);
  const { t } = useTranslation();
  const inputValue = watch(fieldName);
  const { setError, clearError, clearAllFieldErrors } = useScopeAndAccountsErrors(fieldName);

  useEffect(() => {
    if (inputType !== 'limit') return;

    const limitIsNotAccordingToGapErrorMsg = t('screens.OrderScopeAndAccounts.form.errors.unitPrice.gap');
    const priceBelowMinErrorMsg = t('screens.OrderScopeAndAccounts.form.errors.unitPrice.minPrice');
    const interestAboveMaxErrorMsg = t('screens.OrderScopeAndAccounts.form.errors.unitPrice.maxInterest');

    /* remove error for empty input. it dose not trigger unnecessary rerenders. 'at least one valid bid' validation works anyway*/
    if (!inputValue && inputValue !== 0) {
      clearAllFieldErrors();
      return;
    }

    /* gap */
    if (priceGap) {
      const isValidGap = checkGap(inputValue, priceGap, type, { maxInterest, minPrice });
      isValidGap ? clearError(limitIsNotAccordingToGapErrorMsg) : setError(limitIsNotAccordingToGapErrorMsg);
    }

    /* min price */
    if (type === auctionTypes.PRICE) {
      const priceBelowMin = inputValue < minPrice;
      priceBelowMin ? setError(priceBelowMinErrorMsg) : clearError(priceBelowMinErrorMsg);
    }

    /* max interest */
    if ((type === auctionTypes.INTEREST || type === auctionTypes.GAP) && maxInterest) {
      const interestAboveMax = inputValue > maxInterest;
      interestAboveMax ? setError(interestAboveMaxErrorMsg) : clearError(interestAboveMaxErrorMsg);
    }
  }, [inputValue, priceGap, maxInterest, minPrice, setError, clearError, clearAllFieldErrors, type, inputType, t]);

  /* validate 'amount' (limitations: min amount) */
  useEffect(() => {
    if (inputType !== 'amount') return;

    const amountIsBelowMinErrorMsg = t('screens.OrderScopeAndAccounts.form.errors.unitAmount.min');
    const amountIsNotAnIntegerErrorMsg = t('screens.OrderScopeAndAccounts.form.errors.unitAmount.integer');

    /* empty input, but it dose not trigger unnecessary rerenders, and there is 'at least one valid bid' validation anyway */
    if (!inputValue && inputValue !== 0) {
      clearAllFieldErrors();
      return;
    }

    if (!Number.isInteger(inputValue)) {
      setError(amountIsNotAnIntegerErrorMsg);
    } else {
      clearError(amountIsNotAnIntegerErrorMsg);
    }

    /* min amount */
    const amountIsBelowMin = inputValue < minUnitsPerOrder;
    amountIsBelowMin ? setError(amountIsBelowMinErrorMsg) : clearError(amountIsBelowMinErrorMsg);
  }, [inputValue, minUnitsPerOrder, setError, clearError, clearAllFieldErrors, inputType, t]);
};
