import { Container } from 'react-bootstrap';

import { NotificationsBox } from 'components/UI/Notifications';

export const NotificationsScreen = () => {
  return (
    <Container className="mt-5">
      <NotificationsBox />
    </Container>
  );
};
