Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'align-left';
var width = 25;
var height = 25;
var aliases = [];
var unicode = 'f036';
var svgPathData =
  'M8.06541 16.1478C7.90541 16.1478 7.74441 16.0968 7.60841 15.9928C7.28041 15.7398 7.21841 15.2688 7.47141 14.9408L10.4644 11.0508C10.5864 10.8918 10.7674 10.7888 10.9654 10.7638C11.1674 10.7378 11.3654 10.7938 11.5224 10.9188L14.3424 13.1338L16.8094 9.9508C17.0634 9.6218 17.5334 9.5608 17.8614 9.8168C18.1894 10.0708 18.2494 10.5418 17.9954 10.8688L15.0654 14.6488C14.9434 14.8068 14.7634 14.9098 14.5654 14.9338C14.3654 14.9608 14.1674 14.9028 14.0094 14.7798L11.1914 12.5658L8.66041 15.8548C8.51241 16.0468 8.29041 16.1478 8.06541 16.1478ZM20.8165 3.6449C20.1705 3.6449 19.6445 4.1699 19.6445 4.8169C19.6445 5.4629 20.1705 5.9899 20.8165 5.9899C21.4625 5.9899 21.9885 5.4629 21.9885 4.8169C21.9885 4.1699 21.4625 3.6449 20.8165 3.6449ZM20.8165 7.4899C19.3435 7.4899 18.1445 6.2909 18.1445 4.8169C18.1445 3.3429 19.3435 2.1449 20.8165 2.1449C22.2905 2.1449 23.4885 3.3429 23.4885 4.8169C23.4885 6.2909 22.2905 7.4899 20.8165 7.4899ZM17.0821 22.848H8.47812C5.11112 22.848 2.84912 20.483 2.84912 16.963V8.881C2.84912 5.356 5.11112 2.987 8.47812 2.987H15.7461C16.1601 2.987 16.4961 3.323 16.4961 3.737C16.4961 4.151 16.1601 4.487 15.7461 4.487H8.47812C5.97012 4.487 4.34912 6.211 4.34912 8.881V16.963C4.34912 19.668 5.93112 21.348 8.47812 21.348H17.0821C19.5901 21.348 21.2111 19.627 21.2111 16.963V9.924C21.2111 9.51 21.5471 9.174 21.9611 9.174C22.3751 9.174 22.7111 9.51 22.7111 9.924V16.963C22.7111 20.483 20.4491 22.848 17.0821 22.848Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faActivity = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
