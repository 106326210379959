import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';

const UrlStateParams = {
  isActive: 'isActive',
};

const UrlStateConfig = {
  [UrlStateParams.isActive]: withDefault(BooleanParam, true),
};

export const useIsActive = () => useQueryParam(UrlStateParams.isActive, UrlStateConfig[UrlStateParams.isActive]);
