import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

import NewRoleFormModal from './NewRoleFormModal';

const UserRoles = () => {
  const { t } = useTranslation();
  const [showRoleFormModal, setShowRoleFormModal] = useState(false);
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles',
  });

  const localizeRole = (role) => {
    switch (role) {
      case contractRoles.SIGNATORY:
        return t('screens.company.labels.signatory');
      case contractRoles.EMPLOYEE:
        return t('screens.company.labels.employee');
      case contractRoles.COMPANY_ADMIN:
        return t('screens.company.labels.admin');
      default:
        return role;
    }
  };

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>{t('screens.user.table.columns.bnNumber')}</th>
            <th>{t('screens.user.table.columns.companyName')}</th>
            <th>{t('screens.user.table.columns.role')}</th>
            <th>{t('screens.user.table.columns.isMandatorySignatory')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            return (
              <tr key={index}>
                <td>{getValues(`roles.${index}.bnNumber`)}</td>
                <td>{getValues(`roles.${index}.companyName`)}</td>
                <td>{localizeRole(getValues(`roles.${index}.role`))}</td>
                <td>{getValues(`roles.${index}.isMandatorySignatory`) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTrash} />}</td>
                <td>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="d-flex justify-center">
        <Button type="button" variant="primary" onClick={() => setShowRoleFormModal(true)}>
          {t('screens.user.buttons.addRole')}
        </Button>

        <NewRoleFormModal
          append={append}
          getRoles={() => getValues('roles')}
          showRoleFormModal={showRoleFormModal}
          setShowRoleFormModal={setShowRoleFormModal}
        />
      </div>
    </>
  );
};

export default UserRoles;
