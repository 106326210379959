import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneNumberUtil from 'libphonenumber-js';
import partition from 'lodash/partition';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { extractSignatories, userHasSigned } from 'shared/utils/offers';

import Box from 'components/UI/Box';

const SignatoriesTrackingBoard = () => {
  const { offer } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);

  const buildSignatoryRow = useCallback(
    (signatory) => {
      const isMe = signatory.userId === userId;
      const fullName = `${signatory.firstName} ${signatory.lastName}`;
      const formattedName = isMe ? `${t('common.me')} (${fullName})` : fullName;
      const formattedMobile = `(${PhoneNumberUtil(signatory.mobile).formatNational()})`;
      const signatoryHasSigned = userHasSigned(offer, signatory.userId);

      return (
        <div key={signatory.userId} className={`${!signatoryHasSigned ? 'text-[#C9841C]' : null}`}>
          {signatoryHasSigned ? (
            <FontAwesomeIcon icon={faCheck} className="text-[#6EB244]" />
          ) : (
            <FontAwesomeIcon icon={faClock} className="text-[#C9841C]" />
          )}{' '}
          {formattedName} {isMe ? '' : formattedMobile}
        </div>
      );
    },
    [offer, t, userId],
  );

  const [mandatorySignatoriesRows, additionalSignatoriesRows] = useMemo(() => {
    if (!offer) return [[], []];
    const signatories = extractSignatories(offer);
    const [mandatorySignatories, additionalSignatories] = partition(signatories, (employee) => employee.isMandatorySignatory);

    const mandatorySignatoriesRows = mandatorySignatories.map(buildSignatoryRow);
    const additionalSignatoriesRows = additionalSignatories.map(buildSignatoryRow);

    return [mandatorySignatoriesRows, additionalSignatoriesRows];
  }, [offer, buildSignatoryRow]);

  const mandatorySignatoriesLength = useMemo(() => mandatorySignatoriesRows?.length, [mandatorySignatoriesRows]);
  const additionalSignatoriesLength = useMemo(() => additionalSignatoriesRows?.length, [additionalSignatoriesRows]);
  const hasBoth = useMemo(
    () => !!mandatorySignatoriesLength && !!additionalSignatoriesLength,
    [mandatorySignatoriesLength, additionalSignatoriesLength],
  );

  const MandatorySignatoriesHeader = useCallback(() => {
    if (!mandatorySignatoriesLength) return null;
    const header =
      mandatorySignatoriesLength > 1
        ? t('components.SignatoriesTable.labels.mandatorySignatories')
        : t('components.SignatoriesTable.labels.mandatorySignatory');

    return <h2 className="font-semibold">{header}</h2>;
  }, [mandatorySignatoriesLength, t]);

  const AdditionalSignatoriesHeader = useCallback(() => {
    if (!additionalSignatoriesLength) return null;
    const header =
      additionalSignatoriesLength > 1
        ? t('components.SignatoriesTable.labels.additionalSignatories')
        : t('components.SignatoriesTable.labels.signatories');

    return <h2 className="font-semibold">{header}</h2>;
  }, [additionalSignatoriesLength, t]);

  //JSX
  return (
    <div>
      <Box>
        <div className="flex flex-col min-w-[300px] p-2 gap-2">
          <MandatorySignatoriesHeader />
          {mandatorySignatoriesRows}

          {hasBoth && <hr className="my-4" />}

          <AdditionalSignatoriesHeader />
          {additionalSignatoriesRows}
        </div>
      </Box>
    </div>
  );
};

export default SignatoriesTrackingBoard;
