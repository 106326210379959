import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { initPinCodesAuth, initQuickAuth, resendPinCode } from 'shared/apis';
import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { sendGAEvent } from 'hooks/useGoogleAnalytics';

export const useInitTFASubmission = ({ setShow, onHide, onInitiationError, mobileOnly }) => {
  const { email, mobile, personalId } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);
  const [isLoading, setIsLoading] = useState(null);
  const [encryptedTFASubmission, setEncryptedTFASubmission] = useState(null);

  //INIT PIN CODES AUTHENTICATION REQUEST
  const errorHandlingForInitPinCodes = (error) => {
    sendGAEvent(GAEventNames.INIT_PIN_CODES_VALIDATION, GAEventStages.ERROR, { email, mobile, personalId });
    /** this hook is a pipeline, some times the hook caller wants to handle some of the
     * pipeline possible errors, here we let the caller handle errors about the pin codes initiation
     * in a custom way
     */
    if (onInitiationError) {
      onInitiationError(error);
    } else {
      addAlert(errorAlertBuilder.bug(error));
    }
    onHide(false);
  };

  const { mutate: initPinCodesAuthMutation, isPending: initPinCodesAuthIsLoading } = useMutation({
    mutationKey: mobileOnly ? 'initQuickAuth' : 'initPinCodesAuth',
    mutationFn: mobileOnly ? initQuickAuth : initPinCodesAuth,
    onSuccess: ({ encryptedTFASubmission }) => {
      setEncryptedTFASubmission(encryptedTFASubmission);
      setShow(true);
      sendGAEvent(GAEventNames.INIT_PIN_CODES_VALIDATION, GAEventStages.SUCCESS, { email, mobile, personalId });
    },
    onError: errorHandlingForInitPinCodes,
  });

  const initPinCodes = useCallback(() => {
    const neededValuesLoaded = !!email && !!mobile && !!personalId;

    // if the needed values are not loaded yet, we do not want to run the mutation
    // if the mutation is already running, we do not want to run it again
    // if the user already submitted the pin codes successfully, we do not want to rerun the mutation
    if (!neededValuesLoaded || isLoading || encryptedTFASubmission) return;

    const expectedPayload = {
      email,
      mobile,
      personalId,
    };

    initPinCodesAuthMutation(expectedPayload);
  }, [initPinCodesAuthMutation, encryptedTFASubmission, isLoading, email, mobile, personalId]);

  //RESEND PIN CODE REQUEST
  const errorHandlingResendPinCode = useCallback(
    (error) => {
      if (onInitiationError) {
        onInitiationError(error);
      } else {
        addAlert(errorAlertBuilder.bug(error));
      }
    },
    [addAlert, onInitiationError],
  );
  const { mutate: resendPinCodeMutation, isPending: isResendingPinCode } = useMutation({
    mutationKey: 'resendPinCode',
    mutationFn: resendPinCode,
    onSuccess: ({ encryptedTFASubmission }) => {
      setEncryptedTFASubmission(encryptedTFASubmission);
    },
    onError: errorHandlingResendPinCode,
  });

  const resendPinCodeCallback = useCallback(
    async (type) => {
      const expectedPayload = {
        encryptedTFASubmission,
        type,
      };
      resendPinCodeMutation(expectedPayload);
    },
    [resendPinCodeMutation, encryptedTFASubmission],
  );

  //ADDITIONAL HOOKS
  /** update component is loading */
  useEffect(() => {
    const isThereMutationThatsLoading = initPinCodesAuthIsLoading || isResendingPinCode;
    setIsLoading(isThereMutationThatsLoading);
  }, [initPinCodesAuthIsLoading, isResendingPinCode]);

  return {
    initPinCodes,
    resendPinCode: resendPinCodeCallback,
    isLoading,
    encryptedTFASubmission,
  };
};
