import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { offerCreationRoutesOrderedArray, offerCreationRoutesForEachScreen as screens } from 'shared/const/offerCreationStages';

import { useOfferStateUtils } from './useOfferStateUtils';

export const useOfferCreationNavigationUtils = () => {
  const navigate = useNavigate();
  const url = useLocation();
  const { t } = useTranslation();
  const { addAlert } = useContext(AlertContentContext);
  const { offer, scopeAndAccountsErrors } = useContext(OfferCreationContext);
  const { userShouldQuickSend, userCanQuickSend, userCanSend, isOfferComplete, allowSendingWithMissingSignatures, isUserOfferInitiator } =
    useOfferStateUtils({ offer });

  const route = url.pathname.split('/')[4];

  const isScopesAndAccountsValid = useMemo(() => {
    return Object.values(scopeAndAccountsErrors).every((fieldErrorsArray) => !fieldErrorsArray?.length);
  }, [scopeAndAccountsErrors]);

  const shouldProceed = () => {
    switch (route) {
      case screens.SCOPE_AND_ACCOUNTS:
        return isScopesAndAccountsValid;
      case screens.LIMITATIONS_AND_CONTACTS:
        return true;
      case screens.SIGNATURES:
        if (userShouldQuickSend) {
          return userCanQuickSend;
        }
        return true;
      case screens.SENDING:
        return userCanSend || allowSendingWithMissingSignatures;
      default:
        return false;
    }
  };

  const getPreviousStage = useCallback(() => {
    const currentRouteIndex = offerCreationRoutesOrderedArray.findIndex((stage) => stage === route);
    if (currentRouteIndex < 1) {
      addAlert({
        title: t('components.offerCreationNavigation.errors.cannotGoBack.title'),
        description: t('components.offerCreationNavigation.errors.cannotGoBack.message'),
      });
      return null;
    }
    return offerCreationRoutesOrderedArray[currentRouteIndex - 1];
  }, [t, addAlert, route]);

  const onPrevClick = useCallback(() => {
    const previousStage = getPreviousStage();
    if (!previousStage) {
      return;
    }
    const path = `/user/offers/create/${previousStage}/${offer._id}`;
    navigate(path);
  }, [offer, navigate, getPreviousStage]);

  const proceedButtonText = useMemo(() => {
    switch (route) {
      case screens.SENDING:
        if (isOfferComplete) {
          return t('common.backToMyOffers');
        } else if (allowSendingWithMissingSignatures) {
          return t('components.OfferCreationNavigation.labels.sendWithoutSignatures');
        }
        return t('components.OfferCreationNavigation.labels.sending');
      case screens.SIGNATURES:
        return userShouldQuickSend
          ? t('components.OfferCreationNavigation.labels.sending')
          : t('components.OfferCreationNavigation.labels.signatures');
      default:
        return t('common.next');
    }
  }, [t, route, userShouldQuickSend, isOfferComplete, allowSendingWithMissingSignatures]);

  const proceedButtonColor = useMemo(() => {
    const DEFAULT_COLOR = 'bg-green-400';
    switch (route) {
      case screens.SENDING:
        return allowSendingWithMissingSignatures ? 'bg-warning' : DEFAULT_COLOR;
      default:
        return DEFAULT_COLOR;
    }
  }, [route, allowSendingWithMissingSignatures]);

  const hidePrevButton = route === screens.SCOPE_AND_ACCOUNTS;
  const showInitiatorReviewCheckBox = route === screens.SIGNATURES && !userShouldQuickSend;

  return {
    route,
    disabled: !shouldProceed(),
    hidePrevButton,
    onPrevClick,
    proceedButtonText,
    proceedButtonColor,
    showInitiatorReviewCheckBox,
    showNavigationBar: isUserOfferInitiator,
  };
};
