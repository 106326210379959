import { faEye, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { useMutation } from '@tanstack/react-query';
import { useContext, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { uploadAccreditedInvestorCert } from 'shared/apis';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { Tooltip } from 'components/UI/Tooltip';

// file upload
export const AccreditedInvestorCertSelector = ({ fieldPath }) => {
  const { addAlert } = useContext(AlertContentContext);

  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const companyId = watch('companyId');
  const fileName = watch(`${fieldPath}FileName`);
  const expiryDate = watch(`${fieldPath}ExpiryDate`);
  const url = watch(`${fieldPath}Url`);
  const [originalFileName, setOriginalFileName] = useState(fileName);

  const { mutate, isPending } = useMutation({
    mutationFn: uploadAccreditedInvestorCert,
    mutationKey: 'uploadAccreditedInvestorCert',
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
      // reset the value of the field
      fileInputRef.current.value = '';
    },
    onSuccess: (data) => {
      const { presignedUrl, fileNameOnCloudStorage } = data;
      setValue(`${fieldPath}Key`, fileNameOnCloudStorage, { shouldDirty: true });
      setValue(`${fieldPath}FileName`, originalFileName, { shouldDirty: true });
      setValue(`${fieldPath}Url`, presignedUrl, { shouldDirty: true });
    },
  });

  // on file change, we immidiately upload the file to the server, and store the url in the form
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setOriginalFileName(file.name);

    mutate({
      companyId,
      accreditedInvestorCert: file,
    });
  };

  const handleRemoveFile = () => {
    setValue(fieldPath, null);
    setValue(`${fieldPath}FileName`, null, { shouldDirty: true });
    setValue(`${fieldPath}Url`, null, { shouldDirty: true });
    setValue(`${fieldPath}Key`, null, { shouldDirty: true });
  };

  const handleRemoveAll = () => {
    handleRemoveFile();
    setValue(`${fieldPath}ExpiryDate`, null, { shouldDirty: true });
  };

  return (
    <div className="flex gap-3 align-items-end">
      {isPending && <Spinner animation="border" />}

      <Form.Group controlId="accreditedInvestorCertKey">
        <Form.Label>{t('screens.company.labels.accreditedInvestorCert')}</Form.Label>
        {url && (
          <div className="mb-[0.4rem]">
            <Link to={url} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faEye} className="text-success" />
              {originalFileName}
            </Link>
          </div>
        )}
        {!url && <Form.Control type="file" onChange={handleChange} isInvalid={errors[fieldPath]} ref={fileInputRef} accept=".pdf" />}
        <ErrorMessage
          errors={errors}
          name="accreditedInvestorCertKey"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      {url && <Button onClick={handleRemoveFile}>{t('screens.company.labels.removeFile')}</Button>}

      <Form.Group className="grow" controlId="accreditedInvestorCertExpiryDate">
        <Form.Label>{t('screens.company.labels.expiryDate')}</Form.Label>
        <span className="flex gap-2">
          <Form.Control type="date" {...register(`${fieldPath}ExpiryDate`)} isInvalid={errors[`${fieldPath}ExpiryDate`]} />
          <Tooltip Content={<div>{t('screens.company.tooltips.expiryDate')}</div>} Trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
        </span>
        <ErrorMessage
          errors={errors}
          name={`${fieldPath}ExpiryDate`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      {(fileName || expiryDate) && (
        <Button variant="danger" onClick={handleRemoveAll}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
    </div>
  );
};
