import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import { AuthContext } from 'context/Auth';

import { systemRoles } from 'shared/const/systemRoles';

const AdminLandingScreen = () => {
  const { t } = useTranslation();
  const { systemRole, firstName, lastName } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!systemRole === systemRoles.SB_ADMIN) {
      navigate(location?.state?.from ? location.state.from : '/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemRole]);

  return (
    <div>
      <p className="my-4 mt-5 text-center text-[32px] text-bold text-[#333333]">{t('screens.AdminLandingScreen.welcome2')}</p>
      <p className="my-4 mt-5 text-center text-[20px] text-[#333333]">
        {firstName} {lastName} : admin
      </p>
    </div>
  );
};

export default AdminLandingScreen;
