Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'align-left';
var width = 26;
var height = 26;
var aliases = [];
var unicode = 'f036';
var svgPathData =
  'M5.94507 0.542969C5.21572 0.542969 4.51625 0.8327 4.00052 1.34843C3.4848 1.86415 3.19507 2.56362 3.19507 3.29297V4.85084L1.90119 5.54109C1.4613 5.77564 1.09345 6.12536 0.836963 6.55283C0.580479 6.9803 0.445019 7.46945 0.445068 7.96797V19.793C0.445068 20.5223 0.7348 21.2218 1.25052 21.7375C1.76625 22.2532 2.46572 22.543 3.19507 22.543H19.6951C20.4244 22.543 21.1239 22.2532 21.6396 21.7375C22.1553 21.2218 22.4451 20.5223 22.4451 19.793V7.96797C22.4451 7.46945 22.3097 6.9803 22.0532 6.55283C21.7967 6.12536 21.4288 5.77564 20.9889 5.54109L19.6951 4.85084V3.29297C19.6951 2.56362 19.4053 1.86415 18.8896 1.34843C18.3739 0.8327 17.6744 0.542969 16.9451 0.542969L5.94507 0.542969ZM19.6951 6.41009L20.3413 6.75384C20.5615 6.87108 20.7456 7.04601 20.874 7.25988C21.0024 7.47376 21.0702 7.71852 21.0701 7.96797V9.09134L19.6951 9.91634V6.41009ZM18.3201 10.7413L13.1638 13.8351L11.4451 12.8038L9.72632 13.8351L4.57007 10.7413V3.29297C4.57007 2.9283 4.71493 2.57856 4.9728 2.3207C5.23066 2.06283 5.5804 1.91797 5.94507 1.91797H16.9451C17.3097 1.91797 17.6595 2.06283 17.9173 2.3207C18.1752 2.57856 18.3201 2.9283 18.3201 3.29297V10.7413ZM3.19507 9.91634L1.82007 9.09134V7.96797C1.82023 7.71876 1.88813 7.47428 2.01649 7.26067C2.14485 7.04706 2.32885 6.87235 2.54882 6.75522L3.19507 6.41009V9.91634ZM21.0701 10.6946V18.5788L14.5003 14.6367L21.0701 10.6946ZM21.0219 20.1546C20.9426 20.4457 20.7697 20.7026 20.5299 20.8857C20.2901 21.0689 19.9968 21.168 19.6951 21.168H3.19507C2.89318 21.1679 2.59968 21.0686 2.35988 20.8852C2.12007 20.7018 1.94729 20.4446 1.86819 20.1532L11.4451 14.4071L21.0219 20.1546ZM1.82007 18.5775V10.6946L8.38982 14.6367L1.82007 18.5775Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faEnvelopePaper = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
