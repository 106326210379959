import PropTypes from 'prop-types';
import { Badge, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink as Link } from 'react-router-dom';

const NavLink = ({ linkTo, label, comingSoon, isLocked, end }) => {
  const { t } = useTranslation();
  return (
    <>
      <Nav.Link className="d-flex whitespace-nowrap" as={Link} to={linkTo} disabled={comingSoon || isLocked} end={end}>
        {label}
        {`${isLocked ? '🔒' : ''}`}
        {comingSoon ? (
          <Badge className="ms-1" bg="light">
            {t(`components.NavigationBar.labels.comingSoon`)}
          </Badge>
        ) : (
          ''
        )}
      </Nav.Link>
    </>
  );
};

export default NavLink;

NavLink.defaultProps = {
  comingSoon: null,
  isLocked: false,
  end: false,
};

NavLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  comingSoon: PropTypes.bool,
  isLocked: PropTypes.bool,
  end: PropTypes.bool,
};
