import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import numbro from 'numbro';

import { getFormattedMaxPercentageFromAuction } from 'shared/utils/auctions';

const INVALID_MULTI_LIMITATION_NOTATION = '?';

export const getDistinct = (data, key) => {
  return uniq(data.map((d) => get(d, key)));
};

const getAccountLimitation = (accountData) => {
  const maxPercentageFromAuctions = getDistinct(accountData, 'maxPercentageFromAuction');

  let limitation;
  if (maxPercentageFromAuctions.length > 1) {
    limitation = INVALID_MULTI_LIMITATION_NOTATION;
  } else {
    limitation = getFormattedMaxPercentageFromAuction(maxPercentageFromAuctions[0]);
  }

  return limitation;
};

const findBankAccountMismatch = (accountData) => {
  const cashAccountNumbers = getDistinct(accountData, 'selectedCashAccount.accountNumber');
  const cashBanks = getDistinct(accountData, 'selectedCashAccount.bank');
  const cashBranches = getDistinct(accountData, 'selectedCashAccount.branch');
  const sharesAccountNumbers = getDistinct(accountData, 'selectedSharesAccount.accountNumber');
  const sharesBanks = getDistinct(accountData, 'selectedSharesAccount.bank');
  const sharesBranches = getDistinct(accountData, 'selectedSharesAccount.branch');

  // if any of them has more than one distinct value, there is a mismatch
  const mismatch =
    cashAccountNumbers.length > 1 ||
    cashBanks.length > 1 ||
    cashBranches.length > 1 ||
    sharesAccountNumbers.length > 1 ||
    sharesBanks.length > 1 ||
    sharesBranches.length > 1;

  return mismatch;
};

const buildAccountErrors = (accountData, t) => {
  const errors = [];
  const limitation = getAccountLimitation(accountData, errors, t);
  if (limitation === INVALID_MULTI_LIMITATION_NOTATION) {
    errors.push(t('components.SplitConfirmModal.table.multipleLimitsError'));
  }

  const bankMismatch = findBankAccountMismatch(accountData);
  if (bankMismatch) {
    errors.push(t('components.SplitConfirmModal.table.bankAccountMismatchError'));
  }

  return { limitation, errors };
};

const groupTableByAccountName = (table) => {
  const trimmedTable = table.map((row) => ({
    ...row,
    accountName: row.accountName.trim(),
  }));
  const groupedByAccount = groupBy(trimmedTable, 'accountName');
  return groupedByAccount;
};

const buildTableData = (groupedByAccount, t) => {
  const tableData = Object.entries(groupedByAccount).map(([accountName, accountData]) => {
    const totalAmount = accountData.reduce((acc, { amount }) => acc + amount, 0);
    const { limitation, errors } = buildAccountErrors(accountData, t);

    return {
      accountName,
      totalAmount,
      accountData,
      limitation,
      errors,
    };
  });

  return tableData;
};

export const getTableData = (data, t) => {
  const { table } = data;
  const groupedByAccount = groupTableByAccountName(table);
  const tableData = buildTableData(groupedByAccount, t);
  return tableData;
};

export const getFormattedAmountForLimit = (bid, accountData) => {
  const { limit } = bid;
  const amountForLimit = accountData.find((data) => data.limit === limit)?.amount;
  const formattedAmountForLimit = amountForLimit ? numbro(amountForLimit).format('0,0') : '';
  return formattedAmountForLimit;
};

export const getFormattedTotalAmountForLimit = (tableData, bid) => {
  const totalAmountForLimit = tableData.reduce((acc, { accountData }) => {
    const amountForLimit = accountData.find((data) => data.limit === bid.limit)?.amount || 0;
    return acc + amountForLimit;
  }, 0);

  const formattedTotalAmountForLimit = totalAmountForLimit ? numbro(totalAmountForLimit).format('0,0') : '';
  return formattedTotalAmountForLimit;
};
