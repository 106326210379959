import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MOSADI_AUCTION_DATE_OPTIONS_VALUES, PUBLIC_OFFERINGS_FILTERS } from '../consts';

import { PublicOfferingFilter } from './PublicOfferingFilter';

export const MosadiAuctionDateFilter = () => {
  const { t } = useTranslation();

  const staticOptions = useMemo(() => {
    const thisYear = dayjs().year();
    const lastYear = dayjs().subtract(1, 'year').year();
    return [
      { label: t('screens.publicOfferings.mosadiAuctionDate.options.twelveMonths'), value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.LAST_TWELVE_MONTHS },
      { label: t('screens.publicOfferings.mosadiAuctionDate.options.sixMonths'), value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.LAST_SIX_MONTHS },
      { label: t('screens.publicOfferings.mosadiAuctionDate.options.threeMonths'), value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.LAST_THREE_MONTHS },
      { label: t('screens.publicOfferings.mosadiAuctionDate.options.month'), value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.LAST_MONTH },
      {
        label: `${t('screens.publicOfferings.mosadiAuctionDate.options.startOfYear')} (${thisYear})`,
        value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.START_OF_THIS_YEAR,
      },
      {
        label: `${t('screens.publicOfferings.mosadiAuctionDate.options.startOfLastYear')} (${lastYear}) `,
        value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.START_OF_LAST_YEAR,
      },
      { label: t('screens.publicOfferings.mosadiAuctionDate.options.all'), value: MOSADI_AUCTION_DATE_OPTIONS_VALUES.ALL },
    ];
  }, [t]);

  return <PublicOfferingFilter filterKey={PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE} staticOptions={staticOptions} />;
};
