import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import MainBrowserRouter from 'routers';
import { useOpenReplay } from 'services/openreplay.js';

import { NotificationContextProvider } from 'context/Notifications';

import { useAxiosCustomizations } from 'shared/axios.js';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics.js';

import { useSockets } from './services/socket.js';

export const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContextProvider>
        <Inner />
      </NotificationContextProvider>
    </QueryClientProvider>
  );
};

const Inner = () => {
  const { setAxiosListeners } = useAxiosCustomizations();
  setAxiosListeners();
  useOpenReplay();
  useSockets();
  useGoogleAnalytics();

  return (
    <>
      <MainBrowserRouter />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};
