import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const InstructionsTooltip = () => {
  const { t } = useTranslation();

  const TooltipTrigger = <FontAwesomeIcon icon={faInfoCircle} className="text-gray-600 cursor-pointer" />;

  return (
    <Tooltip
      Content={t('components.DividedAccountsInputTable.SavedAccountsControls.instructions')}
      Trigger={TooltipTrigger}
      position="bottom"
      wrapperClassName="inline-block ms-1"
    />
  );
};
