import numbro from 'numbro';

const getAllocationRate = (publicOffering, winningOfferUnits) => {
  const mosadiClosingUnits = publicOffering.stages.mosadi.closing.units;
  if (!winningOfferUnits || !mosadiClosingUnits) {
    return 0;
  } else {
    return winningOfferUnits / mosadiClosingUnits;
  }
};

export const getAllocationRateForDisplay = (publicOffering, winningOfferUnits) => {
  const allocationRate = getAllocationRate(publicOffering, winningOfferUnits);
  return numbro(allocationRate).format('0.00%');
};
