import { Route } from 'react-router-dom';

import { OfferCreationContextProvider } from 'context/offerCreation';

import { offerCreationRoutesForEachScreen } from 'shared/const/offerCreationStages';
import { systemRoles } from 'shared/const/systemRoles';

import { BidsAndAccounts } from 'screens/offers/createOffer/BidsAndAccounts';
import { CreateOfferScreen } from 'screens/offers/createOffer/CreateOfferScreen';
import { LimitationsAndContacts } from 'screens/offers/createOffer/LimitationsAndContacts';
import NewOfferCompanySelection from 'screens/offers/createOffer/NewOfferCompanySelection';
import { Sending } from 'screens/offers/createOffer/Sending';
import { Signatures } from 'screens/offers/createOffer/Signatures/Signatures';
import ManageOffers from 'screens/offers/ManageOffers/ManageOffers';

import ProtectedRoute from 'components/UI/ProtectedRoute';

const offersRoutes = (
  <Route path="offers">
    <Route path="create">
      <Route element={<OfferCreationContextProvider />}>
        <Route
          path={offerCreationRoutesForEachScreen.COMPANY_SELECTION + '/:auctionId'}
          element={
            <ProtectedRoute allowedRoles={[systemRoles.USER]}>
              <NewOfferCompanySelection />
            </ProtectedRoute>
          }
        />

        {/* following routes must be nested in CreateOfferScreen component to ensure offer is loaded before rendering */}
        <Route element={<CreateOfferScreen />}>
          <Route
            path={offerCreationRoutesForEachScreen.SCOPE_AND_ACCOUNTS + '/:offerId'}
            element={
              <ProtectedRoute allowedRoles={[systemRoles.USER]}>
                <BidsAndAccounts />
              </ProtectedRoute>
            }
          />

          <Route
            path={offerCreationRoutesForEachScreen.LIMITATIONS_AND_CONTACTS + '/:offerId'}
            element={
              <ProtectedRoute allowedRoles={[systemRoles.USER]}>
                <LimitationsAndContacts />
              </ProtectedRoute>
            }
          />

          <Route
            path={offerCreationRoutesForEachScreen.SIGNATURES + '/:offerId'}
            element={
              <ProtectedRoute allowedRoles={[systemRoles.USER]}>
                <Signatures />
              </ProtectedRoute>
            }
          />

          <Route
            path={offerCreationRoutesForEachScreen.SENDING + '/:offerId'}
            element={
              <ProtectedRoute allowedRoles={[systemRoles.USER]}>
                <Sending />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </Route>
    <Route element={<OfferCreationContextProvider />}>
      <Route path="manage" element={<ManageOffers />} />
    </Route>
  </Route>
);
export default offersRoutes;
