import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import { Badge } from 'react-bootstrap';

export const buildProceedModalContent = (t, values, defaultValues) => {
  const changedValuesSegment = buildChangedValuesSegment(t, defaultValues, values);
  const changedBankAccountsSegment = buildChangedBankAccountsSegment(t, defaultValues, values);
  const userSegmentsContent = buildUserSegments(t, defaultValues, values);

  const content = (
    <>
      <p>{t('screens.company.proceedModal.content')} ?</p>
      {changedValuesSegment}
      {changedBankAccountsSegment}
      {userSegmentsContent}
    </>
  );

  return content;
};

const buildUserSegments = (t, defaultValues, values) => {
  const { users } = values;

  const existingUsersByEmail = keyBy(defaultValues.users, (user) => user.email);
  const formDataUsersByEmail = keyBy(users, (user) => user.email);
  const allUsers = uniq([...keys(existingUsersByEmail), ...keys(formDataUsersByEmail)]);

  let content = allUsers.map((email) => {
    const existingUser = existingUsersByEmail[email];
    const formDataUser = formDataUsersByEmail[email];

    if (!existingUser) {
      return (
        <li key={email}>
          <Badge bg="success">{t('screens.company.proceedModal.addedUser')}</Badge> {formDataUser.email}
        </li>
      );
    } else if (!formDataUser) {
      return (
        <li key={email}>
          <Badge bg="info">{t('screens.company.proceedModal.removedUser')}</Badge> {existingUser.email}
        </li>
      );
    } else {
      const lookFor = ['firstName', 'lastName', 'email', 'mobile', 'role'];
      const changedValues = lookFor
        .map((key) => {
          if (existingUser[key] !== formDataUser[key]) {
            return {
              key: t(`screens.company.labels.${key}`),
              oldValue: existingUser[key],
              newValue: formDataUser[key],
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (isEmpty(changedValues)) {
        return null;
      }

      return (
        <li key={email}>
          <Badge bg="warning">{t('screens.company.proceedModal.updatedUser')}</Badge> {formDataUser.email}
          <ul>
            {changedValues.map((value) => (
              <li key={value.key}>
                ({value.key} {t('screens.company.proceedModal.from')} <span className="font-bold">"{value.oldValue}"</span>{' '}
                {t('screens.company.proceedModal.to')} <span className="font-bold">"{value.newValue}"</span>)
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  content = compact(content);

  if (isEmpty(content)) {
    return null;
  }

  return (
    <>
      <p>{t('screens.company.proceedModal.updatedUsers')}:</p>
      <ul>{content}</ul>
    </>
  );
};

const findUpdatedBankAccounts = (remainingBankAccounts, existingBankAccountsByAccountNumber, formDataBankAccountsByAccountNumber) => {
  const updatedBankAccounts = remainingBankAccounts.map((accountNumber) => {
    const existingBankAccount = existingBankAccountsByAccountNumber[accountNumber];
    const formDataBankAccount = formDataBankAccountsByAccountNumber[accountNumber];

    const lookFor = ['bank', 'branchNumber', 'accountNumber', 'isCashAccount', 'isSharesAccount'];
    const changedKeys = lookFor.map((key) => {
      if (existingBankAccount[key] !== formDataBankAccount[key]) {
        return {
          key,
          oldValue: existingBankAccount[key],
          newValue: formDataBankAccount[key],
        };
      }

      return null;
    });
    const compactedChangedKeys = compact(changedKeys);

    if (isEmpty(compactedChangedKeys)) {
      return null;
    }

    return {
      accountNumber,
      compactedChangedKeys,
    };
  });

  const compactedUpdatedBankAccounts = compact(updatedBankAccounts);
  return compactedUpdatedBankAccounts;
};

const findChangesInBankAccounts = (defaultValues, values) => {
  const { bankAccounts } = values;

  const existingBankAccountsByAccountNumber = keyBy(defaultValues.bankAccounts, (bankAccount) => bankAccount.accountNumber);
  const formDataBankAccountsByAccountNumber = keyBy(bankAccounts, (bankAccount) => bankAccount.accountNumber);
  const allBankAccounts = uniq([...keys(existingBankAccountsByAccountNumber), ...keys(formDataBankAccountsByAccountNumber)]);

  const addedBankAccounts = allBankAccounts.filter((accountNumber) => !existingBankAccountsByAccountNumber[accountNumber]);
  const removedBankAccounts = allBankAccounts.filter((accountNumber) => !formDataBankAccountsByAccountNumber[accountNumber]);
  const remainingBankAccounts = allBankAccounts.filter(
    (accountNumber) => !addedBankAccounts.includes(accountNumber) && !removedBankAccounts.includes(accountNumber),
  );
  const updatedBankAccounts = findUpdatedBankAccounts(
    remainingBankAccounts,
    existingBankAccountsByAccountNumber,
    formDataBankAccountsByAccountNumber,
  );

  return { addedBankAccounts, removedBankAccounts, updatedBankAccounts };
};

const buildAddedBankAccountsContent = (t, addedBankAccounts) => {
  const content = addedBankAccounts.map((accountNumber) => (
    <li key={accountNumber}>
      <Badge bg="success">{t('screens.company.proceedModal.addedBankAccount')}</Badge> {accountNumber}
    </li>
  ));

  return content;
};

const buildRemovedBankAccountsContent = (t, removedBankAccounts) => {
  const content = removedBankAccounts.map((accountNumber) => (
    <li key={accountNumber}>
      <Badge bg="info">{t('screens.company.proceedModal.removedBankAccount')}</Badge> {accountNumber}
    </li>
  ));

  return content;
};

const buildUpdatedBankAccountsContent = (t, updatedBankAccounts) => {
  const content = updatedBankAccounts.map((updatedBankAccount) => {
    const { compactedChangedKeys, accountNumber } = updatedBankAccount;
    return (
      <li key={accountNumber}>
        <Badge bg="warning">{t('screens.company.proceedModal.updatedBankAccount')}</Badge> {accountNumber}
        <ul>
          {compactedChangedKeys.map((value) => (
            <li key={value.key}>
              ({t(`screens.company.labels.${value.key}`)} {t('screens.company.proceedModal.from')}{' '}
              <span className="font-bold">"{value.oldValue?.toString()}"</span> {t('screens.company.proceedModal.to')}{' '}
              <span className="font-bold">"{value.newValue?.toString()}"</span>)
            </li>
          ))}
        </ul>
      </li>
    );
  });

  return content;
};

const buildChangedBankAccountsSegment = (t, defaultValues, values) => {
  const { addedBankAccounts, removedBankAccounts, updatedBankAccounts } = findChangesInBankAccounts(defaultValues, values);

  const addedBankAccountsContent = buildAddedBankAccountsContent(t, addedBankAccounts);
  const removedBankAccountsContent = buildRemovedBankAccountsContent(t, removedBankAccounts);
  const updatedBankAccountsContent = buildUpdatedBankAccountsContent(t, updatedBankAccounts);

  const compactedChangedBankAccountsContent = compact([...addedBankAccountsContent, ...removedBankAccountsContent, ...updatedBankAccountsContent]);

  if (isEmpty(compactedChangedBankAccountsContent)) {
    return null;
  }

  return (
    <>
      <p>{t('screens.company.proceedModal.updatedBankAccounts')}:</p>
      <ul>{compactedChangedBankAccountsContent}</ul>
    </>
  );
};

const buildChangedValuesSegment = (t, defaultValues, values) => {
  const lookFor = ['companyName', 'bnNumber', 'domain'];
  const changedValues = lookFor
    .map((key) => {
      if (values[key] !== defaultValues[key]) {
        return {
          key: t(`screens.company.labels.${key}`),
          oldValue: defaultValues[key],
          newValue: values[key],
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const changedValuesSegment = some(changedValues) ? (
    <>
      <p>{t('screens.company.proceedModal.changedValues')}:</p>
      <ul>
        {changedValues.map((value) => (
          <li key={value.key}>
            {value.key}: {value.oldValue} {t('screens.company.proceedModal.to')} {value.newValue}
          </li>
        ))}
      </ul>
    </>
  ) : null;

  return changedValuesSegment;
};
