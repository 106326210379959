import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

import { useRoleCompletion } from 'components/UserDetails/Form/useRoleCompletion';

const UserRolesAsForm = () => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles',
  });

  useRoleCompletion(fields);

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>{t('screens.user.table.columns.bnNumber')}</th>
            <th>{t('screens.user.table.columns.companyName')}</th>
            <th>{t('screens.user.table.columns.role')}</th>
            <th>{t('screens.user.table.columns.isMandatorySignatory')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Form.Control type="text" {...register(`roles.${index}.bnNumber`)} isInvalid={errors?.roles?.[index]?.bnNumber} />
                  <ErrorMessage
                    errors={errors}
                    name={`roles.${index}.bnNumber`}
                    render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                  />
                </td>
                <td>
                  <Form.Control type="text" {...register(`roles.${index}.companyName`)} isInvalid={errors?.roles?.[index]?.companyName} />
                  <ErrorMessage
                    errors={errors}
                    name={`roles.${index}.companyName`}
                    render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                  />
                </td>
                <td>
                  <Form.Select {...register(`roles.${index}.role`)} isInvalid={errors?.roles?.[index]?.role}>
                    <option value={contractRoles.SIGNATORY}>{t('screens.company.labels.signatory')}</option>
                    <option value={contractRoles.EMPLOYEE}>{t('screens.company.labels.employee')}</option>
                    <option value={contractRoles.COMPANY_ADMIN}>{t('screens.company.labels.admin')}</option>
                  </Form.Select>
                  <ErrorMessage
                    errors={errors}
                    name={`roles.${index}.role`}
                    render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                  />
                </td>
                <td>
                  <Form.Check type="checkbox" {...register(`roles.${index}.isMandatorySignatory`)} />
                </td>
                <td>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="d-flex justify-center">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            append({
              bnNumber: '',
              companyName: '',
              role: contractRoles.EMPLOYEE,
            });
          }}
        >
          {t('screens.user.buttons.addRole')}
        </Button>
      </div>
    </>
  );
};

export default UserRolesAsForm;
