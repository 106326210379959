import numbro from 'numbro';

// numbro settings:
numbro.registerLanguage({
  languageTag: 'he-IL',
  // languageName: 'Hebrew',
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '₪',
    position: 'prefix',
  },
});

numbro.setLanguage('he-IL');

export { numbro };
