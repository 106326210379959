import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Columns = ({ deleteAccount }) => {
  const { t } = useTranslation();

  return [
    {
      Header: t('components.DividedAccountsInputTable.table.headers.account_name'),
      accessor: 'accountName',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.cash_account_bank'),
      accessor: 'selectedCashAccount.bank',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.cash_account_branch'),
      accessor: 'selectedCashAccount.branchNumber',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.cash_account_number'),
      accessor: 'selectedCashAccount.accountNumber',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.shares_account_bank'),
      accessor: 'selectedSharesAccount.bank',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.shares_account_branch'),
      accessor: 'selectedSharesAccount.branchNumber',
    },
    {
      Header: t('components.DividedAccountsInputTable.table.headers.shares_account_number'),
      accessor: 'selectedSharesAccount.accountNumber',
    },
    {
      Header: '',
      accessor: 'controls',
      Cell: (row) => {
        return (
          <Button variant="link" onClick={() => deleteAccount(row._id)}>
            {t('components.DividedAccountsInputTable.table.headers.delete')}
          </Button>
        );
      },
    },
  ];
};
