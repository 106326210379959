import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { approveOrRejectContractByEmployee } from 'shared/apis';
import { contractActionTypes } from 'shared/const/contractActionTypes';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

export const ContractPopup = ({ contract }) => {
  const [showModal, setShowModal] = useState(true);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['contracts']);
    queryClient.invalidateQueries(['getEmploymentState']);
    queryClient.invalidateQueries(['contractsByUserId']);
  }, [queryClient]);

  const {
    mutate: handleInvitation,
    isPending,
    isError,
  } = useMutation({
    mutationKey: 'approveOrRejectContractByEmployee',
    mutationFn: approveOrRejectContractByEmployee,
    onSuccess: () => {
      invalidateQueries();
    },
  });

  const handleApprove = () => {
    handleProceed(contractActionTypes.APPROVE);
  };

  const handleReject = () => {
    handleProceed(contractActionTypes.REJECT);
  };

  const handleProceed = useCallback(
    (action) => {
      handleInvitation({ contractId: contract._id, action });
      setShowModal(false);
    },
    [handleInvitation, contract._id, setShowModal],
  );

  const onClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const content = useMemo(() => {
    if (isError) {
      return <div className="text-danger">{t('components.ContractRow.text.error')}</div>;
    }

    if (isPending) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <h2>
        {t('components.ContractRow.text.invitedToJoin')} <strong>{contract.companyName}</strong>
      </h2>
    );
  }, [isError, isPending, t, contract.companyName]);

  return (
    <>
      <Modal show={showModal} onHide={onClose} className="d-flex align-items-center" size={'md'}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer className="d-flex justify-between">
          <Button onClick={handleReject} variant="danger">
            {t(`components.ContractRow.modal.actions.reject`)}
          </Button>
          <Button onClick={handleApprove} variant="success" autoFocus disabled={isPending}>
            {t(`components.ContractRow.modal.actions.approve`)}
            {isPending && <Spinner animation="border" size="sm" />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
