import some from 'lodash/some';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { getPublicOfferingsLookupTable } from 'shared/apis';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import { columns } from './columns';

export const PublicOfferingsTable = (props) => {
  const { t } = useTranslation();
  const { activeContracts } = useContext(AuthContext);
  const { isSBAdmin } = useContext(AuthContext);
  const enabled = isSBAdmin || some(activeContracts);

  return (
    <TableQuery
      queryKey="latest-public-offerings"
      queryFn={getPublicOfferingsLookupTable}
      defaultSort={{ auctionDate: sortDirections.DESC, securityName: sortDirections.ASC }}
      Columns={columns}
      columnTPrefix="screens.dashboard.publicOfferings.columns."
      title={t('screens.dashboard.titles.activeTenders')}
      enabled={enabled}
      {...props}
    />
  );
};
