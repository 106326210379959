import { some } from 'lodash';
import { useCallback, useContext } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { useAwaitModal } from 'components/Offers/BidsAndAccountsModals';

import { getHighValueBids, getLowValueBids, getUniqLimitsForAuctionByCompany } from './utils';

export const useBidsAndAccountsPopUps = () => {
  const { offer, distinctLimitsForAuctionByCompanyData } = useContext(OfferCreationContext);

  const maxThreeLimitOpts = useAwaitModal();
  const valueWarningOpts = useAwaitModal();
  const splitConfirmOpts = useAwaitModal();

  const checkSplitsLimitsMismatch = useCallback(
    async (payload, bids) => {
      const { table } = payload;
      if (!table) {
        // no table, no mismatch (united accounts)
        return true;
      }

      if (!bids || !bids.length || bids.length === 1) {
        // no bids at all should not happen, but just in case
        // only one bids means only one limit, so no mismatch
        return true;
      }

      const splitsWithLimits = table.filter((row) => row.maxPercentageFromAuction);
      if (splitsWithLimits.length === 0) {
        // no limits set, no mismatch
        return true;
      }

      splitConfirmOpts.setData({
        table,
        bids,
        offer,
      });

      const confirm = await splitConfirmOpts.showModal();
      return confirm;
    },
    [splitConfirmOpts, offer],
  );

  const checkDistinctLimitsForAuctionByCompany = useCallback(
    async (bids) => {
      if (!distinctLimitsForAuctionByCompanyData) {
        return true;
      }

      const uniqueLimitsForAuctionByCompany = getUniqLimitsForAuctionByCompany(distinctLimitsForAuctionByCompanyData, bids);
      if (uniqueLimitsForAuctionByCompany.length <= 3) {
        return true;
      }

      maxThreeLimitOpts.setData({
        uniqueLimitsForAuctionByCompany,
        offer,
      });

      const confirm = await maxThreeLimitOpts.showModal();
      return confirm;
    },
    [distinctLimitsForAuctionByCompanyData, maxThreeLimitOpts, offer],
  );

  const checkValueWarning = useCallback(
    async (bids) => {
      const highValueBids = getHighValueBids(offer, bids);
      const lowValueBids = getLowValueBids(offer, bids);
      const hasAboveThresholdBids = some(highValueBids);
      const hasUnderLimitBids = some(lowValueBids);

      if (!hasAboveThresholdBids && !hasUnderLimitBids) {
        return true;
      }

      valueWarningOpts.setData({
        highValueBids,
        lowValueBids,
        type: offer?.initiation.auctionDetails.type,
        hasAboveThresholdBids,
        hasUnderLimitBids,
      });
      const confirm = await valueWarningOpts.showModal();
      return confirm;
    },
    [offer, valueWarningOpts],
  );

  return {
    valueWarningOpts,
    maxThreeLimitOpts,
    splitConfirmOpts,
    checkSplitsLimitsMismatch,
    checkDistinctLimitsForAuctionByCompany,
    checkValueWarning,
  };
};
