import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { AccountsStructures } from 'shared/const/accountsStructures';

import { useDownloadDividedAccountsExcel, useOfferStateUtils } from 'hooks/offers';

import Box from 'components/UI/Box';

import { OfferSendingModal } from '../OfferSendingModal/OfferSendingModal';
import { SignaturePadModal } from '../SignaturePadModal/SignaturePadModal';

export const ViewAndSignSummary = () => {
  const { offer } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);
  const { userCanSign } = useOfferStateUtils({ offer });
  const { downloadDividedAccountsExcel } = useDownloadDividedAccountsExcel();
  const proposalDocumentContainer = useRef(null);
  const [showSignaturePadModal, setShowSignaturePadModal] = useState(false);
  const [showOfferSentModal, setShowOfferSentModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState(null);

  /**
   * this components dose two things:
   * 1. it renders the html content of the proposal document
   * 2. if the current user should sign the offer, it renders a button that will open the signature pad modal
   */

  useEffect(() => {
    if (!offer) return;
    const htmlContentFromOffer = offer?.internalApproval.proposalDocument.asHtml?.data;
    setHtmlContent(htmlContentFromOffer);
  }, [offer, setHtmlContent, t, userId, userCanSign]);

  /**
   * unfortunately, the html content is not rendered when the component is mounted, so we need to add the listeners after the html content is rendered
   */
  useEffect(() => {
    const addSignButtonToHtml = () => {
      const elementDedicatedToThisUser = document.getElementById(userId.toString());
      if (!elementDedicatedToThisUser) {
        return;
      }
      const userAlreadySigned = offer.internalApproval.signatures.find((signature) => {
        const signatoryId = signature.userId;
        const isSigned = userId === signatoryId;
        return isSigned;
      });
      if (userAlreadySigned) {
        return;
      }
      elementDedicatedToThisUser.innerHTML = '';

      const htmlButton = document.createElement('button');
      htmlButton.classList.add('greenButton');
      htmlButton.classList.add('mb-2');
      htmlButton.innerHTML = t('components.SignatoriesTable.sign');
      htmlButton.id = 'signButton';
      elementDedicatedToThisUser.appendChild(htmlButton);
    };

    const enrichSignatureButton = (node) => {
      const signatureHtmlButton = node.querySelector('#signButton');
      if (signatureHtmlButton) {
        signatureHtmlButton.onclick = () => {
          setShowSignaturePadModal(true);
        };
      }
    };

    const enrichDividedAccountingSpan = (node) => {
      const chosenAccountsStructure = offer?.bankAccounts?.chosenType;
      if (chosenAccountsStructure === AccountsStructures.DIVIDED) {
        const dividedAccountingSpan = node.querySelector('#dividedAccounting');
        if (dividedAccountingSpan) {
          dividedAccountingSpan.onclick = () => downloadDividedAccountsExcel(offer);
          dividedAccountingSpan.style.cursor = 'pointer';
          dividedAccountingSpan.style.textDecoration = 'underline';
          dividedAccountingSpan.style.color = '#0369a1';
          // hover colors
          dividedAccountingSpan.onmouseover = () => {
            dividedAccountingSpan.style.color = '#075985';
          };
          dividedAccountingSpan.onmouseout = () => {
            dividedAccountingSpan.style.color = '#0369a1';
          };
        }
      }
    };

    const node = proposalDocumentContainer.current;
    if (node) {
      addSignButtonToHtml();
      /* first add a listener to the signature button */
      enrichSignatureButton(node);

      /* then add a link for the divided accounting table if exists */
      enrichDividedAccountingSpan(node);
    }
  }, [proposalDocumentContainer, t, htmlContent, offer, downloadDividedAccountsExcel, userId]);

  if (!htmlContent) return null;

  return (
    <>
      <Box title={t('components.ViewAndSignSummary.title')}>
        <div ref={proposalDocumentContainer} className="w-100">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </Box>
      <OfferSendingModal
        show={showOfferSentModal}
        onHide={() => setShowOfferSentModal(false)}
        auctionId={offer.initiation.auctionDetails.auctionId}
      />
      <SignaturePadModal
        show={showSignaturePadModal}
        close={() => {
          setShowSignaturePadModal(false);
        }}
        setShowOfferSentModal={setShowOfferSentModal}
      />
    </>
  );
};
