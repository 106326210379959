export const hashBankAccount = (bankAccount) => {
  if (!bankAccount) return undefined;
  const { accountNumber, bank, branchNumber } = bankAccount;
  const result = [bank, branchNumber || 0, accountNumber].join('-#-');
  return result;
};

export const unHashBankAccount = (bankAccountHash) => {
  if (!bankAccountHash) return undefined;
  const [bank, branchNumber, accountNumber] = bankAccountHash.split('-#-');
  const result = {
    bank,
    accountNumber,
    branchNumber: Number(branchNumber),
  };
  return result;
};

export const isSameBankAccount = (bankAccount1, bankAccount2) => {
  const bankAccount1Hashed = hashBankAccount(bankAccount1);
  const bankAccount2Hashed = hashBankAccount(bankAccount2);
  const result = bankAccount1Hashed === bankAccount2Hashed;
  return result;
};

export const filterDuplicatedBankAccounts = (firstPriorityBankAccounts, secondPriorityBankAccounts) => {
  const result = [...firstPriorityBankAccounts];
  if (!secondPriorityBankAccounts?.length) return result;
  for (const newBankAccount of secondPriorityBankAccounts) {
    if (!result.find((account) => isSameBankAccount(account, newBankAccount))) {
      result.push(newBankAccount);
    }
  }
  return result;
};
