import * as XLSX from 'xlsx';

const parseTheUploadEvent = (event) => {
  const data = new Uint8Array(event.target.result);
  const workbook = XLSX.read(data, { type: 'array' });

  /* Assuming that the data is in the first sheet */
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  return sheet;
};

const handleFileUpload = (event, callback) => {
  const sheet = parseTheUploadEvent(event);
  const json = XLSX.utils.sheet_to_json(sheet, { defval: '', raw: false });
  callback(json);
};

export const initiateFileReading = (e, cb) => {
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => handleFileUpload(e, cb);
  reader.readAsArrayBuffer(file);
  e.target.value = null;
};
