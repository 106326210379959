import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics.js';

import { Footer } from './Footer.js';
import { NavigationBar } from './NavigationBar';

const Layout = ({ children }) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const { sendGAPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendGAPageView(location);
  }, [location, sendGAPageView]);

  const loggedInRoutes = ['/user', '/admin', '/offer'];
  const loggedInPage = loggedInRoutes.find((route) => new RegExp(route).test(pathname));

  return (
    <div className="d-flex flex-col min-h-[100vh] w-full justify-between">
      {loggedInPage ? <NavigationBar /> : null}
      <div className="grow position-relative">{children}</div>
      {!loggedInPage ? <Footer /> : null}
    </div>
  );
};

export default Layout;
