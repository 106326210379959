import { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext, PublicOfferingsFiltersContextProvider } from 'context/PublicOfferingsFilters';

import { PublicOfferingsFilters } from 'components/publicOfferingsFilters';
import { PublicOfferingsTable } from 'components/PublicOfferingsTable';
import Box from 'components/UI/Box';

export const PublicOfferingsScreen = () => {
  const { t } = useTranslation();

  return (
    <PublicOfferingsFiltersContextProvider>
      <Container className="mt-5 mb-5">
        <Row>
          <h1 className="text-center">{t('screens.publicOfferings.texts.title')}</h1>
        </Row>
        <Row className="mt-5 container">
          <PublicOfferingsFilters />
        </Row>
        <Row className="mt-5 container">
          <Box>
            <PublicOfferingsTableWrapper />
          </Box>
        </Row>
      </Container>
    </PublicOfferingsFiltersContextProvider>
  );
};

const PublicOfferingsTableWrapper = () => {
  const { filters } = useContext(PublicOfferingsFiltersContext);

  return <PublicOfferingsTable scrollable withPagination filters={filters} />;
};
