import { useQuery } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/context/user-details';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { getUserDetails } from 'shared/apis';
import { userViewModes } from 'shared/const/userViewModes';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { AuthContext } from './Auth';

export const UserDetailsContext = createContext(null);

export const UserDetailsContextProvider = ({ children }) => {
  const [mode, setMode] = useState(userViewModes.VIEW);
  const [userId, setUserId] = useState(null);
  const [isEditingSelf, setIsEditingSelf] = useState(false);
  const { userId: userIdFromParams } = useParams();
  const { userId: currentUserId } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const isEditingSelf = currentUserId === userId;
    setIsEditingSelf(isEditingSelf);
  }, [currentUserId, userId]);

  useEffect(() => {
    if (userIdFromParams) {
      setUserId(userIdFromParams);
    } else {
      setUserId(currentUserId);
    }
  }, [userIdFromParams, currentUserId]);

  const {
    data: userDetailsFromServer,
    isLoading: userDetailsIsLoading,
    error: userDetailsError,
  } = useQuery({
    queryKey: ['getUserDetails', userId],
    queryFn: () => getUserDetails(userId),
    enabled: !!userId,
    retry: false,
    staleTime: 0,
  });

  useEffect(() => {
    if (userDetailsError) {
      const errorName = getErrorNameFromErrorResponse(userDetailsError);
      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.USER_NOT_FOUND:
          errorAlertContent = {
            title: t('context.userDetails.errors.userNotFound.title'),
            description: t('context.userDetails.errors.userNotFound.description'),
          };
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(userDetailsError);
          break;
      }

      addAlert(errorAlertContent);
      navigate('/');
    }
  }, [userDetailsError, addAlert, navigate, t]);

  const toggleMode = () => {
    setMode(mode === userViewModes.VIEW ? userViewModes.EDIT : userViewModes.VIEW);
  };

  const contextValue = {
    userId,
    setUserId,
    mode,
    setMode,
    isEditingSelf,
    setIsEditingSelf,
    toggleMode,
    userDetailsFromServer,
    userDetailsIsLoading,
    userDetailsError,
  };

  return <UserDetailsContext.Provider value={contextValue}>{children}</UserDetailsContext.Provider>;
};
