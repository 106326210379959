const ALL_RATINGS = [
  'AAA / Aaa',
  'AA+ / Aa1',
  'AA / Aa2',
  'AA- / Aa3',
  'A+ / A1',
  'A / A2',
  'A- / A3',
  'BBB+ / Baa1',
  'BBB / Baa2',
  'BBB- / Baa3',
  'BB+ / Ba1',
  'BB / Ba2',
  'BB- / Ba3',
  'B+ / B1',
  'B / B2',
  'B- / B3',
  'CCC+ / Caa1',
  'CCC / Caa2',
  'CCC- / Caa3',
  'CC / Ca',
  'C / C',
];

export const RATING_OPTIONS = ALL_RATINGS.map((label, idx) => ({
  label,
  value: ALL_RATINGS.length - idx,
}));

const getRatingCleaned = (rating) => {
  return rating.split('/').map((l) => l.trim());
};

const RatingScoreMap = RATING_OPTIONS.reduce((acc, e) => {
  const cleanRatings = getRatingCleaned(e.label);

  cleanRatings.forEach((rating) => {
    const key = rating.toLowerCase();
    acc[key] = e.value;
  });

  return acc;
}, {});

export const getScore = (rating) => {
  const canonicalRating = rating
    .toLowerCase()
    .replace(/^\(p\)/, '')
    .replace(/il$/, '')
    // fix minus charcode 8722 vs hyphen charcode 45 fml
    .split('')
    .map((e) => (e.charCodeAt(0) === 45 ? String.fromCharCode(8722) : e))
    .join('');

  return RatingScoreMap[canonicalRating];
};
