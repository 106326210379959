export const buildMutationPayload = (offer, data) => {
  const payload = {
    offerId: offer._id,
    maxPercentageFromAuction: data.maxPercentageFromAuction || undefined,
    issuedCapitalWinningPercentLimit: data.limit_issuedCapitalWinningPercentLimit || undefined,
    issuedCapitalPresentHoldings: data.issuedCapitalPresentHoldings || undefined,
    mutualLimitation: buildMutualLimitationFromData(offer, data),
    minProceeds: data.limit_minFundraisingVolume || undefined,
    forbidParticipatingUnderwriters: data.noUnderwriters || undefined,
    allowUnderwritersAtMinProfitOnly: data.underwritersAtMinProfitOnly || undefined,
    additionalComment: data.additionalComment,
    representativeUserId: data.contactPerson,
    emailsForAuctionResults: data.emailsForAuctionResults,
    sendAcceptanceSMS: !!data.sendAcceptanceSMS,
  };

  return payload;
};

export const buildMutualLimitationFromData = (offer, data) => {
  const companyAssociates = offer?.initiation.investingCompany?.associates || [];
  const associates = companyAssociates
    .filter((associate) => data[`mutualLimitation_${associate.bnNumber}`])
    .map((associate) => ({
      bnNumber: associate.bnNumber,
      name: associate.name,
      companyId: associate.companyId,
    }));
  return { associates };
};
