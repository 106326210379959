import { useCallback, useContext, useEffect, useState } from 'react';

import { AlertContentContext } from 'context/Alert';

import { ConfettiShow, DollarsConfettiShow } from './ConfettiShow';

export const visualEffects = {
  CONFETTI: 'CONFETTI',
  DOLLAR_CONFETTI: 'DOLLAR_CONFETTI',
};

const VISUAL_EFFECT_DURATION = 10_000;
const FADE_OUT_DURATION = 3000; // 3 seconds for the fade-out effect

export const VisualEffectsContainer = () => {
  const { activeVisualEffect, stopVisualEffect } = useContext(AlertContentContext);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (!activeVisualEffect) {
      return;
    }

    setTimeout(() => {
      setIsFadingOut(true);

      setTimeout(() => {
        stopVisualEffect();
      }, FADE_OUT_DURATION);
    }, VISUAL_EFFECT_DURATION - FADE_OUT_DURATION);
  }, [activeVisualEffect, stopVisualEffect]);

  const VisualEffect = useCallback(() => {
    switch (activeVisualEffect) {
      case visualEffects.CONFETTI:
        return <ConfettiShow />;
      case visualEffects.DOLLAR_CONFETTI:
        return <DollarsConfettiShow />;
      default:
        return null;
    }
  }, [activeVisualEffect]);

  return (
    activeVisualEffect && (
      <div className={`fixed left-0 top-0 w-full h-full z-[2000] pointer-events-none  ${isFadingOut ? 'animate-fade-out' : ''}`}>
        <VisualEffect />
      </div>
    )
  );
};
