import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'context/Notifications';

import { NotificationItem } from './NotificationItem';

export const NotificationsList = ({ maxLength }) => {
  const { t } = useTranslation();
  const { notifications, unreadNotificationsCount, error } = useContext(NotificationContext);

  const limitedNotifications = useMemo(
    () => notifications.pages.flatMap((page) => page).slice(0, maxLength),
    // we need to add unreadNotificationsCount to the dependency array because
    // notifications is an array and it's reference doesn't change when a new
    // notification is added to it, so useMemo will not recompute the value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications, maxLength, unreadNotificationsCount],
  );

  if (error) return <p> {t('components.Notifications.error')} </p>;

  return limitedNotifications.map((notification, index) => (
    <div key={index}>
      <NotificationItem notification={notification} />
    </div>
  ));
};
