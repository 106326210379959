import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { InstructionsTooltip } from './InstructionsTooltip';
import { SavedAccountsModal } from './SavedAccountsModal';

export const SavedAccountsControls = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="flex gap-1">
        <Button variant="secondary" size="xs" className="flex gap-1 align-items-center text-nowrap" onClick={() => setShow(true)}>
          <FontAwesomeIcon icon={faFile} />
          {t('components.dividedAccountsUploader.downloadLink')}
        </Button>
        <InstructionsTooltip />
      </div>
      <SavedAccountsModal show={show} setShow={setShow} />
    </>
  );
};
