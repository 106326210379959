import styled from 'styled-components';

import ellipse from 'asset/landing/ellipse.svg';

export const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  li: {
    line-height: 1.5;
  }

  li::before {
    content: '';
    position: absolute;
    inset-inline-start: 0;
    top: 7px;
    width: 0.8em;
    height: 1em;
    background-repeat: no-repeat;
    background-image: url(${ellipse});
    background-size: contain;
  }
`;
