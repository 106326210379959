import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { SplitTable } from './SplitTable';

export const SplitConfirmModal = ({ config: { show }, handleConfirm, handleCancel, data }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleCancel} centered dialogClassName="justify-center" contentClassName="w-auto" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('components.SplitConfirmModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">{t('components.SplitConfirmModal.subtitle')}</p>
        <SplitTable data={data} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          {t('components.ValueWarning.cancel')}
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          {t('components.SplitConfirmModal.proceed')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
