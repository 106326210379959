import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink as Link } from 'react-router-dom';

import { LanguageContext } from 'context/Language';

import { languageMap } from 'shared/const/languageMap';

export const Footer = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <Navbar expand="lg" bg="light" variant="light" className="p-2">
      <Container fluid>
        <Nav>
          <Nav.Link as={Link} to="/terms-of-service" style={({ isActive }) => (isActive ? { color: '#63a103' } : null)} target="blank">
            {t('components.Footer.links.termsOfService')}
          </Nav.Link>
          <Nav.Link as={Link} to="/privacy-policy" style={({ isActive }) => (isActive ? { color: '#63a103' } : null)} target="blank">
            {t('components.Footer.links.privacy')}
          </Nav.Link>
        </Nav>
        <Nav className="justify-content-end">
          {languageMap.map((item, index) => (
            <Nav.Link
              key={index}
              onClick={() => setLanguage(item.value)}
              disabled={language === item.value}
              className={language === item.value ? 'active' : ''}
            >
              {item.label}
            </Nav.Link>
          ))}
        </Nav>
      </Container>
    </Navbar>
  );
};
