import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { CompanyDetailsContext, CompanyDetailsContextProvider } from 'context/CompanyDetails';

import { companyViewModes } from 'shared/const/companyViewModes';
import { systemRoles } from 'shared/const/systemRoles';

import { EditMode } from 'components/CompanyDetails/EditMode/EditMode';
import { ViewMode } from 'components/CompanyDetails/ViewMode/ViewMode';

const CompanyScreen = () => {
  const { mode } = useContext(CompanyDetailsContext);

  const renderMode = () => {
    switch (mode) {
      case companyViewModes.EDIT:
        return <EditMode />;
      case companyViewModes.VIEW:
      default:
        return <ViewMode role={systemRoles.SB_ADMIN} />;
    }
  };

  return <Container className="mt-5">{renderMode()}</Container>;
};

export const CompanyScreenWithContext = () => (
  <CompanyDetailsContextProvider>
    <CompanyScreen />
  </CompanyDetailsContextProvider>
);
