import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { useContext, useEffect } from 'react';

import { AuthContext } from 'context/Auth';

import { isDevelopment, isProduction } from 'shared/utils/appEnv';

const getProjectKey = () => {
  if (isProduction) {
    console.log('OpenReplay: Production Environment, using production key');
    return process.env.REACT_APP_OPEN_REPLAY_PRODUCTION_KEY;
  }

  if (isDevelopment) {
    console.log('OpenReplay: Local Environment, using test key');
    return process.env.REACT_APP_OPEN_REPLAY_TEST_KEY;
  }

  console.log('OpenReplay: Staging Environment, using staging key');
  return process.env.REACT_APP_OPEN_REPLAY_STAGING_KEY;
};

const buildTracker = () => {
  const ingestPoint = process.env.REACT_APP_OPEN_REPLAY_INGEST_POINT;
  if (!ingestPoint) console.log('Missing openReplay ingest point');
  const projectKey = getProjectKey();

  if (!projectKey) console.log('Missing openReplay project key');

  const props = {
    projectKey,
    ingestPoint,
    // __DISABLE_SECURE_MODE: true, // uncomment this line if you want to check the tracker in localhost
    obscureTextEmails: false,
    obscureInputEmails: false,
    verbose: true,
  };
  const tracker = new Tracker(props);
  return tracker;
};

const tracker = buildTracker();

export const useOpenReplay = () => {
  const { email, firstName, lastName, mobile, activeContracts } = useContext(AuthContext);

  /* INITIATE */
  useEffect(() => {
    if (isDevelopment) {
      console.log('OpenReplay: Development Environment, skipping tracker start');
      return;
    }

    if (tracker.isActive()) return;

    console.log('OpenReplay: Starting tracker');
    tracker.start();
    tracker.use(trackerAssist({}));
  }, []);

  /* USER ID */
  useEffect(() => {
    if (!tracker?.isActive()) return;
    tracker.setUserID(email);
  }, [email]);
  /* METADATA */

  useEffect(() => {
    if (!tracker?.isActive()) return;
    tracker.setMetadata({ firstName, lastName, mobile, activeContracts });
  }, [firstName, lastName, mobile, activeContracts]);
};
