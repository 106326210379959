import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { investorTypes } from 'shared/const/investorTypes';

import { AccreditedInvestorCertAlert } from './AccreditedInvestorCertAlert';

dayjs.extend(isSameOrBefore);
export const AccreditedInvestorCertAlerts = ({ activeContracts }) => {
  // find all contracts where:
  // the accreditedInvestorCert is either missing or expired
  // and the investorType is not one of the following: BANK, INSURANCE_AGENCY, PENSION_FUND, ETF_FUND, MUTUAL_FUND
  const relevantContracts = useMemo(() => {
    const res = activeContracts?.filter((contract) => {
      const {
        company: { accreditedInvestorCert, investorType },
      } = contract;

      if (
        [investorTypes.BANK, investorTypes.INSURANCE_AGENCY, investorTypes.PENSION_FUND, investorTypes.ETF_FUND, investorTypes.MUTUAL_FUND].includes(
          investorType,
        )
      ) {
        return false;
      }

      if (!accreditedInvestorCert || dayjs(accreditedInvestorCert.expirationDate).isSameOrBefore(dayjs(), 'day')) {
        return true;
      }

      return false;
    });

    return res;
  }, [activeContracts]);

  if (isEmpty(relevantContracts)) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      {relevantContracts?.map((contract, index) => (
        <>
          <AccreditedInvestorCertAlert contract={contract} key={index} />
        </>
      ))}
    </div>
  );
};
