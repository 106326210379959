import { useMutation } from '@tanstack/react-query';
import PhoneNumberUtil from 'libphonenumber-js';
import partition from 'lodash/partition';
import some from 'lodash/some';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { setSignatories } from 'shared/apis';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';
import { extractSignatoriesIds, isSignatoryById } from 'shared/utils/offers';

import Box from 'components/UI/Box';

export const SignatoriesManagementBoard = ({ viewMode = false }) => {
  const { setOffer, offer, offerDataForAnalytics } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const { userId: currentUserId } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);
  const [mandatorySignatoriesErr, setMandatorySignatoriesErr] = useState(null);
  const [additionalSignatoriesErr, setAdditionalSignatoriesErr] = useState(null);

  const setSignatoriesErrorHandling = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      switch (errorName) {
        case 'MANDATORY_SIGNATORIES_MISSING':
          setMandatorySignatoriesErr(t('components.SignatoriesTable.errors.mandatorySignatoriesMissing'));
          break;
        case 'NO_SIGNATORIES_PROVIDED':
          setAdditionalSignatoriesErr(t('components.SignatoriesTable.errors.missingSignatures'));
          break;
        case 'MISSING_SIGNATURES':
        case 'OFFER_NOT_FOUND':
        default:
          addAlert(errorAlertBuilder.bug(error));
          break;
      }
    },
    [addAlert, t, setMandatorySignatoriesErr, setAdditionalSignatoriesErr],
  );

  const { mutate: setSignatoriesMutation, isPending } = useMutation({
    mutationFn: setSignatories,
    mutationKey: 'setSignatories',
    onSuccess: (data) => {
      setOffer(data);
      setMandatorySignatoriesErr(null);
      setAdditionalSignatoriesErr(null);
    },
    onError: setSignatoriesErrorHandling,
  });

  const onCheckboxChange = useCallback(
    (userId, isChecked) => {
      if (!offer) return;

      // Used for disabling the change of signatories in the view and sign screen too quickly which might cause a bug
      if (isPending) return;

      const currentSignatoriesIds = extractSignatoriesIds(offer);

      let signatoriesIds = [];
      if (isChecked) {
        signatoriesIds = [...currentSignatoriesIds, userId];
      } else {
        signatoriesIds = currentSignatoriesIds.filter((signatoryId) => signatoryId !== userId);
      }

      const expectedPayload = {
        offerId: offer._id,
        signatoriesIds: signatoriesIds,
        dataLayer: offerDataForAnalytics,
      };
      setSignatoriesMutation(expectedPayload);
    },
    [isPending, offer, setSignatoriesMutation, offerDataForAnalytics],
  );

  const generateSignatoryText = useCallback(
    (employee) => {
      const isMe = employee.userId === currentUserId;
      const fullName = `${employee.firstName} ${employee.lastName}`;
      const formattedName = isMe ? `${t('common.me')} (${fullName})` : fullName;
      const formattedMobile = isMe ? '' : `(${PhoneNumberUtil(employee.mobile).formatNational()})`;
      return `${formattedName} ${formattedMobile}`.trim();
    },
    [t, currentUserId],
  );

  const generateSignatoryRow = useCallback(
    (employee) => {
      return (
        <Form.Check key={employee.userId} id={`${employee.userId}-checkbox`}>
          <Form.Check.Input
            type="checkbox"
            disabled={viewMode || isPending}
            onChange={(e) => onCheckboxChange(employee.userId, e.target.checked)}
            checked={!!isSignatoryById(offer, employee.userId)}
            id={`${employee.userId}-checkbox`}
          />
          <Form.Check.Label>{generateSignatoryText(employee)}</Form.Check.Label>
        </Form.Check>
      );
    },
    [generateSignatoryText, isPending, offer, onCheckboxChange, viewMode],
  );

  const [mandatorySignatoriesRows, additionalSignatoriesRows] = useMemo(() => {
    if (!offer) return [[], []];
    const companyEmployees = offer?.initiation.investingCompany.employees;

    const [mandatorySignatories, additionalSignatories] = partition(companyEmployees, (employee) => employee.isMandatorySignatory);

    const mandatorySignatoriesRows = mandatorySignatories.map(generateSignatoryRow);
    const additionalSignatoriesRows = additionalSignatories.map(generateSignatoryRow);

    return [mandatorySignatoriesRows, additionalSignatoriesRows];
  }, [offer, generateSignatoryRow]);

  const buildSignatoriesBlock = useCallback(
    (signatoriesRows, errors, header) => {
      if (!offer) return null;
      if (!some(signatoriesRows)) return null;
      return (
        <>
          <h2 className="font-semibold">{header}</h2>
          {errors && <div className="text-danger">{errors}</div>}
          <div className="flex flex-row justify-between align-items-center">
            <div className="flex flex-col min-w-[300px] p-2 gap-2">{signatoriesRows}</div>
          </div>
        </>
      );
    },
    [offer],
  );

  const MandatorySignatoriesBlock = useCallback(() => {
    const hasMandatorySignatories = some(mandatorySignatoriesRows);
    const header = hasMandatorySignatories
      ? t('components.SignatoriesTable.labels.mandatorySignatories')
      : t('components.SignatoriesTable.labels.mandatorySignatory');

    return buildSignatoriesBlock(mandatorySignatoriesRows, mandatorySignatoriesErr, header);
  }, [mandatorySignatoriesRows, t, mandatorySignatoriesErr, buildSignatoriesBlock]);

  const AdditionalSignatoriesBlock = useCallback(() => {
    const header = some(mandatorySignatoriesRows)
      ? t('components.SignatoriesTable.labels.additionalSignatories')
      : t('components.SignatoriesTable.labels.signatories');

    return buildSignatoriesBlock(additionalSignatoriesRows, additionalSignatoriesErr, header);
  }, [additionalSignatoriesRows, mandatorySignatoriesRows, t, additionalSignatoriesErr, buildSignatoriesBlock]);

  const hasBoth = useMemo(
    () => some(mandatorySignatoriesRows) && some(additionalSignatoriesRows),
    [mandatorySignatoriesRows, additionalSignatoriesRows],
  );

  //JSX
  return (
    <Box>
      <MandatorySignatoriesBlock />
      {hasBoth && <hr className="my-4" />}
      <AdditionalSignatoriesBlock />
      <div>{isPending && <Spinner size="sm" />}</div>
    </Box>
  );
};
