import dayjs from 'dayjs';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { WinningOffersTable } from './WinningOffersTable';

export const WinningOffersModal = ({ show, setShow, publicOffering }) => {
  const title = publicOffering
    ? `${publicOffering.securityName} (${publicOffering.securityId}), ${dayjs(publicOffering.auctionDate).format('DD.MM.YYYY')}`
    : '';

  return (
    <Modal onHide={() => setShow(false)} centered show={show} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WinningOffersTable winningOffers={publicOffering.winningOffers} publicOffering={publicOffering} />
      </Modal.Body>
    </Modal>
  );
};
