import { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'context/Notifications';

import { NotificationsList } from './NotificationsList';

export const NotificationsBox = () => {
  const { t } = useTranslation();
  const { notifications, isFetchingNextPage, isFetching, hasNextPage, markAllAsRead, fetchNextPage } = useContext(NotificationContext);

  if (!notifications) return null;

  return (
    <>
      <div className="bg-white mt-2 rounded-lg z-50 overflow-auto border !border-slate-200">
        <div className="flex justify-between items-center bg-blue-50 py-2 px-3 border-b border-b-white shadow-sm">
          <h5 className="font-semibold">
            {t('components.Notifications.allNotifications')}
            {isFetching && <Spinner size="sm" animation="border" className="ms-2" />}
          </h5>
          <button className="btn-link text-gray-500 text-xs" onClick={markAllAsRead}>
            {t('components.Notifications.markAllAsRead')}
          </button>
        </div>
        <NotificationsList />
      </div>
      <div className="flex flex-col justify-center mt-2 align-items-center">
        {hasNextPage && (
          <Button variant="primary" onClick={fetchNextPage} className="w-max-content">
            {t('components.Notifications.fetchNextPage')}
            {isFetchingNextPage && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
          </Button>
        )}
      </div>
    </>
  );
};
