import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/components/user/edit-mode';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { UserDetailsContext } from 'context/UserDetails';

import { updateCurrentUser, updateUserByAdmin } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { userViewModes } from 'shared/const/userViewModes';
import { schema } from 'shared/schemas/user';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useTFAMutation } from 'components/Auth/TFA/useTFAMutation';
import { buildProceedModalContent } from 'components/UserDetails/EditMode/utils';
import UserForm from 'components/UserDetails/Form';

export const EditMode = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  /* context for editing user details */
  const { userId, userDetailsFromServer, setMode, userDetailsIsLoading, userDetailsError, isEditingSelf } = useContext(UserDetailsContext);
  const { isSBAdmin, logout } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  //FORM INITIALIZATION
  const defaultValues = useMemo(() => {
    return { userId, ...userDetailsFromServer };
  }, [userDetailsFromServer, userId]);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { getValues } = methods;

  const onUserUpdatedSuccessfully = useCallback(() => {
    queryClient.invalidateQueries('getUserDetails');
    setMode(userViewModes.VIEW);
  }, [queryClient, setMode]);

  //UPDATE USER BY SB ADMIN REQUEST
  const handleUpdateUserByAdminError = useCallback(
    (error) => {
      const errorName = getErrorNameFromErrorResponse(error);

      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.COMPANY_ALREADY_EXISTS:
          errorAlertContent = {
            title: t('context.company.errors.companyAlreadyExists.title'),
            description: t('context.company.errors.companyAlreadyExists.description'),
            error,
            type: ALERT_TYPES.ERROR,
          };
          break;
        case ERROR_CODES.EMAIL_SENDING_FAILED:
          errorAlertContent = {
            title: t('screens.user.errors.editUserSendingEmailFailed.title'),
            description: t('screens.user.errors.editUserSendingEmailFailed.description'),
            error,
            type: ALERT_TYPES.WARNING,
          };
          // if the error is email sending failed, the user was still updated successfully
          onUserUpdatedSuccessfully();
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(error);
          break;
      }
      addAlert(errorAlertContent);
    },
    [addAlert, onUserUpdatedSuccessfully, t],
  );

  const { mutate: updateUserByAdminMutation, isPending: isLoadingUpdateUserByAdmin } = useMutation({
    mutationKey: 'updateUserByAdmin',
    mutationFn: updateUserByAdmin,
    onSuccess: onUserUpdatedSuccessfully,
    onError: handleUpdateUserByAdminError,
    onSettled: () => {
      setShowConfirmModal(false);
    },
  });

  const onAdminSubmit = useCallback(
    (data) => {
      const expectedPayload = {
        userId,
        ...data,
      };
      updateUserByAdminMutation(expectedPayload);
    },
    [updateUserByAdminMutation, userId],
  );

  //UPDATE USER BY ITSELF REQUEST
  const updateCurrentUserMutationParams = useMemo(() => {
    const params = {
      mainMutationParams: {
        mutationKey: 'updateCurrentUser',
        mutationFn: updateCurrentUser,
        onSuccess: async (data) => {
          queryClient.invalidateQueries('getUserDetails');
          setMode(userViewModes.VIEW);
        },
        onError: (error) => {
          const errorName = getErrorNameFromErrorResponse(error);
          switch (errorName) {
            case ERROR_CODES.COMPANY_ALREADY_EXISTS:
              addAlert({
                title: t('context.company.errors.companyAlreadyExists.title'),
                description: t('context.company.errors.companyAlreadyExists.description'),
                error,
                type: ALERT_TYPES.ERROR,
              });
              break;
            case ERROR_CODES.TFA_DETAILS_CHANGED:
              addAlert({
                title: t('screens.authentication.errors.tfaDetailsChanged.title'),
                description: t('screens.authentication.errors.tfaDetailsChanged.message'),
                error,
                type: ALERT_TYPES.ERROR,
              });
              logout();
              break;
            default:
              addAlert(errorAlertBuilder.bug(error));
          }
        },
      },
      initiationMutationParams: {
        /** not needed in the scenario */
      },
    };
    return params;
  }, [addAlert, logout, queryClient, setMode, t]);

  const { mutate: updateCurrentUserMutation, ValidationModal } = useTFAMutation(updateCurrentUserMutationParams);

  const onUserSubmit = (data) => {
    updateCurrentUserMutation({ userUpsertDetails: data });
  };

  //ADDITIONAL HOOKS
  const proceedModalContent = useMemo(() => {
    const values = getValues();
    return buildProceedModalContent(t, values, defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, methods, defaultValues, getValues]);

  const onCancel = (e) => {
    e.preventDefault();
    const navigationDestination = isSBAdmin ? '/admin/users/table' : '/user';
    navigate(navigationDestination);
  };

  const onSubmit = isSBAdmin ? onAdminSubmit : onUserSubmit;

  //JSX
  if (!isEditingSelf && !isSBAdmin) {
    return <div>un authorized</div>;
  }

  if (userDetailsError) {
    return <div>{userDetailsError}</div>;
  }

  if (userDetailsIsLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Container>
          <Row>
            <h2>{t('screens.user.titles.editUser')}</h2>
          </Row>
        </Container>
        <UserForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          isLoading={isLoadingUpdateUserByAdmin}
          actionStr={t('screens.companies.actions.update')}
          actionVariant={'warning'}
          proceedModalContent={proceedModalContent}
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
        />
      </FormProvider>
      {ValidationModal}
    </>
  );
};
