import { useQuery } from '@tanstack/react-query';
import some from 'lodash/some';
import { useContext, useMemo } from 'react';
import { Container, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { useIsActive } from 'context/OffersScreen';

import { getActiveAuctionsAndAssociatedOffers, getEmploymentState } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';

import LiveAuctionCard from 'components/LiveAuctionCard';
import { PastOffersTable } from 'components/Offers/PastOffersTable';

const ManageOffers = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useIsActive(true);
  const { addAlert } = useContext(AlertContentContext);
  const { activeContracts } = useContext(AuthContext);

  //SERVER DATA
  const { data: activeAuctions, isLoading: isLoadingActiveAuctions } = useQuery({
    queryKey: ['getActiveAuctionsOfferState'],
    queryFn: getActiveAuctionsAndAssociatedOffers,
  });

  //SERVER DATA
  const { data: employmentState, isLoading: isLoadingEmploymentState } = useQuery({
    queryKey: ['getEmploymentState'],
    queryFn: getEmploymentState,
  });

  const noActiveAuctions = useMemo(() => !activeAuctions || activeAuctions.length === 0, [activeAuctions]);

  const activeAuctionsContent = useMemo(() => {
    if (isLoadingActiveAuctions || isLoadingEmploymentState) {
      // todo: enhance this, test this
      return (
        <div className="flex justify-center items-center">
          <Spinner animation="border" />
        </div>
      );
    }

    const noEmploymentState = !employmentState || employmentState.length === 0;
    if (noActiveAuctions || noEmploymentState) {
      return <div className="text-center">{t('screens.manageOffers.noActive')}</div>;
    }

    return activeAuctions.map((auction) => {
      return <LiveAuctionCard key={auction._id} auction={auction} offers={auction.offers} employmentState={employmentState} />;
    });
  }, [activeAuctions, noActiveAuctions, isLoadingActiveAuctions, isLoadingEmploymentState, employmentState, t]);

  //JSX

  if (isLoadingActiveAuctions || isLoadingEmploymentState) {
    return (
      <div className="flex justify-center items-center align-items-center pt-[10vh]">
        <Spinner animation="border" size="large" />
      </div>
    );
  }

  const handleSelect = (key) => {
    if (key === 'active' && !some(activeContracts)) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        title: t('screens.companies.alerts.noActiveContracts.title'),
        description: t('screens.companies.alerts.noActiveContracts.description'),
      });
      // do not change the tab
      return false;
    }

    setIsActive(key === 'active');
  };

  const getActiveKey = () => {
    if (!some(activeContracts)) {
      return 'inactive';
    }

    return isActive ? 'active' : 'inactive';
  };

  return (
    <Container className="flex flex-col items-center my-4" id="pageContainer">
      <h1 className="mb-4">{t('screens.offers.manageOffers.header')}</h1>
      <Tab.Container id="OffersTables" className="flex-nowrap" onSelect={handleSelect} unmountOnExit mountOnEnter activeKey={getActiveKey()}>
        <Nav fill variant="tabs" defaultActiveKey="/activeAuctions" className="flex-nowrap w-100">
          <Nav.Item>
            <Nav.Link eventKey="active">
              {t('screens.manageOffers.tabs.activeOffers')} ({activeAuctions.length})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="inactive">{t('screens.manageOffers.tabs.pastOffers')}</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="flex-nowrap w-100 py-5">
          <Tab.Pane eventKey="active">
            <div className="flex flex-col gap-4">{activeAuctionsContent}</div>
          </Tab.Pane>
          <Tab.Pane eventKey="inactive">
            <Row className="mb-4">
              <PastOffersTable />
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default ManageOffers;
