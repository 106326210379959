import PropTypes from 'prop-types';
import React from 'react';

const Box = ({ title, children, footer, headerClassName = '', bodyClassName = '', extraHeaderContent = null, ...props }) => {
  return (
    <div
      className={`flex flex-col w-full h-full bg-zinc-50 border-solid border-1 border-[#08796F] rounded-[10px] py-[1rem] px-[1.5rem] ${props.className}`}
    >
      {title && (
        <div className={`flex justify-start items-center rounded-tl-xl rounded-tr-xl font-medium mb-[1rem] ${headerClassName}`}>
          <h2>{title}</h2>
          {extraHeaderContent}
        </div>
      )}
      <div className={`flex flex-col flex-1 ${bodyClassName}`}>{children}</div>
      {footer && <div className="flex justify-end align-items-center">{footer}</div>}
    </div>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default Box;
