import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'components/UI/Tooltip';

export const BidInputControllerWrapper = ({ controller, errors, isInvalid, ...props }) => {
  const TooltipTrigger = <FontAwesomeIcon icon={faExclamationCircle} className="text-red-700 h-4 w-4 opacity-50" title="Invalid input" />;
  return (
    <div className={`flex items-center relative ${props.className}`} {...props}>
      {controller}
      {isInvalid && (
        <div className="absolute inset-y-0 ps-1 flex items-center end-[0px]">
          <div className="relative flex items-center">
            <Tooltip Content={errors} Trigger={TooltipTrigger} />
          </div>
        </div>
      )}
    </div>
  );
};
