import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Selector = ({ options, fieldPath = '' }) => {
  const { register } = useFormContext();
  return (
    <Form.Select {...register(fieldPath)}>
      {options.map(({ value, label }, index) => (
        <option key={index} value={value}>
          {label}
        </option>
      ))}
    </Form.Select>
  );
};

export default Selector;
