import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

export const CompanyDetailsSubDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activeContracts } = useContext(AuthContext);

  if (isEmpty(activeContracts)) {
    return null;
  }

  if (activeContracts.length === 1) {
    return (
      <NavDropdown.Item className="text-start" onClick={() => navigate('/user/companies')}>
        {t('components.NavigationBar.companyDetails')}
      </NavDropdown.Item>
    );
  }

  return (
    <NavDropdown
      title={t('components.NavigationBar.companyDetails')}
      id="company-details-dropdown"
      drop="start"
      bsPrefix="nested-dropdown"
      className="text-start flex [&>.nested-dropdown]:text-[#333333] [&>.nested-dropdown]:flex-1 [&>.nested-dropdown]:py-1 [&>.nested-dropdown]:px-4 [&>.nested-dropdown]:before:hidden [&>.nested-dropdown]:hover:bg-[#F5F5F5] [&>.nested-dropdown]:hover:cursor-pointer"
    >
      {activeContracts.map((contract) => {
        return (
          <NavDropdown.Item className="text-start" onClick={() => navigate(`/user/companies/${contract.companyId}`)}>
            {contract.companyName}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};
