import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferCreationNavigationUtils } from 'hooks/offers/useOfferCreationNavigationUtils';

import { NavbarActionsContainer } from './NavbarActionsContainer';
import { OfferCreationSteps } from './OfferCreationStepper';

export const OfferCreationNavigation = () => {
  const { offer } = useContext(OfferCreationContext);
  const { showNavigationBar } = useOfferCreationNavigationUtils();

  if (!showNavigationBar) {
    return null;
  }

  return (
    <>
      <div className="fixed bottom-0 w-100 backdrop-blur-lg p-2">
        <Container className="flex flex-col md:!flex-row gap-3 justify-between align-items-center">
          <OfferCreationSteps offer={offer} />
          <NavbarActionsContainer />
        </Container>
      </div>
    </>
  );
};
