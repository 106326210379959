import { StyledTable } from '../TableQuery/styles';

export const ScrollableTable = ({ maxHeight = '80vh', style, children }) => {
  return (
    <div className="scrollableTable" style={{ maxHeight }}>
      <StyledTable borderless hover style={{ ...style }}>
        {children}
      </StyledTable>
    </div>
  );
};
