import { ErrorMessage } from '@hookform/error-message';
import PhoneNumberUtil from 'libphonenumber-js';
import { useContext } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { useInitiation } from 'hooks/useInitiation';

import { useInitTFASubmission } from './useInitTFASubmission';

export const TFAModal = ({ onHide, delayedMutation, onInitiationError, setShow, mobileOnly }) => {
  const { t } = useTranslation();
  const { mobile, email } = useContext(AuthContext);

  //INITIATE PIN CODES AUTHENTICATION
  const {
    initPinCodes,
    resendPinCode,
    isLoading: isInitiationLoading,
    encryptedTFASubmission,
  } = useInitTFASubmission({ setShow, onHide, onInitiationError, mobileOnly });

  /** init pin codes on start */
  useInitiation(initPinCodes);

  //FORM CONTEXT
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  //ON SUBMIT
  const onSubmit = ({ emailPinCode, smsPinCode }) => {
    const TFASubmission = {
      encryptedTFASubmission,
      emailPinCode,
      smsPinCode,
    };
    delayedMutation(TFASubmission);
  };

  //ADDITIONAL HOOKS
  const formattedMobile = mobile ? PhoneNumberUtil(mobile).formatNational() : '';

  //JSX
  if (isInitiationLoading) {
    return null;
    /*
    we do not return <Spinner> because it is the hook caller responsibility to update 
    the user about 'loading state' and we do not want two spinners one next to the other 
    */
  }
  return (
    /**
     * if error like 'cannot update state after component mount / memory leak
     * it is probably because the modal is on 'show={true}'
     */
    <Modal show={true} onHide={onHide} centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('components.PinCodesValidationModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4">
            <ErrorMessage errors={errors} name="general" render={({ message }) => <Alert variant="danger">{t(message)}</Alert>} />
            <Form.Group>
              <Form.Label>
                {t('components.PinCodesValidationModal.labels.smsPinCode')} {formattedMobile}
              </Form.Label>
              <Form.Control
                {...register('smsPinCode')}
                isInvalid={errors.smsPinCode}
                tabIndex={0}
                autoFocus
                inputMode="numeric"
                autoComplete="one-time-code"
                /** This is for mobile keyboard to only show numbers, */
                pattern="[0-9]*"
                maxLength="6"
                minLength="6"
              />
              <ErrorMessage
                errors={errors}
                name="smsPinCode"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
              <div className="d-flex align-items-center gap-1 text-muted-more">
                {t('components.PinCodesValidationModal.labels.didntRecieveSmsPinCode')}
                <Button variant="link" onClick={() => resendPinCode('sms')} tabIndex={4}>
                  {isInitiationLoading ? <Spinner animation="border" /> : t('components.PinCodesValidationModal.links.sendAgain')}
                </Button>
              </div>
            </Form.Group>
            {!mobileOnly && (
              <Form.Group>
                <Form.Label>
                  {t('components.PinCodesValidationModal.labels.emailPinCode')} {email}
                </Form.Label>
                <Form.Control
                  {...register('emailPinCode')}
                  required={true}
                  isInvalid={errors.emailPinCode}
                  tabIndex={0}
                  inputMode="numeric"
                  /** This is for mobile keyboard to only show numbers, */
                  pattern="[0-9]*"
                  maxLength="6"
                  minLength="6"
                />
                <ErrorMessage
                  errors={errors}
                  name="emailPinCode"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
                <div className="d-flex align-items-center gap-1 text-muted-more">
                  {t('components.PinCodesValidationModal.labels.didntRecieveEmailPinCode')}
                  <Button variant="link" onClick={() => resendPinCode('email')} tabIndex={5}>
                    {isInitiationLoading === 'email' ? <Spinner animation="border" /> : t('components.PinCodesValidationModal.links.sendAgain')}
                  </Button>
                </div>
              </Form.Group>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" tabIndex={3} className="w-full">
            {isInitiationLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : (
              t('components.PinCodesValidationModal.confirm')
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TFAModal;
