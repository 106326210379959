import { Button, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BankAccountRow } from './BankAccountRow';

export const BankAccountsFormTable = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bankAccounts',
  });

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>{t('screens.company.labels.bank')}</th>
            <th>{t('screens.company.labels.branchNumber')}</th>
            <th>{t('screens.company.labels.accountNumber')}</th>
            <th>{t('screens.company.labels.isCashAccount')}</th>
            <th>{t('screens.company.labels.isSharesAccount')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            return <BankAccountRow key={item.id} index={index} remove={remove} />;
          })}
        </tbody>
      </Table>

      <div className="d-flex justify-center">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            append({
              bank: '',
              branchNumber: '',
              accountNumber: '',
              isCashAccount: true,
              isSharesAccount: true,
            });
          }}
        >
          {t('screens.company.buttons.addAccount')}
        </Button>
      </div>
    </>
  );
};
