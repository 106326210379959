import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';

import { ALERT_TYPES } from 'shared/const/alerts';
import { mobileOnlySchema, schema } from 'shared/schemas/pinCodeValidation';

import TFAModal from './TFAModal';

/**
 * this custom hook is used to handle the TFA validation process.
 * it mimics the useMutation hook, but instead of calling the mutationFn immediately, it goes through a two factor authentication process.
 * finally it calls the original mutationFn but adds 'TFASubmission' to the payload.
 * on the server side, there is a middleware that validates the TFASubmission and only then calls the original flow.
 *
 * !important!
 * this hook relies on the assumption that this user email and mobile are set in the AuthContext.
 */
export const useTFAMutation = ({ mainMutationParams, initiationMutationParams, mobileOnly = false }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [originalPayload, setOriginalPayload] = useState(null);
  const { addAlert } = useContext(AlertContentContext);

  //PIN CODES FORM INITIALIZATION
  const defaultValues = useMemo(() => {
    return {
      smsPinCode: '',
      emailPinCode: '',
    };
  }, []);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(mobileOnly ? mobileOnlySchema : schema),
  });

  const { setError, reset } = methods;
  const onHide = useCallback(() => {
    setIsLoading(false);
    setShow(false);
    reset();
  }, [setIsLoading, setShow, reset]);

  //DELAYED MUTATION (FOR THE MODAL)
  const pinCodesErrorHandling = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      if (!errorName) return;
      switch (errorName) {
        case 'WRONG_EMAIL_AND_SMS_CODE':
          setError('emailPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.WRONG_EMAIL_CODE' });
          setError('smsPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.WRONG_SMS_CODE' });
          break;
        case 'WRONG_EMAIL_CODE':
          setError('emailPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.WRONG_EMAIL_CODE' });
          break;
        case 'WRONG_SMS_CODE':
          setError('smsPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.WRONG_SMS_CODE' });
          break;
        case 'TOO_MANY_REQUESTS':
          setError('general', { type: 'manual', message: 'components.PinCodesValidationModal.errors.TOO_MANY_REQUESTS' });
          break;
        case 'BAD_PHONE_NUMBER_FORMAT':
          setError('mobile', { type: 'manual', message: 'components.PinCodesValidationModal.errors.BAD_PHONE_NUMBER_FORMAT' });
          break;
        case 'SMS_CODE_EXPIRED':
          setError('smsPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.SMS_CODE_EXPIRED' });
          break;
        case 'EMAIL_COULD_NOT_BE_SENT':
          setError('smsPinCode', { type: 'manual', message: 'components.PinCodesValidationModal.errors.EMAIL_COULD_NOT_BE_SENT' });
          break;
        case 'EMAIL_AND_SMS_CODE_EXPIRED':
          const errorAlertContent = {
            title: t('components.PinCodesValidationModal.errors.EMAIL_AND_SMS_CODE_EXPIRED.title'),
            description: t('components.PinCodesValidationModal.errors.EMAIL_AND_SMS_CODE_EXPIRED.description'),
            type: ALERT_TYPES.ERROR,
          };
          addAlert(errorAlertContent);
          onHide();
          break;
        default:
          return false;
      }
      return true;
    },
    [setError, addAlert, t, onHide],
  );

  const delayedMutationParams = useMemo(() => {
    const { mutationKey, mutationFn: parentMutationFn, onSuccess: parentOnSuccess, onError: parentOnError } = mainMutationParams;
    const mutationParams = {
      mutationKey,
      mutationFn: async (TFASubmission) => {
        const result = await parentMutationFn({ ...originalPayload, TFASubmission });
        return result;
      },
      onSuccess: (data) => {
        onHide();
        if (parentOnSuccess) {
          parentOnSuccess(data);
        }
      },
      onError: (error) => {
        const errorIsPinCodesError = pinCodesErrorHandling(error);
        if (!errorIsPinCodesError && parentOnError) {
          parentOnError(error);
          onHide();
        }
      },
    };
    return mutationParams;
  }, [pinCodesErrorHandling, onHide, originalPayload, mainMutationParams]);
  const { mutate: delayedMutation } = useMutation(delayedMutationParams);

  //MODAL (AND INIT PIN CODES)
  const ValidationModal = show ? (
    <>
      <FormProvider {...methods}>
        {/* initiationMutationParams.onInitiationError is an optional error handler for the internal 'initPinCodesAuth' api call  */}
        <TFAModal
          onHide={onHide}
          delayedMutation={delayedMutation}
          onInitiationError={initiationMutationParams?.onInitiationError}
          setShow={setShow}
          mobileOnly={mobileOnly}
        />
      </FormProvider>
    </>
  ) : null;

  //ADDITONAL HOOKS
  /*a 'starter' like function for the hook user to start the whole process*/
  const starter = useCallback(
    (data) => {
      setIsLoading(true);
      setShow(true);
      setOriginalPayload(data);
    },
    [setIsLoading, setShow, setOriginalPayload],
  );

  return { mutate: starter, isLoading, ValidationModal };
};
