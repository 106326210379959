import PhoneNumberUtil from 'libphonenumber-js';
import { useContext, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { AuctionResultsEmailsTextArea } from 'components/UI/AuctionResultsEmailsTextArea';

const OfferContacts = () => {
  const { t } = useTranslation();
  const { offer } = useContext(OfferCreationContext);
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const ContactPersonOptions = useMemo(() => {
    const employees = offer?.initiation?.investingCompany.employees;

    return (
      <>
        {employees.map((user, index) => {
          return (
            <option key={index} value={user.userId}>
              {user.firstName} {user.lastName}
            </option>
          );
        })}
      </>
    );
  }, [offer]);

  const contactPersonId = watch('contactPerson');
  const contactPersonDetails = useMemo(() => {
    const employees = offer?.initiation?.investingCompany?.employees;
    const contactPerson = employees?.find((user) => user.userId === contactPersonId);
    const rawNumber = contactPerson?.mobile;
    const mobile = rawNumber ? PhoneNumberUtil(rawNumber).formatNational() : '';
    const result = {
      email: contactPerson?.email,
      mobile,
    };
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, watch, contactPersonId]);

  return (
    <>
      <h3>{t('screens.LimitationsAndContacts.form.Contacts.header')}</h3>

      <Form.Group className="mb-4">
        <Form.Text className="block">
          {t('screens.LimitationsAndContacts.form.contacts.labels.initiator')} {offer.initiation.activity.firstName}{' '}
          {offer.initiation.activity.lastName}
        </Form.Text>
        <Form.Text className="block">
          {t('screens.LimitationsAndContacts.form.contacts.labels.investingCompanyName')} {offer.initiation.investingCompany.name}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label> {t('screens.LimitationsAndContacts.form.contacts.labels.contactPerson')} </Form.Label>
        <Form.Select type="text" {...register('contactPerson')} isInvalid={errors.contactPerson}>
          {ContactPersonOptions}
        </Form.Select>
        <Form.Text className="block"> {t('screens.LimitationsAndContacts.form.contacts.labels.contactPersonDetails')} </Form.Text>
        <Form.Text className="block">
          {`${t('screens.LimitationsAndContacts.form.contacts.labels.contactPersonMobile')}: ${contactPersonDetails.mobile}`}
        </Form.Text>
        <Form.Text className="block">
          {`${t('screens.LimitationsAndContacts.form.contacts.labels.contactPersonEmail')}: ${contactPersonDetails.email}`}
        </Form.Text>
        <Form.Check type="checkbox" {...register('sendAcceptanceSMS')} className="inline relative -bottom-2" />
        <Form.Label style={{ marginTop: '7px' }}> {t('screens.LimitationsAndContacts.form.contacts.labels.sendAcceptanceSMS')} </Form.Label>
      </Form.Group>

      <AuctionResultsEmailsTextArea formFieldName="emailsForAuctionResults" shouldDirty />
    </>
  );
};

export default OfferContacts;
