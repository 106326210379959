import isNumber from 'lodash/isNumber';
import { useCallback, useContext, useEffect } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { columnNames, orderedDividedAccountsTableHeaders } from 'shared/const/dividedAccountsTable';
import { normalizeBankName } from 'shared/const/israelBanks';
import { sharesIsraelBanks } from 'shared/const/israelBanks';
import { calculateLimits } from 'shared/utils/offers';

import { dictionariesUtil } from './utils';

export const useDividedAccountsParserInserter = () => {
  const {
    dividedAccountsUploadedArray,
    setDividedAccountsUploadedArray,
    setHasLoadedDividedAccounts,
    setDividedAccountsState,
    setDividedAccountsFileErrors,
    setScopeAndAccountsErrors,
  } = useContext(OfferCreationContext);

  const hasHeaderErrors = useCallback((errorObject) => {
    const hasErrors = errorObject.missingHeaders.length > 0 || Object.keys(errorObject.duplicatedHeaders).length > 0;
    return hasErrors;
  }, []);

  const validateArrayFormat = useCallback(
    (array) => {
      const { newHeaders, columnAliasesAsArray } = dictionariesUtil();

      /* Replace headers in the sheet and track replacements */
      const arrayHeaders = Object.keys(array[0]);
      const replacementCounts = Object.fromEntries(newHeaders.map((key) => [key, []]));
      arrayHeaders.forEach((header, index) => {
        for (const [newHeader, oldHeaders] of columnAliasesAsArray) {
          if (oldHeaders.includes(header.trim())) {
            /* Update cell value with new header */
            array.forEach((row) => {
              row[newHeader] = row[header];
              delete row[header];
            });

            replacementCounts[newHeader].push(header);
          }
        }
      });

      const headersErrorsCollector = {
        missingHeaders: [],
        duplicatedHeaders: {},
      };

      /* Validate replacements */
      for (const [newHeader, oldHeaders] of Object.entries(replacementCounts)) {
        const count = oldHeaders.length;
        if (count === 0) {
          if (newHeader === columnNames.maxPercentageFromAuction) continue; // maxPercentageFromAuction is optional
          headersErrorsCollector.missingHeaders.push(newHeader);
        } else if (count > 1) {
          headersErrorsCollector.duplicatedHeaders[newHeader] = oldHeaders;
        }
      }

      /* Keep only necessary columns */
      array = array.map((row) => {
        const newRow = {};
        newHeaders.forEach((key) => {
          newRow[key] = row[key];
        });
        return newRow;
      });

      /* Filter out empty objects */
      const filteredArray = array.map((row) => {
        return Object.fromEntries(Object.entries(row).filter(([key, value]) => value !== ''));
      });

      const originalHeaders = Object.fromEntries(Object.entries(replacementCounts).map(([newHeader, oldHeaders]) => [newHeader, oldHeaders[0]]));

      const headersErrors = hasHeaderErrors(headersErrorsCollector) ? headersErrorsCollector : undefined;
      return { filteredArray, headersErrors, originalHeaders };
    },
    [hasHeaderErrors],
  );

  const validateFileData = useCallback((filteredArray, originalHeaders, offer) => {
    const { type, biddingLimitations, unitStructures } = offer?.initiation.auctionDetails || {};
    const { priceGap } = biddingLimitations || {};
    const { maxInterest, minPrice } = calculateLimits(biddingLimitations, unitStructures);
    const { newHeaders } = dictionariesUtil();
    const dataErrors = {
      empty: {},
      limitValue: [],
      gap: [],
      notANumber: {},
      excessiveLimit: [],
      badBankName: {},
    };

    const limits = [];
    filteredArray.forEach((row) => {
      const accountName = row['account_name'];
      /* Validate the row have a value for each one of the columns */
      /* first, we validate that at least one of the accounts is set */
      /* currently stashed. might be used in the future */
      // syncAccountDetails(row);

      /* now, we can validate the row, which should have a value for each one of the columns */
      newHeaders.forEach((header) => {
        if (!row[header]) {
          /* bank account number is not mandatory */
          if (header === columnNames.cash_account_number || header === columnNames.shares_account_number) {
            return;
          }

          /* bank account branch is not mandatory on specific banks */
          if (header === columnNames.cash_account_branch || header === columnNames.shares_account_branch) {
            const bankName = row[header.replace('_branch', '_bank')];
            if (bankName) {
              // if bank name is empty, we can't validate the branch, so we skip this validation
              const validBankName = normalizeBankName(bankName.trim());
              if (sharesIsraelBanks[validBankName]) {
                return;
              }
            }
          }

          /* maxPercentageFromAuction is not mandatory */
          if (header === columnNames.maxPercentageFromAuction) return;

          dataErrors.empty[accountName] = dataErrors.empty[accountName] || [];
          dataErrors.empty[accountName].push(header);
        }
      });

      /* Validate 'limit' and 'amount' are numerable and convert  */
      orderedDividedAccountsTableHeaders.forEach((key) => {
        if (['cash_account_bank', 'shares_account_bank'].includes(key)) return;

        const value = row[key];
        if (!value) return;

        if (['account_name', 'shares_account_number', 'cash_account_number'].includes(key)) {
          row[key] = value.toString().trim();
          return;
        }

        const trimmed = value.toString().trim().replace(/,/g, '');
        if (!trimmed) {
          row[key] = trimmed;
          return;
        }

        const numericValue = parseFloat(trimmed);
        if (!isNumber(numericValue)) {
          dataErrors.notANumber[accountName] = dataErrors.notANumber[accountName] || [];
          dataErrors.notANumber[accountName].push(originalHeaders[key]);
        } else {
          row[key] = numericValue;
        }
      });

      /* Validate limits maximum variety (3 max) */
      const { limit } = row;
      const newLimit = !limits.includes(limit);
      const alreadyThreeLimits = limits.length === 3;
      if (newLimit) {
        if (!alreadyThreeLimits) {
          limits.push(limit);
        } else {
          dataErrors.excessiveLimit.push(accountName);
        }
      }

      /* Validate the limit value can be divided by the gap value */
      if (priceGap > 0) {
        const badGap = (row.limit * 1000000) % (priceGap * 1000000) !== 0;
        if (badGap) {
          dataErrors.gap.push(accountName);
        }
      }

      /* Validate limit according to maxInterest / minPrice (based on auction type) */
      if ((type === auctionTypes.INTEREST || type === auctionTypes.GAP) && maxInterest && row.limit > maxInterest) {
        dataErrors.limitValue.push(accountName);
      }
      if (type === auctionTypes.PRICE && row.limit < minPrice) {
        dataErrors.limitValue.push(accountName);
      }

      /* Validate bank names */
      ['cash_account_bank', 'shares_account_bank'].forEach((key) => {
        if (!row[key]) return;
        /* if an excel sheet cell have a numerable string as value (exp: '10') it will parse it as number automatically */
        const validBankName = normalizeBankName(row[key].toString().trim());
        if (!validBankName) {
          dataErrors.badBankName[accountName] = dataErrors.badBankName[accountName] || [];
          dataErrors.badBankName[accountName].push(row[key]);
        } else {
          row[key] = validBankName;
        }
      });
    });

    const isNotEmpty = (input) => {
      if (Array.isArray(input)) {
        return input.length > 0;
      } else if (typeof input === 'object' && input !== null) {
        return Object.keys(input).length > 0;
      }
    };

    const anyErrorsFound = Object.values(dataErrors).some((prop) => isNotEmpty(prop));

    if (!anyErrorsFound) return null;
    return dataErrors;
  }, []);

  const handleParseError = useCallback(
    (errorMsg, errorData) => {
      console.log(errorMsg, errorData);
      setDividedAccountsUploadedArray(null);
      setDividedAccountsFileErrors(errorData);
      throw new Error(errorMsg);
    },
    [setDividedAccountsUploadedArray, setDividedAccountsFileErrors],
  );

  const compactArray = useCallback((array) => {
    const compactedArray = array.filter((row) => {
      const cellsWithValues = Object.values(row).filter((value) => value !== '');
      /**
       * a partially filled row can be one of two things:
       * 1. a row that is missing a value in one or more of the required columns
       * 2. any additional data that was added to the sheet (exp: calculated sums, comments, etc.)
       * we want to filter out the second case, but handle the first case as an missing value error.
       * we can assume that a row with less than 5 cells only contain an additional data personally used by our investors.
       * (done the same way in the Auction system as well)
       */
      return cellsWithValues.length > 4;
    });
    return compactedArray;
  }, []);

  const parseArrayForTable = useCallback(
    (array) => {
      // first compact the array (remove empty rows)
      array = compactArray(array);
      const { filteredArray, headersErrors, originalHeaders } = validateArrayFormat(array);
      if (headersErrors) handleParseError('headersErrors', headersErrors);

      const dataErrors = validateFileData(filteredArray, originalHeaders);
      if (dataErrors) handleParseError('dataErrors', dataErrors);

      return filteredArray;
    },
    [validateFileData, validateArrayFormat, handleParseError, compactArray],
  );

  const resetErrors = useCallback(() => {
    setScopeAndAccountsErrors((prev) => {
      const updatedErrors = { ...prev };

      Object.keys(updatedErrors).forEach((key) => {
        if (key.match(/^table\.(\d+)\./)) {
          delete updatedErrors[key];
        }
      });

      return updatedErrors;
    });
  }, [setScopeAndAccountsErrors]);

  useEffect(() => {
    // must await setDividedAccountsState to allow for the errors to flow correctly
    // therefore the function.
    const handleDividedAccountingUpload = async () => {
      setDividedAccountsFileErrors(null);

      try {
        const dataForTable = parseArrayForTable(dividedAccountsUploadedArray);
        await setDividedAccountsState(dataForTable);
        setHasLoadedDividedAccounts(true);
        setDividedAccountsUploadedArray(null);
        resetErrors();
      } catch (err) {
        // note the actual error handling is happening at handleParseError()
        // done this way because the Error object don't accept data.
        console.error(err);
      }
    };

    if (dividedAccountsUploadedArray) {
      handleDividedAccountingUpload();
    }
  }, [
    dividedAccountsUploadedArray,
    setDividedAccountsState,
    setDividedAccountsUploadedArray,
    parseArrayForTable,
    setDividedAccountsFileErrors,
    setHasLoadedDividedAccounts,
    resetErrors,
  ]);
};
