import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { isObjectId } from 'shared/utils/validate';

const TitleHandler = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const split = pathname.split('/')?.filter((item) => {
    return item !== '' && !isObjectId(item);
  });
  const routeLocalePath = `components.TitleHandler.routes.${split.join('.')}.title`;

  let title;
  if (i18n.exists(routeLocalePath)) {
    title = `${t(routeLocalePath)} | ${t('common.smartbullOnline')}`;
  } else {
    title = t('common.smartbullOnline');
  }

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default TitleHandler;
