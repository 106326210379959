/**
 * Returns an array of bids field values with a priority of results bids over bids.
 * For example, if we have results bids and the resultBidField is requested amount
 * we want to return an array with all the bids results requested amounts.
 * @param {object} offer - The offer object.
 * @param {string} bidField - The field  for accessing the value in bids array.
 * @param {string} resultBidField - The field for accessing the value in bid results array.
 */
export const getBidsResultsFieldArray = (offer, bidField, resultBidField) => {
  const mosadiBidsResults = offer.results?.mosadi?.bids;
  // If we have results bids, we want to show the results bids.
  if (mosadiBidsResults) {
    return mosadiBidsResults.map((bid) => bid[resultBidField]) || [];
  } else {
    // We don't have results bids, so we want to show the bids.
    return offer.bids.map((bid) => bid[bidField]) || [];
  }
};
