export const PUBLIC_OFFERINGS_FILTERS = {
  ISSUER: 'issuer',
  LINKAGE: 'linkage',
  SECTOR: 'sector',
  COVENANT: 'covenant',
  OFFERING_TYPE: 'offeringType',
  AUCTION_TYPE: 'auctionType',
  LEAD_UNDERWRITER: 'leadUnderwriter',
  MIN_DURATION: 'minDuration',
  MAX_DURATION: 'maxDuration',
  MIN_RATING: 'minRating',
  MAX_RATING: 'maxRating',
  MOSADI_AUCTION_DATE: 'mosadiAuctionDate',
};

export const MOSADI_AUCTION_DATE_OPTIONS_VALUES = {
  LAST_TWELVE_MONTHS: 'LAST_TWELVE_MONTHS',
  LAST_SIX_MONTHS: 'LAST_SIX_MONTHS',
  LAST_THREE_MONTHS: 'LAST_THREE_MONTHS',
  LAST_MONTH: 'LAST_MONTH',
  START_OF_THIS_YEAR: 'START_OF_THIS_YEAR',
  START_OF_LAST_YEAR: 'START_OF_LAST_YEAR',
  ALL: 'ALL',
};
