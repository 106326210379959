import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import Box from 'components/UI/Box';
import ModalConfirm from 'components/UI/ModalConfirm';
import UserIdentityForm from 'components/UserDetails/Form/UserIdentityForm';

import UserAdditionalInfoForm from './UserAdditionalInfoForm';
import UserRoles from './UserRoles';
import UserRolesAsForm from './UserRolesAsForm';

const UserForm = ({ onCancel, onSubmit, isLoading, actionStr, actionVariant, proceedModalContent, showModal, setShowModal }) => {
  const { isSBAdmin } = useContext(AuthContext);

  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isDirty },
    trigger,
  } = useFormContext();

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!isDirty) {
      alert('No changes detected');
      return;
    }
    const isValid = await trigger();
    isValid && setShowModal(true);
  };

  return (
    <Container>
      <form onSubmit={handleOnSubmit}>
        <Row className="mt-3">
          <Col md={6}>
            <Box title={t('screens.user.titles.identifyingInfo')}>
              <UserIdentityForm />
            </Box>
          </Col>
          <Col md={6}>
            <Box title={t('screens.user.titles.additionalInfo')}>
              <UserAdditionalInfoForm />
            </Box>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Box title={t('screens.user.titles.UserRoles')}>{isSBAdmin ? <UserRolesAsForm /> : <UserRoles />}</Box>
          </Col>
        </Row>

        <Row className="mt-3">
          <div className="d-flex flex-row-reverse gap-2">
            <Button type="submit" variant={actionVariant}>
              {actionStr}
            </Button>
            <Button type="cancel" variant="secondary" onClick={onCancel}>
              {t('screens.company.actions.cancel')}
            </Button>
          </div>
        </Row>
        <ModalConfirm
          show={showModal}
          onClose={() => setShowModal(false)}
          onProceed={handleSubmit(onSubmit)}
          title={actionStr}
          variant={actionVariant}
          content={proceedModalContent}
          isLoading={isLoading}
          proceed={t('screens.user.actions.proceed')}
        />
      </form>
    </Container>
  );
};

export default UserForm;

UserForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  actionStr: PropTypes.string.isRequired,
  actionVariant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
  proceedModalContent: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
