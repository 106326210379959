import isNumber from 'lodash/isNumber';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { sharesIsraelBanks } from 'shared/const/israelBanks';

import { useDividedAccountsInputWrapper, useScopeAndAccountsErrors } from 'hooks/offers';

import './style.css';

export const GeneralInput = ({ index, name, type = 'number', onPaste, inputProps = {} }) => {
  const fieldName = useMemo(() => `table.${index}.${name}`, [index, name]);
  const { setError, clearError } = useScopeAndAccountsErrors(fieldName);
  const { t } = useTranslation();
  const { watch, setValue, control } = useFormContext();
  const watchFieldName = watch(fieldName);
  const bankAccountType = name.split('_')[0]; // cash or shares
  const watchChosenBank = watch('table')[index][`${bankAccountType}_account_bank`];

  useEffect(() => {
    /* trick watch update to overcome react-hook-form bug */
    /* watch is not updated when the value is an object and and inner value is changed */
    /* stringifying the object and saving it in a different field accomplishes the needed update */
    const tableStringified = JSON.stringify(watch('table'));
    setValue('tableStr', tableStringified);
  }, [watch, setValue, watchFieldName]);

  /* validate value */
  useEffect(() => {
    let isRequired = true;
    let haveValue = watchFieldName?.toString().length;

    // if a shares account bank was chosen, branch is not required
    if (name === 'shares_account_branch' || name === 'cash_account_branch') {
      if (watchChosenBank && sharesIsraelBanks[watchChosenBank]) {
        isRequired = false;
      }
    }

    if (name === 'shares_account_number' || name === 'cash_account_number') {
      isRequired = false;

      if (haveValue) {
        const illegalCharsErrorMsg = t('components.DividedAccountsInputTable.table.errors.illegalChars');
        !/^[0-9-./\s]*$/.test(watchFieldName) ? setError(illegalCharsErrorMsg) : clearError(illegalCharsErrorMsg);
      }
    }

    // maxPercentageFromAuction is not required
    if (name === 'maxPercentageFromAuction') {
      isRequired = false;
    }

    const errorMsg = t('components.DividedAccountsInputTable.table.errors.required');
    if (isRequired) {
      haveValue ? clearError(errorMsg) : setError(errorMsg);
    } else {
      clearError(errorMsg);
    }

    if (haveValue && type === 'number') {
      const notANumberErrorMsg = t('components.DividedAccountsInputTable.table.errors.notANumber');
      !isNumber(watchFieldName) ? setError(notANumberErrorMsg) : clearError(notANumberErrorMsg);
    }
  }, [setError, clearError, watchFieldName, watchChosenBank, type, name, t, index, watch]);

  const controller = useMemo(() => {
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => {
          const onChangeWrapper = (event) => {
            if (type === 'number') {
              onChange(+event.target.value || null);
            } else {
              onChange(event.target.value);
            }
          };

          inputProps.type = type;
          inputProps.value = value;
          inputProps.onChange = onChangeWrapper;

          if (onPaste) {
            inputProps.onPaste = onPaste;
          }

          return <Form.Control className="form-control p-0" {...inputProps} />;
        }}
      />
    );
  }, [fieldName, control, onPaste, type, inputProps]);

  return useDividedAccountsInputWrapper({ controller, fieldName, onPaste });
};
