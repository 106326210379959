const TermsOfService = () => {
  return (
    <div>
      <div className="text-center mb-4">
        <h1>תנאי שימוש למשקיעים</h1>
      </div>
      <p>
        תנאי שימוש אלה קובעים את זכויותיך וחובותיך בעת השימוש בפלטפורמה הטכנולוגית (לרבות האתר, האפליקציה וכל פלטפורמה אחרת שעל גביה יוצגו השירותים
        (להלן: "הפלטפורמה") ובשירותים אשר מסופקים לך על ידי חברת סמארטבול בע"מ וחברות הבת שלה (להלן יחדיו: "סמארטבול" או "החברה", וה"שירותים"). יש
        לקרוא תנאים אילו יחד עם מדיניות הפרטיות של החברה אשר מצויה ב-online.smartbull.co.il/privacy-policy (תנאי השימוש ומדיניות הפרטיות יקראו יחדיו
        להלן "התנאים"). אנא קרא תנאים אלה בקפידה בטרם השימוש בפלטפורמה ו/או בשירותים. בתנאים אלה, פירוש המילים "לרבות" ו"כולל" הינו "לרבות, בין היתר".
      </p>
      <p>
        השימוש שלך בפלטפורמה ו/או בשירותים מעידה על הסכמתך לתנאים אלה (בלא כל תיקון או הוספה), ומבססת יחסים חוזיים בינך לבין סמארטבול. במידה ואינך
        מסכים לתנאים אלה בכללותם, אינך רשאי לגשת או לעשות שימוש בפלטפורמה ו/או בשירותים. תנאים אלה גוברים במפורש על הסכמים או על הסדרים קודמים מולך.
        סמארטבול רשאית לחסום את השימוש שלך ו/או גישתך \ לפלטפורמה ו/או לחלק או לכל השירותים בפלטפורמה, לתקופה מוגבלת או בלתי מוגבלת, בהתאם לשיקול דעתה
        הבלעדי ומכל סיבה שהיא וללא צורך בנימוק או הסבר. אם נחסמה גישתך לפלטפורמה כאמור, לא תהיה/י רשאי/ת לשוב ולהירשם בפלטפורמה תחת שם משתמש/ת אחר/ת
        בתקופת ההגבלה, ככל שתהיה כזו. לתשומת לבך כי רישום לפלטפורמה תוך התחזות לאחר מהווה עבירה פלילית ואסורה על פי חוק.
      </p>
      <p>
        סמארטבול רשאית, בהתאם לשיקול דעתה הבלעדי, לתקן, מעת לעת, את התנאים הנוגעים לפלטפורמה ו/או לשירותים. תיקונים אלה ייכנסו לתוקפם עם פרסום התנאים
        המעודכנים על ידי סמארטבול בכתובת זאת, בפלטפורמה סמארטבול או בתנאים הנוספים בשירות(ים) הרלוונטי(ים). המשך שימושך בפלטפורמה ו/או בשירותים לאחר
        פרסום זה מהווה את הסכמתך לתנאים המתוקנים.
      </p>

      <ol>
        <li>
          <span className="text-xl font-medium underline">השירותים</span>
          <br />
          <p>
            השירותים מהווים פלטפורמת טכנולוגיה המאפשרת למשתמשים הנמנים על אחד מהגופים המוגדרים כ-"משקיע מסווג" בתקנות ניירות ערך (אופן הצעת ניירות ערך
            לציבור), תשס"ז-2007, לשלוח בקשות הקשורות בניהול תיק ני״ע, דוגמת שליחת הצעות במסגרת מכרזים למשקיעים מסווגים. כל שימוש בפלטפורמה ו/או
            בשירותים יעשה למטרות חוקיות בלבד ובהתאם לכל הוראות הדין הרלבנטיות ותנאי שימוש אלו.
          </p>
          <span className="underline">רישיון</span>
          <p>
            בכפוף לעמידתך בתנאים אלה, סמארטבול מעניקה לך רישיון אישי, מוגבל, לא בלעדי, בלתי ניתן להמחאה, ללא זכות לתת רישיונות משנה, ובלתי עביר: (1)
            לגישה ולשימוש בפלטפורמה סמארטבול; ו(2) לגישה ולשימוש בכל תוכן, מידע וחומרים שעשויים להיות מונגשים דרך השירותים. כל זכות שלא הוענקה במפורש
            בתנאים שמורה ל-סמארטבול.
          </p>
          <span className="underline">הגבלות</span>
          <p>
            במסגרת השימוש בפלטפורמה אינך רשאי: (1) לעשות כל שימוש אשר פוגע בפלטפורמה של סמארטבול; (2) לפגוע בזכויות הקניין הרוחניות (בין אם הן ניתנות
            לרישום ובין אם לאו) של סמארטבול ו/או כל משתמש ו/או כל צד שלישי אחר הקשור אליה לרבות באמצעות הסרת כל זכות יוצרים או סימן מסחרי רלבנטי; (3)
            לשכפל, לשנות, ליצור יצירות נגזרות, להפיץ, לתת רישיון, להשכיר, למכור, להעביר, להציג או לנצל באופן כלשהו את השירותים למעט כמותר במפורש על
            ידי סמארטבול; (4) לעשות די-קומפילציה (decompilation), להנדס לאחור (reverse engineering), או פעולות אחרות אשר נועדו להתחקות אחר קוד המקור
            של הפלטפורמה; (5) להעביר באמצעות בפלטפורמה של סמארטבול כל וירוס, "תולעת", באג או כל קוד או קובץ מחשב אחר אשר מזיק ו/או נועד להזיק
            לפלטפורמה ו/או למי מהמשתמשים בהם; (6) לנסות להשיג גישה לא מורשית לכל פן שהוא בשירותים או למערכות או לרשתות הקשורות אליהם או לפגוע בהם; (7)
            לעשות שימוש לא חוקי או לא מורשה בפלטפורמה ; או (8) לפגוע בפרטיות צדדים שלישיים ו/או משתמשים אחרים בפלטפורמה .
          </p>
          <span className="underline">בעלות</span>
          <p>
            הפלטפורמה וכל הזכויות בה לרבות כל זכויות הקניין הרוחני (בין שהן רשומות ובין שאינן רשומות) הינן ויישארו רכושה של סמארטבול. השימוש בפלטפורמה
            ו/או בשירותים לא מעבירים או מעניקים לך זכויות כלשהן למעט הרישיון המוגבל המוענק לעיל. הנך מתחייב שלא להעתיק, להפיץ, להציג, להעביר, לשנות,
            לעבד, ליצור יצירות נגזרות, למכור כל חלק מזכויותיה של החברה, בכל דרך או אמצעי בין אם אלקטרוניים, מכאניים, אופטיים או בכל אמצעי או דרך אחרת.
          </p>
        </li>
        <li>
          <span className="text-xl font-medium underline">שימושך בשירותים</span>
          <br />
          <span className="underline">חשבונות משתמש</span>
          <p>
            על מנת להשתמש בשירותים, עליך להירשם לחשבון משתמש ("חשבון") ולהחזיקו פעיל. עליך להיות בן 18 לפחות בכדי לפתוח חשבון. רישום לחשבון מחייב אותך
            להזין מידע אישי מסוים, כגון שמך, כתובתך, מספר הטלפון הנייד שלך וכתובת הדואר האלקטרוני שלך. על מנת להגיש הצעות על גבי הפלטפורמה, יש להזין
            גם פרטי חשבון בנק. הינך מסכים להחזיק בחשבונך מידע מדויק, מלא ומעודכן. אי החזקת מידע מדויק, מלא ומעודכן על ידך, לרבות פרטי חשבון שאינם
            תקינים או תקפים, עלול לגרום לאי יכולתך לגשת לשירותים או להשתמש בהם או לסיום הסכם זה עימך מצד סמארטבול. הנך נושא באחריות בלעדית ומלאה לכל
            פעילות שמתרחשת בחשבונך ומתחייב לשמור על בטיחות ועל סודיות שם המשתמש והסיסמה לחשבונך בכל עת. עליך להודיע לחברה ללא דיחוי על כל שימוש בלתי
            מורשה בחשבון שלך או כל פגיעה אחרת באבטחת המידע הנוגעת לסיסמא או לגניבת המכשיר הנייד שלך בדואר אלקטרוני support@smartbull.co.il .
          </p>
          <span className="underline">הצהרת משקיע כשיר.</span>
          <p>
            בעת הרישום לשירותים אתה מאשר ומצהיר כי אתה משקיע הנמנה בתוספת הראשונה לחוק ניירות ערך, תשכ"ח 1968 ועונה על לפחות אחד מהקריטריונים שנקבעו
            על ידי הרשות לניירות ערך, כפי שיתוקנו מעת לעת.
          </p>
          <span className="underline">שליחת מסרונים</span>
          <p>
            ביצירת חשבון הינך מסכים לקבל מסרוני מידע (SMS) או מיילים מאת החברה וזאת כחלק ממהלך העסקים הרגיל של שימושך בשירותים. אתה רשאי לבחור שלא
            לקבל מסרונים (SMS) מ-סמארטבול בכל עת. הינך מצהיר שבחירה שלא לקבל מסרונים (SMS) עשויה להשפיע על שימושך בשירותים. כמו כן החברה תהיה רשאית
            לשלוח אליך מעת לעת הודעות ו/או מיילים לגבי מכרזים ושירותים רלוונטיים, והנך מסכים בזאת לקבלת הודעות ו/או מיילים אילו.
          </p>
        </li>
        <li>
          <span className="text-xl font-medium ">ויתורים; הגבלת חבות; שיפוי.</span>
          <br />
          <p>
            סמארטבול אינה צד לעסקת מכירת ני״ע. זכות השימוש בשירותים ובפלטפורמה והנתונים המוצגים במסגרת המכרזים בפלטפורמה ניתנים לך כמות שהם (“AS-IS”),
            ושימושך בשירות ובפלטפורמה הינו על אחריותך האישית והבלעדית. סמארטבול לא תהיה אחראית לאמיתות או דיוק הפרטים המוצגים בפלטפורמה ואין בהצגת
            מכרז בפלטפורמה או במתן אפשרות למשתמשים להציע הצעות משום המלצה או מתן שרותי ייעוץ השקעות מטעם סמארטבול. הפרסום בפלטפורמה איננו מהווה הצעה
            ו/או ייעוץ ו/או שידול, כלליים או המתחשבים בנתונים ובצרכים המיוחדים של המשקיע לרכישה ו/או ביצוע פעולות ו/או עסקאות, ואין בהם משום המלצה
            ו/או חוות דעת ו/או תחליף לשיקול דעתך העצמאי. לפני ביצוע כל עסקה, השקעה ונטילת חבות כספית מכל סוג שהוא, בהסתמך על מידע המצוי בפלטפורמה,
            סמארטבול ממליצה להיוועץ ביועץ מקצועי מוסמך בתחום הרלוונטי.
          </p>
          <p>
            המשתמש מסכים, מצהיר ומתחייב במפורש ליטול על עצמו בלבד את כל האחריות מכל סוג שהוא לגבי החלטות ו/או פעולות עסקיות, כלכליות ו/או כספיות,
            לרבות כל סיכון אפשרי הנובעים מכל שימוש שייעשה בפלטפורמה. אין לחברה את היכולת לוודא כי המשקיע או המציע השלימו את עסקת המכירה בהצלחה, אלא
            ככל שמי מהם ידווח לה על כך.
          </p>
          <p>
            המשקיע מסכים בזאת במפורש כי השימוש בפלטפורמה הוא על אחריותו וסיכונו הבלעדי. סמארטבול, עובדיה, סוכניה, מנהליה, צדדים שלישיים המספקים תוכן
            אינם מתחייבים כי השימוש בפלטפורמה יהיה נטול הפרעות או חסר תקלות או טעויות או יינתן כסדרו בלא הפסקות. כמו-כן סמארטבול אינה ערבה לדיוק,
            למהימנות התוכן או המידע המפורסמים בפלטפורמה.
          </p>
          <p>
            סמארטבול לא תהא אחראית, בכל מקרה ועל פי כל תיאוריה משפטית כלשהי (לרבות נזיקית, חוזית, עשיית עושר שלא במשפט ועוד), כלפי המשקיע או כלפי כל
            אדם אחר, במישרין ובעקיפין, לכל נזק ישיר או עקיף, הנובעים משימוש בפלטפורמה ו/או בשירותים ו/או בשל חוסר יכולת לעשות שימוש בפלטפורמה ו/או
            בשירותים ו/או מאי תקינותו ו/או נזק למחשבו של משתמש הקצה ו/או מידע המאוחסן במחשבו של משתמש הקצה ו/או נזק הנובע ממחיקה מלאה או חלקית של
            המידע המאוחסן ולכל נזק אחר, צפוי או שאינו צפוי ומשתמש הקצה מצהיר בזאת כי הינו אחראי באופן בלעדי לכל שימוש הנעשה על ידו בפלטפורמה או
            במוצרים.
          </p>
          <p>סמארטבול לא תישא באחריות לפעילות בלתי חוקית שהתבצעה ע"י המשקיעים או המציעים או ע"י מי ממשתמשי הקצה בפלטפורמה.</p>
          <p>
            סמארטבול לא תישא באחריות לכל אתר חיצוני המקושר אליה באמצעות קישור או לינק. סמארטבול לא תישא באחריות לכל תוכן, פעולה, פרסום או מכירה
            הזמינים באתרים כאמור.
          </p>
          <p>
            מבלי לגרוע מהאמור לעיל ובכל מקרה, סך האחריות והחבות הכוללת של סמארטבול הנובעת או הקשורה לתנאי שימוש אלו, לא תעלה על סכום של 1,000 ש"ח.
          </p>
          <p>
            בכל מקרה של הפרה או הודעה על טענה להפרה, של משתמש קצה בפלטפורמה, סמארטבול תהיה רשאית למסור את פרטיו של משתמש הקצה, אם על פי צו שיפוטי, אם
            במסגרת הליך משפטי או בשל הודעה על כוונה לנקוט נגדה בהליך משפטי כאמור (פליליים או אזרחיים).
          </p>
          <p>
            הינך מסכים לשפות ולהגן על סמארטבול ועל נושאי המשרה, המנהלים, העובדים והסוכנים שלה מפני כל תביעה, דרישה, אובדן, חבות והוצאה (לרבות שכ"ט
            עורכי דין), הנובעים או נוגעים: (1) להפרת תנאים אילו על ידך; או (2) להפרת זכויותיו של כל צד שלישי שהוא על ידך.
          </p>
        </li>
        <li>
          <span className="text-xl font-medium underline">תניות אחרות</span>
          <p>כותרות הסעיפים נועדו לצרכי נוחות והתמצאות בלבד, ולא יעשה בהן כל שימוש לפרשנות ההסכם.</p>
          <p>כל זכות או סעד של סמארטבול בהתאם לתנאי השימוש לא יגרעו מכל זכות או סעד אחרים של סמארטבול הקיימים לה על פי דין.</p>
          <p>תנאי שימוש אלו אינם מהווים חוזה לטובת צד שלישי ואינם מקנים כל זכות לצד שלישי כלשהו.</p>
          <p>
            שום ויתור, הימנעות מפעולה או מתן ארכה הניתנים על ידי סמארטבול לא יחשבו כויתור על זכויותיה על פי תנאי השימוש או על פי כל דין, ולא יהוו
            תקדים לכל מקרה אחר, ולא יפגעו בזכותה של סמארטבול לממש את זכויותיה בכל עת, ולא תישמע כל טענה בדבר ויתור או השעייה של זכויותיה.
          </p>
          <p>
            הדין החל על הפעולות בפלטפורמה ועל תנאי השימוש, הוא הדין הישראלי בלבד. סמכות השיפוט הייחודית והבלעדית לדון בכל תביעה או בקשה העשויים להיות
            מוגשים בקשר לתנאי שימוש אלה נתונה לבית המשפט המוסמך בתל-אביב יפו.
          </p>
          <p>
            חוסר התקפות של אחד או יותר מהסעיפים בתנאי השימוש לא תשפיע על תקפות תנאי השימוש בכללותם. תנאי שאינו תקף או שלא ניתן לאוכפו יוחלף בתנאי תקף
            אשר יהיה קרוב ביותר לתנאי המקורי שאותו הוא מחליף, עד כמה שניתן על פי החוק.
          </p>
          <p>
            הודעות. סמארטבול רשאית למסור הודעות על השירותים, בדואר אלקטרוני לכתובת הדוא"ל שלך בחשבונך, או בתקשורת כתובה שתישלח בדואר רשום או מבויל
            מראש לכתובתך בחשבונך. הודעה שכזו תיחשב כאילו נמסרה בחלוף 48 שעות משליחתה (במידה ונשלחה בדואר רשום או מבויל מראש) או 12 שעות משליחתה (במידה
            ונשלחה בדוא"ל). אתה רשאי למסור הודעות ל- סמארטבול, כאשר הודעה שכזו תיחשב כאילו נמסרה בקבלתה על ידי סמארטבול, בכל עת בדואר רשום או מבויל
            מראש ל-סמארטבול בע״מ, רחוב לוי אשכול 63ג, ת״א, מיקוד 6936197.
          </p>
          <p>
            אינך רשאי להעביר את זכויותיך או להעביר את חובותיך לכל צד שלישי ללא אישורה הכתוב המוקדם של סמארטבול. סמארטבול רשאית להמחות תנאים אלה בלא
            הסכמתך. כל המחאה שתפר סעיף זה תהיה בטלה. בינך לבין סמארטבול או כל ספק צד שלישי לא קיימים יחסי עובד מעביד, שותפות, העסקה, או סוכנות כלשהם
            כתוצאה מתנאי שימוש אילו או מקבלת השירותים על ידך. במידה ותנייה כלשהי בתנאים אלה אינה תקיפה או אכיפה, תנייה זו תימחק ושאר התניות ייאכפו
            בהיקף המרבי המותר בחוק. אי אכיפת סמארטבול איזו מזכויותיה או מתניותיה בתנאים אלה לא תהווה ויתור על זכות או על תנייה שכזו אלא אם הוצהר והוכר
            כך בכתב על ידי סמארטבול.
          </p>
        </li>
      </ol>
      <p>מעודכן נכון ליום: 1 במאי, 2023</p>
    </div>
  );
};

export default TermsOfService;
