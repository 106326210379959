import { useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { visualEffects } from 'components/UI/VisualEffectsContainer/VisualEffectsContainer';

import vSign from 'asset/offers/v-sign.svg';

export const OfferSendingModal = ({ show, onHide, auctionId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let location = useLocation();
  const { setActiveVisualEffect } = useContext(AlertContentContext);

  useEffect(() => {
    if (!show) return;
    setActiveVisualEffect(visualEffects.CONFETTI);
  }, [setActiveVisualEffect, show]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          navigate('/user/offers/manage');
        }}
        dialogClassName={'w-80'}
        centered
      >
        <Modal.Body className="flex flex-col items-center">
          <img src={vSign} className="w-20 mt-5 mb-5" alt="" />
          <p className="font-normal  text-2xl mb-5">{t('components.offerSendingModal.content.header')}</p>
          <p className="text-sm mb-3"> {t('components.offerSendingModal.content.mailSent')}</p>
          <Button
            variant="primary"
            className="w-64 mb-3"
            onClick={() => {
              if (location.pathname !== '/user/offers/manage') navigate('/user/offers/manage');
              onHide();
            }}
          >
            {t('components.offerSendingModal.buttons.manageOffers')}
          </Button>
          <Button
            variant="outline-primary"
            className="w-64 mb-3"
            onClick={() => {
              navigate(`/user/offers/create/company-selection/${auctionId}`);
            }}
          >
            {t('components.offerSendingModal.buttons.createAnotherOffer')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
