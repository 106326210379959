import { keepPreviousData, useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Spinner } from 'react-bootstrap';

import { getEmploymentState } from 'shared/apis';
import { contractStatues } from 'shared/const/contractStatues';

import { AccreditedInvestorCertAlerts } from 'components/User/Dashboard/ContractsRow/AccreditedInvestor';
import { InvitationAlerts } from 'components/User/Dashboard/ContractsRow/InvitationAlerts/index';
import MySignedContracts from 'components/User/Dashboard/ContractsRow/MySignedContracts';

export const ContractRow = () => {
  const {
    data: contractsByStatus,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['contractsByUserId'],
    queryFn: getEmploymentState,
    placeholderData: keepPreviousData,
    retry: false,
  });

  if (isEmpty(contractsByStatus)) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {JSON.stringify(error)}
      </div>
    );
  }

  return (
    <>
      <MySignedContracts contractsByStatus={contractsByStatus} />
      <InvitationAlerts contractsByStatus={contractsByStatus} />
      <AccreditedInvestorCertAlerts activeContracts={contractsByStatus[contractStatues.ACTIVE]} />
    </>
  );
};
