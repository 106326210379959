import { useCallback, useContext, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { columnNames as dividedAccountsTableHeaders, orderedDividedAccountsTableHeaders } from 'shared/const/dividedAccountsTable';
import { buildNewTableLine, isTsv, tsvToJson } from 'shared/utils/offers';

import { useDividedAccountsParserInserter, useScopeAndAccountsErrors } from 'hooks/offers';

import { DividedAccountsUploader } from '../DividedAccountsUploader';

import './style.css';

import { BankNameInput } from './BankNameInput';
import { BidsInput } from './BidsInput';
import { CopyCashToSharesInput } from './CopyCashToSharesInput';
import { GeneralInput } from './GeneralInput';
import { RowActionsCell } from './RowActionsCell';

export const DividedAccountsInputTable = () => {
  useDividedAccountsParserInserter();
  const { control, setValue, watch } = useFormContext();
  const {
    fields: accounts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'table',
  });
  const {
    offer,
    dividedAccountsState,
    setDividedAccountsState,
    dividedAccountLimitChanged,
    setScopeAndAccountsErrors,
    setDividedAccountsUploadedArray,
  } = useContext(OfferCreationContext);
  const { type } = offer?.initiation.auctionDetails;
  const { t } = useTranslation();
  const { errorDisplay, setError, clearError } = useScopeAndAccountsErrors('tooManyLimits');

  const isRowEmpty = (row) => {
    return Object.values(row).every((value) => !value);
  };

  /* update divided account table if a new file is loaded to dividedAccountsState */
  useEffect(() => {
    if (dividedAccountsState?.length) {
      const table = watch('table');
      let newState = [];
      if (table.length === 1 && isRowEmpty(table[0])) {
        newState = dividedAccountsState;
      } else {
        newState = table.concat(dividedAccountsState);
      }

      const tableStringified = JSON.stringify(newState);

      setValue('table', newState);
      setValue('tableStr', tableStringified);
      setDividedAccountsState(null);
    }
  }, [dividedAccountsState, watch, setValue, setDividedAccountsState]);

  /* add a new line if there are no lines */
  useEffect(() => {
    /* must assign watchTable within to get the correct, updated value. not sure why */
    const watchTable = watch('table');

    /* if there are no lines, add a new line */
    if (!watchTable?.length) {
      append(buildNewTableLine());
    }
  }, [append, watch]);

  const watchTableStr = watch('tableStr');
  /* validate there are no more then 3 different limits */
  useEffect(() => {
    /* must assign watchTable within to get the correct, updated value. not sure why */
    const watchTable = watch('table');
    const currentLimits = watchTable.map((account) => parseFloat(account.limit)).filter((limit) => limit);
    const uniqueLimits = [...new Set(currentLimits)];
    const errorMsg = t('components.DividedAccountsInputTable.table.errors.tooManyLimits');
    uniqueLimits.length > 3 ? setError(errorMsg) : clearError(errorMsg);
  }, [dividedAccountLimitChanged, setError, clearError, t, watch, watchTableStr]);

  /* validate either all or none of the divided accounts have a maxPercentageFromAuction */
  useEffect(() => {
    /* must assign watchTable within to get the correct, updated value. not sure why */
    const watchTable = watch('table');
    const currentMaxPercentageFromAuction = watchTable
      .map((account) => parseFloat(account.maxPercentageFromAuction))
      .filter((maxPercentageFromAuction) => maxPercentageFromAuction);
    const errorMsg = t('components.DividedAccountsInputTable.table.errors.maxPercentageFromAuction');
    currentMaxPercentageFromAuction.length !== watchTable.length && currentMaxPercentageFromAuction.length !== 0
      ? setError(errorMsg)
      : clearError(errorMsg);
  }, [setError, clearError, t, watch, watchTableStr]);

  /* onPaste event override to allow pasting multiple lines from excel */
  /* designed to only be available on first cell */
  const onPaste = (e) => {
    console.log('handling divided accounts paste event');
    const clipboardData = e.clipboardData.getData('Text');
    if (isTsv(clipboardData)) {
      e.preventDefault();
      const json = tsvToJson(clipboardData);
      setDividedAccountsUploadedArray(json);
    }
  };

  const thClassName = 'text-s font-bold leading-5 text-center';

  const onRemoveRow = useCallback(
    (index) => {
      // clear errors
      setScopeAndAccountsErrors((prev) => {
        const updatedErrors = { ...prev };
        const fieldName = `table.${index}.`;

        Object.keys(updatedErrors).forEach((key) => {
          if (key.startsWith(fieldName)) {
            delete updatedErrors[key];
          }
        });

        // Reindex the remaining keys
        Object.keys(updatedErrors).forEach((key) => {
          const matches = key.match(/^table\.(\d+)\./);
          if (matches) {
            const currentIndex = parseInt(matches[1], 10);
            if (currentIndex > index) {
              const newIndex = currentIndex - 1;
              const newKey = key.replace(`table.${currentIndex}.`, `table.${newIndex}.`);
              updatedErrors[newKey] = updatedErrors[key];
              delete updatedErrors[key];
            }
          }
        });

        return updatedErrors;
      });

      // remove row
      remove(index);

      /* trick watch update to overcome react-hook-form bug */
      /* watch is not updated when the value is an object and and inner value is changed */
      /* stringifying the object and saving it in a different field accomplishes the needed update */
      const tableStringified = JSON.stringify(watch('table'));
      setValue('tableStr', tableStringified);
    },
    [remove, setValue, watch, setScopeAndAccountsErrors],
  );

  const onRemoveAllRows = useCallback(() => {
    for (let i = accounts.length - 1; i >= 0; i--) {
      onRemoveRow(i);
    }

    /* eventually append a new line */
    append(buildNewTableLine());
  }, [accounts.length, onRemoveRow, append]);

  const headers = [...orderedDividedAccountsTableHeaders];
  /* add general actions header at the beginning */
  headers.splice(0, 0, 'actions');
  /* add the cash -> shares account copy action header after cash_account_number */
  const indexOfCashAccountNumber = headers.indexOf(dividedAccountsTableHeaders.cash_account_number);
  headers.splice(indexOfCashAccountNumber + 1, 0, 'copyAction');

  const getRowControl = (header, index) => {
    switch (header) {
      case 'actions':
        return <RowActionsCell index={index} onRemoveRow={onRemoveRow} />;
      case 'copyAction':
        return <CopyCashToSharesInput index={index} />;
      case dividedAccountsTableHeaders.account_name:
        /* on paste override (for loading excel from clipboard) is only available on the first cell (A1) */
        return <GeneralInput index={index} name={dividedAccountsTableHeaders.account_name} type="string" onPaste={index === 0 ? onPaste : null} />;
      case dividedAccountsTableHeaders.amount:
      case dividedAccountsTableHeaders.limit:
        return <BidsInput index={index} name={header} auctionType={type} />;
      case dividedAccountsTableHeaders.cash_account_bank:
      case dividedAccountsTableHeaders.shares_account_bank:
        return <BankNameInput index={index} name={header} />;
      case dividedAccountsTableHeaders.shares_account_number:
      case dividedAccountsTableHeaders.cash_account_number:
        return <GeneralInput index={index} name={header} type="string" />;
      case dividedAccountsTableHeaders.cash_account_branch:
      case dividedAccountsTableHeaders.shares_account_branch:
        return <GeneralInput index={index} name={header} />;
      case dividedAccountsTableHeaders.maxPercentageFromAuction:
        return <GeneralInput index={index} name={header} type="number" inputProps={{ min: 0, max: 100 }} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <DividedAccountsUploader />
      {errorDisplay}
      <div className="overflow-x-scroll">
        <Table borderless className="dividedTableStyling min-w-[1000px]">
          <thead>
            <tr>
              {headers.map((header) => {
                if (header === 'copyAction') {
                  return (
                    <th key={header} className={thClassName}>
                      {<CopyCashToSharesInput />}
                    </th>
                  );
                }

                if (header === 'limit') {
                  switch (type) {
                    case auctionTypes.INTEREST:
                      header = 'unitInterest';
                      break;
                    case auctionTypes.GAP:
                      header = 'unitGap';
                      break;
                    default:
                    case auctionTypes.PRICE:
                      header = 'unitPrice';
                      break;
                  }
                }

                return (
                  <th key={header} className={thClassName}>
                    {t(`components.DividedAccountsInputTable.table.headers.${header}`)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {accounts.map((account, index) => (
              <tr key={account.id}>{headers.map((header) => getRowControl(header, index))}</tr>
            ))}
          </tbody>
        </Table>
        <div className="flex gap-4">
          <Button type="button" variant="link" onClick={() => append(buildNewTableLine())}>
            {t('components.DividedAccountsInputTable.table.addLine')}
          </Button>
          <Button type="button" variant="link" className="link-danger" onClick={onRemoveAllRows}>
            {t('components.DividedAccountsInputTable.table.removeAll')}
          </Button>
        </div>
      </div>
    </div>
  );
};
