import { useCallback, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { companyViewModes } from 'shared/const/companyViewModes';

export const EditModeCompanyTitle = ({ className, ...props }) => {
  const { t } = useTranslation();
  const { toggleMode, mode } = useContext(CompanyDetailsContext);
  const navigate = useNavigate();
  const { watch } = useFormContext();
  const watchCompanyName = watch('companyName');

  const onCancel = useCallback(
    (e) => {
      e.preventDefault();

      const isNewMode = mode === companyViewModes.NEW;
      if (isNewMode) {
        navigate('/admin/companies/table');
        return;
      }

      toggleMode();
    },
    [mode, navigate, toggleMode],
  );

  return (
    <div className={`flex justify-between ${className}`} {...props}>
      <h1>{watchCompanyName}</h1>
      <div className="flex flex-row-reverse gap-2">
        <Button type="submit" variant="warning">
          {t('screens.companies.actions.update')}
        </Button>
        <Button type="cancel" variant="secondary" onClick={onCancel}>
          {t('screens.company.actions.cancel')}
        </Button>
      </div>
    </div>
  );
};
