import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNavbar = styled(Navbar)`
  --bs-navbar-padding-x: 1rem;
  --bs-navbar-padding-y: 0.5rem;

  .navbar-brand {
    margin-right: unset;
  }
`;
