import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NewEmployeeFormModal } from 'components/CompanyDetails/CompanyForm/NewEmployeeFormModal';

import { EmployeeRow } from './EmployeeRow';

export const Employees = () => {
  const { t } = useTranslation();
  const [showEmployeeFormModal, setShowEmployeeFormModal] = useState(false);
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>{t('screens.company.labels.email')}</th>
            <th>{t('screens.company.labels.firstName')}</th>
            <th>{t('screens.company.labels.lastName')}</th>
            <th>{t('screens.company.labels.mobile')}</th>
            <th>{t('screens.company.labels.id')}</th>
            <th>{t('screens.company.labels.role')}</th>
            <th>{t('screens.company.labels.mandatorySignatory')}</th>
            <th>{t('screens.company.labels.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <EmployeeRow key={item.id} index={index} remove={remove} />
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-center">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            setShowEmployeeFormModal(true);
          }}
        >
          {t('screens.company.buttons.addUser')}
        </Button>
      </div>

      <NewEmployeeFormModal
        append={append}
        getUsers={() => getValues('users')}
        showEmployeeFormModal={showEmployeeFormModal}
        setShowEmployeeFormModal={setShowEmployeeFormModal}
      />
    </>
  );
};
