import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

const Associates = () => {
  const { t } = useTranslation();
  const { companyDetails } = useContext(CompanyDetailsContext);

  const currentAssociates = companyDetails?.associates || [];

  //JSX
  return (
    <div className="mt-3">
      <p>{t('screens.company.labels.sharingLimitation')}:</p>
      {currentAssociates?.map((associate, index) => (
        <p key={index}>{`${associate.name} (${associate.bnNumber}) `}</p>
      ))}
    </div>
  );
};

export default Associates;
