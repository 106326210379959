import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ERROR_CODES } from 'errors/context/offer-creation';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { sendOffer } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { OfferSendingModal } from 'components/Offers/OfferSendingModal/OfferSendingModal';

export const useSendOfferMutation = ({ offer }) => {
  const { setOffer } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getFormattedPhoneNumber = useCallback((phoneNumber) => {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'IL');
    return !parsedPhoneNumber?.isValid() || parsedPhoneNumber.country !== 'IL' ? phoneNumber : parsedPhoneNumber.formatNational();
  }, []);

  const { isPending, mutate } = useMutation({
    mutationFn: sendOffer,
    mutationKey: 'sendOffer',
    onSuccess: (data) => {
      setShowSuccessModal(true);
      setOffer(data);
    },
    onError: (error) => {
      const errorName = getErrorNameFromErrorResponse(error);
      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.SEND_OFFER_NOT_ALL_SIGNATORIES_SIGNED:
          errorAlertContent = {
            title: t('components.offerSendingModal.errors.notAllSignatoriesSigned.title'),
            description: t('components.offerSendingModal.errors.notAllSignatoriesSigned.description'),
            type: ALERT_TYPES.ERROR,
            error,
          };
          break;
        case ERROR_CODES.AUCTION_NOT_ACTIVE_ANYMORE:
          const auctionDetails = offer.initiation.auctionDetails;
          const underwriterPhone = auctionDetails.underwriterPhone;
          const formattedPhone = getFormattedPhoneNumber(underwriterPhone);

          errorAlertContent = {
            title: t('components.offerSendingModal.errors.auctionNotActiveAnymore.title'),
            description: t('components.offerSendingModal.errors.auctionNotActiveAnymore.description', {
              close_time: dayjs(auctionDetails.closeTime).format('DD/MM/YYYY HH:mm'),
              underwriter_name: auctionDetails.underwriterName,
              underwriter_phone: formattedPhone,
              underwriter_email: auctionDetails.underwriterEmail,
            }),
            type: ALERT_TYPES.ERROR,
            error,
          };
          break;
        case ERROR_CODES.OFFER_ALREADY_SENT:
          errorAlertContent = {
            title: t('components.offerSendingModal.errors.alreadySend.title'),
            description: t('components.offerSendingModal.errors.alreadySend.description'),
          };
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(error);
      }
      addAlert(errorAlertContent);
    },
  });

  const Modal = useCallback(
    () => (
      <>
        <OfferSendingModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} auctionId={offer.initiation.auctionDetails.auctionId} />
      </>
    ),
    [showSuccessModal, setShowSuccessModal, offer],
  );

  return { mutate, isPending, Modal };
};
