import dayjs from 'dayjs';
import some from 'lodash/some';
import * as yup from 'yup';

import { contractRoles } from 'shared/const/contractRoles';
import { investorTypes } from 'shared/const/investorTypes';
import { systemRoles } from 'shared/const/systemRoles';
import { userGenders } from 'shared/const/userGenders';

// hack for i18n ally inline translations
const t = (key) => key;

// ************** company related **************
export const bnNumber = yup.string().min(5, t('validationErrors.bnNumber.min')).max(15, t('validationErrors.bnNumber.max'));
export const bnNumberRequired = bnNumber.required(t('validationErrors.bnNumber.required'));

export const companyName = yup.string().min(2, t('validationErrors.companyName.min')).max(50, t('validationErrors.companyName.max'));
export const companyNameRequired = companyName.required(t('validationErrors.companyName.required'));

export const domain = yup.string().test({
  name: 'domain',
  message: t('validationErrors.domain.format'),
  test: function (value) {
    const domain = this.parent.domain;
    if (domain && domain.length > 0) {
      return /^[a-zA-Z-]{2,}\.[a-zA-Z.]{2,}$/.test(value);
    }
    return true;
  },
});
export const domainRequired = domain.required(t('validationErrors.domain.required'));

export const role = yup.string().oneOf(Object.values(contractRoles), t('validationErrors.role.oneOf')).required(t('validationErrors.role.required'));

// ************** user related **************
export const personalId = yup.string().matches(/^\d+$/, t('validationErrors.personalId.format')).length(9, t('validationErrors.personalId.length'));
export const personalIdRequired = personalId.required(t('validationErrors.personalId.required'));

export const mobile = yup
  .string()
  .matches(/^\+\d+$/, t('validationErrors.mobile.format'))
  .min(8, t('validationErrors.mobile.min'))
  .max(15, t('validationErrors.mobile.max'));
export const mobileRequired = mobile.required(t('validationErrors.mobile.required'));

export const email = yup.string().email(t('validationErrors.email.format'));
export const emailRequired = email.required(t('validationErrors.email.required'));

export const name = yup
  .string()
  .required(t('validationErrors.name.required'))
  .matches(/^[a-zA-Zא-ת' -]+$/, t('validationErrors.name.format'))
  .min(2, t('validationErrors.name.min'))
  .max(50, t('validationErrors.name.max'));
export const nameRequired = name.required(t('validationErrors.name.required'));

export const gender = yup.string().oneOf(Object.values(userGenders));

// ************** auth related **************
export const pinCode = yup
  .string()
  .required(t('validationErrors.pinCode.required'))
  .matches(/^\d+$/, t('validationErrors.pinCode.format'))
  .length(6, t('validationErrors.pinCode.length'));

export const systemRole = yup.string().oneOf(Object.values(systemRoles));

export const termsOfUse = yup.boolean().isTrue(t('validationErrors.termsOfUse.isTrue'));

export const investorType = yup.string().oneOf(Object.values(investorTypes), t('validationErrors.investorType.oneOf'));

export const uniqueArray = (typeOfArray) =>
  yup
    .array()
    .of(typeOfArray)
    .test('isUnique', t('validationErrors.array.isUnique'), (array) => array?.length === new Set(array).size);

export const uniqueEmailsArray = uniqueArray(email);

export const accreditedInvestorCertExpiryDateSchema = yup.mixed().when('accreditedInvestorCertKey', {
  is: (file) => !!file && some(file), // check if the file exists
  then: (schema) =>
    yup
      .date()
      .typeError(t('validationErrors.date.typeError'))
      .min(new Date(), t('validationErrors.date.min'))
      .max(dayjs().add(1, 'year').toDate(), t('validationErrors.date.max'))
      .required(t('validationErrors.date.required')),
  otherwise: (schema) => schema.nullable(),
});
