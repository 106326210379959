export const GAEventStages = {
  START: 'START',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  /** for events that not are'nt divided into multiple stages */
  OCCURRED: 'OCCURRED',
};

export const GAEventNames = {
  SIGN_UP: 'SIGN_UP',
  INIT_PIN_CODES_VALIDATION: 'INIT_PIN_CODES_VALIDATION',
  APPROVE_OR_REJECT_CONTRACT_BY_EMPLOYEE: 'APPROVE_OR_REJECT_CONTRACT_BY_EMPLOYEE',
  INITIATE_OFFER: 'INITIATE_OFFER',
  INITIATE_OFFER_EDIT_MODE: 'INITIATE_OFFER_EDIT_MODE',
  UPDATE_OFFER_SCOPE_AND_UNITED_ACCOUNTS: 'UPDATE_OFFER_SCOPE_AND_UNITED_ACCOUNTS',
  SIGN_OFFER: 'SIGN_OFFER',
  SEND_OFFER: 'SEND_OFFER',
  DEACTIVATE_OFFER: 'DEACTIVATE_OFFER',
  LOGIN: 'LOGIN',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  APPROVE_REJECT_MULTIPLE_CONTRACTS_BY_SB_ADMIN: 'APPROVE_REJECT_MULTIPLE_CONTRACTS_BY_SB_ADMIN',
  SEND_INVITE: 'SEND_INVITE',
  CREATE_COMPANY_BY_ADMIN: 'CREATE_COMPANY_BY_ADMIN',
  UPDATE_COMPANY_BY_SB_ADMIN: 'UPDATE_COMPANY_BY_SB_ADMIN',
  UPDATE_COMPANY_BY_COMPANY_ADMIN: 'UPDATE_COMPANY_BY_COMPANY_ADMIN',
  GET_USERS_DATA_FOR_COMPANY_FORM: 'GET_USERS_DATA_FOR_COMPANY_FORM',
  UPDATE_USER_BY_ADMIN: 'UPDATE_USER_BY_ADMIN',
  RESEND_PIN_CODE: 'RESEND_PIN_CODE',
  CREATE_USER_BY_ADMIN: 'CREATE_USER_BY_ADMIN',
  MARK_ALL_NOTIFICATIONS_AS_READ: 'MARK_ALL_NOTIFICATIONS_AS_READ',
  REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
  MARK_NOTIFICATION_AS_READ: 'MARK_NOTIFICATION_AS_READ',
  SEND_OFFER_TO_SIGNATORIES: 'SEND_OFFER_TO_SIGNATORIES',
  SET_SIGNATORIES: 'SET_SIGNATORIES',
  SET_INITIATOR_REVIEW_NEEDED: 'SET_INITIATOR_REVIEW_NEEDED',
  UPDATE_OFFER_LIMITATIONS_AND_CONTACTS: 'UPDATE_OFFER_LIMITATIONS_AND_CONTACTS',
  UPDATE_OFFER_SCOPE_AND_DIVIDED_ACCOUNTS: 'UPDATE_OFFER_SCOPE_AND_DIVIDED_ACCOUNTS',
  ADD_BANK_ACCOUNT_TROUGH_OFFER_FLOW: 'ADD_BANK_ACCOUNT_TROUGH_OFFER_FLOW',
  ADD_BANK_ACCOUNT_TO_COMPANY: 'ADD_BANK_ACCOUNT_TO_COMPANY',
  CANCEL_OFFER_EDIT_MODE: 'CANCEL_OFFER_EDIT_MODE',
  UPLOAD_ACCREDITED_INVESTOR_CERT: 'UPLOAD_ACCREDITED_INVESTOR_CERT',
};
