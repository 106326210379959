import { useCallback, useMemo, useState } from 'react';

export const useAwaitModal = () => {
  const [config, setConfig] = useState({ show: false, resolve: null });
  const [data, setData] = useState({});

  const showModal = useCallback(() => {
    return new Promise((resolve) => {
      setConfig({ show: true, resolve });
    });
  }, [setConfig]);

  const handleConfirm = useCallback(() => {
    if (config.resolve) {
      config.resolve(true);
      setConfig({ ...config, show: false });
    }
  }, [setConfig, config]);

  const handleCancel = useCallback(() => {
    if (config.resolve) {
      config.resolve(false);
      setConfig({ ...config, show: false });
    }
  }, [config, setConfig]);

  const options = useMemo(
    () => ({
      config,
      setConfig,
      showModal,
      handleConfirm,
      handleCancel,
      data,
      setData,
    }),
    [config, setConfig, showModal, handleConfirm, handleCancel, data, setData],
  );

  return options;
};
