import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import Box from 'components/UI/Box';

import { ViewModeCompanyTitle } from '../CompanyTitle';

import { BankAccountsTable } from './BankAccountsTable';
import { CompanyConnections } from './CompanyConnections';
import { CompanyInfo } from './CompanyInfo';
import { CompanyUsersTable } from './CompanyUsersTable';

export const ViewMode = () => {
  const { companyDetails } = useContext(CompanyDetailsContext);
  const { t } = useTranslation();

  if (!companyDetails) {
    return null;
  }

  return (
    <>
      <ViewModeCompanyTitle className="col-span-2" />

      <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_1fr] gap-[1rem] mt-[1rem]">
        {/* half and half until mobile */}
        <Box title={t('screens.company.titles.generalInfo')}>
          <CompanyInfo />
        </Box>
        <Box title={t('screens.company.titles.companySettings')}>
          <CompanyConnections />
        </Box>

        <Box title={t('screens.company.titles.bankAccounts')} className="lg:col-span-2">
          <BankAccountsTable />
        </Box>

        <Box title={t('screens.company.titles.companyUsers')} className="lg:col-span-2">
          <CompanyUsersTable />
        </Box>
      </div>
    </>
  );
};
