import { useContext } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

const { useOfferCreationNavigationUtils } = require('hooks/offers/useOfferCreationNavigationUtils');
const { Button, Spinner } = require('react-bootstrap');

export const ProceedButton = () => {
  const { disabled, proceedButtonText, proceedButtonColor } = useOfferCreationNavigationUtils();
  const { onProceed, isLoadingFormSubmit: isLoading } = useContext(OfferCreationContext);

  return (
    <Button className={`min-w-[164px] ${proceedButtonColor}`} onClick={onProceed} disabled={disabled || isLoading}>
      {isLoading ? <Spinner animation="border" /> : proceedButtonText}
    </Button>
  );
};
