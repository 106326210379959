import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/context/offer-edit-mode';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { cancelOfferEditMode, initiateOfferEditMode } from 'shared/apis';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import ModalConfirm from 'components/UI/ModalConfirm';

export const useOfferEditMode = (offerId) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { addAlert } = useContext(AlertContentContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [content, setContent] = useState('');
  const [onProceed, setOnProceed] = useState(() => () => {});

  const { mutate: initiateEditMode, isPending: isLoadingInitiate } = useMutation({
    mutationKey: 'initiateOfferEditMode',
    mutationFn: () => initiateOfferEditMode(offerId),
    onSuccess: (offer) => {
      resetState();
      queryClient.invalidateQueries('getActiveAuctionsOfferState');
      navigate(`/user/offers/create/order-scope-and-accounts/${offer._id}`);
    },
    onError: (error) => {
      resetState();
      handleInitiateEditModeError(error);
      navigate('/user/offers/manage');
    },
  });

  const handleInitiateEditModeError = (error) => {
    //OFFER_NOT_FOUND, OFFER_NOT_IN_FINISHED_STATE, default
    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.OFFER_NOT_FOUND:
        errorAlertContent = {
          title: t('hooks.useOfferEditMode.errors.offerNotFound.title'),
          description: t('hooks.useOfferEditMode.errors.offerNotFound.description'),
          error,
        };
        break;
      case ERROR_CODES.OFFER_NOT_IN_FINISHED_STATE:
        errorAlertContent = {
          title: t('hooks.useOfferEditMode.errors.offerNotInFinishedState.title'),
          description: t('hooks.useOfferEditMode.errors.offerNotInFinishedState.description'),
          error,
        };
        break;
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
    }
    addAlert(errorAlertContent);
  };

  const { mutate: cancelInitiateEditMode, isPending: isLoadingCancel } = useMutation({
    mutationKey: 'cancelInitiateOfferEditMode',
    mutationFn: () => cancelOfferEditMode(offerId),
    onSuccess: () => {
      queryClient.invalidateQueries('getActiveAuctionsOfferState');
      resetState();
      navigate('/user/offers/manage');
    },
    onError: (error) => {
      resetState();
      handleCancelInitiateEditModeError(error);
      navigate('/user/offers/manage');
    },
  });

  const handleCancelInitiateEditModeError = (error) => {
    //OFFER_NOT_FOUND, OFFER_NOT_IN_FINISHED_STATE, default
    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.OFFER_NOT_FOUND:
        errorAlertContent = {
          title: t('hooks.useOfferEditMode.errors.offerNotFound.title'),
          description: t('hooks.useOfferEditMode.errors.offerNotFound.description'),
          error,
        };
        break;
      case ERROR_CODES.OFFER_NOT_IN_EDIT_MODE:
        errorAlertContent = {
          title: t('hooks.useOfferEditMode.errors.offerNotInEditState.title'),
          description: t('hooks.useOfferEditMode.errors.offerNotInEditState.description'),
          error,
        };
        break;
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
    }
    addAlert(errorAlertContent);
  };

  const resetState = useCallback(() => {
    setShow(false);
    setOnProceed(() => () => {});
    setContent('');
  }, [setShow, setOnProceed, setContent]);

  const setShowConfirmEditModal = useCallback(() => {
    setOnProceed(() => initiateEditMode);
    setContent(t('hooks.useOfferEditMode.modal.confirmEditContent'));
    setShow(true);
  }, [initiateEditMode, setShow, setContent, setOnProceed, t]);

  const setShowCancelEditModal = useCallback(() => {
    setOnProceed(() => cancelInitiateEditMode);
    setContent(t('hooks.useOfferEditMode.modal.cancelEditContent'));
    setShow(true);
  }, [cancelInitiateEditMode, setShow, setContent, setOnProceed, t]);

  const OfferEditModalConfirm = useCallback(() => {
    if (!show) return null;

    return (
      <ModalConfirm
        show={show}
        onProceed={onProceed}
        onClose={() => setShow(false)}
        title={t('components.ManageOfferDropdown.modal.title')}
        content={content}
        variant="danger"
        proceed={t('components.ManageOfferDropdown.modal.proceed')}
        isLoading={isLoadingInitiate || isLoadingCancel}
      />
    );
  }, [show, setShow, t, content, onProceed, isLoadingInitiate, isLoadingCancel]);

  return { OfferEditModalConfirm, setShowConfirmEditModal, setShowCancelEditModal };
};
