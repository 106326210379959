import { useCallback } from 'react';

import PlaceholderImage from 'asset/placeholder.svg';

export const ProtectedImage = ({ imageSrc, alt, withFallbackImage = false, fallbackImageSrc = PlaceholderImage, ...props }) => {
  const handleImageLoadError = useCallback(
    (e) => {
      if (withFallbackImage) {
        e.target.onerror = null;
        e.target.src = fallbackImageSrc;
        e.target.alt = 'Fallback image';
      }
    },
    [withFallbackImage, fallbackImageSrc],
  );

  return <img src={imageSrc} onError={handleImageLoadError} alt={alt} {...props} />;
};
