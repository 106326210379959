import { useContext } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { CompanyDetailsSubDropdown } from './CompanyDetailsSubDropdown';

export const UserDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { email, logout } = useContext(AuthContext);

  const menuText = t('components.NavigationBar.menu');

  return (
    <NavDropdown title={email ? email : menuText} id="collasible-nav-dropdown">
      {!!email && (
        <>
          <NavDropdown.Item className="text-start" onClick={() => navigate('/user/details')}>
            {t('components.NavigationBar.userDetails')}
          </NavDropdown.Item>

          <CompanyDetailsSubDropdown />
        </>
      )}
      <NavDropdown.Divider />

      <NavDropdown.Item className="text-start" onClick={() => window.open('/terms-of-service', '_blank')}>
        {t('components.NavigationBar.termsOfService')}
      </NavDropdown.Item>

      <NavDropdown.Item className="text-start" onClick={() => window.open('/privacy-policy', '_blank')}>
        {t('components.NavigationBar.privacyPolicy')}
      </NavDropdown.Item>

      <NavDropdown.Divider />

      {!!email && (
        <NavDropdown.Item className="text-start" onClick={() => logout()}>
          {t('components.NavigationBar.logout')}
        </NavDropdown.Item>
      )}
    </NavDropdown>
  );
};
