import dayjs from 'dayjs';
import PhoneNumberUtil from 'libphonenumber-js';
import { Link } from 'react-router-dom';

import { LocalizedRole } from 'components/common/LocalizedRole';

const Columns = [
  {
    Header: 'email',
    accessor: 'email',
    Cell: (row) => {
      const isBlocked = !row.isActive;
      return (
        <div>
          <Link to={`/admin/users/${row._id}`} className={isBlocked ? 'text-red-700' : ''}>
            {row.email}
          </Link>
        </div>
      );
    },
  },
  { Header: 'personalId', accessor: 'personalId' },
  {
    Header: 'firstName',
    accessor: 'firstName',
  },
  {
    Header: 'lastName',
    accessor: 'lastName',
  },
  {
    Header: 'mobile',
    accessor: 'mobile',
    Cell: (row) => {
      return PhoneNumberUtil(row.mobile).formatNational();
    },
  },
  {
    Header: 'companyNames',
    accessor: 'companyNames',
    noSort: true,
    Cell: (row) => {
      return (
        <div className="grid gap-2 h-full">
          {row.activeContracts?.map((role, index) => (
            <div className="d-flex align-items-center" key={index}>
              <Link to={`/admin/companies/${role.companyId}`}>
                {role.companyName} ({role.bnNumber})
              </Link>
            </div>
          ))}
        </div>
      );
    },
    exportAccessor: (row) => {
      return row.activeContracts?.map((role) => `${role.companyName}-${role.bnNumber} (${role.role})`).join(', ');
    },
  },
  {
    Header: 'approvedBy',
    accessor: 'approvedBy',
    noSort: true,
    Cell: (row) => {
      return (
        <div className="grid gap-2 h-full">
          {row.activeContracts?.map((role, index) => (
            <div className="d-flex align-items-center" key={index}>
              {role.emailOfApprover}
            </div>
          ))}
        </div>
      );
    },
    noExport: true,
  },
  {
    Header: 'approvedDate',
    accessor: 'approvedDate',
    noSort: true,
    Cell: (row) => {
      return (
        <div className="grid gap-2 h-full">
          {row.activeContracts?.map((role, index) => (
            <div className="d-flex align-items-center" key={index}>
              {role.approvedAt && dayjs(role.approvedAt).format('DD/MM/YYYY mm:HH')}
            </div>
          ))}
        </div>
      );
    },
    noExport: true,
  },
  {
    Header: 'role',
    accessor: 'role',
    noSort: true,
    Cell: (row) => {
      return (
        <div className="grid gap-2 h-full">
          {row.activeContracts?.map((role, index) => (
            <div className="d-flex justify-center align-items-center" key={index}>
              <LocalizedRole role={role?.role} />
            </div>
          ))}
        </div>
      );
    },
    noExport: true,
  },
];

export default Columns;
