export const ERROR_CODES = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  NOT_FOUND: 'NOT_FOUND',
  REFRESH_TOKEN_EXPIRED: 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_INVALID: 'REFRESH_TOKEN_INVALID',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
  USER_IS_INACTIVE: 'USER_IS_INACTIVE',
  TOO_MANY_REQUESTS_BY_IP: 'TOO_MANY_REQUESTS_BY_IP',
  TOO_MANY_REQUESTS_BY_EMAIL: 'TOO_MANY_REQUESTS_BY_EMAIL',
};
