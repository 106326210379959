import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { detailedStatuses } from 'shared/const/detailedStatuses';

import ApprovedContractsTable from 'components/Admin/Contracts/ApprovedTable';
import PendingOnCompanyContractsTable from 'components/Admin/Contracts/PendingOnCompanyTable';
import PendingOnEmployeeContractsTable from 'components/Admin/Contracts/PendingOnEmployeeTable';
import RejectedContractsTable from 'components/Admin/Contracts/RejectedTable';

export const ContractsTabs = ({ queryFn }) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const { PENDING_ON_COMPANY, PENDING_ON_EMPLOYEE, ACTIVE: APPROVED, REJECTED } = detailedStatuses;

  // these must be separate states, because the query cache is not updated when the status changes
  const [activeCount, setActiveCount] = useState(0);
  const [pendingOnCompanyCount, setPendingOnCompanyCount] = useState(0);
  const [pendingOnEmployeeCount, setPendingOnEmployeeCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    // listen to changes in the query cache for the contractsLookup query, and update the counts accordingly
    queryCache.subscribe(({ query }) => {
      if (query.state.status !== 'success' || query.queryKey[0] !== 'contractsLookup') return;

      const count = query.state.data?.count;
      const status = query.queryKey[1].detailedStatus;

      if (!status || !count || count === 0) return;

      switch (status) {
        case PENDING_ON_COMPANY:
          if (pendingOnCompanyCount === count) return;
          setPendingOnCompanyCount(count);
          break;
        case PENDING_ON_EMPLOYEE:
          if (pendingOnEmployeeCount === count) return;
          setPendingOnEmployeeCount(count);
          break;
        case APPROVED:
          if (activeCount === count) return;
          setActiveCount(count);
          break;
        case REJECTED:
          if (rejectedCount === count) return;
          setRejectedCount(count);
          break;
        default:
          break;
      }
    });
  }, [
    queryCache,
    activeCount,
    pendingOnCompanyCount,
    pendingOnEmployeeCount,
    rejectedCount,
    PENDING_ON_COMPANY,
    PENDING_ON_EMPLOYEE,
    APPROVED,
    REJECTED,
  ]);

  return (
    <Container className="mb-5 mt-4">
      <Tab.Container id="ContractsTables" defaultActiveKey={PENDING_ON_COMPANY}>
        <Nav fill variant="tabs" defaultActiveKey="/pending" className="flex-nowrap">
          <Nav.Item>
            <Nav.Link eventKey={PENDING_ON_COMPANY}>
              {t('screens.contracts.nav.pending')} ({pendingOnCompanyCount})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={PENDING_ON_EMPLOYEE}>
              {t('screens.contracts.nav.pendingOnEmployee')} ({pendingOnEmployeeCount})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={APPROVED}>
              {t('screens.contracts.nav.approved')} ({activeCount})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={REJECTED}>
              {t('screens.contracts.nav.rejected')} ({rejectedCount})
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="bg-[white] pt-3 border-left border-right border-bottom rounded-bottom pb-3">
          <Tab.Pane eventKey={PENDING_ON_COMPANY}>
            <PendingOnCompanyContractsTable queryFn={queryFn} />
          </Tab.Pane>
          <Tab.Pane eventKey={PENDING_ON_EMPLOYEE}>
            <PendingOnEmployeeContractsTable queryFn={queryFn} />
          </Tab.Pane>
          <Tab.Pane eventKey={APPROVED}>
            <ApprovedContractsTable queryFn={queryFn} />
          </Tab.Pane>
          <Tab.Pane eventKey={REJECTED}>
            <RejectedContractsTable queryFn={queryFn} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};
