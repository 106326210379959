import * as React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import 'react-popper-tooltip/dist/styles.css';

/**
 *
 * @param {Trigger} Trigger the element that will trigger the tooltip
 * @param {Content} Content element that will be displayed in the tooltip
 * @returns
 */
export const Tooltip = ({ Trigger, Content }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <>
      <button type="button" ref={setTriggerRef}>
        {Trigger}
      </button>

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {Content}
        </div>
      )}
    </>
  );
};
