import { useCallback, useContext, useMemo } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

export const useScopeAndAccountsErrors = (name) => {
  const { scopeAndAccountsErrors, setScopeAndAccountsErrors } = useContext(OfferCreationContext);

  const setScopeAndAccountsErrorKey = useCallback(
    (newErrors) => {
      setScopeAndAccountsErrors((prev) => ({ ...prev, ...newErrors }));
    },
    [setScopeAndAccountsErrors],
  );

  const currentErrors = useMemo(() => {
    return scopeAndAccountsErrors[name] || [];
  }, [name, scopeAndAccountsErrors]);

  const setError = useCallback(
    (message) => {
      if (!currentErrors.includes(message)) {
        const newErrors = {};
        newErrors[name] = [...currentErrors, message];
        setScopeAndAccountsErrorKey(newErrors);
      }
    },
    [currentErrors, setScopeAndAccountsErrorKey, name],
  );

  const clearError = useCallback(
    (message) => {
      if (currentErrors.includes(message)) {
        const newErrors = {};
        newErrors[name] = currentErrors.filter((error) => error !== message);
        setScopeAndAccountsErrorKey(newErrors);
      }
    },
    [currentErrors, setScopeAndAccountsErrorKey, name],
  );

  const clearAllFieldErrors = useCallback(() => {
    if (!currentErrors.length) return;
    const newErrors = {};
    newErrors[name] = [];
    setScopeAndAccountsErrorKey(newErrors);
  }, [currentErrors, name, setScopeAndAccountsErrorKey]);

  const errorToPresent = useMemo(() => {
    return currentErrors[0] || '';
  }, [currentErrors]);

  const errorDisplay = useMemo(() => {
    const result = errorToPresent?.length ? <p className="text-red-700">{errorToPresent}</p> : '';
    return result;
  }, [errorToPresent]);

  return { setError, clearError, clearAllFieldErrors, errorToPresent, errorDisplay };
};
