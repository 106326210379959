/* OFFER CREATION SCREENS */
export const offerCreationScreens = {
  COMPANY_SELECTION: 'COMPANY_SELECTION',
  SCOPE_AND_ACCOUNTS: 'SCOPE_AND_ACCOUNTS',
  LIMITATIONS_AND_CONTACTS: 'LIMITATIONS_AND_CONTACTS',
  SIGNATURES: 'SIGNATURES',
  SENDING: 'SENDING',
};

export const offerCreationRoutesForEachScreen = {
  [offerCreationScreens.COMPANY_SELECTION]: 'company-selection',
  [offerCreationScreens.SCOPE_AND_ACCOUNTS]: 'order-scope-and-accounts',
  [offerCreationScreens.LIMITATIONS_AND_CONTACTS]: 'limitations-and-contacts',
  [offerCreationScreens.SIGNATURES]: 'signing',
  [offerCreationScreens.SENDING]: 'sending',
};

export const offerCreationRoutesOrderedArray = [
  offerCreationRoutesForEachScreen.SCOPE_AND_ACCOUNTS,
  offerCreationRoutesForEachScreen.LIMITATIONS_AND_CONTACTS,
  offerCreationRoutesForEachScreen.SIGNATURES,
  offerCreationRoutesForEachScreen.SENDING,
];

/* OFFER SERVER STATUSES */

export const offerServerStatuses = {
  INITIATED: 'INITIATED',
  SCOPE_AND_ACCOUNTS_FILLED: 'SCOPE_AND_ACCOUNTS_FILLED',
  LIMITATIONS_AND_CONTACTS_FILLED: 'LIMITATIONS_AND_CONTACTS_FILLED',
  SENT_TO_SIGNATORIES: 'SENT_TO_SIGNATORIES',
  REJECTED_BY_SIGNATORIES: 'REJECTED_BY_SIGNATORIES',
  APPROVED_BY_SIGNATORIES: 'APPROVED_BY_SIGNATORIES',
  READY_TO_AUCTIONS: 'READY_TO_AUCTIONS',
  ACCEPTED_BY_UNDERWRITER: 'ACCEPTED_BY_UNDERWRITER',
  REJECTED_BY_UNDERWRITER: 'REJECTED_BY_UNDERWRITER',
  EDITING_MODE: 'EDITING_MODE',
};

export const offerServerStatusesOrderedArray = [
  offerServerStatuses.INITIATED,
  offerServerStatuses.SCOPE_AND_ACCOUNTS_FILLED,
  offerServerStatuses.LIMITATIONS_AND_CONTACTS_FILLED,
  offerServerStatuses.SENT_TO_SIGNATORIES,
  offerServerStatuses.APPROVED_BY_SIGNATORIES,
  offerServerStatuses.READY_TO_AUCTIONS,
  offerServerStatuses.EDITING_MODE,
];

/* COMBINED TYPES */

export const minServerStatusesForEachScreen = {
  [offerCreationScreens.COMPANY_SELECTION]: 'none',
  [offerCreationScreens.SCOPE_AND_ACCOUNTS]: offerServerStatuses.INITIATED,
  [offerCreationScreens.LIMITATIONS_AND_CONTACTS]: offerServerStatuses.SCOPE_AND_ACCOUNTS_FILLED,
  [offerCreationScreens.SIGNATURES]: offerServerStatuses.LIMITATIONS_AND_CONTACTS_FILLED,
  [offerCreationScreens.SENDING]: offerServerStatuses.SENT_TO_SIGNATORIES,
};
