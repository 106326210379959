import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { UserDetailsContext } from 'context/UserDetails';
import { UserDetailsContextProvider } from 'context/UserDetails';

import { userViewModes } from 'shared/const/userViewModes';

import { EditMode } from 'components/UserDetails/EditMode/EditMode';
import { ViewMode } from 'components/UserDetails/ViewMode/ViewMode';

const UserDetailsScreenContent = () => {
  const { mode, userDetailsFromServer, toggleMode } = useContext(UserDetailsContext);

  const MainContent = useMemo(() => {
    if (!userDetailsFromServer) {
      return null;
    }
    switch (mode) {
      case userViewModes.EDIT:
        return <EditMode />;
      case userViewModes.VIEW:
      default:
        return <ViewMode />;
    }
  }, [userDetailsFromServer, mode]);

  const HeaderSection = useMemo(() => {
    if (!userDetailsFromServer) {
      return null;
    }
    const result = (
      <Row>
        <Col>
          <h1>
            {userDetailsFromServer.email}{' '}
            <Button onClick={toggleMode} variant="secondary">
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </h1>
        </Col>
      </Row>
    );
    return result;
  }, [userDetailsFromServer, toggleMode]);

  return (
    <Container>
      {HeaderSection}
      {MainContent}
    </Container>
  );
};

const UserDetailsScreen = () => {
  return (
    <UserDetailsContextProvider>
      <UserDetailsScreenContent />
    </UserDetailsContextProvider>
  );
};

export default UserDetailsScreen;
