import { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';
import { buildAuctionTitle } from 'shared/utils/auctions';
import { formatBidValue } from 'shared/utils/offers';

export const MaxThreeLimitWarning = ({ config: { show }, handleConfirm, handleCancel, data }) => {
  const { t } = useTranslation();

  const auctionTitle = useMemo(() => {
    if (!data) return null;

    const { offer } = data;
    const auctionTitle = buildAuctionTitle({ offer, t });
    return auctionTitle;
  }, [data, t]);

  const companyName = useMemo(() => {
    if (!data) return null;

    const { offer } = data;
    const companyName = offer?.initiation?.investingCompany?.name;
    return companyName;
  }, [data]);

  const content = useMemo(() => {
    if (!data) return null;
    const { uniqueLimitsForAuctionByCompany, offer } = data;

    const formattedLimits = uniqueLimitsForAuctionByCompany
      ?.map((limit) => {
        return formatBidValue(limit, BID_INPUT_FIELDS.LIMIT, offer);
      })
      .join(', ');

    const auctionType = offer?.initiation?.auctionDetails?.type;

    return (
      <>
        <p>{t('components.MaxThreeLimitWarning.body', { companyName, auctionTitle })}:</p>
        <ul className="list-disc px-2">
          <li>
            {t(`components.MaxThreeLimitWarning.auctionTypes.${auctionType}`)}: {formattedLimits}
          </li>
        </ul>
      </>
    );
  }, [data, t, auctionTitle, companyName]);

  return (
    <Modal show={show} onHide={handleCancel} centered dialogClassName="justify-center" contentClassName="w-auto text-danger">
      <Modal.Header closeButton>
        <Modal.Title>{t('components.MaxThreeLimitWarning.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          {t('components.ValueWarning.cancel')}
        </Button>
        <Button variant="warning" onClick={handleConfirm}>
          {t('components.MaxThreeLimitWarning.proceed')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
