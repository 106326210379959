import sum from 'lodash/sum';

import { auctionTypes } from 'shared/const/auctions';

export const getUnitPrice = (offer) => {
  if (!offer) return 0;
  const { unitStructures } = offer?.initiation?.auctionDetails;

  const unitPrice = unitStructures.reduce((acc, us) => {
    const price = us.limit * us.amount;
    return acc + price;
  }, 0);

  return unitPrice;
};

export const getOfferScope = (offer) => {
  if (!offer) return 0;

  const { type } = offer?.initiation?.auctionDetails;
  const { bids } = offer;

  const unitPrice = getUnitPrice(offer);

  switch (type) {
    case auctionTypes.INTEREST:
    case auctionTypes.GAP:
      return sum(bids.map((field) => +field.amount * unitPrice) || 0);
    case auctionTypes.PRICE:
    default:
      return sum(bids.map((field) => +field.amount * +field.limit));
  }
};
