import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { deactivateOffer } from 'shared/apis';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import ModalConfirm from 'components/UI/ModalConfirm';

export const useOfferDeactivation = ({ offer: offerFromProps, onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer: offerFromContext, offerDataLayer } = useContext(OfferCreationContext);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const { addAlert } = useContext(AlertContentContext);

  const { mutate: deactivateOfferMutation, isPending } = useMutation({
    mutationKey: 'deactivateOffer',
    mutationFn: deactivateOffer,
    onSuccess,
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
    onSettled: () => {
      setShowDeactivationModal(false);
    },
  });

  /**
   * Handle offer deactivation.
   * Use offer for deactivation from hook param if exists,
   * otherwise use offer from context
   */
  const handleDeleteOffer = useCallback(() => {
    const offer = offerFromProps || offerFromContext;
    if (!offer) {
      navigate('/user');
      return;
    }

    const expectedPayload = {
      offerId: offer._id,
      dataLayer: offerDataLayer,
    };
    deactivateOfferMutation(expectedPayload);
  }, [deactivateOfferMutation, navigate, offerFromContext, offerFromProps, offerDataLayer]);

  const DeactivationModal = useCallback(
    () => (
      <ModalConfirm
        show={showDeactivationModal}
        setShow={setShowDeactivationModal}
        onProceed={handleDeleteOffer}
        onClose={() => setShowDeactivationModal(false)}
        title={t('screens.LimitationsAndContacts.cancel-modal.title')}
        content={t('screens.LimitationsAndContacts.cancel-modal.content')}
        variant="danger"
        proceed={t('screens.LimitationsAndContacts.cancel-modal.confirm')}
        isLoading={isPending}
      />
    ),
    [showDeactivationModal, setShowDeactivationModal, handleDeleteOffer, t, isPending],
  );

  return {
    isPending,
    DeactivationModal,
    handleDeleteOffer,
    setShowDeactivationModal,
    showDeactivationModal,
  };
};
