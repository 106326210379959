import { Container, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useIsActive } from 'context/CompaniesScreen';

import { useAdminStats } from 'hooks/useAdminStats';

import CompaniesTable from 'components/Admin/CompaniesTable/table';

const CompaniesScreen = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useIsActive(true);
  const { companiesCount } = useAdminStats({ enabled: true });

  const handleSelect = (key) => {
    setIsActive(key === 'active');
  };

  const getDefaultActiveKey = () => {
    return isActive ? 'active' : 'inactive';
  };

  return (
    <Container className="mb-5 mt-4">
      <Tab.Container
        id="CompaniesTables"
        defaultActiveKey={getDefaultActiveKey()}
        className="flex-nowrap"
        onSelect={handleSelect}
        unmountOnExit
        mountOnEnter
      >
        <Nav fill variant="tabs" defaultActiveKey="/active" className="flex-nowrap">
          <Nav.Item>
            <Nav.Link eventKey="active">
              {t('screens.companies.nav.active')} ({companiesCount?.active})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="inactive">
              {t('screens.companies.nav.inactive')} ({companiesCount?.inactive})
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="active">
            <CompaniesTable isActive={true} />
          </Tab.Pane>
          <Tab.Pane eventKey="inactive">
            <CompaniesTable isActive={false} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default CompaniesScreen;
