import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';

import { ALERT_TYPES } from 'shared/const/alerts';
import { contractRoles } from 'shared/const/contractRoles';
import { roleSchema } from 'shared/schemas/company';

import ModalConfirm from 'components/UI/ModalConfirm';

export const NewRoleForm = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.bnNumber')}</Form.Label>
        <Form.Control type="text" {...register(`bnNumber`)} isInvalid={errors?.bnNumber} />
        <ErrorMessage
          errors={errors}
          name={`bnNumber`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('screens.company.labels.companyName')}</Form.Label>
        <Form.Control type="text" {...register(`companyName`)} isInvalid={errors?.companyName} />
        <ErrorMessage
          errors={errors}
          name={`companyName`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('screens.company.labels.role')}</Form.Label>
        <Form.Select {...register(`role`)} isInvalid={errors?.role}>
          <option value={contractRoles.SIGNATORY}>{t('screens.company.labels.signatory')}</option>
          <option value={contractRoles.EMPLOYEE}>{t('screens.company.labels.employee')}</option>
          <option value={contractRoles.COMPANY_ADMIN}>{t('screens.company.labels.admin')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name={`role`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
    </Form>
  );
};

const NewRoleFormModal = ({ append, getRoles, showRoleFormModal, setShowRoleFormModal }) => {
  const [companyName, setCompanyName] = useState('');
  const { addAlert } = useContext(AlertContentContext);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      bnNumber: '',
      companyName: '',
      role: contractRoles.EMPLOYEE,
    },
    resolver: yupResolver(roleSchema),
  });

  const { t } = useTranslation();
  const { reset, trigger, getValues, setError } = methods;

  const onProceed = async () => {
    const isValid = await trigger(null, { shouldFocus: true });

    if (!isValid) {
      return;
    }

    const roles = getRoles();
    const duplicatedRole = roles.find((user) => user.bnNumber === getValues().bnNumber);
    if (duplicatedRole) {
      setError('bnNumber', {
        type: 'manual',
        message: 'validationErrors.bnNumber.duplicated',
      });
      return;
    }

    const values = getValues();
    append(values);
    reset();
    setShowRoleFormModal(false);
    setCompanyName(values.companyName);

    addAlert({
      type: ALERT_TYPES.INFO,
      title: t('screens.user.AlertModal.title'),
      description: t('screens.user.AlertModal.content', { companyName }),
    });
  };

  return (
    <FormProvider {...methods}>
      <ModalConfirm
        show={showRoleFormModal}
        onClose={() => setShowRoleFormModal(false)}
        title={t('screens.user.labels.newRole')}
        content={<NewRoleForm />}
        proceed={t('screens.user.buttons.addRole')}
        onProceed={onProceed}
        variant="primary"
      />
    </FormProvider>
  );
};

export default NewRoleFormModal;
