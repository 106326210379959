import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { WinningOffersModal } from './WinningOffersModal';

export const WinningOffersCell = ({ publicOffering }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <WinningOffersModal show={isModalOpen} setShow={setIsModalOpen} publicOffering={publicOffering} />
      <Button variant="link" onClick={() => setIsModalOpen(true)}>
        {t('screens.dashboard.publicOfferings.winningOffersTable.texts.view')}
      </Button>
    </>
  );
};
