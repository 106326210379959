export const AUCTION_NOTIFICATION_TYPES = {
  AUCTION_CREATED: 'AUCTION_CREATED',
  AUCTION_CANCELLED: 'AUCTION_CANCELLED',
  AUCTION_CLOSE_TIME_UPDATED: 'AUCTION_CLOSE_TIME_UPDATED',
  AUCTION_ATTACHMENTS_UPDATED: 'AUCTION_ATTACHMENTS_UPDATED',
  // auctionEnded: 'AUCTION_ENDED', // how to handle this?
};

export const CONTRACT_NOTIFICATION_TYPES = {
  NEW_ACTIVE_CONTRACT: 'NEW_ACTIVE_CONTRACT', // used for contracts that are approved upon creation (by the sb admin)
  CONTRACT_APPROVED_BY_EMPLOYEE: 'CONTRACT_APPROVED_BY_EMPLOYEE',
  CONTRACT_REJECTED_BY_EMPLOYEE: 'CONTRACT_REJECTED_BY_EMPLOYEE',
  CONTRACT_REQUESTED_BY_EMPLOYEE: 'CONTRACT_REQUESTED_BY_EMPLOYEE',
  CONTRACT_APPROVED_BY_COMPANY: 'CONTRACT_APPROVED_BY_COMPANY',
  CONTRACT_REJECTED_BY_COMPANY: 'CONTRACT_REJECTED_BY_COMPANY',
  CONTRACT_REQUESTED_BY_COMPANY: 'CONTRACT_REQUESTED_BY_COMPANY',
};

export const OFFER_NOTIFICATION_TYPES = {
  OFFER_CREATED: 'OFFER_CREATED',
  SIGN_REQUEST: 'SIGN_REQUEST',
  OFFER_SUBMITTED: 'OFFER_SUBMITTED',
  OFFER_SIGNER_SIGNED: 'OFFER_SIGNER_SIGNED',
  OFFER_UPDATED: 'OFFER_UPDATED',
  OFFER_APPROVED: 'OFFER_APPROVED',
  OFFER_DEACTIVATED: 'OFFER_DEACTIVATED',
  OFFER_READY_FOR_SUBMISSION: 'OFFER_READY_FOR_SUBMISSION',
};

export const NOTIFICATION_TYPES = {
  ...AUCTION_NOTIFICATION_TYPES,
  ...CONTRACT_NOTIFICATION_TYPES,
  ...OFFER_NOTIFICATION_TYPES,
};

export const NOTIFICATION_DATA = {
  companyId: 'companyId',
  companyName: 'companyName',
  auctionId: 'auctionId',
  auctionName: 'auctionName',
  offerId: 'offerId',
  emitterId: 'emitterId',
  emitterName: 'emitterName',
  underWriterId: 'underWriterId',
  underWriterName: 'underWriterName',
  employeeId: 'employeeId',
  employeeName: 'employeeName',
};

export const AUCTION_NOTIFICATION_DATA = {
  underWriterId: NOTIFICATION_DATA.underWriterId,
  underWriterName: NOTIFICATION_DATA.underWriterName,
  auctionId: NOTIFICATION_DATA.auctionId,
  auctionName: NOTIFICATION_DATA.auctionName,
};

export const CONTRACT_NOTIFICATION_DATA = {
  companyId: NOTIFICATION_DATA.companyId,
  companyName: NOTIFICATION_DATA.companyName,
  emitterId: NOTIFICATION_DATA.emitterId,
  emitterName: NOTIFICATION_DATA.emitterName,
  employeeId: NOTIFICATION_DATA.employeeId,
  employeeName: NOTIFICATION_DATA.employeeName,
};

export const OFFER_NOTIFICATION_DATA = {
  companyId: NOTIFICATION_DATA.companyId,
  companyName: NOTIFICATION_DATA.companyName,
  offerId: NOTIFICATION_DATA.offerId,
  emitterId: NOTIFICATION_DATA.emitterId,
  emitterName: NOTIFICATION_DATA.emitterName,
  auctionId: NOTIFICATION_DATA.auctionId,
  auctionName: NOTIFICATION_DATA.auctionName,
};
