import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const RowActionsCell = ({ index, onRemoveRow }) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();

  const handleCopyRow = () => {
    const table = watch('table');
    const newTable = [...table];
    const row = { ...newTable[index] };

    /* it's safe to just delete as row is part of table, which is a copy of the original table, and not a reference */
    delete row.amount;
    delete row.limit;

    newTable.splice(index + 1, 0, row);
    setValue('table', newTable, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  return (
    <td className="p-0 m-0 flex justify-content-evenly align-items-center">
      <Tooltip
        Content={t('components.Offers.DividedAccountsInputTable.RowActionCell.RemoveRowTooltip')}
        Trigger={
          <Button type="button" variant="link" onClick={() => onRemoveRow(index)} size="sm" className="text-sm text-danger">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        }
      />
      <Tooltip
        Content={t('components.Offers.DividedAccountsInputTable.RowActionCell.CopyRowTooltip')}
        Trigger={
          <Button type="button" variant="link" onClick={() => handleCopyRow(index)} size="sm" className="text-sm text-success">
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        }
      />
    </td>
  );
};
