import { useContext, useMemo, useState } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { BidInputControllerWrapper } from 'components/Offers/BidInput/BidInputControllerWrapper';

export const useDividedAccountsInputWrapper = ({ controller, fieldName, onBlur, onFocus }) => {
  /* errors to present */
  const { scopeAndAccountsErrors, hasLoadedDividedAccounts } = useContext(OfferCreationContext);
  const fieldErrors = useMemo(() => scopeAndAccountsErrors?.[fieldName], [scopeAndAccountsErrors, fieldName]);

  /* should show errors icon */
  const [fieldTouched, setFieldTouched] = useState(hasLoadedDividedAccounts);

  const handleBlur = () => {
    onBlur && onBlur();
    setFieldTouched(true);
  };

  const handleFocus = () => {
    onFocus && onFocus();
    setFieldTouched(false);
  };

  const isInvalid = useMemo(() => !!fieldErrors?.length && fieldTouched, [fieldErrors, fieldTouched]);

  return (
    <td className="p-0 m-0">
      <BidInputControllerWrapper
        controller={controller}
        errors={fieldErrors ? <p className="text-red-700">{fieldErrors}</p> : null}
        isInvalid={isInvalid}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={'border relative'}
      />
    </td>
  );
};
