import { useQueryClient } from '@tanstack/react-query';
import values from 'lodash/values';
import { useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AuctionsTable } from 'components/Admin/AuctionsTable';

const LIVE_AUCTIONS = 'LIVE_AUCTIONS';
const CLOSED_AUCTIONS = 'CLOSED_AUCTIONS';
const QUERY_KEYS = {
  LIVE_AUCTIONS: 'active_auctionsLookup',
  CLOSED_AUCTIONS: 'inactive_auctionsLookup',
};

export const AuctionsTabs = () => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  // these must be separate states, because the query cache is not updated when the status changes
  const [activeCount, setActiveCount] = useState(0);
  const [pastCount, setPastCount] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    // listen to changes in the query cache for the auctionsLookup query, and update the counts accordingly
    queryCache.subscribe(({ query }) => {
      if (query.state.status !== 'success') return;

      const queryName = query.queryKey[0];
      if (!values(QUERY_KEYS).includes(queryName)) return;

      const count = query.state.data?.count;
      if (!count) return;

      switch (queryName) {
        case QUERY_KEYS.LIVE_AUCTIONS:
          if (activeCount === count) return;
          setActiveCount(count);
          break;
        case QUERY_KEYS.CLOSED_AUCTIONS:
          if (pastCount === count) return;
          setPastCount(count);
          break;

        default:
          break;
      }
    });
  }, [queryCache, activeCount, pastCount]);

  return (
    <Container className="mb-5 mt-4">
      <Tab.Container id="AuctionsTabs" defaultActiveKey={LIVE_AUCTIONS}>
        <Nav fill variant="tabs" className="flex-nowrap">
          <Nav.Item>
            <Nav.Link eventKey={LIVE_AUCTIONS}>
              {t('screens.auctions.nav.live')} ({activeCount})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={CLOSED_AUCTIONS}>
              {t('screens.auctions.nav.closed')} ({pastCount})
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="bg-[white] pt-3 border-left border-right border-bottom rounded-bottom pb-3">
          <Tab.Pane eventKey={LIVE_AUCTIONS}>
            <AuctionsTable isActive queryKey={QUERY_KEYS.LIVE_AUCTIONS} />
          </Tab.Pane>
          <Tab.Pane eventKey={CLOSED_AUCTIONS}>
            <AuctionsTable isActive={false} queryKey={QUERY_KEYS.CLOSED_AUCTIONS} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};
