import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { login } from 'shared/apis';
import { schema } from 'shared/schemas/auth';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useDirection } from 'hooks/useDirection';
import { useWindowSize } from 'hooks/useWindowSize';

import ControlledPhoneInput from 'components/UI/Form/ControlledPhoneInput';

import { useTFAMutation } from '../TFA/useTFAMutation';

import 'react-phone-number-input/style.css';

import backgroundEn from 'asset/login/background-en.svg';
import backgroundHe from 'asset/login/background-he.svg';
import smartbullLogo from 'asset/logo-online.svg';

const AuthenticationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContentContext);
  const { setEmail, setMobile, setPersonalId } = useContext(AuthContext);
  const { isDesktop } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { isRtl } = useDirection();

  //FORM INITIALIZATION
  const defaultValues = useMemo(() => {
    return {
      email: '',
      mobile: '',
      id: '',
    };
  }, []);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    formState: { isDirty, errors },
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
  } = methods;

  //ON SUBMIT
  const onInitiationError = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      switch (errorName) {
        case 'ID_NOT_MATCH':
          setError('personalId', { type: 'manual', message: t('screens.authentication.errors.ID_NOT_MATCH') }, { shouldFocus: true });
          break;
        case 'MOBILE_NOT_MATCH':
          setError('mobile', { type: 'manual', message: t('screens.authentication.errors.MOBILE_NOT_MATCH') }, { shouldFocus: true });
          break;
        default:
          addAlert(errorAlertBuilder.bug(error));
      }
    },
    [setError, addAlert, t],
  );

  const {
    mutate: loginMutation,
    isLoading: isLoginIn,
    ValidationModal,
  } = useTFAMutation({
    mainMutationParams: {
      mutationKey: 'login',
      mutationFn: login,
      /**
       * there is no 'onSuccess' because successful response only trigger side effects
       * there is an axios interceptor that being triggered by each response with new authorization token
       * the interceptor will parse the token and set the user data in a context, then,
       * the user is redirected to the next screen by our auth logic according to the context state
       */
      onError: (error) => {
        //todo
        addAlert(errorAlertBuilder.bug(error));
      },
    },
    initiationMutationParams: {
      onInitiationError,
    },
  });
  const onSubmit = useCallback(
    ({ email, mobile, personalId }) => {
      if (!isDirty) {
        alert('No changes detected');
        return;
      }
      setEmail(email.toLowerCase());
      setMobile(mobile);
      setPersonalId(personalId);
      loginMutation({ invitingCompanyBnNumber: searchParams.get('bn-number') || undefined });
    },
    [setEmail, setMobile, setPersonalId, loginMutation, isDirty, searchParams],
  );

  //ADDITIONAL HOOKS
  const isUserInvited = useMemo(() => (searchParams.get('bn-number') ? true : false), [searchParams]);

  const updateInvitationValuesFromUrl = useCallback(() => {
    const email = searchParams.get('email');
    const personalId = searchParams.get('id');
    const mobile = searchParams.get('mobile');

    if (email) setValue('email', email, { shouldValidate: true, shouldDirty: true });
    if (personalId) setValue('personalId', personalId, { shouldValidate: true, shouldDirty: true });
    if (mobile) {
      const parsedPhoneNumber = parsePhoneNumber(mobile);
      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        setError('mobile', { type: 'manual', message: t('screens.authentication.errors.BAD_PHONE_NUMBER_FORMAT') });
        setValue('mobile', mobile, { shouldValidate: true, shouldDirty: true });
      } else {
        clearErrors('mobile');
        setValue('mobile', parsedPhoneNumber.number, { shouldValidate: true, shouldDirty: true });
      }
    }
  }, [searchParams, setValue, setError, t, clearErrors]);
  useEffect(updateInvitationValuesFromUrl, [updateInvitationValuesFromUrl]);

  //JSX
  return (
    <>
      <Container className="h-full d-flex flex-col pt-3 md:max-w-[95vw]">
        <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] cursor-pointer my-4" alt="" onClick={() => navigate('/')} />

        <Form onSubmit={handleSubmit(onSubmit)} noValidate className="mt-2 max-w-[480px]">
          <FormProvider {...methods}>
            <h4>{t('screens.authentication.welcomeText')}</h4>

            <p className="text-muted">{t('screens.authentication.descriptionText')}</p>

            <ErrorMessage errors={errors} name="general" render={({ message }) => <Alert variant="danger">{message}</Alert>} />

            <div>
              <Form.Group className="mb-4">
                <Form.Label>{t('screens.authentication.labels.id')}</Form.Label>
                <Form.Control
                  {...register('personalId')}
                  required
                  isInvalid={errors.personalId}
                  disabled={isUserInvited}
                  style={{ direction: 'ltr' }}
                  pattern="\d*"
                />
                <ErrorMessage
                  errors={errors}
                  name="personalId"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>{t('screens.authentication.labels.email')}</Form.Label>
                <Form.Control
                  {...register('email')}
                  required
                  isInvalid={errors.email}
                  disabled={isUserInvited}
                  style={{ direction: 'ltr' }}
                  inputMode="email"
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>{t('screens.authentication.labels.mobile')}</Form.Label>
                <ControlledPhoneInput control={control} name="mobile" errors={errors} disabled={!!errors.mobile && isUserInvited} />
                <ErrorMessage
                  errors={errors}
                  name="mobile"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>
            </div>

            <Button variant="primary" type="submit">
              {isLoginIn ? (
                <Spinner animation="border" />
              ) : isUserInvited ? (
                t('screens.authentication.actions.acceptInvitation')
              ) : (
                t('screens.authentication.actions.joinOrSignIn')
              )}
            </Button>
          </FormProvider>
        </Form>
      </Container>
      {ValidationModal}
      {isDesktop && (
        <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
          <img src={isRtl ? backgroundHe : backgroundEn} className="position-absolute top-0 end-[0] h-full  z-[-9999]" alt="decorative ellipse" />
        </div>
      )}
    </>
  );
};

export default AuthenticationForm;
