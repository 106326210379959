import { useMutation } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';

import { AlertContentContext } from 'context/Alert';

import { createPublicOfferingNewsletter } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { PublicOfferingsTable } from 'components/PublicOfferingsTable';

export const NewsletterScreen = () => {
  const [selected, setSelected] = useState([]);
  const { addAlert } = useContext(AlertContentContext);

  const { mutate } = useMutation({
    mutationFn: async () => {
      return createPublicOfferingNewsletter({ publicOfferingsIds: selected });
    },
    mutationKey: 'createPublicOfferingNewsletter',
    onSuccess: () => {
      addAlert({
        needTranslate: true,
        title: 'screens.newsletters.publicOffering.success.title',
        description: 'screens.newsletters.publicOffering.success.description',
        type: ALERT_TYPES.SUCCESS,
      });
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  return (
    <div className="mt-5 mb-5 justify-center items-center container">
      {selected.length ? <Button onClick={mutate}>Create newsletter</Button> : null}
      <PublicOfferingsTable scrollable withSelection selected={selected} setSelected={setSelected} />
    </div>
  );
};
