import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import EditRHF from './EditRHF';

export const EditMode = () => {
  const { companyDetailsIsLoading, companyDetailsError, companyUsersIsLoading, companyUsersError } = useContext(CompanyDetailsContext);

  if (companyDetailsIsLoading || companyUsersIsLoading) {
    return <Spinner animation="border" />;
  }

  if (companyDetailsError || companyUsersError) {
    /* error will be handled by the context */
    return null;
  }

  return <EditRHF />;
};
