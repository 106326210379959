import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { email } from 'shared/schemas/general';

import { FieldError } from '../FieldError';
import { TagsInput } from '../TagsInput';

export const AuctionResultsEmailsTextArea = ({ formFieldName }) => {
  const { t } = useTranslation();

  const emailValidation = (value) => {
    return email.isValidSync(value);
  };

  return (
    <Form.Group className="mb-4">
      <Form.Label>{t('components.AuctionResultsEmailsTextArea.title')}</Form.Label>
      <TagsInput formFieldName={formFieldName} valueValidation={emailValidation} />
      <Form.Text>*{t('components.AuctionResultsEmailsTextArea.text')}</Form.Text>
      <FieldError formFieldName={formFieldName} />
    </Form.Group>
  );
};
