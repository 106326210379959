import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { contractStatues } from 'shared/const/contractStatues';

import ContractItem from 'components/User/Dashboard/ContractsRow/ContractItem';

const MySignedContracts = ({ contractsByStatus }) => {
  if (isEmpty(contractsByStatus[contractStatues.ACTIVE]) && isEmpty(contractsByStatus[contractStatues.WAITING_FOR_COMPANY_APPROVAL])) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-4 mb-4 items-start justify-start flex-col md:flex-row md:items-center">
      {contractsByStatus[contractStatues.ACTIVE]?.map((contract, index) => (
        <ContractItem contract={contract} key={index} />
      ))}
      {contractsByStatus[contractStatues.WAITING_FOR_COMPANY_APPROVAL]?.map((contract, index) => (
        <ContractItem contract={contract} key={index} />
      ))}
      {contractsByStatus[contractStatues.COMPANY_DEACTIVATED]?.map((contract, index) => (
        <ContractItem contract={contract} key={index} />
      ))}
    </div>
  );
};

export default MySignedContracts;
