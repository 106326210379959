import { useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

const MutualLimitation = () => {
  const { t } = useTranslation();
  const { offer } = useContext(OfferCreationContext);
  const { register, watch, setValue } = useFormContext();

  const associates = offer?.initiation.investingCompany.associates;

  const watchMaxPercentage = watch('isActive_maxPercentageFromAuction');
  useEffect(() => {
    if (!watchMaxPercentage) {
      setValue('mutualLimitation', []);
      associates.forEach((associate) => {
        setValue(`mutualLimitation_${associate.bnNumber}`, false);
      });
    }
  }, [watchMaxPercentage, setValue, associates]);

  if (!associates?.length) return null;

  return (
    <div className="mb-2">
      {t('screens.LimitationsAndContacts.form.limitations.labels.mutualLimitation')}
      <div className="pr-8">
        {associates.map((associate) => (
          <Form.Check
            key={associate._id}
            type="checkbox"
            {...register(`mutualLimitation_${associate.bnNumber}`)}
            label={`${associate.name} (${associate.bnNumber})`}
            disabled={!watch('isActive_maxPercentageFromAuction')}
          />
        ))}
      </div>
    </div>
  );
};

export default MutualLimitation;
