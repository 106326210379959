import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NotificationContext } from 'context/Notifications';

import { useDropDown } from 'hooks/useDropDown';

import { NotificationsList } from './NotificationsList';

export const NotificationsIcon = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    notifications, // This should now contain pages array
    isFetchingNextPage,
    unreadNotificationsCount,
    markAllAsRead,
    showNotificationsDropdown: show,
    setShowNotificationsDropdown: setShow,
    isFetching,
  } = useContext(NotificationContext);
  const { triggerRef, dropdownRef, toggleDropdown } = useDropDown({ show, setShow });

  const goToNotifications = () => {
    setShow(false);
    navigate('/user/notifications');
  };

  if (!notifications) return null;

  return (
    <div className="relative flex align-items-center px-2">
      <div className="cursor-pointer" ref={triggerRef} onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faBell} className={`${show ? 'text-gray-50' : 'text-gray-200'} text-md`} />
        {unreadNotificationsCount > 0 && (
          <span className="absolute top-[4px] right-0 bg-green-400 text-white text-[9px] rounded-full px-[4px] border !border-green-400">
            {unreadNotificationsCount}
          </span>
        )}
      </div>
      {show && (
        <div ref={dropdownRef} className="absolute right-0 bg-white shadow-md mt-2 rounded-lg z-50 overflow-auto w-[400px]">
          <div className="flex justify-between items-center bg-blue-50 py-2 px-3 border-b border-b-white shadow-sm">
            <h5 className="font-semibold">
              {t('components.Notifications.notifications')}
              {isFetching && <Spinner size="sm" animation="border" className="ms-2" />}
            </h5>
            <button className="btn-link text-gray-500 text-xs" onClick={markAllAsRead}>
              {t('components.Notifications.markAllAsRead')}
            </button>
          </div>
          <NotificationsList maxLength={4} />
          {isFetchingNextPage && <p>Loading more...</p>}
          <div className="flex justify-center items-center py-2">
            <button className="text-xs btn-link" onClick={goToNotifications}>
              {t('components.Notifications.seeAll')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
