import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const AssociateLink = ({ associate }) => (
  <Link to={`/admin/companies/${associate.companyId}`}>
    {associate.name} ({associate.bnNumber})
  </Link>
);

const Columns = ({ t }) => [
  { Header: 'bnNumber', accessor: 'bnNumber' },
  {
    Header: 'name',
    accessor: 'name',
    Cell: (row) => <Link to={`/admin/companies/${row._id}`}>{row.name}</Link>,
    exportAccessor: 'name',
  },
  {
    Header: 'investorType',
    accessor: 'investorType',
    Cell: (row) => t(`components.selectors.investorType.${row.investorType}`),
  },
  {
    Header: 'pendingUsers',
    accessor: 'pendingUsers',
    tdStyle: (cell) => {
      if (cell && cell > 0) {
        return { backgroundColor: '#FCE3C2' };
      }
    },
  },
  {
    Header: 'activeUsers',
    accessor: 'activeUsers',
    tdStyle: (cell) => {
      if (cell && cell > 0) {
        return { backgroundColor: '#DCEACD' };
      }
    },
  },
  {
    Header: 'rejectedUsers',
    accessor: 'rejectedUsers',
    tdStyle: (cell) => {
      if (cell && cell > 0) {
        return { backgroundColor: '#F6B9BA' };
      }
    },
  },
  {
    Header: 'createdAt',
    accessor: 'createdAt',
    Cell: (row) => dayjs(row.createdAt).format('DD/MM/YYYY'),
  },
  {
    Header: 'associates',
    accessor: 'associates',
    Cell: (row) => {
      const associates = row.associates?.map((associate) => <AssociateLink associate={associate} key={associate._id} />);

      const commaSeparatedAssociates = associates?.reduce((acc, curr) => (acc === null ? [curr] : [...acc, ', ', curr]), null);

      return <div>{commaSeparatedAssociates}</div>;
    },
    noSort: true,
    exportAccessor: (row) => row.associates?.map((associate) => `${associate.name} (${associate.bnNumber})`).join(', '),
  },
  {
    Header: 'accreditedInvestorCert',
    accessor: 'accreditedInvestorCert',
    Cell: (row) => {
      if (!row.accreditedInvestorCert) {
        return null;
      }

      return (
        <Link to={row.accreditedInvestorCert.url} target="_blank">
          {row.accreditedInvestorCert.fileName}
        </Link>
      );
    },
  },
  {
    Header: 'accreditedInvestorCertExpiryDate',
    accessor: 'accreditedInvestorCert.expirationDate',
    Cell: (row) => {
      if (!row.accreditedInvestorCert) {
        return null;
      }

      const expirationDate = dayjs(row.accreditedInvestorCert.expirationDate);

      const isExpired = dayjs(expirationDate).isBefore(dayjs());
      if (isExpired) {
        return (
          <span className="text-danger">
            {t('common.expired')} {dayjs(expirationDate).format('DD/MM/YYYY')}
          </span>
        );
      } else {
        return (
          <span className="text-success">
            {t('common.nonExpired')} {dayjs(expirationDate).format('DD/MM/YYYY')}
          </span>
        );
      }
    },
  },
  // for future use
  // {
  //   Header: 'relatedDistributors',
  //   accessor: 'relatedDistributors',
  // },
];

export default Columns;
