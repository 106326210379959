import { createPopper } from '@popperjs/core';
import { useEffect, useRef } from 'react';

export const useDropDown = ({ show, setShow }) => {
  const triggerRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (triggerRef.current && dropdownRef.current) {
      createPopper(triggerRef.current, dropdownRef.current, {
        placement: 'bottom-start',
      });
    }
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && dropdownRef.current && !dropdownRef.current.contains(event.target) && !triggerRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, setShow]);

  const toggleDropdown = () => {
    setShow(!show);
  };

  return { triggerRef, dropdownRef, toggleDropdown };
};
