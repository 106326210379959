import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { contractsLookupForEmployee, contractsLookupForSbAdmin } from 'shared/apis';

import { ContractsTabs } from 'components/ContractsTabs';

export const UserContractsTable = () => {
  const { isSBAdmin, userId } = useContext(AuthContext);
  const path = useLocation().pathname;
  const pathParts = path.split('/');
  const userIdFromUrl = pathParts[pathParts.length - 1];

  const queryFn = useCallback(
    (options) => {
      if (isSBAdmin) {
        const fn = contractsLookupForSbAdmin;
        options.filters.userId = userIdFromUrl;
        return fn(options);
      } else {
        const fn = contractsLookupForEmployee;
        return fn(options, userId);
      }
    },
    [isSBAdmin, userId, userIdFromUrl],
  );

  return <ContractsTabs queryFn={queryFn} />;
};
