import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import PhoneNumberUtil from 'libphonenumber-js';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';
import { serverDetailedStatuses } from 'shared/const/detailedStatuses';

import { ContractStatusSpan } from 'components/common/LocalizeContractStatus';

export const EmployeeRow = ({ index, remove }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const formatMobile = (number) => {
    const phoneNumber = PhoneNumberUtil(number);
    return phoneNumber?.formatNational() || number;
  };

  const handleApprove = (index) => {
    /* setting detailedStatus to empty string will trigger an update in the server where a new status will be calculated */
    setValue(`users.${index}.detailedStatus`, '', { shouldDirty: true, shouldTouch: true });
  };

  const detailedStatus = getValues(`users.${index}.detailedStatus`);
  const notApprovedStatuses = [serverDetailedStatuses.WAITING_FOR_COMPANY_APPROVAL, serverDetailedStatuses.REJECTED_BY_COMPANY];
  const isApproved = !notApprovedStatuses.includes(detailedStatus);

  return (
    <tr key={index}>
      <td>
        <div>{getValues(`users.${index}.email`)}</div>
      </td>
      <td>
        <div>{getValues(`users.${index}.firstName`)}</div>
      </td>
      <td>
        <div>{getValues(`users.${index}.lastName`)}</div>
      </td>
      <td>
        <div>{formatMobile(getValues(`users.${index}.mobile`))}</div>
      </td>
      <td>
        <div>{getValues(`users.${index}.personalId`)}</div>
      </td>
      <td>
        <Form.Select {...register(`users.${index}.role`)} isInvalid={errors?.users?.[index]?.role}>
          <option value={contractRoles.SIGNATORY}>{t('screens.company.labels.signatory')}</option>
          <option value={contractRoles.EMPLOYEE}>{t('screens.company.labels.employee')}</option>
          <option value={contractRoles.COMPANY_ADMIN}>{t('screens.company.labels.admin')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name={`users.${index}.role`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Check type="checkbox" {...register(`users.${index}.isMandatorySignatory`)} />
      </td>
      <td>
        <ContractStatusSpan detailedStatus={detailedStatus} />
      </td>
      <td>
        <div className="flex justify-between gap-1 dir-ltr">
          <Button type="button" variant="danger" onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          {!isApproved && (
            <Button variant="primary" onClick={() => handleApprove(index)}>
              {t('screens.company.buttons.approveUser')}
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};
