import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useOfferCreationNavigationUtils } from 'hooks/offers/useOfferCreationNavigationUtils';

export const PrevButton = () => {
  const { onPrevClick, hidePrevButton } = useOfferCreationNavigationUtils();
  const { t } = useTranslation();

  if (hidePrevButton) {
    return null;
  }

  return (
    <Button className="min-w-[164px]" variant="outline-primary" onClick={onPrevClick}>
      {t('common.previous')}
    </Button>
  );
};
