import * as yup from 'yup';

import { uniqueEmailsArray } from './general';

const t = (key) => key; //just a mock to make the translation plugin show translations
export const schema = yup.object().shape({
  //LIMITS FORM GROUP 1
  maxPercentageFromAuction: yup.number().when('isActive_maxPercentageFromAuction', {
    is: true,
    then: (schema) =>
      schema
        .typeError(t('screens.LimitationsAndContacts.form.errors.general.number'))
        .min(1, t('screens.LimitationsAndContacts.form.errors.general.min'))
        .max(100, t('screens.LimitationsAndContacts.form.errors.general.max'))
        .required(t('screens.LimitationsAndContacts.form.errors.general.required')),
    otherwise: (schema) => yup.number().nullable(),
  }),
  //LIMITS FORM GROUP 2
  issuedCapitalPresentHoldings: yup.number().when('isActive_issuedCapitalWinningPercentLimit', {
    is: true,
    then: (schema) =>
      schema
        .typeError(t('screens.LimitationsAndContacts.form.errors.general.number'))
        .min(1, t('screens.LimitationsAndContacts.form.errors.general.min'))
        .required(t('screens.LimitationsAndContacts.form.errors.general.required')),
    otherwise: (schema) => yup.number().nullable(),
  }),
  limit_issuedCapitalWinningPercentLimit: yup.number().when('isActive_issuedCapitalWinningPercentLimit', {
    is: true,
    then: (schema) =>
      schema
        .typeError(t('screens.LimitationsAndContacts.form.errors.general.number'))
        .min(1, t('screens.LimitationsAndContacts.form.errors.general.min'))
        .max(100, t('screens.LimitationsAndContacts.form.errors.general.max'))
        .required(t('screens.LimitationsAndContacts.form.errors.general.required')),
    otherwise: (schema) => yup.number().nullable(),
  }),
  //LIMITS FORM GROUP 3
  limit_minFundraisingVolume: yup.number().when('isActive_minProceeds', {
    is: true,
    then: (schema) =>
      schema
        .typeError(t('screens.LimitationsAndContacts.form.errors.general.number'))
        .min(1, t('screens.LimitationsAndContacts.form.errors.general.min'))
        .required(t('screens.LimitationsAndContacts.form.errors.general.required')),
    otherwise: (schema) => yup.number().nullable(),
  }),
  //LIMITS FORM GROUP 4
  noUnderwriters: yup.boolean().default(false),
  underwritersAtMinProfitOnly: yup.boolean().default(false),
  //LIMITS FORM GROUP 5
  additionalComment: yup.string().optional(),
  //CONTACTS GROUP 1
  contactPerson: yup.string().required(t('screens.LimitationsAndContacts.form.errors.contactPerson.required')),
  //CONTACTS GROUP 2
  emailsForAuctionResults: uniqueEmailsArray,
});
