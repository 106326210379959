import React, { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TagsInput as Input } from 'react-tag-input-component';

import { AlertContentContext } from 'context/Alert';

import { ALERT_TYPES } from 'shared/const/alerts';

export const TagsInput = ({ formFieldName, valueValidation = null }) => {
  const { setValue: setFormFieldValue, watch } = useFormContext();
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();

  const onChange = useCallback(
    (value) => {
      setFormFieldValue(formFieldName, value, { shouldDirty: true });
    },
    [setFormFieldValue, formFieldName],
  );

  const validate = useCallback(
    (tag) => {
      if (!valueValidation) return true;

      if (!tag) return false;

      if (!valueValidation(tag)) {
        addAlert({
          title: t('validationErrors.email.format'),
          description: tag,
          type: ALERT_TYPES.WARNING,
        });
        return false;
      }
      return true;
    },
    [valueValidation, addAlert, t],
  );

  const fieldValue = watch(formFieldName) || [];

  return <Input name={formFieldName} value={fieldValue} onChange={onChange} beforeAddValidate={validate} classNames={{ tag: 'text-primary' }} />;
};
