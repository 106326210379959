import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyleButton from 'components/UI/PrimaryButton';

import './styles.css';

const RefusedAgreement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container className="flex flex-col items-center justify-center bg-white">
      <div className=" p-5 text-center text-lg font-medium max-w-xl	">
        <h2>{t('screens.RefusedSigning.headerText')}</h2>
        <div className="flex flex-row items-center justify-center gap-20 mt-5 text-base">
          <StyleButton
            primary
            type="submit"
            title={t('screens.RefusedSigning.showAgreementAgain')}
            fn={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default RefusedAgreement;
