import PhoneNumberUtil from 'libphonenumber-js';
import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserDetailsContext } from 'context/UserDetails';

const UserIdentifyingInfo = () => {
  const { userDetailsFromServer, userDetailsIsLoading, userDetailsError } = useContext(UserDetailsContext);

  const { t } = useTranslation();

  if (userDetailsIsLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (userDetailsError) {
    return (
      <div className="alert alert-danger" role="alert">
        {userDetailsError}
      </div>
    );
  }

  if (!userDetailsFromServer?.email) {
    return (
      <div className="alert alert-danger" role="alert">
        {t('screens.user.errors.noUser')}
      </div>
    );
  }

  const { email, mobile, firstName, lastName } = userDetailsFromServer;

  return (
    <ul className="list-group p-0">
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.email')}:</span> {email}
      </li>
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.mobile')}:</span> {PhoneNumberUtil(mobile).formatNational()}
      </li>
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.firstName')}:</span> {firstName}
      </li>
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.lastName')}:</span> {lastName}
      </li>
    </ul>
  );
};

export default UserIdentifyingInfo;
