import numbro from 'numbro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollableTable } from 'components/UI/ScrollableTable';

import { getAllocationRateForDisplay } from './utils';

export const WinningOffersTable = ({ winningOffers = [], publicOffering = {} }) => {
  const { t } = useTranslation();
  return (
    <ScrollableTable>
      <thead>
        <tr>
          <th>
            <span>{t('screens.dashboard.publicOfferings.winningOffersTable.columns.bidder')}</span>
          </th>
          <th>
            <span>{t('screens.dashboard.publicOfferings.winningOffersTable.columns.units')}</span>
          </th>
          <th>
            <span>{t('screens.dashboard.publicOfferings.winningOffersTable.columns.limit')}</span>
          </th>
          <th>
            <span>{t('screens.dashboard.publicOfferings.winningOffersTable.columns.pcntOfMosadi')}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {winningOffers.map((winningOffer, index) => (
          <tr key={index}>
            <td>{winningOffer.investorCompanyName}</td>
            <td>{numbro(winningOffer.units).format('0,0')}</td>
            <td>{numbro(winningOffer.limit).format('0,0')}</td>
            <td>{getAllocationRateForDisplay(publicOffering, winningOffer.units)}</td>
          </tr>
        ))}
      </tbody>
    </ScrollableTable>
  );
};
