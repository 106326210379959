import _ from 'lodash';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const FieldError = ({ formFieldName }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const fieldErrors = errors[formFieldName];

  const errorsText = useMemo(() => {
    if (_.isEmpty(fieldErrors)) {
      return;
    }

    if (_.isArray(fieldErrors)) {
      const errors = _.compact(fieldErrors).map((error) => t(error.message));
      return errors.join(', ');
    } else {
      return t(fieldErrors.message);
    }
  }, [fieldErrors, t]);

  return errorsText ? (
    <Form.Text className="text-danger">
      <p>{errorsText}</p>
    </Form.Text>
  ) : null;
};
