import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'context/Notifications';

import { generateNotificationDate, generateNotificationMessage, getNotificationTitle, isUnread } from 'shared/utils/notifications';

import { useNotificationOnClick } from 'hooks/useNotificationOnClick';

export const NotificationItem = ({ notification }) => {
  const { t, i18n } = useTranslation();
  const { markAsRead } = useContext(NotificationContext);
  const { onClick: notificationOnClick } = useNotificationOnClick();

  if (!notification || !notification.type) return null;

  return (
    <div key={notification._id} className="notification-item border-b border-gray-200 relative group/item overflow-hidden">
      {notification.isRead === false && (
        <Button
          variant="secondary"
          size="xs"
          className="absolute left-[0.5rem] top-1/2 -translate-y-1/2 -translate-x-full transform transition-all duration-300 opacity-0 group-hover/item:!opacity-100 group-hover/item:translate-x-0"
          onClick={() => markAsRead(notification._id)}
        >
          {t('components.Notifications.markAsRead')}
        </Button>
      )}
      <div className="flex justify-start cursor-pointer p-2" onClick={() => notificationOnClick(notification)}>
        {isUnread(notification) && <span className="w-2 h-2 bg-green-400 rounded-md !me-2 mt-2"></span>}
        <div className={`flex flex-col flex-1 min-w-0 ${!isUnread(notification) ? '!ps-4' : ''}`}>
          <div className={`flex flex-row justify-between items-center`}>
            {getNotificationTitle(notification, t)}
            <span className="text-gray-500 text-xs">{generateNotificationDate(notification, t, i18n.language)}</span>
          </div>
          <span className="text-gray-500 text-xs truncate w-full">{generateNotificationMessage(notification, t)}</span>
        </div>
      </div>
    </div>
  );
};
