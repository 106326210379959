import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export const useBidInputsDataSetter = () => {
  const { watch, setValue } = useFormContext();

  /* UPDATE BIDS ACCORDING TO DIVIDED ACCOUNTS */
  const accountingStructure = watch('accountsStructure');
  const dividedAccounts = watch('table');
  const tableStr = watch('tableStr');

  /* summarize the bids */
  const summarizedBids = useMemo(() => {
    if (!dividedAccounts?.length) return;

    const bidsCluster = [];
    dividedAccounts.forEach((row) => {
      const { limit, amount } = row;
      const bidItem = bidsCluster.find((bid) => bid.limit === limit);
      if (!bidItem) {
        bidsCluster.push({ limit, amount });
        return;
      }
      bidItem.amount += amount;
    });

    return bidsCluster;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividedAccounts, tableStr]);

  const setBidInputsValues = useCallback(
    (bid, index) => {
      const { limit, amount } = bid;
      if (!limit && !amount) return;
      const bidPrefix = `bid_${index}_`;
      if (amount) {
        setValue(bidPrefix + 'amount', amount);
      }
      if (limit) {
        setValue(bidPrefix + 'limit', limit);
      }
    },
    [setValue],
  );

  /* load summarized bids to form state */
  useEffect(() => {
    // clear all bids
    for (let i = 0; i < 3; i++) {
      const bidPrefix = `bid_${i}_`;
      setValue(bidPrefix + 'amount', null);
      setValue(bidPrefix + 'limit', null);
    }
    if (!summarizedBids?.length) return;
    // load summarized bids
    summarizedBids.forEach(setBidInputsValues);
  }, [accountingStructure, summarizedBids, setValue, setBidInputsValues]);
};
