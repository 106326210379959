export const ERROR_CODES = {
  MOCK_ERROR: 'MOCK_ERROR',
  SEND_OFFER_NOT_ALL_SIGNATORIES_SIGNED: 'SEND_OFFER_NOT_ALL_SIGNATORIES_SIGNED',
  OFFER_NOT_FOUND: 'OFFER_NOT_FOUND',
  NO_NEED_TO_VALIDATE_INITIATED_STATUS: 'NO_NEED_TO_VALIDATE_INITIATED_STATUS',
  OFFER_ALREADY_SENT: 'OFFER_ALREADY_SENT',
  OFFER_NOT_FILLED: 'OFFER_NOT_FILLED',
  OFFER_NOT_READY: 'OFFER_NOT_READY',
  MISSING_SIGNATURES: 'MISSING_SIGNATURES',
  OFFER_PROPOSAL_DOCUMENT_NOT_FOUND: 'OFFER_PROPOSAL_DOCUMENT_NOT_FOUND',
  ORDER_READY_STATUS_NOT_ALLOWED_BEFORE_APPROVED_BY_SIGNATORIES: 'ORDER_READY_STATUS_NOT_ALLOWED_BEFORE_APPROVED_BY_SIGNATORIES',
  BID_UNITS_TOTAL_UNDER_MINIMAL: 'BID_UNITS_TOTAL_UNDER_MINIMAL',
  BID_UNITS_AMOUNT_NOT_INTEGER: 'BID_UNITS_AMOUNT_NOT_INTEGER',
  BID_INTEREST_LIMIT_TOO_HIGH: 'BID_INTEREST_LIMIT_TOO_HIGH',
  BID_INTEREST_LIMIT_TOO_LOW: 'BID_INTEREST_LIMIT_TOO_LOW',
  INVALID_GAP: 'INVALID_GAP',
  SEND_SUBMIT_OFFER_EMAIL_NO_PDF: 'SEND_SUBMIT_OFFER_EMAIL_NO_PDF',
  EMAIL_SENDING_FAILED: 'EMAIL_SENDING_FAILED',
  COMPANY_NOT_FOUND: 'COMPANY_NOT_FOUND',
  BANK_ACCOUNT_NUMBER_ALREADY_EXISTS: 'BANK_ACCOUNT_NUMBER_ALREADY_EXISTS',
  BANK_ACCOUNT_NAME_ALREADY_EXISTS: 'BANK_ACCOUNT_NAME_ALREADY_EXISTS',
  AUCTION_NOT_FOUND: 'AUCTION_NOT_FOUND',
  AUCTION_NOT_ACTIVE: 'AUCTION_NOT_ACTIVE',
  AUCTION_IS_CLOSED: 'AUCTION_IS_CLOSED',
  AUCTION_IS_CANCELLED: 'AUCTION_IS_CANCELLED',
  AUCTION_BLOCKS_NEW_OFFERS: 'AUCTION_BLOCKS_NEW_OFFERS',
  REPRESENTATIVE_NOT_FOUND: 'REPRESENTATIVE_NOT_FOUND',
  REPRESENTATIVE_NOT_IN_COMPANY: 'REPRESENTATIVE_NOT_IN_COMPANY',
  UNDERWRITER_NOT_FOUND: 'UNDERWRITER_NOT_FOUND',
  AUCTION_NOT_ACTIVE_ANYMORE: 'AUCTION_NOT_ACTIVE_ANYMORE',
  PRECEDING_STATUS_NOT_ALLOWED: 'PRECEDING_STATUS_NOT_ALLOWED',
  OFFER_NOT_IN_FINISHED_STATE: 'OFFER_NOT_IN_FINISHED_STATE',
  OFFER_NOT_IN_EDIT_MODE: 'OFFER_NOT_IN_EDIT_MODE',
  MANDATORY_SIGNATORIES_MISSING: 'MANDATORY_SIGNATORIES_MISSING',
  NO_SIGNATORIES_PROVIDED: 'NO_SIGNATORIES_PROVIDED',
  BANK_MISSING_BRANCH_NUMBER: 'BANK_MISSING_BRANCH_NUMBER',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_NOT_SIGNATORY: 'USER_NOT_SIGNATORY',
  USER_ALREADY_SIGNED: 'USER_ALREADY_SIGNED',
};
