import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Box from 'components/UI/Box';
import { UpcomingOfferingsTable } from 'components/UpcomingOfferingsTable';

export const UpcomingOfferingsScreen = () => {
  const { t } = useTranslation();
  return (
    <Container className="mt-5">
      <Row>
        <h1 className="text-center">{t('screens.upcomingOfferings.texts.title')}</h1>
      </Row>
      <Row className="mt-5">
        <Box>
          <UpcomingOfferingsTable scrollable />
        </Box>
      </Row>
    </Container>
  );
};
