import { auctionTypes } from 'shared/const/auctions';

import $ from '../safeNumbers';

export const calculateUnitPrice = (unitStructures) => {
  if (!unitStructures?.length) {
    return undefined;
  }
  let minUnitPrice = unitStructures.reduce((total, unitStructure) => {
    const { amount, limit } = unitStructure;
    if (!amount || !limit) {
      return total;
    }
    const unitPrice = $.multiply(amount, limit);
    const newTotal = $.add(total, unitPrice);
    return newTotal;
  }, 0);
  return minUnitPrice >= 0 ? minUnitPrice : undefined;
};

export const calculateLimits = (biddingLimitations, unitStructures) => {
  const { maxInterest } = biddingLimitations || {};
  const minPrice = calculateUnitPrice(unitStructures);
  return { maxInterest, minPrice };
};

export const calcBidValue = (bid, unitStructures, type) => {
  if (!bid) {
    return 0;
  }

  const minPrice = calculateUnitPrice(unitStructures);
  const { amount, limit } = bid;
  const pricePerUnit = type === auctionTypes.PRICE ? limit : minPrice;
  return $.multiply(amount, pricePerUnit);
};

export const calcTotalBidsValue = (bids, unitStructures, type) => {
  if (!bids?.length) {
    return 0;
  }

  return bids.reduce((total, bid) => {
    const bidValue = calcBidValue(bid, unitStructures, type);
    return $.add(total, bidValue);
  }, 0);
};

export const calcOfferValue = (offer) => {
  const { bids, initiation } = offer;
  const { unitStructures, type } = initiation.auctionDetails;

  return calcTotalBidsValue(bids, unitStructures, type);
};
