import { Route } from 'react-router-dom';

import { systemRoles } from 'shared/const/systemRoles';

import { AuctionsTabs } from 'screens/Admin/Auctions';
import CompaniesTableScreen from 'screens/Admin/CompaniesTable';
import { CompanyScreenWithContext as CompanyDetailsScreen } from 'screens/Admin/CompanyDetails';
import ContractsScreen from 'screens/Admin/Contracts';
import { DataScreen } from 'screens/Admin/Data';
import AdminLandingScreen from 'screens/Admin/Landing';
import { NewCompanyScreenWithContext as NewCompanyScreen } from 'screens/Admin/NewCompany';
import { NewsletterScreen } from 'screens/Admin/Newsletters';
import NewUserScreen from 'screens/Admin/NewUser';
import UnderwritersScreen from 'screens/Admin/UnderwritersTable';
import UsersTableScreen from 'screens/Admin/UsersTable';
import UserDetailsScreen from 'screens/User/UserDetails';

import ProtectedRoute from 'components/UI/ProtectedRoute';

const adminRoutes = (
  <Route path="admin">
    <Route
      index
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <AdminLandingScreen />
        </ProtectedRoute>
      }
    />
    <Route path="users">
      <Route
        path="table"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <UsersTableScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <NewUserScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path=":userId"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <UserDetailsScreen />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="companies">
      <Route
        path="table"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <CompaniesTableScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <NewCompanyScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path=":companyId"
        element={
          <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
            <CompanyDetailsScreen />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route
      path="contracts"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <ContractsScreen />
        </ProtectedRoute>
      }
    />
    <Route
      path="underwriters"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <UnderwritersScreen />
        </ProtectedRoute>
      }
    />
    <Route
      path="auctions"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <AuctionsTabs />
        </ProtectedRoute>
      }
    />
    <Route
      path="data"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <DataScreen />
        </ProtectedRoute>
      }
    />
    <Route
      path="newsletter"
      element={
        <ProtectedRoute allowedRoles={[systemRoles.SB_ADMIN]}>
          <NewsletterScreen />
        </ProtectedRoute>
      }
    />
  </Route>
);
export default adminRoutes;
