import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import UnderwriterTable from 'components/Admin/UnderwritersTable/table';

const UnderwritersScreen = () => {
  const { t } = useTranslation();

  return (
    <Container className="mb-5 mt-4">
      <h1>{t('screens.underwriters.titles.underwritersTable')}</h1>
      <UnderwriterTable />
    </Container>
  );
};

export default UnderwritersScreen;
