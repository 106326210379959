import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';
import { serverDetailedStatuses } from 'shared/const/detailedStatuses';

import ControlledPhoneInput from 'components/UI/Form/ControlledPhoneInput';

export const EmployeesRow = ({ remove, index }) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const handleApprove = (index) => {
    /* setting detailedStatus to empty string will trigger an update in the server where a new status will be calculated */
    setValue(`users.${index}.detailedStatus`, '', { shouldDirty: true, shouldTouch: true });
  };

  const detailedStatus = getValues(`users.${index}.detailedStatus`);
  const notApprovedStatuses = [serverDetailedStatuses.WAITING_FOR_COMPANY_APPROVAL, serverDetailedStatuses.REJECTED_BY_COMPANY];
  const isApproved = !notApprovedStatuses.includes(detailedStatus);

  return (
    <tr key={index}>
      <td>
        <Form.Control type="text" {...register(`users.${index}.email`)} isInvalid={errors?.users?.[index]?.email} />
        <ErrorMessage
          errors={errors}
          name={`users.${index}.email`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Control type="text" {...register(`users.${index}.firstName`)} isInvalid={errors?.users?.[index]?.firstName} />
        <ErrorMessage
          errors={errors}
          name={`users.${index}.firstName`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Control type="text" {...register(`users.${index}.lastName`)} isInvalid={errors?.users?.[index]?.lastName} />
        <ErrorMessage
          errors={errors}
          name={`users.${index}.lastName`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <ControlledPhoneInput control={control} name={`users.${index}.mobile`} errors={errors} />
        <ErrorMessage
          errors={errors}
          name={`users.${index}.mobile`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Control type="text" {...register(`users.${index}.personalId`)} isInvalid={errors?.users?.[index]?.personalId} />
        <ErrorMessage
          errors={errors}
          name={`users.${index}.id`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Select {...register(`users.${index}.role`)} isInvalid={errors?.users?.[index]?.role}>
          <option value={contractRoles.SIGNATORY}>{t('screens.company.labels.signatory')}</option>
          <option value={contractRoles.EMPLOYEE}>{t('screens.company.labels.employee')}</option>
          <option value={contractRoles.COMPANY_ADMIN}>{t('screens.company.labels.admin')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name={`users.${index}.role`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </td>
      <td>
        <Form.Check type="checkbox" {...register(`users.${index}.isMandatorySignatory`)} />
      </td>
      <td>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            remove(index);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        {!isApproved && (
          <Button variant="primary" onClick={() => handleApprove(index)}>
            {t('screens.company.buttons.approveUser')}
          </Button>
        )}
      </td>
    </tr>
  );
};
