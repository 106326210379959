import { faCheck, faClock, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { contractStatues } from 'shared/const/contractStatues';

import { Tooltip } from 'components/UI/Tooltip';
import { AccreditedInvestorDetails } from 'components/User/Dashboard/ContractsRow/AccreditedInvestor';

const ContractItem = ({ contract }) => {
  const { t } = useTranslation();

  if (![contractStatues.ACTIVE, contractStatues.WAITING_FOR_COMPANY_APPROVAL, contractStatues.COMPANY_DEACTIVATED].includes(contract.status)) {
    return null;
  }

  if (contract.status === contractStatues.COMPANY_DEACTIVATED) {
    return (
      <div className="flex flex-wrap gap-2 items-center">
        <Tooltip
          Trigger={
            <>
              <FontAwesomeIcon icon={faWarning} className="text-yellow-500" />
              <span className="text-gray-600 text-lg font-normal">{contract.companyName}</span>
            </>
          }
          Content={t('components.ContractsRow.ContractItem.companyDeactivated')}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-2 items-center">
      {contract.status === contractStatues.ACTIVE && <FontAwesomeIcon icon={faCheck} className="text-green-500" />}
      {contract.status === contractStatues.WAITING_FOR_COMPANY_APPROVAL && <FontAwesomeIcon icon={faClock} className="text-yellow-500" />}
      <Link to={`/user/companies/${contract.companyId}`} className="text-gray-600 text-lg font-normal no-underline">
        {contract.companyName}
      </Link>
      {contract.status === contractStatues.ACTIVE && <AccreditedInvestorDetails contract={contract} />}
    </div>
  );
};

export default ContractItem;
