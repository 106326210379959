import Decimal from 'decimal.js';

class SafeNumbers {
  constructor() {
    this.SAFE_MULTIPLIER = 1000000;
  }

  #isWeirdNull(number) {
    return number === null || number === undefined || number === '' || number === 'null' || number === 'undefined';
  }

  #safe(number) {
    return new Decimal(number);
  }

  /**
   * Adds two numbers and returns the result.
   * @param {number} a - number
   * @param {number} b - number
   * @returns a + b
   */
  add(a, b) {
    const safeA = this.#safe(a);
    const safeB = this.#safe(b);
    return safeA.add(safeB).toNumber();
  }

  /**
   * Subtract two numbers and returns the result.
   * @param {number} a - number
   * @param {number} b - number
   * @returns a - b
   */
  subtract(a, b) {
    const safeA = this.#safe(a);
    const safeB = this.#safe(b);
    return safeA.sub(safeB).toNumber();
  }

  /**
   * Multiply two numbers and return the result.
   * @param {number} a - number to multiply
   * @param {number} b - number to multiply
   * @returns a * b
   */
  multiply(a, b) {
    if (this.#isWeirdNull(a) || this.#isWeirdNull(b)) return 0;

    const safeA = this.#safe(a);
    const safeB = this.#safe(b);
    return safeA.mul(safeB).toNumber();
  }

  /**
   * Divide two numbers and return the result.
   * @param {number} a - number to divide
   * @param {number} b - number to divide
   * @returns a / b
   */
  divide(a, b) {
    const safeA = this.#safe(a);
    const safeB = this.#safe(b);
    return safeA.div(safeB).toNumber();
  }

  /**
   * Modulo two numbers and return the result.
   * @param {number} a - number to modulo
   * @param {number} b - number to modulo
   * @returns a % b
   */
  modulo(a, b) {
    /* JS BUG: in js 1%0.2 = 0.1999999. because of that we use '1000000' multiplication */
    const safeA = this.#safe(a).mul(this.SAFE_MULTIPLIER);
    const safeB = this.#safe(b).mul(this.SAFE_MULTIPLIER);
    return safeA.modulo(safeB).toNumber();
  }
}

const sn = new SafeNumbers();
export default sn;
