export const customLocalStorage = {
  getAccessToken: () => {
    return localStorage.getItem('accessToken');
  },
  setAccessToken: (token) => {
    localStorage.setItem('accessToken', token);
  },
  getRefreshToken: () => {
    return localStorage.getItem('refreshToken');
  },
  setRefreshToken: (refreshToken) => {
    localStorage.setItem('refreshToken', refreshToken);
  },
  removeTokens: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
  removeAccessToken: () => {
    localStorage.removeItem('accessToken');
  },
  getLanguage: () => {
    return localStorage.getItem('language');
  },
  setLanguage: (language) => {
    localStorage.setItem('language', language);
  },
  getCompanyLimit: () => {
    localStorage.getItem('company-limit');
  },
  setCompanyLimit: (limit) => {
    window.localStorage.setItem('company-limit', limit);
  },
  removeCompanyLimit: () => {
    window.localStorage.removeItem('company-limit');
  },
  getUserLimit: () => {
    localStorage.getItem('user-limit');
  },
  setUserLimit: (limit) => {
    window.localStorage.setItem('user-limit', limit);
  },
  removeUserLimit: () => {
    window.localStorage.removeItem('user-limit');
  },
};
