import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { signAgreement } from 'shared/apis';
import { schema } from 'shared/schemas/agreementModal';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import ModalConfirm from 'components/UI/ModalConfirm';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

export const AgreementModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addAlert } = useContext(AlertContentContext);
  const { setSignedCurrentAgreementVersion } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      readingApproval: false,
    },
  });

  const { mutate: sendAgreementSignRequest, isPending: isLoadingSignResponse } = useMutation({
    mutationKey: 'signAgreement',
    mutationFn: signAgreement,
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
    onSuccess: (data) => {
      setSignedCurrentAgreementVersion(data);
    },
  });

  const onSubmit = async () => {
    sendAgreementSignRequest();
  };

  const closeAfterRefuseToSign = () => {
    navigate('/refused-agreement');
  };

  /* on some browsers, onChange for some reason check value instead of checked, which causes a yup error */
  /* this is a silly and annoying workaround */
  const handleChange = (e) => {
    setValue('readingApproval', e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalConfirm
        show={true}
        onClose={closeAfterRefuseToSign}
        title={t('components.AgreementModal.title')}
        onProceed={handleSubmit(onSubmit)}
        content={
          <div className="min-w-[300px]">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                id="readingApproval"
                {...register('readingApproval')}
                onChange={handleChange}
                isInvalid={!!errors.readingApproval}
              />
              <Form.Label>
                {t('components.AgreementModal.form.labels.readingApproval')}
                <Link to="/terms-of-service" target="blank">
                  {t('components.AgreementModal.form.termsOfUse')}
                </Link>
              </Form.Label>
              <ErrorMessage
                errors={errors}
                name="readingApproval"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Check>
          </div>
        }
        variant="primary"
        proceed={t('components.AgreementModal.form.actions.agree')}
        cancel={t('components.AgreementModal.form.actions.refuse')}
        isLoading={isLoadingSignResponse}
        size="lg"
      />
    </form>
  );
};
