import { saveAs } from 'file-saver';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';

import { buildAuctionTitleFromOffer } from 'shared/utils/auctions';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const useDownloadDividedAccountsExcel = () => {
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();

  const downloadDividedAccountsExcel = useCallback(
    (offer) => {
      const binaryData = offer.internalApproval.dividedAccountingExcel?.data;
      if (!binaryData) {
        addAlert(errorAlertBuilder.bug());
        return;
      }
      const auctionTitle = buildAuctionTitleFromOffer(offer, t);

      // Create a Uint8Array from the binary data
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${binaryData}`;
      const investorName = offer?.initiation?.investingCompany.name;
      const fileName = `${investorName}-${auctionTitle}-divided-accounting.xlsx`;

      saveAs(file, fileName);
    },
    [addAlert, t],
  );

  return { downloadDividedAccountsExcel };
};
