import some from 'lodash/some';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { getUpcomingOfferingsLookupTable } from 'shared/apis';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import { columns } from './columns';

export const UpcomingOfferingsTable = ({ isExtended = true, ...props }) => {
  const { t } = useTranslation();
  const { activeContracts } = useContext(AuthContext);

  return (
    <TableQuery
      queryKey="upcoming-offerings"
      queryFn={getUpcomingOfferingsLookupTable}
      defaultSort={{ institutionalAuctionDate: sortDirections.DESC, issuingCompany: sortDirections.ASC }}
      Columns={columns(isExtended)}
      columnTPrefix="screens.dashboard.upcomingOfferings.columns."
      title={t('screens.dashboard.titles.upcomingOfferings')}
      enabled={some(activeContracts)}
      {...props}
    />
  );
};
