import { useTranslation } from 'react-i18next';

export const Instructions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="font-bold mb-3">{t('components.dividedAccountsUploader.instructions')}</h4>
      <ul className="list-inside list-decimal">
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions1.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions1.text')}
        </li>
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions2.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions2.text')}
        </li>
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions3.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions3.text')}
        </li>
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions4.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions4.text')}
        </li>
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions5.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions5.text')}
        </li>
        <li>
          <span className="font-medium">{t('components.dividedAccountsUploader.instructions6.Header')}</span>{' '}
          {t('components.dividedAccountsUploader.instructions6.text')}
        </li>
      </ul>
    </div>
  );
};
