import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext, CompanyDetailsContextProvider } from 'context/CompanyDetails';

import { companyViewModes } from 'shared/const/companyViewModes';

import { EditMode } from 'components/CompanyDetails/EditMode/EditMode';
import { ViewMode } from 'components/CompanyDetails/ViewMode/ViewMode';

const CompanyScreen = () => {
  const { t } = useTranslation();

  const {
    mode,
    enableGetEmploymentState,
    setEnableGetEmploymentState,
    activeContracts,
    companyDetails,
    employmentStateIsLoading,
    employmentStateError,
  } = useContext(CompanyDetailsContext);

  useEffect(() => {
    setEnableGetEmploymentState(true);
  }, [setEnableGetEmploymentState]);

  if (employmentStateIsLoading) {
    return (
      <div className="flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (employmentStateError) {
    return (
      <div className="flex justify-content-center">
        <h2>{t('screens.companies.titles.error')}</h2>
      </div>
    );
  }

  if (enableGetEmploymentState && isEmpty(activeContracts)) {
    return (
      <div className="flex justify-content-center">
        <h2>{t('screens.companies.titles.noCompanies')}</h2>
      </div>
    );
  }

  if (!companyDetails) {
    return (
      <div className="flex justify-content-center">
        <Spinner animation="border" size="xl" />
      </div>
    );
  }

  const renderMode = () => {
    switch (mode) {
      case companyViewModes.EDIT:
        return <EditMode />;
      case companyViewModes.VIEW:
      default:
        return <ViewMode />;
    }
  };

  return <Container className="my-5">{renderMode()}</Container>;
};

export const CompanyScreenWithContext = () => (
  <CompanyDetailsContextProvider>
    <CompanyScreen />
  </CompanyDetailsContextProvider>
);
