import get from 'lodash/get';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import { PhoneFieldWrapper } from './styles';

const ControlledPhoneInput = ({ control, errors, defaultCountry = 'IL', name, disabled = false }) => {
  const { watch, setValue } = useFormContext();
  const phoneNumber = watch(name);

  // force a re-render when the phone number changes
  // this is needed because the phone number is not a controlled input
  // and the react-hook-form does not know when the value changes
  // so we need to force a re-render

  useEffect(() => {
    setValue(name, phoneNumber);
  }, [phoneNumber, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <PhoneFieldWrapper className={get(errors, name) ? 'is-invalid' : null} disabled={disabled}>
            <PhoneInput
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              placeholder="Enter phone number"
              value={value}
              defaultCountry={defaultCountry}
              initialValueFormat="national"
            />
          </PhoneFieldWrapper>
        );
      }}
    />
  );
};

export default ControlledPhoneInput;
