import { ErrorMessage } from '@hookform/error-message';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

import ControlledPhoneInput from 'components/UI/Form/ControlledPhoneInput';

export const NewEmployeeForm = () => {
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.email')}</Form.Label>
        <Form.Control type="text" {...register(`email`)} isInvalid={errors?.email} />
        <ErrorMessage
          errors={errors}
          name={`email`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.firstName')}</Form.Label>
        <Form.Control type="text" {...register(`firstName`)} isInvalid={errors?.firstName} />
        <ErrorMessage
          errors={errors}
          name={`firstName`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.lastName')}</Form.Label>
        <Form.Control type="text" {...register(`lastName`)} isInvalid={errors?.lastName} />
        <ErrorMessage
          errors={errors}
          name={`lastName`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.mobile')}</Form.Label>
        <ControlledPhoneInput control={control} name={`mobile`} errors={errors} />
        <ErrorMessage
          errors={errors}
          name={`mobile`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.id')}</Form.Label>
        <Form.Control type="text" {...register(`personalId`)} isInvalid={errors?.personalId} />
        <ErrorMessage
          errors={errors}
          name={`personalId`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('screens.company.labels.role')}</Form.Label>
        <Form.Select {...register(`role`)} isInvalid={errors?.role}>
          <option value={contractRoles.SIGNATORY}>{t('screens.company.labels.signatory')}</option>
          <option value={contractRoles.EMPLOYEE}>{t('screens.company.labels.employee')}</option>
          <option value={contractRoles.COMPANY_ADMIN}>{t('screens.company.labels.admin')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name={`role`}
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>
    </Form>
  );
};
