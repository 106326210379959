import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getCompanyDataForRoleCompletion } from 'shared/apis';

export const useCompanyNameCompletion = ({ callback }) => {
  const [bnNumberToFetch, setBnNumberToFetch] = useState(undefined);
  const [timeOutPointer, setTimeOutPointer] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ['companyNameCompletion', bnNumberToFetch],
    queryFn: () =>
      getCompanyDataForRoleCompletion({
        bnNumbers: [bnNumberToFetch],
        onlyCompaniesInSystem: true,
      }),
    enabled: !!bnNumberToFetch,
  });

  useEffect(() => {
    if (data) {
      callback(data?.[0]);
      setBnNumberToFetch(undefined);
    }

    setIsLoading(false);
  }, [data, callback]);

  const fetchBnNumber = (bnNumber) => {
    setIsLoading(true);

    if (timeOutPointer) {
      clearTimeout(timeOutPointer);
    }
    const valueToCheck = bnNumber;
    setTimeOutPointer(
      setTimeout(() => {
        if (bnNumber === valueToCheck) {
          setBnNumberToFetch(bnNumber);
        }
      }, 1000),
    );
  };
  return { fetchBnNumber, isLoading };
};
