import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils } from 'hooks/offers';

import ScrollToSignAlert from 'components/Offers/ScrollToSignAlert';
import { SignatoriesManagementBoard } from 'components/Offers/SignatoriesManagementBoard';
import { ViewAndSignSummary } from 'components/Offers/ViewAndSignSummary/ViewAndSignSummary';

import { useSignaturesForm } from './useSignaturesForm';

export const Signatures = () => {
  const { offer } = useContext(OfferCreationContext);
  const { userCanSign, isUserOfferInitiator, isOfferComplete } = useOfferStateUtils({ offer });
  const { OfferSendingModal } = useSignaturesForm({ offer });

  return (
    <>
      <fieldset disabled={isOfferComplete}>
        <Container className="flex flex-col md:flex-row items-start gap-2 md:!gap-4 pb-[130px]">
          <div className="flex flex-col gap-4 w-full md:w-fit">
            <SignatoriesManagementBoard viewMode={!isUserOfferInitiator} />
            {userCanSign && <ScrollToSignAlert />}
          </div>
          <ViewAndSignSummary />
        </Container>
      </fieldset>

      <OfferSendingModal />
    </>
  );
};
