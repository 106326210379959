import { RATING_OPTIONS } from 'shared/utils/ratingUtils';

import { PUBLIC_OFFERINGS_FILTERS } from '../consts';

import { PublicOfferingFilter } from './PublicOfferingFilter';

export const IssuerFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.ISSUER });

export const LinkageFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.LINKAGE });

export const SectorFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.SECTOR });

export const CovenantFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.COVENANT });

export const OfferingTypeFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE });

export const AuctionTypeFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE });

export const LeadUnderwriterFilter = PublicOfferingFilter.bind(null, { filterKey: PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER });

// an array of all numbers from 1-100
const durationOptions = Array.from({ length: 100 }, (_, i) => ({ label: i + 1, value: i + 1 }));

export const MinDurationFilter = PublicOfferingFilter.bind(null, {
  filterKey: PUBLIC_OFFERINGS_FILTERS.MIN_DURATION,
  staticOptions: durationOptions,
});

export const MaxDurationFilter = PublicOfferingFilter.bind(null, {
  filterKey: PUBLIC_OFFERINGS_FILTERS.MAX_DURATION,
  staticOptions: durationOptions,
});

export const MinRatingFilter = PublicOfferingFilter.bind(null, {
  filterKey: PUBLIC_OFFERINGS_FILTERS.MIN_RATING,
  staticOptions: RATING_OPTIONS,
});

export const MaxRatingFilter = PublicOfferingFilter.bind(null, {
  filterKey: PUBLIC_OFFERINGS_FILTERS.MAX_RATING,
  staticOptions: RATING_OPTIONS,
});
