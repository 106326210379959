import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { UserDetailsContext } from 'context/UserDetails';

import { userGenders } from 'shared/const/userGenders';

const UserAdditionalInfo = () => {
  const { userDetailsFromServer, userDetailsIsLoading, userDetailsError } = useContext(UserDetailsContext);
  const { isSBAdmin } = useContext(AuthContext);

  const { t } = useTranslation();

  if (userDetailsIsLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (userDetailsError) {
    return (
      <div className="alert alert-danger" role="alert">
        {userDetailsError}
      </div>
    );
  }

  if (!userDetailsFromServer?.email) {
    return (
      <div className="alert alert-danger" role="alert">
        {t('screens.user.errors.noUser')}
      </div>
    );
  }

  let genderText;
  switch (userDetailsFromServer.gender) {
    case userGenders.FEMALE:
      genderText = t('common.genders.female');
      break;
    case userGenders.MALE:
      genderText = t('common.genders.male');
      break;
    case userGenders.OTHER:
    default:
      genderText = t('common.genders.other');
      break;
  }

  const isActiveText = userDetailsFromServer.isActive ? t('screens.user.labels.active') : t('screens.user.labels.blocked');

  return (
    <ul className="list-group p-0">
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.tenderAlerts')}:</span>{' '}
        {userDetailsFromServer.tenderAlerts ? t('screens.user.labels.yes') : t('screens.user.labels.no')}
      </li>
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.personalId')}:</span> {userDetailsFromServer.personalId}
      </li>
      <li className="list-group-item">
        <span className="fw-bold">{t('screens.user.labels.gender')}:</span> {genderText}
      </li>
      {isSBAdmin && (
        <li className="list-group-item">
          <span className="fw-bold">{t('screens.user.labels.userStatus')}:</span> {isActiveText}
        </li>
      )}
    </ul>
  );
};

export default UserAdditionalInfo;
