import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalConfirm = ({
  show,
  onClose,
  title,
  onProceed,
  content,
  proceed = 'common.proceed',
  cancel = 'common.cancel',
  variant = 'danger',
  isLoading = false,
  size = 'md',
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onClose} className="d-flex align-items-center" size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>

      <Modal.Footer className="d-flex justify-between">
        <Button onClick={onClose} variant="secondary">
          {t(cancel)}
        </Button>
        <Button onClick={onProceed} variant={variant} autoFocus disabled={isLoading}>
          {t(proceed)} {isLoading && <Spinner animation="border" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  proceed: PropTypes.string,
  variant: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl', 'md']),
};

export default ModalConfirm;
