import _ from 'lodash';

import { minServerStatusesForEachScreen, offerCreationRoutesForEachScreen, offerServerStatusesOrderedArray } from 'shared/const/offerCreationStages';

export const isRouteAllowed = (offer, route) => {
  const currentOfferServerStatusIndex = _.findIndex(offerServerStatusesOrderedArray, (status) => status === offer.status);
  const thisRouteStage = _.findKey(offerCreationRoutesForEachScreen, (routeValue) => routeValue === route);
  const minOfferServerStatus = minServerStatusesForEachScreen[thisRouteStage];
  const minOfferServerStatusIndex = _.findIndex(offerServerStatusesOrderedArray, (status) => status === minOfferServerStatus);
  const isRouteAllowed = currentOfferServerStatusIndex >= minOfferServerStatusIndex;
  return isRouteAllowed;
};
