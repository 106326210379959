import { useCallback, useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/Auth';

import { GAEventStages } from 'shared/const/googleAnalytics';
import { formatUserProps, processParams } from 'shared/utils/googleAnalytics';

const debug = (...props) => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE === 'true') {
    console.log(...props);
  }
};

/* EXPORTED METHODS */
export const sendGAEvent = (eventName, eventStage = GAEventStages.OCCURRED, paramsDictionary = {}) => {
  const { formattedEventName, eventParams } = processParams(eventName, eventStage, paramsDictionary);
  debug('GA event:', formattedEventName, eventParams);
  window.gtag('event', formattedEventName, eventParams);
};

/**
 * replace last user props with new ones (not merge)
 */
const setUserProps = (userProps) => {
  debug('GA user properties:', userProps);
  window.gtag('set', 'user_properties', userProps);
};

/* EXPORTED HOOK */
export const useGoogleAnalytics = () => {
  const authDetails = useContext(AuthContext);
  const [lastPath, setLastPath] = useState();
  const [formattedUserProps, setFormattedUserProps] = useState({});

  /* update auth details */
  useEffect(() => {
    const newFormattedUserProps = formatUserProps(authDetails);
    if (JSON.stringify(newFormattedUserProps) === JSON.stringify(formattedUserProps)) {
      return;
    }

    setFormattedUserProps(newFormattedUserProps);
  }, [authDetails, formattedUserProps]);

  /* update user props */
  useEffect(() => {
    setUserProps(formattedUserProps);
  }, [formattedUserProps]);

  const sendGAPageView = useCallback(
    (location) => {
      const pathname = location?.pathname || '';
      const currentSearch = location?.search || '';
      const currentPath = pathname + currentSearch;

      if (lastPath === currentPath) {
        return;
      }
      setLastPath(currentPath);

      window.gtag('event', 'page_view', {
        page_location: currentPath,
        page_path: pathname,
        page_title: pathname,
      });
    },
    [lastPath],
  );

  return { sendGAPageView };
};
