import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import { Badge } from 'react-bootstrap';

export const buildProceedModalContent = (t, values, defaultValues) => {
  const userSegmentsContent = buildUserSegments(t, defaultValues, values);
  const changedValuesSegment = buildChangedValuesSegment(t, defaultValues, values);
  const content = (
    <>
      <p>{t('screens.company.proceedModal.content')}</p>
      {changedValuesSegment}
      {userSegmentsContent}
    </>
  );

  return content;
};

const buildUserSegments = (t, defaultValues, values) => {
  const { users } = values;

  const usersInDbByDocumentId = keyBy(defaultValues.users, (user) => user.userId);
  const usersInFormByDocumentId = keyBy(users, (user) => user.userId);
  const allUsers = uniq([...keys(usersInDbByDocumentId), ...keys(usersInFormByDocumentId)]);

  const content = allUsers.map((userId) => {
    const userInDb = usersInDbByDocumentId[userId];
    const userInForm = usersInFormByDocumentId[userId];

    if (!userInDb) {
      return (
        <li key={userId}>
          <Badge bg="success">{t('screens.company.proceedModal.addedUser')}</Badge> {userInForm.email}
        </li>
      );
    } else if (!userInForm) {
      return (
        <li key={userId}>
          <Badge bg="info">{t('screens.company.proceedModal.removedUser')}</Badge> {userInForm.email}
        </li>
      );
    } else {
      const lookFor = ['firstName', 'lastName', 'email', 'mobile', 'role'];
      const changedValues = lookFor
        .map((key) => {
          if (userInDb[key] !== userInForm[key]) {
            return {
              key: t(`screens.company.labels.${key}`),
              oldValue: userInDb[key],
              newValue: userInForm[key],
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (isEmpty(changedValues)) {
        return null;
      }

      return (
        <li key={userId}>
          <Badge bg="warning">{t('screens.company.proceedModal.updatedUser')}</Badge> {userInForm.email}
          <ul>
            {changedValues.map((value) => (
              <li key={value.key}>
                ({value.key} {t('screens.company.proceedModal.from')} <span className="font-bold">"{value.oldValue}"</span>{' '}
                {t('screens.company.proceedModal.to')} <span className="font-bold">"{value.newValue}"</span>)
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  if (isEmpty(content) || some(content, (item) => item === null)) {
    return null;
  }

  return (
    <>
      <p>{t('screens.company.proceedModal.updatedUsers')}:</p>
      <ul>{content}</ul>
    </>
  );
};

const buildChangedValuesSegment = (t, defaultValues, values) => {
  const lookFor = ['companyName', 'bnNumber', 'domain'];
  const changedValues = lookFor
    .map((key) => {
      if (values[key] !== defaultValues[key]) {
        return {
          key: t(`screens.company.labels.${key}`),
          oldValue: defaultValues[key],
          newValue: values[key],
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const changedValuesSegment = some(changedValues) ? (
    <>
      <p>{t('screens.company.proceedModal.changedValues')}:</p>
      <ul>
        {changedValues.map((value) => (
          <li key={value.key}>
            {value.key}: {value.oldValue} {t('screens.company.proceedModal.to')} {value.newValue}
          </li>
        ))}
      </ul>
    </>
  ) : null;

  return changedValuesSegment;
};
