import { faCircleInfo, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { initiateFileReading } from 'shared/utils/offers/xlsx';

import excelIllustration from 'asset/offers/excel-illustration.jpg';

import { Instructions } from './Instructions';

export const ExcelUploadControls = () => {
  const { setDividedAccountsUploadedArray } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const fileInputRef = useRef(null);

  const fileReadingCb = useCallback(
    (json) => {
      setDividedAccountsUploadedArray(json);
    },
    [setDividedAccountsUploadedArray],
  );

  const UploadButton = ({ variant, size }) => {
    return (
      <Button variant={variant} size={size} className="flex gap-1 align-items-center" onClick={() => fileInputRef.current.click()}>
        <FontAwesomeIcon icon={faFile} />
        {t('components.dividedAccountsUploader.uploadLink')}
      </Button>
    );
  };

  return (
    <>
      <div className="flex gap-1 align-items-center text-nowrap">
        <UploadButton variant="secondary" size="xs" />
        <FontAwesomeIcon icon={faCircleInfo} className="text-gray-600 cursor-pointer" onClick={() => setShow(true)} />
      </div>

      <input type="file" accept=".xlsx,.xls" onChange={(e) => initiateFileReading(e, fileReadingCb)} ref={fileInputRef} style={{ display: 'none' }} />
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t('components.dividedAccountsUploader.instructionsModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container flex flex-col gap-5">
            <Instructions />
            <div className="flex justify-center">
              <img src={excelIllustration} alt="excel illustration" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-center">
          <Button variant="outline-primary" size="md" onClick={() => setShow(false)}>
            {t('components.dividedAccountsUploader.instructionsModal.close')}
          </Button>
          <UploadButton variant="success" size="md" />
        </Modal.Footer>
      </Modal>
    </>
  );
};
