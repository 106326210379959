import { ErrorMessage } from '@hookform/error-message';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { userGenders } from 'shared/const/userGenders';

const UserAdditionalInfoForm = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { isSBAdmin } = useContext(AuthContext);

  return (
    <>
      <Form.Group controlId="firstName">
        <Form.Label>{t('screens.user.labels.firstName')}</Form.Label>
        <Form.Control type="text" {...register('firstName')} isInvalid={errors.firstName} />
        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group controlId="lastName">
        <Form.Label>{t('screens.user.labels.lastName')}</Form.Label>
        <Form.Control type="text" {...register('lastName')} isInvalid={errors.lastName} />
        <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group controlId="gender">
        <Form.Label>{t('screens.user.labels.gender')}</Form.Label>
        <Form.Select type="text" {...register('gender')} isInvalid={errors.gender}>
          <option value={userGenders.FEMALE}>{t('screens.user.options.gender.female')}</option>
          <option value={userGenders.MALE}>{t('screens.user.options.gender.male')}</option>
          <option value={userGenders.OTHER}>{t('screens.user.options.gender.other')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name="gender"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      <Form.Group controlId="tenderAlerts">
        <Form.Label>{t('screens.user.labels.tenderAlerts')}</Form.Label>
        <Form.Select type="text" {...register('tenderAlerts')} isInvalid={errors.tenderAlerts}>
          <option value={true}>{t('screens.user.options.tenderAlerts.true')}</option>
          <option value={false}>{t('screens.user.options.tenderAlerts.false')}</option>
        </Form.Select>
        <ErrorMessage
          errors={errors}
          name="tenderAlerts"
          render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
        />
      </Form.Group>

      {isSBAdmin && (
        <Form.Group controlId="isActive">
          <Form.Label>{t('screens.user.labels.userStatus')}</Form.Label>
          <Form.Select type="text" {...register('isActive')} isInvalid={errors.isActive}>
            <option value={true}>{t('screens.user.labels.active')}</option>
            <option value={false}>{t('screens.user.labels.blocked')}</option>
          </Form.Select>
          <ErrorMessage
            errors={errors}
            name="isActive"
            render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
          />
        </Form.Group>
      )}
    </>
  );
};

export default UserAdditionalInfoForm;
