import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const ExpirationDateInfo = ({ expirationDate }) => {
  const { t } = useTranslation();

  if (!expirationDate) {
    return null;
  }

  const isExpired = dayjs(expirationDate).isBefore(dayjs());
  const icon = isExpired ? faTimesCircle : faCheckCircle;
  const colorClass = isExpired ? 'text-danger' : 'text-success';

  const TooltipTrigger = <FontAwesomeIcon icon={icon} className={`${colorClass} h-4 w-4`} />;
  const TooltipContent = (
    <div>
      <div>
        {t('common.expirationDate')} {dayjs(expirationDate).format('DD.MM.YYYY')}
      </div>
      <div>
        ({isExpired ? t('common.before') : t('common.inTime')} {dayjs(expirationDate).diff(dayjs(), 'days')} {t('common.days')})
      </div>
    </div>
  );

  return (
    <span>
      <Tooltip Content={TooltipContent} Trigger={TooltipTrigger} />
    </span>
  );
};
