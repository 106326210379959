import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  AUCTION_NOTIFICATION_DATA,
  AUCTION_NOTIFICATION_TYPES,
  CONTRACT_NOTIFICATION_DATA,
  CONTRACT_NOTIFICATION_TYPES,
  OFFER_NOTIFICATION_DATA,
  OFFER_NOTIFICATION_TYPES,
} from 'shared/const/notifications';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.locale('he');

export const isUnread = (notification) => {
  return notification.isRead === false;
};

export const getNotificationTitle = (notification, t) => {
  return t(`notifications.types.${notification.type}.title`);
};

const validateNotificationData = (notification, constObj) => {
  const { data } = notification;

  const requiredFields = Object.keys(constObj);

  for (const field of requiredFields) {
    if (!data[field]) {
      return false;
    }
  }

  return true;
};

const generateNotificationMessageFromConst = (notification, t, constObj) => {
  const { type } = notification;
  const requiredFields = Object.keys(constObj);
  const tObj = {};

  for (const field of requiredFields) {
    tObj[field] = notification.data[field];
  }

  return t(`notifications.types.${type}.message`, tObj);
};

export const getConstsForNotificationType = (notification) => {
  const { type } = notification;
  if (AUCTION_NOTIFICATION_TYPES[type]) {
    return AUCTION_NOTIFICATION_DATA;
  }
  if (CONTRACT_NOTIFICATION_TYPES[type]) {
    return CONTRACT_NOTIFICATION_DATA;
  }
  if (OFFER_NOTIFICATION_TYPES[type]) {
    return OFFER_NOTIFICATION_DATA;
  }
  return null;
};

export const generateNotificationMessage = (notification, t) => {
  let constObj = getConstsForNotificationType(notification);

  if (!validateNotificationData(notification, constObj)) {
    return null;
  }
  return generateNotificationMessageFromConst(notification, t, constObj);
};

export const generateNotificationDate = (notification, t, lang) => {
  dayjs.locale(lang);

  if (!notification.createdAt) {
    return null;
  }

  const createdAt = dayjs(notification.createdAt);
  const now = dayjs();

  if (now.diff(createdAt, 'day') < 1) {
    // If under 24 hours, return time passed
    return createdAt.fromNow();
  } else if (now.diff(createdAt, 'day') < 7) {
    // If under 7 days, return day of week
    if (createdAt.isToday()) {
      return t('notifications.today');
    } else if (createdAt.isYesterday()) {
      return t('notifications.yesterday');
    } else {
      return createdAt.format('dddd'); // Formats to day of the week, e.g., 'Monday'
    }
  } else {
    // Else return date
    return createdAt.format('DD/MM/YYYY'); // Change the format as needed
  }
};
