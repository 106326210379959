import { useTranslation } from 'react-i18next';

import { serverDetailedStatuses } from 'shared/const/detailedStatuses';

export const localizeStatus = (t, status) => {
  switch (status) {
    case serverDetailedStatuses.ACTIVE:
      return t('screens.company.table.detailedStatuses.ACTIVE');
    case serverDetailedStatuses.WAITING_FOR_USER_APPROVAL:
      return t('screens.company.table.detailedStatuses.WAITING_FOR_USER_APPROVAL');
    case serverDetailedStatuses.WAITING_FOR_COMPANY_APPROVAL:
      return t('screens.company.table.detailedStatuses.WAITING_FOR_COMPANY_APPROVAL');
    case serverDetailedStatuses.WAITING_FOR_COMPANY_AND_USER_APPROVAL:
      return t('screens.company.table.detailedStatuses.WAITING_FOR_COMPANY_AND_USER_APPROVAL');
    case serverDetailedStatuses.REJECTED_BY_COMPANY:
      return t('screens.company.table.detailedStatuses.REJECTED_BY_COMPANY');
    case serverDetailedStatuses.REJECTED_BY_USER:
      return t('screens.company.table.detailedStatuses.REJECTED_BY_USER');

    default:
      return status;
  }
};

export const LocalizedContractStatus = ({ status }) => {
  const { t } = useTranslation();
  return localizeStatus(t, status);
};

export const ContractStatusSpan = ({ detailedStatus }) => {
  let variant;
  switch (detailedStatus) {
    case serverDetailedStatuses.ACTIVE:
      variant = 'text-success';
      break;
    case serverDetailedStatuses.REJECTED_BY_COMPANY:
    case serverDetailedStatuses.REJECTED_BY_USER:
      variant = 'text-danger';
      break;
    case serverDetailedStatuses.WAITING_FOR_COMPANY_AND_USER_APPROVAL:
    case serverDetailedStatuses.WAITING_FOR_COMPANY_APPROVAL:
    case serverDetailedStatuses.WAITING_FOR_USER_APPROVAL:
      variant = 'text-warning';
      break;
    default:
      variant = 'text-secondary';
  }

  return (
    <span className={variant}>
      <LocalizedContractStatus status={detailedStatus} />
    </span>
  );
};
