import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/context/offer-creation';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { getDistinctLimitsForAuctionByCompany, getOfferById } from 'shared/apis';
import { buildAuctionTitle, buildAuctionTitleFromOffer } from 'shared/utils/auctions';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const OfferCreationContext = createContext(null);

export const OfferCreationContextProvider = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addAlert } = useContext(AlertContentContext);
  const { offerId } = useParams();

  const contextLoaded = useState(true)[0];
  const [auction, setAuction] = useState(null);
  /* divide accounts state can be loaded by manual input, or by loading an Excel file, the data transfer is cleaner through context*/
  const [dividedAccountsUploadedArray, setDividedAccountsUploadedArray] = useState(null);
  /* set once, on first load, used to trigger mass isTouched update */
  const [hasLoadedDividedAccounts, setHasLoadedDividedAccounts] = useState(false);
  const [dividedAccountsState, setDividedAccountsState] = useState([]);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [lastAddedBankAccount, setLastAddedBankAccount] = useState({});
  const [scopeAndAccountsErrors, setScopeAndAccountsErrors] = useState({});
  const [onProceed, setOnProceed] = useState(() => {});
  const [isLoadingFormSubmit, setIsLoadingFormSubmit] = useState(false);

  /**
   * the divided account input table uses 'fieldArray' (a 'react-hook-form' feature) but the 'watch' function is watching the value array as a pointer.
   * because of that wen one of the fields inside the array is changed, the watch function will not trigger any dependent hook (useEffect/useCallback/useMemo etc..)
   * to solve that we use an useState hook that will be changed (the value dose not meter anything) whenever one of the fieldArray values is changed
   * this useState will trigger dependant hooks
   */
  const [dividedAccountsFileErrors, setDividedAccountsFileErrors] = useState(null);
  const [atLeastOneBidTouched, setAtLeastOneBidTouched] = useState(null);
  const [offerDataLayer, setOfferDataLayer] = useState(null);

  /* OFFER QUERY */
  const {
    isLoading: isLoadingOffer,
    data: offer,
    error: getOfferByIdError,
  } = useQuery({
    queryKey: ['getOfferById', offerId],
    queryFn: () => getOfferById(offerId),
    enabled: !!offerId,
  });

  const setOffer = (newOffer) => {
    queryClient.setQueryData(['getOfferById', offerId], newOffer);
    queryClient.invalidateQueries([
      {
        queryKey: ['getActiveAuctionsOfferState'],
      },
      {
        queryKey: ['getUserOffersLookupTable'],
      },
    ]);
  };

  const { data: distinctLimitsForAuctionByCompanyData, isLoading: isLoadingDistinctLimitsForAuctionByCompanyData } = useQuery({
    queryKey: ['distinctLimitsForAuctionByCompany', offer?.initiation.auctionDetails.auctionId, offer?.initiation.investingCompany.companyId],
    queryFn: () => getDistinctLimitsForAuctionByCompany(offer?.initiation.auctionDetails.auctionId, offer?.initiation.investingCompany.companyId),
    enabled: !!offer?.initiation.auctionDetails.auctionId && !!offer?.initiation.investingCompany.companyId,
    initialData: [],
  });

  useEffect(() => {
    if (!offer) {
      return;
    }

    const relevantOfferDetailsForAnalytics = {
      _id: offer?._id,
      status: offer?.status,
      accountingType: offer?.bankAccounts.chosenType,
      creatorName: offer?.initiation.activity.firstName + '_' + offer?.initiation.activity.firstName,
      creatorEmail: offer?.initiation.activity.email,
      creatorMobile: offer?.initiation.activity.mobile,
      creatorPersonalId: offer?.initiation.activity.personalId,
      auctionId: offer?.initiation.auctionDetails.auctionId,
      auctionIssuedCompanyName: offer?.initiation.auctionDetails.issuedCompanyName,
      auctionType: offer?.initiation.auctionDetails.type,
      underwriterEmail: offer?.initiation.auctionDetails.underwriterEmail,
      underwriterId: offer?.initiation.auctionDetails.underwriterId,
      underwriterName: offer?.initiation.auctionDetails.underwriterName,
      investingCompanyBnNumber: offer?.initiation.investingCompany.bnNumber,
      investingCompanyId: offer?.initiation.investingCompany.companyId,
      investingCompanyType: offer?.initiation.investingCompany.investorType,
      investingCompanyName: offer?.initiation.investingCompany.name,
      auctionTitle: offer ? buildAuctionTitleFromOffer(offer, t) : '',
    };

    setOfferDataLayer(relevantOfferDetailsForAnalytics);
  }, [t, offer]);

  useEffect(() => {
    if (getOfferByIdError) {
      const errorName = getErrorNameFromErrorResponse(getOfferByIdError);
      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.OFFER_NOT_FOUND:
          errorAlertContent = {
            title: t('context.offerCreation.errors.offerNotFound.title'),
            description: t('context.offerCreation.errors.offerNotFound.description'),
          };
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(getOfferByIdError);
      }
      addAlert(errorAlertContent);

      navigate('/');
    }
  }, [getOfferByIdError, navigate, t, addAlert]);

  const auctionTitle = useMemo(() => {
    return buildAuctionTitle({ auction, t, offer });
  }, [auction, offer, t]);

  const contextValue = {
    contextLoaded,
    auction,
    setAuction,
    auctionTitle,
    offerId,
    offer,
    setOffer,
    isLoadingOffer,
    dividedAccountsState,
    setDividedAccountsState,
    dividedAccountsUploadedArray,
    setDividedAccountsUploadedArray,
    hasLoadedDividedAccounts,
    setHasLoadedDividedAccounts,
    showAddAccountModal,
    setShowAddAccountModal,
    lastAddedBankAccount,
    setLastAddedBankAccount,
    scopeAndAccountsErrors,
    setScopeAndAccountsErrors,
    dividedAccountsFileErrors,
    setDividedAccountsFileErrors,
    atLeastOneBidTouched,
    setAtLeastOneBidTouched,
    offerDataLayer,
    onProceed,
    setOnProceed,
    isLoadingFormSubmit,
    setIsLoadingFormSubmit,
    distinctLimitsForAuctionByCompanyData,
    isLoadingDistinctLimitsForAuctionByCompanyData,
  };

  return (
    <OfferCreationContext.Provider value={contextValue}>
      <Outlet />
    </OfferCreationContext.Provider>
  );
};
