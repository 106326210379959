import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { CompanyDetailsContext, CompanyDetailsContextProvider } from 'context/CompanyDetails';

import { companyViewModes } from 'shared/const/companyViewModes';

import NewRHF from 'components/CompanyDetails/NewMode/NewRHF';

const NewCompanyScreen = () => {
  const { setMode, mode } = useContext(CompanyDetailsContext);
  if (mode !== companyViewModes.NEW) {
    setMode(companyViewModes.NEW);
    return null;
  }

  return (
    <Container className="mt-5">
      <NewRHF />
    </Container>
  );
};

export const NewCompanyScreenWithContext = () => (
  <CompanyDetailsContextProvider>
    <NewCompanyScreen />
  </CompanyDetailsContextProvider>
);
