import { faEdit, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';

const getColumns = (regenerateSecretKey, deleteUnderwriter, editUnderwriter) => [
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'secretKeySneakPeek',
    accessor: 'secretKeySneakPeek',
    noSort: true,
    // text dir ltr to prevent arabic numbers from being reversed
    Cell: (row) => <span dir="ltr">{`${row.secretKeySneakPeek}****************`}</span>,
  },
  {
    Header: 'allowedHostnames',
    accessor: 'allowedHostnames',
    Cell: (row) => row.allowedHostnames?.join(', '),
    noSort: true,
  },
  {
    Header: 'updatedAt',
    accessor: 'updatedAt',
    noSort: true,
    Cell: (row) => dayjs(row.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    Header: 'createdAt',
    accessor: 'createdAt',
    noSort: true,
    Cell: (row) => dayjs(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: (row) => (
      <div className="d-flex gap-2">
        <Button variant="primary" onClick={() => regenerateSecretKey(row)}>
          <FontAwesomeIcon icon={faRecycle} />
        </Button>
        <Button variant="danger" onClick={() => deleteUnderwriter(row)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="warning" onClick={() => editUnderwriter(row)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </div>
    ),
  },
];

export default getColumns;
