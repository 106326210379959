import { useQuery } from '@tanstack/react-query';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { contractsLookupForEmployee } from 'shared/apis';
import { serverDetailedStatuses } from 'shared/const/detailedStatuses';

const getCompanyNameFromContract = (contract) => contract.company.name;

export const NoActiveContractsAlert = () => {
  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);
  const [alertContent, setAlertContent] = useState('');

  const { data, isLoading, error } = useQuery({
    queryKey: 'contracts-lookup-for-employee',
    queryFn: () =>
      contractsLookupForEmployee(
        {
          page: 1,
          limit: 50,
          sortBy: 'employee.firstName',
          sortDirection: 'DESC',
          UriEncodedFilters: { detailedStatus: 'REJECTED', userId: '66d6e8b36d03dfae11a55ed0' },
          searchKey: '',
        },
        userId,
      ),
  });

  useEffect(() => {
    if (isLoading || error || !data) return;
    const deactivatedCompaniesContracts = filter(data?.data, { detailedStatus: serverDetailedStatuses.COMPANY_DEACTIVATED });
    const hasDeactivatedCompaniesContracts = some(deactivatedCompaniesContracts);
    const hasPendingContracts = some(
      data?.data,
      (contract) =>
        contract.detailedStatus === serverDetailedStatuses.WAITING_FOR_COMPANY_AND_USER_APPROVAL ||
        contract.detailedStatus === serverDetailedStatuses.WAITING_FOR_COMPANY_APPROVAL ||
        contract.detailedStatus === serverDetailedStatuses.WAITING_FOR_USER_APPROVAL,
    );

    if (hasPendingContracts) {
      setAlertContent(t('screens.dashboard.labels.noActiveContracts'));
      return;
    }

    if (hasDeactivatedCompaniesContracts) {
      if (deactivatedCompaniesContracts.length > 1) {
        const companyNames = deactivatedCompaniesContracts.map((contract) => getCompanyNameFromContract(contract)).join(', ');
        setAlertContent(t('screens.dashboard.labels.deactivatedCompaniesContracts', { companyNames }));
        return;
      }

      const companyName = getCompanyNameFromContract(deactivatedCompaniesContracts[0]);
      setAlertContent(t('screens.dashboard.labels.deactivatedCompanyContract', { companyName }));
      return;
    }

    setAlertContent(t('screens.dashboard.labels.noActiveContracts'));
  }, [t, data, isLoading, error, setAlertContent]);

  return (
    <Alert variant="info">
      <p className="text-lg">{t('screens.dashboard.labels.welcome')}</p>
      <p>{alertContent}</p>
    </Alert>
  );
};
