import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { systemRoles } from 'shared/const/systemRoles';

const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authContextLoaded, isTFAValidated, isLoggedIn, systemRole, lastLocation, setLastLocation } = useContext(AuthContext);

  /** navigate logged in users */
  useEffect(() => {
    if (!authContextLoaded) return;
    if (isLoggedIn) {
      if (lastLocation) {
        /**
         * the state will not update in this function run, but if navigate command run first it abort the state update
         * in case sb admins logout out and login as user or vice versa. they will be redirected by the route guard
         */
        setLastLocation(null);
        navigate(lastLocation);
      } else if (systemRole === systemRoles.SB_ADMIN) {
        navigate('/admin');
      } else if (systemRole === systemRoles.USER) {
        navigate('/user');
      }
    } else if (isTFAValidated) {
      if (location.pathname === '/sign-up') return;
      navigate('/sign-up');
    }
  }, [authContextLoaded, isTFAValidated, isLoggedIn, navigate, systemRole, location.pathname, lastLocation, setLastLocation]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default UnprotectedRoute;
