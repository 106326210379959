import numbro from 'numbro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { auctionTypes } from 'shared/const/auctions';
import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';

import { useBidInputValidation } from '../../../hooks/offers/useBidInputValidation';
import { useDividedAccountsInputWrapper } from '../../../hooks/offers/useDividedAccountsInputWrapper';
import { BidInputController } from '../BidInput/BidInputController';

import './style.css';

export const BidsInput = ({ index, name, auctionType }) => {
  const fieldName = useMemo(() => `table.${index}.${name}`, [index, name]);
  const { watch, setValue } = useFormContext();
  const [isEditing, setIsEditing] = useState(false);
  const watchFieldName = watch(fieldName);

  useBidInputValidation({ fieldName, inputType: name });

  useEffect(() => {
    /* trick watch update to overcome react-hook-form bug */
    /* watch is not updated when the value is an object and and inner value is changed */
    /* stringifying the object and saving it in a different field accomplishes the needed update */
    const tableStringified = JSON.stringify(watch('table'));
    setValue('tableStr', tableStringified);
  }, [watch, setValue, watchFieldName]);

  const format = useCallback(
    (value) => {
      switch (name) {
        case BID_INPUT_FIELDS.LIMIT:
          switch (auctionType) {
            case auctionTypes.INTEREST:
            case auctionTypes.GAP:
              return numbro(value).formatCurrency({
                thousandSeparated: true,
                currencySymbol: '%',
                currencyPosition: 'postfix',
              });

            case auctionTypes.PRICE:
              return numbro(value).format('0,0$');
            default:
              return value;
          }
        case BID_INPUT_FIELDS.AMOUNT:
          return numbro(value).format({
            thousandSeparated: true,
          });
        default:
          return value;
      }
    },
    [name, auctionType],
  );

  const controller = useMemo(() => {
    return <BidInputController fieldName={fieldName} isEditingMode={isEditing} format={format} className={'p-0 m-0'} />;
  }, [fieldName, format, isEditing]);

  return useDividedAccountsInputWrapper({
    onBlur: () => {
      setIsEditing(false);
    },
    onFocus: () => {
      setIsEditing(true);
    },
    controller,
    fieldName,
  });
};
