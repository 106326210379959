import styled from 'styled-components';

export const RowToggle = styled.button`
  width: 57px;
  height: 38px;
  font-size: 13px;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  color: ${(props) => (props.variant === 'danger' ? 'rgb(191 0 0)' : '#212529')};
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${(props) => (props.variant === 'danger' ? 'rgb(255 110 110)' : '#797979')};
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) => props.isFirst && (props.hasRowError ? 'align-self: center; margin-top: 7px;' : 'align-self: end;')}
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;
