import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { contractStatues } from 'shared/const/contractStatues';

import { ContractPopup } from '../ContractPopup';

import { InvitationAlert } from './InvitationAlert';

export const InvitationAlerts = ({ contractsByStatus }) => {
  if (isEmpty(contractsByStatus[contractStatues.WAITING_FOR_USER_APPROVAL])) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      {contractsByStatus[contractStatues.WAITING_FOR_USER_APPROVAL]?.map((contract, index) => (
        <>
          <InvitationAlert contract={contract} key={index} />
          <ContractPopup contract={contract} key={index} />
        </>
      ))}
    </div>
  );
};
