import numbro from 'numbro';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getFormattedLimit, getLocalizedAuctionType } from 'shared/utils/auctions';

import { getFormattedAmountForLimit, getFormattedTotalAmountForLimit, getTableData } from './utils';

export const SplitTable = ({ data }) => {
  const { bids } = data;
  const { t } = useTranslation();

  const tableData = getTableData(data, t);

  return (
    <Table responsive>
      <thead>
        <TableHeader bids={bids} data={data} />
      </thead>
      <tbody>
        <TableBody tableData={tableData} bids={bids} />
      </tbody>
      <tfoot>
        <TableFooter tableData={tableData} bids={bids} />
      </tfoot>
    </Table>
  );
};
const TableHeader = ({ bids, data }) => {
  const { t } = useTranslation();
  const bidRows = bids.map((bid, index) => <SplitTableLimitColHeader key={index} data={data} bid={bid} index={index} />);

  return (
    <tr>
      <th>{t('components.SplitConfirmModal.table.accountName')}</th>
      {bidRows}
      <th>{t('components.SplitConfirmModal.table.totalAmount')}</th>
      <th>{t('components.SplitConfirmModal.table.limitation')}</th>
      <th>{t('components.SplitConfirmModal.table.note')}</th>
    </tr>
  );
};
const TableFooter = ({ tableData, bids }) => {
  const { t } = useTranslation();
  const totalAmount = numbro(tableData.reduce((acc, { totalAmount }) => acc + totalAmount, 0)).format('0,0');

  return (
    <tr>
      <td>{t('components.SplitConfirmModal.table.total')}</td>
      {bids.map((bid, index) => (
        <td key={index}>{getFormattedTotalAmountForLimit(tableData, bid)}</td>
      ))}
      <td>{totalAmount}</td>
      <td></td>
      <td></td>
    </tr>
  );
};

const TableBody = ({ tableData, bids }) => {
  return tableData.map(({ accountName, totalAmount, accountData, limitation, errors }, index) => (
    <tr key={index} className={errors.length > 0 ? 'text-danger' : ''}>
      <td>{accountName}</td>
      {bids.map((bid, index) => (
        <td key={index}>{getFormattedAmountForLimit(bid, accountData)}</td>
      ))}
      <td>{numbro(totalAmount).format('0,0')}</td>
      <td>{limitation}</td>
      <td>{errors.join(', ')}</td>
    </tr>
  ));
};

const SplitTableLimitColHeader = ({ data, bid, index }) => {
  const { t } = useTranslation();
  const { offer } = data;
  const { limit } = bid;
  const type = offer.initiation.auctionDetails.type;

  const localizedAuctionType = getLocalizedAuctionType(type, t);
  const formattedLimit = getFormattedLimit(limit, type);

  return <th key={index}>{t('components.SplitConfirmModal.table.amountForLimit', { localizedAuctionType, limit: formattedLimit })}</th>;
};
