import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { initiateOffer } from 'shared/apis';
import { offerCreationRoutesForEachScreen } from 'shared/const/offerCreationStages';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

const ActionCell = ({ row }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContentContext);
  const { activeContractsWithOfferCreationPermission } = useContext(AuthContext);

  const { mutate: initiateOfferWithCompany } = useMutation({
    mutationKey: 'initiateOffer',
    mutationFn: initiateOffer,
    onSuccess: (data) => {
      navigate(`/user/offers/create/order-scope-and-accounts/${data._id}`);
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const handleCreateOffer = () => {
    if (activeContractsWithOfferCreationPermission.length === 1) {
      const payload = {
        auctionId: row._id,
        companyId: activeContractsWithOfferCreationPermission[0].companyId,
      };
      initiateOfferWithCompany(payload);
    } else {
      navigate(`/user/offers/create/${offerCreationRoutesForEachScreen.COMPANY_SELECTION}/${row._id}`);
    }
  };

  return (
    <Button variant="primary" onClick={handleCreateOffer}>
      {t('screens.dashboard.table.columns.actions.addNewOffer')}
    </Button>
  );
};

export default ActionCell;
