import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { AuctionResultsEmailsTextArea } from 'components/UI/AuctionResultsEmailsTextArea';

import Associates from './Associates';
import RelatedDistributerList from './RelatedDistributerList';

export const CompanyConnectionEditMode = () => {
  const { companyDetails, companyDetailsIsLoading, companyDetailsError } = useContext(CompanyDetailsContext);

  if (companyDetailsIsLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!companyDetails || companyDetailsError) {
    /* error will be handled by the context */
    return null;
  }

  return (
    <>
      <RelatedDistributerList />
      <Associates />
      <AuctionResultsEmailsTextArea formFieldName="defaultEmailsForAuctionResults" />
    </>
  );
};
