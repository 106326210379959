import * as yup from 'yup';

import { bnNumber, companyName, investorType, name, termsOfUse } from 'shared/schemas/general';

export const schema = yup.object().shape({
  firstName: name,
  lastName: name,
  companies: yup.array().of(
    yup.object().shape({
      name: companyName,
      bnNumber: bnNumber,
      investorType: investorType,
    }),
  ),
  tenderAlertsSubscription: yup.boolean(),
  termsOfUseAcceptance: termsOfUse,
});
