import snakeCase from 'lodash/snakeCase';

import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';

const validateEventParams = (eventName, eventStage, paramsDictionary) => {
  if (!GAEventNames[eventName]) {
    console.warn(`Event name ${eventName} is not defined in eventNames.js`);
  }
  if (!GAEventStages[eventStage]) {
    console.warn(`Event stage ${eventStage} is not defined in eventStages.js`);
  }
  if (typeof paramsDictionary !== 'object') {
    console.warn(`paramsDictionary must be an object, got ${typeof paramsDictionary} instead, so no params were sent`);
  }
};

const stringifyObjectValues = (obj) => {
  let rearrangedObject = {};
  const { dataLayer, ...rest } = obj;
  if (dataLayer) {
    rearrangedObject = { ...rest, ...dataLayer };
  } else {
    rearrangedObject = { ...obj };
  }

  const stringifiedObject = {};

  for (const key in rearrangedObject) {
    const formattedKey = `sb_${snakeCase(key)}`; // add sb_ prefix to all keys
    const value = rearrangedObject[key];
    switch (typeof value) {
      case 'function':
        return;
      case 'object':
        stringifiedObject[formattedKey] = JSON.stringify(value);
        break;
      case 'number':
      case 'boolean':
        stringifiedObject[formattedKey] = value.toString();
        break;
      case 'undefined':
        stringifiedObject[formattedKey] = 'undefined';
        break;
      default:
        stringifiedObject[formattedKey] = value;
    }
  }

  return stringifiedObject;
};

const formatEventParams = (paramsDictionary) => {
  return typeof paramsDictionary === 'object' ? stringifyObjectValues(paramsDictionary) : {};
};

export const processParams = (eventName, eventStage, paramsDictionary) => {
  validateEventParams(eventName, eventStage, paramsDictionary);
  const eventParams = formatEventParams(paramsDictionary);
  const formattedEventName = eventName + '_' + eventStage;
  return { formattedEventName, eventParams };
};

export const clearObjectValues = (originalObject) => {
  if (typeof originalObject !== 'object') {
    return {};
  }
  const keys = Object.keys(originalObject);
  const clearedObject = {};
  keys.forEach((key) => {
    clearedObject[key] = undefined;
  });
  return clearedObject;
};

export const formatUserProps = (authDetails) => {
  const formattedUserProps = {};
  const keysToPick = [
    'userId',
    'systemRole',
    'signedCurrentAgreementVersion',
    'email',
    'mobile',
    'personalId',
    'firstName',
    'lastName',
    'isSBAdmin',
    'isLoggedIn',
    'isTFAValidated',
    'isTokenLoadedToContext',
    'role',
    'status',
  ];

  Object.keys(authDetails)
    .sort((a, b) => keysToPick.indexOf(a) - keysToPick.indexOf(b)) // sort by keysToPick order to keep the order in GA
    .forEach((key) => {
      if (!keysToPick.includes(key)) {
        return;
      }

      formattedUserProps['user_' + key] = authDetails[key];
    });

  return formattedUserProps;
};
