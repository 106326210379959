import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
//ttt replace it with getRoleDataForRoleCompletion
import * as yup from 'yup';

import { getCompanyDataForRoleCompletion } from 'shared/apis';

import { useDebounce } from 'hooks/useDebounce';

const bnNumberSchema = yup.string().required();

export const useRoleCompletion = (defaultState) => {
  // keeps track of the bnNumber value of each company
  // on change of any of them, after debouncing, fetch the company's data from the database
  // if the company exists, populate the rest of the fields
  const { watch, getValues, setValue } = useFormContext();
  const watchRes = watch();

  const [previouslyFetchedBnNumbers, setPreviouslyFetchedBnNumbers] = useState(defaultState?.map((user) => user.bnNumber) || []);
  const [bnNumbersToFetch, setBnNumbersToFetch] = useState([]);
  const [formerBnNumberState, setFormerBnNumberState] = useState([]);
  const debouncedBnNumberState = useDebounce(formerBnNumberState, 1000);

  const { data } = useQuery({
    queryKey: ['getCompanyDataForRoleCompletion', bnNumbersToFetch],
    queryFn: () => getCompanyDataForRoleCompletion({ bnNumbers: bnNumbersToFetch }),
    enabled: some(bnNumbersToFetch),
  });

  useEffect(() => {
    if (data) {
      data?.forEach((company) => {
        if (company) {
          const { name, bnNumber } = company;

          // find the index of the company in the form
          const index = getValues().roles.findIndex((company) => company.bnNumber === bnNumber);
          if (index === -1) {
            return;
          }

          // update the form with the company's data
          setValue(`roles[${index}].companyName`, name);
        }
      });

      setBnNumbersToFetch([]);
      setPreviouslyFetchedBnNumbers([...previouslyFetchedBnNumbers, ...bnNumbersToFetch]);
    }
  }, [data, bnNumbersToFetch, previouslyFetchedBnNumbers, getValues, setValue]);

  useEffect(() => {
    const currFormBnNumbers = watchRes?.roles?.map((company) => company.bnNumber);

    if (!isEqual(currFormBnNumbers, formerBnNumberState)) {
      setFormerBnNumberState(currFormBnNumbers);
    }
  }, [watchRes, getValues, formerBnNumberState]);

  useEffect(() => {
    if (debouncedBnNumberState?.length > 0) {
      // check which bnNumbers are new
      const newBnNumbers = debouncedBnNumberState
        .filter((bnNumber) => !previouslyFetchedBnNumbers.includes(bnNumber))
        // check if the bnNumber is valid
        .filter((bnNumber) => bnNumberSchema.isValidSync(bnNumber));

      if (some(newBnNumbers)) {
        setBnNumbersToFetch(newBnNumbers);
      }
    }
  }, [debouncedBnNumberState, previouslyFetchedBnNumbers]);
};
