import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationContext } from 'context/Notifications';

import { NOTIFICATION_TYPES } from 'shared/const/notifications';

export const useNotificationOnClick = () => {
  const navigate = useNavigate();
  const { markAsRead, setShowNotificationsDropdown } = useContext(NotificationContext);

  const onClick = (notification) => {
    markAsRead(notification._id);
    setShowNotificationsDropdown(false);

    switch (notification.type) {
      case NOTIFICATION_TYPES.OFFER_SIGNER_SIGNED:
      case NOTIFICATION_TYPES.SIGN_REQUEST: {
        const { offerId } = notification.data;
        navigate(`/user/offers/create/signing/${offerId}`);
        break;
      }
      case NOTIFICATION_TYPES.OFFER_READY_FOR_SUBMISSION:
      case NOTIFICATION_TYPES.OFFER_SUBMITTED:
      case NOTIFICATION_TYPES.OFFER_APPROVED:
      case NOTIFICATION_TYPES.OFFER_UPDATED: {
        const { offer } = notification.data;
        navigate(`/user/offers/create/sending/${offer._id}`);
        break;
      }
      case NOTIFICATION_TYPES.OFFER_DEACTIVATED:
        navigate(`/user/offers/manage`);
        break;
      case NOTIFICATION_TYPES.NEW_ACTIVE_CONTRACT:
      case NOTIFICATION_TYPES.CONTRACT_APPROVED_BY_EMPLOYEE:
      case NOTIFICATION_TYPES.CONTRACT_APPROVED_BY_COMPANY: {
        const { companyId } = notification.data;
        navigate(`/user/companies/${companyId}`);
        break;
      }
      case NOTIFICATION_TYPES.OFFER_CREATED:
      default:
        break;
    }
  };

  return { onClick };
};
