import values from 'lodash/values';
import { useCallback, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';

import { AuthContext } from 'context/Auth';
import { NotificationContext } from 'context/Notifications';

import { EMIT_EVENTS, LISTENING_EVENTS } from 'shared/const/socketEvents';

// route is taken care of by the proxy
export const socket = io('/', { autoConnect: false });

// todo: create a socket service for the client
export const useSockets = () => {
  const { addNotification } = useContext(NotificationContext);
  const { authContextLoaded, isTokenLoadedToContext, isTFAValidated, isLoggedIn, currentToken } = useContext(AuthContext);

  const onConnect = useCallback(() => {
    console.log('socket connected');
  }, []);

  const onDisconnect = useCallback(() => {
    console.log('socket disconnected');
  }, []);

  const onNotificationEvent = useCallback(
    (value) => {
      addNotification(value);
    },
    [addNotification],
  );

  const setListeners = useCallback(() => {
    socket.on(LISTENING_EVENTS.CONNECT, onConnect);
    socket.on(LISTENING_EVENTS.DISCONNECT, onDisconnect);
    socket.on(LISTENING_EVENTS.NOTIFICATION, onNotificationEvent);
  }, [onConnect, onDisconnect, onNotificationEvent]);

  const removeListeners = useCallback(() => {
    socket.off(LISTENING_EVENTS.CONNECT, onConnect);
    socket.off(LISTENING_EVENTS.DISCONNECT, onDisconnect);
    socket.off(LISTENING_EVENTS.NOTIFICATION, onNotificationEvent);
  }, [onConnect, onDisconnect, onNotificationEvent]);

  useEffect(() => {
    if (!authContextLoaded || !isTokenLoadedToContext || !isTFAValidated || !isLoggedIn) return;

    setListeners();

    return () => {
      removeListeners();
    };
  }, [authContextLoaded, isTokenLoadedToContext, isTFAValidated, isLoggedIn, setListeners, removeListeners]);

  useEffect(() => {
    // on currentToken change
    if (!authContextLoaded || !isTokenLoadedToContext || !isTFAValidated || !isLoggedIn) return;
    socket.auth = { token: currentToken };
    socket.connect();
  }, [currentToken, authContextLoaded, isTokenLoadedToContext, isTFAValidated, isLoggedIn]);
};

export const emitEvent = (event, data) => {
  if (!event || !values(EMIT_EVENTS).includes(event)) {
    throw new Error(`Unsupported event: ${event}`);
  }

  socket.emit(event, data);
};
