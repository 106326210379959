import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './style.scss';

import smartbullLogo from 'asset/logo-online.svg';
import backgroundBlob from 'asset/tos/background.svg';

const TosScreen = ({ Document }) => {
  const navigate = useNavigate();

  return (
    <>
      <Container className="h-full d-flex flex-col  pt-5 md:pt-5 xxl:max-h-[900px] flex-1">
        <Row>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] cursor-pointer my-4" alt="" onClick={() => navigate('/')} />
          </div>
        </Row>
        <Row>
          {/* scrollbar green and on the right */}
          <div className="rounded-xl max-h-[65vh] d-flex bg-white d-box overflow-y-scroll" id="tos-document">
            <Document />
          </div>
        </Row>
      </Container>
      <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
        <img src={backgroundBlob} className="position-absolute bottom-[55px] w-full opacity-50" alt="decorative ellipse" />
      </div>
    </>
  );
};

export default TosScreen;

PropTypes.TosScreen = {
  document: PropTypes.oneOf(['privacy-policy', 'terms-of-service']).isRequired,
};
