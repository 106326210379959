import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneNumberUtil from 'libphonenumber-js';
import compact from 'lodash/compact';
import { Link } from 'react-router-dom';

import { ContractStatusSpan } from 'components/common/LocalizeContractStatus';
import { LocalizedRole } from 'components/common/LocalizedRole';

const Columns = (isSbOrCompanyAdmin) =>
  compact([
    {
      Header: 'email',
      accessor: 'email',
      Cell: (row) => {
        return <Link to={`/admin/users/${row?.userId}`}>{row?.email}</Link>;
      },
    },
    {
      Header: 'firstName',
      accessor: 'firstName',
    },
    {
      Header: 'lastName',
      accessor: 'lastName',
    },
    {
      Header: 'mobile',
      accessor: 'mobile',
      Cell: (row) => {
        return PhoneNumberUtil(row?.mobile).formatNational();
      },
    },
    { Header: 'personalId', accessor: 'personalId' },
    {
      Header: 'role',
      accessor: 'role',
      noSort: true,
      Cell: (row) => {
        return <LocalizedRole role={row?.role} />;
      },
    },
    {
      Header: 'isMandatorySignatory',
      accessor: 'isMandatorySignatory',
      noSort: true,
      Cell: (row) => {
        const icon = row?.isMandatorySignatory ? faCheck : faTimes;
        return <FontAwesomeIcon icon={icon} />;
      },
    },
    isSbOrCompanyAdmin && {
      Header: 'detailedStatus',
      accessor: 'detailedStatus',
      noSort: true,
      Cell: (row) => <ContractStatusSpan detailedStatus={row?.detailedStatus} />,
    },
  ]);

export default Columns;
