import jwtDecode from 'jwt-decode';
import some from 'lodash/some';
import { createContext, useCallback, useEffect, useState } from 'react';

import { contractRoles } from 'shared/const/contractRoles';
import { systemRoles } from 'shared/const/systemRoles';
import { customLocalStorage } from 'shared/customLocalStorage';

export const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const [authContextLoaded] = useState(true); // we use this state to prevent flickering
  const [isTokenLoadedToContext, setIsTokenLoadedToContext] = useState(false); // we use this state to prevent flickering
  const [isTFAValidated, setIsTFAValidated] = useState(false); // users are TFA validated after submitting the TFA codes
  const [isLoggedIn, setIsLoggedIn] = useState(false); // users are logged in after TFA validation and sign up
  const [lastLocation, setLastLocation] = useState(null); // used to redirect users after login
  /*
   * user details from the access token (JWT)
   */
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [personalId, setPersonalId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [lastTimeVerified, setLastTimeVerified] = useState(null);
  const [signedCurrentAgreementVersion, setSignedCurrentAgreementVersion] = useState(null);
  const [systemRole, setSystemRole] = useState(null);
  const [activeContracts, setActiveContracts] = useState([]);
  const [currentToken, setCurrentToken] = useState(null);

  //private
  const loadAuthDetailsToContext = useCallback(
    (decodedToken, token) => {
      if (!decodedToken || !token) {
        return;
      }
      setIsTokenLoadedToContext(true);
      setIsTFAValidated(true); //token only exists if TFA is validated
      setIsLoggedIn(!!decodedToken.userId); // tokens with userId are only created after sign up

      setEmail(decodedToken.email);
      setMobile(decodedToken.mobile);
      setPersonalId(decodedToken.personalId);
      setUserId(decodedToken.userId);

      setFirstName(decodedToken.firstName);
      setLastName(decodedToken.lastName);
      setLastTimeVerified(decodedToken.lastTimeVerified);
      setSignedCurrentAgreementVersion(decodedToken.signedCurrentAgreementVersion);
      setSystemRole(decodedToken.systemRole);
      setActiveContracts(decodedToken.activeContracts);
      setCurrentToken(token);
    },
    [
      setEmail,
      setMobile,
      setPersonalId,
      setUserId,
      setFirstName,
      setLastName,
      setLastTimeVerified,
      setSignedCurrentAgreementVersion,
      setSystemRole,
      setActiveContracts,
      setIsTFAValidated,
      setIsLoggedIn,
      setCurrentToken,
    ],
  );

  /** login by storage token on reload */
  useEffect(() => {
    const token = customLocalStorage.getAccessToken();
    if (!isTokenLoadedToContext && !!token) {
      const decodedToken = jwtDecode(token);
      loadAuthDetailsToContext(decodedToken, token);
    }
  }, [loadAuthDetailsToContext, isTokenLoadedToContext]);

  const login = (token) => {
    const decodedToken = jwtDecode(token);
    loadAuthDetailsToContext(decodedToken, token);
  };

  const logout = () => {
    setEmail(null);
    setMobile(null);
    setPersonalId(null);
    setUserId(null);
    setFirstName(null);
    setLastName(null);
    setLastTimeVerified(null);
    setSignedCurrentAgreementVersion(null);
    setSystemRole(false);
    setActiveContracts(null);
    setIsTFAValidated(false);
    setIsLoggedIn(false);
    setLastLocation(window.location.pathname);
    customLocalStorage.removeTokens();
  };

  // UTILS
  const getCompanyRoleOfUser = (companyId) => {
    if (!activeContracts) return null;

    const userRoleInRequestedCompany = activeContracts.find((role) => role.companyId === companyId);
    const userRoleType = userRoleInRequestedCompany?.role;
    return userRoleType;
  };

  const isAdminAtCompany = (companyId) => {
    const userRoleType = getCompanyRoleOfUser(companyId);
    return userRoleType === contractRoles.COMPANY_ADMIN;
  };

  const canCreateOffersByCompany = (companyId) => {
    const userRoleType = getCompanyRoleOfUser(companyId);
    return [contractRoles.COMPANY_ADMIN, contractRoles.EMPLOYEE].includes(userRoleType);
  };

  const canCreateOffers = () => {
    return some(activeContracts, (contract) => canCreateOffersByCompany(contract.companyId));
  };

  const activeContractsWithOfferCreationPermission = activeContracts?.filter((contract) => canCreateOffersByCompany(contract.companyId));

  //VALUES
  const contextValue = {
    //general states:
    authContextLoaded,
    isTokenLoadedToContext,
    isTFAValidated,
    setIsTFAValidated,
    isLoggedIn,
    setIsLoggedIn,
    lastLocation,
    setLastLocation,

    // user details:
    email,
    setEmail,
    mobile,
    setMobile,
    personalId,
    setPersonalId,
    userId,
    setUserId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    lastTimeVerified,
    setLastTimeVerified,
    signedCurrentAgreementVersion,
    setSignedCurrentAgreementVersion,
    systemRole,
    setSystemRole,
    activeContracts,
    setActiveContracts,
    currentToken,

    // methods:
    logout,
    login,

    // constants:
    isSBAdmin: systemRole === systemRoles.SB_ADMIN,

    // utils:
    getCompanyRoleOfUser,
    isAdminAtCompany,
    canCreateOffersByCompany,
    canCreateOffers,
    activeContractsWithOfferCreationPermission,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
