import { useTranslation } from 'react-i18next';

import { contractRoles } from 'shared/const/contractRoles';

export const useAccreditedInvestorCertAlertContent = ({ contract }) => {
  const { t } = useTranslation();
  const {
    role,
    company: { accreditedInvestorCert },
  } = contract;
  const isCompanyAdmin = role === contractRoles.COMPANY_ADMIN;

  let alertHeader = '';
  let alertText = '';

  if (!accreditedInvestorCert) {
    if (isCompanyAdmin) {
      alertHeader = t('components.ContractRow.header.noCertHeader');
      alertText = t('components.ContractRow.text.noCert', { companyName: contract.companyName });
    } else {
      alertHeader = t('components.ContractRow.header.noCertHeader');
      alertText = t('components.ContractRow.text.noCertContact', { companyName: contract.companyName });
    }
  } else {
    // accreditedInvestorCert exists but is expired
    if (isCompanyAdmin) {
      alertHeader = t('components.ContractRow.header.expiredCertHeader');
      alertText = t('components.ContractRow.text.expiredCert', { companyName: contract.companyName });
    } else {
      alertHeader = t('components.ContractRow.header.expiredCertHeader');
      alertText = t('components.ContractRow.text.expiredCertContact', { companyName: contract.companyName });
    }
  }

  return { alertHeader, alertText };
};
