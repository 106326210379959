import axios from 'axios';
import { ERROR_CODES } from 'errors/general/general';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { customLocalStorage } from 'shared/customLocalStorage';

import { refreshAccessToken } from './apis';
import { ALERT_TYPES } from './const/alerts';
import { getErrorNameFromErrorResponse } from './utils/error';
import { errorAlertBuilder } from './utils/errorAlertBuilder';

/**
 * this code define customize automatic functionality that run's on each ajax request and response,
 * the way Axios expect us to do that is by creating a component, and wrap the rest of the app with this component,
 * then each 'wrapped' component that use's axios send and receive http communication through this pipelines we define blow
 */

export const useAxiosCustomizations = () => {
  const { addAlert } = useContext(AlertContentContext);
  const { login, logout, isLoggedIn } = useContext(AuthContext);
  const { t } = useTranslation();
  const [listenersSet, setListenersSet] = useState(false);

  const setListenersOnRequests = () => {
    axios.interceptors.request.use(sendAccessTokenWithEveryRequest);
    axios.interceptors.request.use(sendLanguageWithEveryRequest);
    axios.interceptors.request.use(stripDataLayerFromRequest);
  };

  const sendAccessTokenWithEveryRequest = (request) => {
    try {
      const token = customLocalStorage.getAccessToken();
      if (!token) {
        return request;
      }
      request.headers['authorization'] = token;
      return request;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendLanguageWithEveryRequest = (request) => {
    try {
      // eyal_todo: customLocalStorage/language not using proper standard https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
      const language = customLocalStorage.getLanguage();
      if (!language) {
        return request;
      }
      request.headers['accept-language'] = language;
      return request;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const stripDataLayerFromRequest = (request) => {
    try {
      if (request.data && request.data.dataLayer) {
        delete request.data.dataLayer;
      }
      return request;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const setListenersOnResponse = () => {
    axios.interceptors.response.use(updateContextOnEveryRequestWithNewToken, catchGeneralErrors);
  };

  const updateContextOnEveryRequestWithNewToken = (response) => {
    if (!response) return response;

    try {
      const accessToken = response.headers['authorization'];
      const refreshToken = response.headers['x-refresh-token']; // can be null

      if (!accessToken) {
        // No tokens in response should be treated as logout, if user is logged in.
        // Note: logout only cleans up the local storage, and does not send any request to the server.
        if (isLoggedIn) {
          logout();
        }
        return response;
      }

      const accessTokenChanged = accessToken !== customLocalStorage.getAccessToken();
      const refreshTokenChanged = refreshToken && refreshToken !== customLocalStorage.getRefreshToken();
      if (accessTokenChanged) {
        login(accessToken);
        customLocalStorage.setAccessToken(accessToken);
      }
      if (refreshTokenChanged) {
        customLocalStorage.setRefreshToken(refreshToken);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const catchGeneralErrors = async (error) => {
    if (checkIfErrorIsBecauseOfTokenExpiration(error)) {
      if (!error.config._retry) {
        const newResponse = await tryRefreshTokenOrLogout(error);
        return newResponse;
      } else {
        handleLogout();
        return null;
      }
    }

    const errorShouldBeHandledByCaller = lookForGeneralError(error);
    if (errorShouldBeHandledByCaller) {
      return Promise.reject(error);
    }
  };

  const handleLogout = () => {
    addAlert({
      title: t('components.errorAlertBuilder.sessionExpired.title'),
      description: t('components.errorAlertBuilder.sessionExpired.description'),
      type: ALERT_TYPES.ERROR,
    });
    logout();
  };

  const lookForGeneralError = (error) => {
    const response = error?.response;
    const errorName = getErrorNameFromErrorResponse(error);
    if (!response || response.status === 500) {
      addAlert(errorAlertBuilder.bug(error));
    } else if (response.statusText === 'Network Error') {
      addAlert(errorAlertBuilder.network(error));
    } else if (!errorName) {
      addAlert(errorAlertBuilder.bug(error));
    } else if (errorName === ERROR_CODES.TOO_MANY_REQUESTS_BY_IP) {
      addAlert(errorAlertBuilder.blocked(error));
    } else if (errorName === ERROR_CODES.TOO_MANY_REQUESTS_BY_EMAIL) {
      addAlert(errorAlertBuilder.blocked(error));
    } else if (
      [
        ERROR_CODES.NOT_AUTHENTICATED,
        ERROR_CODES.REFRESH_TOKEN_EXPIRED,
        ERROR_CODES.REFRESH_TOKEN_INVALID,
        ERROR_CODES.ACCESS_TOKEN_EXPIRED,
      ].includes(errorName)
    ) {
      /* flow get's here only if token refresh attempt already happen or should not happen at all */
      handleLogout();
    } else if (errorName === ERROR_CODES.NOT_AUTHORIZED) {
      addAlert(errorAlertBuilder.unauthorized(error));
    } else {
      /* send the error to the error handling of the specific client (useQuery/useMutation 'onError') */
      return error;
    }
  };

  const checkIfErrorIsBecauseOfTokenExpiration = (error) => {
    if (error.response?.data?.error?.name === ERROR_CODES.ACCESS_TOKEN_EXPIRED) return true;
    return false;
  };

  /* the return value of this function will handled by the original request 'onSuccess' or 'onError' */
  const tryRefreshTokenOrLogout = async (error) => {
    try {
      await refreshTheToken(error);
    } catch (error) {
      handleLogout();
      return null;
    }
    const originalRequest = error.config;
    const newResponse = await axios.request(originalRequest);
    return newResponse;
  };

  const refreshTheToken = async (error) => {
    const currRefreshToken = customLocalStorage.getRefreshToken();
    if (!currRefreshToken) {
      throw new Error('no refresh token');
    }

    const originalRequest = error.config;
    originalRequest._retry = true; //set the retry here in case the original request was refresh token
    customLocalStorage.removeAccessToken();
    await refreshAccessToken(currRefreshToken);
  };

  /* start read file from here */

  const setAxiosListeners = () => {
    if (listenersSet || !addAlert || !login || !logout || !t || !setListenersSet) return;
    setListenersOnRequests();
    setListenersOnResponse();
    setListenersSet(true);
  };

  return { setAxiosListeners };
};
