import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AuctionTimer = ({ closeTime }) => {
  const { t } = useTranslation();
  const [timeTillEnd, setTimeTillEnd] = useState(0);

  const calculateTimeTillEnd = useCallback(() => {
    const now = dayjs();
    const formattedCloseTime = dayjs(closeTime);
    const diff = formattedCloseTime.diff(now);
    setTimeTillEnd(diff);
  }, [closeTime]);

  /* INITIAL TIMER */
  useEffect(() => {
    calculateTimeTillEnd();
  }, [calculateTimeTillEnd]);

  /* RUN AS INTERVAL */
  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeTillEnd();
    }, 1000);
    return () => clearInterval(interval);
  }, [calculateTimeTillEnd]);

  /* JSX */
  if (timeTillEnd < 0) {
    return <span>{t('components.LiveAuctionCard.labels.auctionEnded')}</span>;
  }

  const duration = dayjs.duration(timeTillEnd);
  const days = duration.days();
  const formattedDays = days > 0 ? `${days}${t('components.liveAuctionCard.timmerDays')}` : '';

  return <span className="bg-orange-100 p-2 font-medium ms-1">{formattedDays + duration.format('HH:mm:ss')}</span>;
};
