import React, { createContext, useCallback, useEffect, useState } from 'react';

import { visualEffects } from 'components/UI/VisualEffectsContainer/VisualEffectsContainer';

const checkIfAlertsAreEqual = (alert1, alert2) => {
  const titleMatch = alert1.title === alert2.title;
  if (!titleMatch) return false;

  const descriptionMatch = alert1.description === alert2.description;
  if (!descriptionMatch) return false;

  const typeMatch = alert1.type === alert2.type;
  if (!typeMatch) return false;

  return true;
};

const checkIfAlertInStack = (alert, stack) => {
  const isSame = stack.find((stackAlert) => {
    return checkIfAlertsAreEqual(alert, stackAlert);
  });
  return !!isSame;
};

export const AlertContentContext = createContext(null);

export const AlertContentContextProvider = ({ children }) => {
  const [activeAlertContent, setActiveAlertContent] = useState(null);
  const [alertsStack, setAlertsStack] = useState([]);
  const [activeVisualEffect, setActiveVisualEffect] = useState(false);

  const addAlertToStack = useCallback(
    (newAlert) => {
      setAlertsStack((stack) => {
        if (checkIfAlertInStack(newAlert, stack)) return stack;
        return [...stack, newAlert];
      });
    },
    [setAlertsStack],
  );

  const popAlertStack = useCallback(() => {
    setAlertsStack((stack) => {
      const temp = [...stack];
      temp.pop();
      return temp;
    });
  }, [setAlertsStack]);

  useEffect(() => {
    if (alertsStack.length > 0) {
      setActiveAlertContent(alertsStack[alertsStack.length - 1]);
    } else {
      setActiveAlertContent(null);
    }
  }, [alertsStack]);

  const setActiveVisualEffectByClient = useCallback((visualEffect) => {
    if (!Object.values(visualEffects).includes(visualEffect)) {
      console.error(`Invalid visual effect: ${visualEffect}`);
      return;
    }
    setActiveVisualEffect(visualEffect);
  }, []);

  const stopVisualEffect = useCallback(() => {
    setActiveVisualEffect(null);
  }, []);

  const contextValue = {
    activeAlertContent,
    addAlert: addAlertToStack,
    removeActiveAlert: popAlertStack,
    activeVisualEffect,
    setActiveVisualEffect: setActiveVisualEffectByClient,
    stopVisualEffect,
  };

  return <AlertContentContext.Provider value={contextValue}>{children}</AlertContentContext.Provider>;
};
