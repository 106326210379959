import { emitEvent } from 'services/socket';

import { getMinimalOffersLookupTable } from 'shared/apis';
import { EMIT_EVENTS } from 'shared/const/socketEvents';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import { Columns } from './columns';

export const AuctionsTable = ({ isActive, queryKey }) => {
  const exportSuccessCallback = () => {
    const excelName = `${isActive ? 'Active' : 'Inactive'}-Auctions-Offers`;
    emitEvent(EMIT_EVENTS.ADMIN_EXCEL_EXPORT, { excelName });
  };

  return (
    <TableQuery
      columnTPrefix={'screens.auctions.table.columns.'}
      Columns={Columns(isActive)}
      defaultSort={{ 'initiation.auctionDetails.closeTime': sortDirections.DESC }}
      queryKey={queryKey}
      queryFn={(tableOptions) => getMinimalOffersLookupTable({ ...tableOptions, filters: { isAuctionActive: isActive } })}
      scrollable
      withExport
      exportSuccessCallback={exportSuccessCallback}
    />
  );
};
