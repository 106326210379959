import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useMemo } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { signUp } from 'shared/apis';
import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';
import { defaultInvestorType } from 'shared/const/investorTypes';
import { schema } from 'shared/schemas/signup';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { sendGAEvent } from 'hooks/useGoogleAnalytics';
import { useWindowSize } from 'hooks/useWindowSize';

import { InvestorTypeSelector } from 'components/CompanyDetails/CompanyForm/InvestorTypeSelector';

import smartbullLogo from 'asset/logo-online.svg';
import backgroundBlob from 'asset/signup/background.svg';

import * as S from './styles';

const SignUp = () => {
  const navigate = useNavigate();
  const { isDesktop } = useWindowSize();
  const { t } = useTranslation();
  const { authContextLoaded, email, mobile, personalId, logout } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);

  useEffect(() => {
    if (!authContextLoaded) return;
    if (!email || !mobile || !personalId) {
      logout();
      navigate('/authentication');
    }
  }, [authContextLoaded, email, mobile, personalId, logout, navigate]);

  //FORM INITIALIZATION
  const defaultValues = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      tenderAlertsSubscription: false,
      termsOfUseAcceptance: false,
      companies: [
        {
          companyName: '',
          bnNumber: '',
          investorType: defaultInvestorType,
        },
      ],
    };
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companies',
    append: {
      companyName: '',
      bnNumber: '',
      investorType: defaultInvestorType,
    },
  });

  const { mutate: handleSignUp, isPending } = useMutation({
    mutationKey: 'signUp',
    mutationFn: signUp,
    onSuccess: () => {
      sendGAEvent(GAEventNames.SIGN_UP, GAEventStages.START, { email, mobile, personalId });
      navigate('/');
    },
    onError: (error) => {
      sendGAEvent(GAEventNames.SIGN_UP, GAEventStages.SUCCESS, { email, mobile, personalId });
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const onSubmit = async (data) => {
    const expectedRequestBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      tenderAlerts: data.tenderAlertsSubscription || false,
      isTermsOfUseAccepted: data.termsOfUseAcceptance || false,
      roles: data.companies,
    };

    handleSignUp(expectedRequestBody);
  };

  const abortSignUp = () => {
    logout();
    navigate('');
  };

  return (
    <>
      <Container className="h-full d-flex flex-col pt-3 md:max-w-[95vw]">
        <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] cursor-pointer my-4" alt="" onClick={() => navigate('/')} />
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} className="max-w-[750px] d-flex flex-col gap-3 mt-2">
            <div className="d-flex flex-row gap-3 text-muted-more text-sm">
              <div className="d-flex flex-row gap-3">
                <span>{t('screens.signUp.labels.id')}</span>
                <span>{personalId}</span>
              </div>
              <span>|</span>
              <div className="d-flex flex-row gap-3">
                <span>{t('screens.signUp.labels.email')}</span>
                <span>{email}</span>
              </div>
              <span>|</span>
              <div className="d-flex flex-row gap-3">
                <span>{t('screens.signUp.labels.mobile')}</span>
                <span>{mobile}</span>
              </div>
            </div>

            <ErrorMessage errors={errors} name="general" render={({ message }) => <Alert variant="danger">{message}</Alert>} />

            <h6>{t('screens.signUp.descriptionText')}</h6>

            <div className="d-flex flex-row gap-3 me-[46px]">
              <Form.Group controlId="firstName" className="w-100">
                <Form.Label>{t('screens.signUp.labels.firstName')}</Form.Label>
                <Form.Control {...register('firstName')} isInvalid={errors.firstName} />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>

              <Form.Group controlId="lastName" className="w-100">
                <Form.Label>{t('screens.signUp.labels.lastName')}</Form.Label>
                <Form.Control {...register('lastName')} isInvalid={errors.lastName} />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>
            </div>

            <h6>{t('screens.signUp.companyInputsHeader')}</h6>

            <div className="d-flex flex-col gap-3">
              {fields.map((item, index) => {
                const isFirst = index === 0;
                const isLast = index === fields.length - 1;

                return (
                  <div key={index} className="d-flex flex-row gap-3">
                    <Form.Group controlId="name" className="w-100">
                      {isFirst && <Form.Label>{t('screens.signUp.labels.companyName')}</Form.Label>}
                      <Form.Control {...register(`companies.${index}.companyName`)} isInvalid={errors.companies?.[index]?.companyName} />
                      <ErrorMessage
                        errors={errors}
                        name={`companies.${index}.companyName`}
                        render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                      />
                    </Form.Group>

                    <Form.Group controlId="bnNumber" className="w-100">
                      {isFirst && <Form.Label>{t('screens.signUp.labels.companyBnNumber')}</Form.Label>}
                      <Form.Control {...register(`companies.${index}.bnNumber`)} isInvalid={errors.companies?.[index]?.bnNumber} />
                      <ErrorMessage
                        errors={errors}
                        name={`companies.${index}.bnNumber`}
                        render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                      />
                    </Form.Group>

                    <Form.Group controlId="investorType" className="w-100">
                      {isFirst && <Form.Label>{t('screens.signUp.labels.investorType')}</Form.Label>}
                      <InvestorTypeSelector fieldPath={`companies.${index}.investorType`} />
                      <ErrorMessage
                        errors={errors}
                        name={`companies.${index}.investorType`}
                        render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                      />
                    </Form.Group>

                    <S.RowToggle
                      onClick={(e) => {
                        e.preventDefault();
                        if (isLast) {
                          append({ bnNumber: '', companyName: '' });
                        } else {
                          remove(index);
                        }
                      }}
                      variant={isLast ? 'info' : 'danger'}
                      isFirst={isFirst}
                      hasRowError={errors.companies?.[index]?.bnNumber || errors.companies?.[index]?.companyName}
                    >
                      {isLast ? '+' : 'x'}
                    </S.RowToggle>
                  </div>
                );
              })}
            </div>
            <p className="text-muted-more">{t('screens.signUp.labels.asAppears')}</p>

            <Form.Check
              type="checkbox"
              label={t('screens.signUp.labels.notificationSubscription')}
              id="tenderAlertsSubscription"
              {...register('tenderAlertsSubscription')}
            />

            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                id="termsOfUseAcceptance"
                {...register('termsOfUseAcceptance')}
                isInvalid={errors.termsOfUseAcceptance}
              />
              <Form.Check.Label>
                {t('screens.signUp.labels.agreeingTo')}
                <Button variant="link" onClick={() => window.open('/terms-of-service', '_blank')} className="p-0">
                  {t('screens.signUp.labels.TOS')}
                </Button>
              </Form.Check.Label>
              <ErrorMessage
                errors={errors}
                name="termsOfUseAcceptance"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Check>

            <div className="d-flex flex-col justify-start align-items-start gap-1 w-fit mt-2">
              <Button variant="primary" type="submit">
                {t('screens.signUp.submitButton')}
                {isPending && <Spinner animation="border" size="sm" className="ms-2" />}
              </Button>
            </div>
            <div className="mt-4">
              <Button variant="link" onClick={abortSignUp} className="text-muted">
                {t('screens.signUp.links.cancel')}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Container>
      {isDesktop && (
        <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
          <img src={backgroundBlob} className="position-absolute top-0 end-[0] h-full ltr:scale-x-[-1]" alt="decorative ellipse" />
        </div>
      )}
    </>
  );
};

export default SignUp;
