import { useTranslation } from 'react-i18next';

export const useDirection = () => {
  const { i18n } = useTranslation();

  const rtlLanguages = ['ar', 'he']; // Add more RTL languages as needed
  const currentLang = i18n.language;

  const direction = rtlLanguages.includes(currentLang) ? 'rtl' : 'ltr';
  return {
    direction,
    isRtl: direction === 'rtl',
    isLtr: direction === 'ltr',
  };
};
