import { Table } from 'react-bootstrap';
import styled from 'styled-components';

// height: '1px' is a hack to fix the table height issue
export const StyledTable = styled(Table)`
  border: 1px solid #dee2e6 !important;
  height: 1px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
