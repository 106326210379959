import values from 'lodash/values';
import { createContext, useCallback, useMemo } from 'react';

import { usePublicOfferingsFilters } from 'hooks/usePublicOfferingsFilters';

import { PUBLIC_OFFERINGS_FILTERS } from 'components/publicOfferingsFilters/consts';

export const PublicOfferingsFiltersContext = createContext(null);

export const PublicOfferingsFiltersContextProvider = ({ children }) => {
  const {
    useIssuerFilter,
    useLinkageFilter,
    useSectorFilter,
    useCovenantFilter,
    useOfferingTypeFilter,
    useAuctionTypeFilter,
    useLeadUnderwriterFilter,
    useMinDurationFilter,
    useMaxDurationFilter,
    useMinRatingFilter,
    useMaxRatingFilter,
    useMosadiAuctionDateFilter,
  } = usePublicOfferingsFilters();

  const [issuer, setIssuer] = useIssuerFilter();
  const [linkage, setLinkage] = useLinkageFilter();
  const [sector, setSector] = useSectorFilter();
  const [covenant, setCovenant] = useCovenantFilter();
  const [offeringType, setOfferingType] = useOfferingTypeFilter();
  const [auctionType, setAuctionType] = useAuctionTypeFilter();
  const [leadUnderwriter, setLeadUnderwriter] = useLeadUnderwriterFilter();
  const [minDuration, setMinDuration] = useMinDurationFilter();
  const [maxDuration, setMaxDuration] = useMaxDurationFilter();
  const [minRating, setMinRating] = useMinRatingFilter();
  const [maxRating, setMaxRating] = useMaxRatingFilter();
  const [mosadiAuctionDate, setMosadiAuctionDate] = useMosadiAuctionDateFilter();

  const setFilterValue = useCallback(
    (filter, value) => {
      switch (filter) {
        case PUBLIC_OFFERINGS_FILTERS.ISSUER:
          setIssuer(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.LINKAGE:
          setLinkage(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.SECTOR:
          setSector(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.COVENANT:
          setCovenant(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE:
          setOfferingType(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE:
          setAuctionType(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER:
          setLeadUnderwriter(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.MIN_DURATION:
          setMinDuration(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.MAX_DURATION:
          setMaxDuration(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.MIN_RATING:
          setMinRating(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.MAX_RATING:
          setMaxRating(value);
          break;
        case PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE:
          setMosadiAuctionDate(value);
          break;
        default:
          throw new Error(`Unknown filter ${filter}`);
      }
    },
    [
      setIssuer,
      setLinkage,
      setSector,
      setCovenant,
      setOfferingType,
      setAuctionType,
      setLeadUnderwriter,
      setMinDuration,
      setMaxDuration,
      setMinRating,
      setMaxRating,
      setMosadiAuctionDate,
    ],
  );

  const removeFilter = useCallback(
    (filter) => {
      setFilterValue(filter, null);
    },
    [setFilterValue],
  );

  const getFilterValue = useCallback(
    (filter) => {
      switch (filter) {
        case PUBLIC_OFFERINGS_FILTERS.ISSUER:
          return issuer;
        case PUBLIC_OFFERINGS_FILTERS.LINKAGE:
          return linkage;
        case PUBLIC_OFFERINGS_FILTERS.SECTOR:
          return sector;
        case PUBLIC_OFFERINGS_FILTERS.COVENANT:
          return covenant;
        case PUBLIC_OFFERINGS_FILTERS.OFFERING_TYPE:
          return offeringType;
        case PUBLIC_OFFERINGS_FILTERS.AUCTION_TYPE:
          return auctionType;
        case PUBLIC_OFFERINGS_FILTERS.LEAD_UNDERWRITER:
          return leadUnderwriter;
        case PUBLIC_OFFERINGS_FILTERS.MIN_DURATION:
          return minDuration;
        case PUBLIC_OFFERINGS_FILTERS.MAX_DURATION:
          return maxDuration;
        case PUBLIC_OFFERINGS_FILTERS.MIN_RATING:
          return minRating;
        case PUBLIC_OFFERINGS_FILTERS.MAX_RATING:
          return maxRating;
        case PUBLIC_OFFERINGS_FILTERS.MOSADI_AUCTION_DATE:
          return mosadiAuctionDate;
        default:
          throw new Error(`Unknown filter ${filter}`);
      }
    },
    [
      issuer,
      linkage,
      sector,
      covenant,
      offeringType,
      auctionType,
      leadUnderwriter,
      minDuration,
      maxDuration,
      minRating,
      maxRating,
      mosadiAuctionDate,
    ],
  );

  const filters = useMemo(() => {
    const filterKeys = values(PUBLIC_OFFERINGS_FILTERS);
    return filterKeys.reduce((acc, key) => {
      const val = getFilterValue(key);

      if (val || val === 0) {
        acc[key] = val;
      }

      return acc;
    }, {});
  }, [getFilterValue]);

  const contextValue = {
    getFilterValue,
    setFilterValue,
    removeFilter,
    filters,
  };

  return <PublicOfferingsFiltersContext.Provider value={contextValue}>{children}</PublicOfferingsFiltersContext.Provider>;
};
