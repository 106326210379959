import { ALERT_TYPES } from 'shared/const/alerts';

export const errorAlertBuilder = {
  network: (error) => createAlertContentPathObject('network', error),
  bug: (error) => createAlertContentPathObject('bug', error),
  unauthorized: (error) => createAlertContentPathObject('unauthorized', error),
  lookupInvalidFilters: (error) => createAlertContentPathObject('lookupInvalidFilters', error),
  ipBlocked: (error) => createAlertContentPathObject('ipBlocked', error),
  emailBlocked: (error) => createAlertContentPathObject('emailBlocked', error),
};

const createAlertContentPathObject = (suffix, error) => {
  return {
    needTranslate: true,
    description: 'components.errorAlertBuilder.' + suffix + '.description',
    title: 'components.errorAlertBuilder.' + suffix + '.title',
    type: ALERT_TYPES.ERROR,
    error,
  };
};
