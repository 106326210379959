import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import AuthenticationForm from 'components/Auth/Authentication/Form';

export const Authentication = () => {
  const { authContextLoaded, isTFAValidated, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  /** redirect authenticated users */
  useEffect(() => {
    if (!authContextLoaded) return;
    if (isLoggedIn) {
      navigate('/');
    } else if (isTFAValidated) {
      navigate('/sign-up');
    }
  }, [authContextLoaded, isLoggedIn, isTFAValidated, navigate]);

  return <AuthenticationForm />;
};
