import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/UI/ModalConfirm';

export const MissingSignaturesConfirmModal = ({ show, setShow, onProceed }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirm
      show={show}
      onClose={() => setShow(false)}
      onProceed={onProceed}
      title={t('components.offerSendingModalWithMissingSignatures.content.title')}
      content={t('components.offerSendingModalWithMissingSignatures.content.message')}
      variant="warning"
    />
  );
};
