const { useTranslation } = require('react-i18next');
const { Link } = require('react-router-dom');

export const CommonLink = ({ link, linkPrefix = '' }) => {
  const { t } = useTranslation();

  if (!link) {
    return '-';
  }

  const translationPrefix = linkPrefix || 'common.link';
  return (
    <Link className="underline" target="_blank" to={link}>
      {t(translationPrefix)}
    </Link>
  );
};
