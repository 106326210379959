import { useEffect, useRef } from 'react';

/**
 * This hook is used to run a callback only once on initiation.
 * It is needed because React.StrictMode runs the useEffect at least twice (In development).
 */
export const useInitiation = (cb) => {
  const initiationRef = useRef(false);

  useEffect(() => {
    if (!initiationRef.current) {
      initiationRef.current = true;
      return;
    }

    cb();
  }, [cb, initiationRef]);
};
