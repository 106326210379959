import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { ExpirationDateInfo } from './ExpirationDateInfo';
import { UpdaterInfo } from './UpdaterInfo';

export const AccreditedInvestorCertLi = () => {
  const { t } = useTranslation();
  const { companyDetails } = useContext(CompanyDetailsContext);

  if (!companyDetails || !companyDetails.accreditedInvestorCert) {
    return null;
  }

  const { url, fileName, expirationDate, user, updatedAt } = companyDetails.accreditedInvestorCert;

  return (
    <li className="my-[1rem]">
      {t('screens.company.labels.accreditedInvestorCert')}:{' '}
      <span className="text-muted">
        <Link to={url} target="_blank" className="text-primary">
          {fileName}
        </Link>{' '}
        <ExpirationDateInfo expirationDate={expirationDate} /> <UpdaterInfo updatedAt={updatedAt} user={user} />
      </span>
    </li>
  );
};
