import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/components/user/new-rhf';
import { useContext, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { createUserByAdmin } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { contractRoles } from 'shared/const/contractRoles';
import { systemRoles } from 'shared/const/systemRoles';
import { userGenders } from 'shared/const/userGenders';
import { schema } from 'shared/schemas/user';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import UserForm from 'components/UserDetails/Form';
import { buildProceedModalContent } from 'components/UserDetails/NewMode/utils';

const NewRHF = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { addAlert } = useContext(AlertContentContext);
  const defaultValues = useMemo(() => {
    return {
      personalId: '',
      email: '',
      mobile: '',
      firstName: '',
      lastName: '',
      tenderAlerts: true,
      gender: userGenders.OTHER,
      systemRole: systemRoles.USER,
      isActive: true,
      roles: [
        {
          companyName: '',
          bnNumber: '',
          role: contractRoles.COMPANY_ADMIN,
        },
      ],
    };
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: 'createUserByAdmin',
    mutationFn: createUserByAdmin,
    onSuccess: (data) => {
      setShowModal(false);
      queryClient.invalidateQueries('users');
      navigate(`/admin/users/${data._id}`);
    },
    onError: (error) => {
      handleError(error);
      setShowModal(false);
    },
  });

  const handleError = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.USER_ALREADY_EXISTS:
        errorAlertContent = {
          title: t('screens.user.errors.userAlreadyExists.title'),
          description: t('screens.user.errors.userAlreadyExists.description'),
          type: ALERT_TYPES.ERROR,
          error: error,
        };
        break;
      case ERROR_CODES.COMPANY_ALREADY_EXISTS:
        errorAlertContent = {
          title: t('context.company.errors.companyAlreadyExists.title'),
          description: t('context.company.errors.companyAlreadyExists.description'),
          type: ALERT_TYPES.ERROR,
          error: error,
        };
        break;
      case ERROR_CODES.EMAIL_SENDING_FAILED:
        errorAlertContent = {
          title: t('screens.user.errors.newUserSendingEmailFailed.title'),
          description: t('screens.user.errors.newUserSendingEmailFailed.description'),
          type: ALERT_TYPES.WARNING,
          error: error,
        };
        // if the error is email sending failed, the user was still created successfully
        queryClient.invalidateQueries('users');
        navigate('/admin/users/table');
        break;
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
        break;
    }

    addAlert(errorAlertContent);
  };

  const onSubmit = (data) => {
    mutate(data);
  };

  const onCancel = (e) => {
    e.preventDefault();
    navigate('/admin/users/table');
  };

  const watch = methods.watch();
  const proceedModalContent = useMemo(() => {
    const values = methods.getValues();
    return buildProceedModalContent(t, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, methods, watch]);

  return (
    <FormProvider {...methods}>
      <Container>
        <Row>
          <h2>{t('screens.user.titles.newUser')}</h2>
        </Row>
      </Container>
      <UserForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isPending}
        actionStr={t('screens.user.actions.create')}
        actionVariant={'success'}
        proceedModalContent={proceedModalContent}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </FormProvider>
  );
};

export default NewRHF;
