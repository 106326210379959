import * as yup from 'yup';

import {
  bnNumberRequired,
  companyNameRequired,
  emailRequired,
  gender,
  mobileRequired,
  nameRequired,
  personalIdRequired,
  role,
  systemRole,
} from 'shared/schemas/general';

// hack for i18n ally inline translations
const t = (key) => key;

export const schema = yup.object().shape({
  personalId: personalIdRequired,
  email: emailRequired,
  mobile: mobileRequired,
  firstName: nameRequired,
  lastName: nameRequired,
  tenderAlerts: yup.boolean(),
  systemRole,
  gender,
  roles: yup
    .array()
    .of(
      yup.object().shape({
        companyName: companyNameRequired,
        bnNumber: bnNumberRequired.test('isUnique', t('validationErrors.bnNumber.isUnique'), function (value) {
          const { from } = this;
          const roles = from[1].value.roles;
          return roles.filter((role) => role.bnNumber === value).length <= 1;
        }),

        role: role,
      }),
    )
    .optional(),
});
