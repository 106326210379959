import { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { columnAliases } from 'shared/const/dividedAccountsTable';
import { stringifyIfExist } from 'shared/utils/stringifyIfExist';

import { SavedAccountsTable } from './SavedAccountsTable';

export const SavedAccountsModal = ({ show, setShow }) => {
  const { t } = useTranslation();
  const [queryData, setQueryData] = useState([]);
  const [selected, setSelected] = useState([]);
  const { setDividedAccountsUploadedArray } = useContext(OfferCreationContext);

  /**
   * This functions essentially flattens the data from the query to the format
   * that the divided accounts inserters expects.
   * since the inserter expects to work with data originating from an excel file,
   * all the values are strings.
   * moreover, the inserter expects a specific format for the headers.
   * also, bank names should actually only be the bank number.
   * lastly, we have to set amount and limit to a space so that the inserter would'nt fail.
   */
  const parseRows = (rows) => {
    return rows.map((row) => {
      return {
        [columnAliases.account_name[0]]: row.accountName,
        [columnAliases.cash_account_bank[0]]: /\d+/.exec(row.selectedCashAccount.bank)[0],
        [columnAliases.cash_account_branch[0]]: stringifyIfExist(row.selectedCashAccount.branchNumber),
        [columnAliases.cash_account_number[0]]: stringifyIfExist(row.selectedCashAccount.accountNumber),
        [columnAliases.shares_account_bank[0]]: /\d+/.exec(row.selectedSharesAccount.bank)[0],
        [columnAliases.shares_account_branch[0]]: stringifyIfExist(row.selectedSharesAccount.branchNumber),
        [columnAliases.shares_account_number[0]]: stringifyIfExist(row.selectedSharesAccount.accountNumber),
        [columnAliases.amount[0]]: ' ',
        [columnAliases.limit[0]]: ' ',
      };
    });
  };

  const handleDownload = () => {
    // find the selected accounts in the queryClient
    const selectedAccounts = selected.map((selectedId) => {
      return queryData.find((account) => account._id === selectedId);
    });

    // parse the selected accounts to the correct format
    const parsedRows = parseRows(selectedAccounts);

    setDividedAccountsUploadedArray(parsedRows);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title> {t('components.dividedAccountsUploader.SavedAccountsControls.header')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[80vh] overflow-auto">
        <p>{t('components.dividedAccountsUploader.SavedAccountsControls.body')}</p>
        <SavedAccountsTable setQueryData={setQueryData} setSelected={setSelected} selected={selected} />
      </Modal.Body>
      <Modal.Footer className="flex gap-1 justify-center">
        <Button variant="outline-primary" onClick={() => setShow(false)} size="md">
          {t('components.dividedAccountsUploader.close')}
        </Button>
        <Button variant="success" onClick={handleDownload} size="md">
          {t('components.dividedAccountsUploader.download')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
