import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { AccountsStructures } from 'shared/const/accountsStructures';
import { buildNewTableLine } from 'shared/utils/offers';

export const AccountsStructureSelector = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const watchAccountStructure = watch('accountsStructure');
  const [currentAccountsStructure, setCurrentAccountsStructure] = useState(watchAccountStructure);
  const { setScopeAndAccountsErrors } = useContext(OfferCreationContext);

  useEffect(() => {
    if (watchAccountStructure === currentAccountsStructure) return;

    /* resetting form state */
    /* not using reset() cause we want a clean slate in this scenario (which the defaultValue might not be) */
    setValue('accountsStructure', watchAccountStructure);
    setValue('bid_0_amount', '');
    setValue('bid_1_amount', '');
    setValue('bid_2_amount', '');
    setValue('bid_0_limit', '');
    setValue('bid_1_limit', '');
    setValue('bid_2_limit', '');

    const table = [buildNewTableLine()];
    setValue('table', table);
    setValue('tableStr', JSON.stringify(table));

    /* resetting component state */
    setCurrentAccountsStructure(watchAccountStructure);
    setScopeAndAccountsErrors({});
  }, [watchAccountStructure, currentAccountsStructure, setCurrentAccountsStructure, setValue, setScopeAndAccountsErrors]);

  return (
    <div className="mb-3">
      <Form.Check
        type="radio"
        value={AccountsStructures.UNITED}
        className="inline"
        isInvalid={!!errors.accountsStructure}
        id="united"
        label={t('screens.OrderScopeAndAccounts.form.bankAccounts.untied')}
        {...register('accountsStructure')}
      />

      <Form.Check
        type="radio"
        value={AccountsStructures.DIVIDED}
        className="inline  mr-10"
        isInvalid={!!errors.accountsStructure}
        id="divided"
        label={t('screens.OrderScopeAndAccounts.form.bankAccounts.divided')}
        {...register('accountsStructure')}
      />
    </div>
  );
};
