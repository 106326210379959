const PrivacyPolicy = () => {
  return (
    <div>
      <div className="text-center mb-4">
        <h1>מדיניות פרטיות</h1>
        <small>התנאים שלהלן מנוסחים בלשון זכר, מטעמי נוחות בלבד, אך מחייבים את שני המינים באופן זהה.</small>
      </div>
      <p>
        התנאים המפורטים להלן, מהווים את מדיניות הפרטיות של חברת סמארטבול בע"מ (להלן: <strong>"אנחנו"</strong>, <strong>"אנו"</strong> או{' '}
        <strong>"החברה"</strong>) ומתארת את האופן שבו נאסף מידע על מבקרים ומשתמשים בפלטפורמה של החברה (להלן: <strong>"הפלטפורמה"</strong>) ועל
        המשקיעים הכשירים הבוחרים לעשות שימוש בפלטפורמה על מנת להשתמש בשירותי החברה (וביחד עם הפלטפורמה: <strong>"הפלטפורמה"</strong>).
      </p>
      <p>
        החברה עושה מאמץ רב כדי לשמור על פרטיות המידע האישי של המשתמשים בשירותים (<strong>"לקוח"</strong>) והמבקרים בפלטפורמה של החברה (להלן:{' '}
        <strong>"משתמש"</strong>, <strong>"אתה"</strong> <strong>"לך"</strong>, או <strong>"אותך"</strong>). כאשר הלקוח מאפשר למשתמשים מטעמו לגשת
        לשירות, אזי (א) כל ההפניות ל"משתמש" במדיניות פרטיות זו מהווה גם הפניה ללקוח, (ב) הגישה של המשתמש לשירותים כפופה לתנאים אלה, ובאחריות הלקוח
        לוודא כי המשתמש הסכים לתנאים האמורים במדיניות פרטיות זו, וכן (ג) החברה עשויה לשתף את הלקוח במידע אישי של המשתמש בקשר לשירות.
      </p>
      <br />
      <p>
        יש לקרוא את תנאי מדיניות הפרטיות בתשומת לב ובזהירות על מנת לוודא שאתה מסכים להתחייב בהם. אם בחרת לעשות שימוש בשירותים המוצעים על ידנו, לרבות
        באמצעות שימוש בפלטפורמה, הדבר מהווה הסכמתך, ובביצוע פעולות אלו הינך מצהיר כלפינו שקראת, הבנת ואתה מסכים להיות מחויב בכל התנאים. השירותים,
        לרבות השימוש בפלטפורמה, מוצעים לך בכפוף לקבלתך את התנאים במלואם וציות להם, ללא כל שינוי או הסתייגות.
      </p>
      <p>
        אם אתה משתמש בשירותים מטעם לקוח כלשהו, ההסכמה שהינך נותן לתנאים שלהלן מטעם הלקוח אליו אתה משתייך כוללת את אישורך לכך שיש לך את הסמכות לחייב את
        הלקוח כאמור (ובהתאם, מילים כגון <strong>"אתה"</strong> ו- <strong>"שלך"</strong> יתייחסו ללקוח כאמור).
      </p>
      <p>
        מובהר כי אם אינך מסכים לתנאים המפורטים להלן, כולם או חלקם, אינך רשאי להמשיך לעשות שימוש כלשהו בשירותים או בפלטפורמה, ועליך לחדול מן השימוש בו
        באופן מידי.
      </p>
      <p>
        החברה שומרת לעצמה את הזכות להתאים ולשנות את התנאים ו\או השירותים בכל זמן וללא הודעה מראש, לרבות באמצעות ביטול, שינוי, הגבלת או הפסקת של כל
        תכונה או מאפיין בשירותים. על כן, אנו ממליצים בחום כי תחזור ותעיין בתנאים אלו בכל פעם שבו אתה בוחר לעשות שימוש בשירותים או בפלטפורמה, על מנת
        לוודא שאתה עדיין מסכים לקבל על עצמך את התנאים לרבות השינויים שחלו בהוראותיו. אם תמשיך להשתמש בשירותים לאחר שינוי בתנאים כאמור, אנחנו נראה אותך
        כמי שהסכים לקבל על עצמו את אותם השינויים.
      </p>
      <ol type="1">
        <li>
          <span className="text-xl font-medium underline">מידע שאנו אוספים על משתמשים</span>
          <ol>
            <li>
              <span className="text-lg underline"> מידע שאינו מזוהה </span>
              <br />
              מידע זה כולל נתונים סטטיסטיים ואגרגטיביים בקשר לשימוש בשירותים, שאינו מזוהה ואינו מאפשר זיהויו של משתמש ספציפי.
            </li>
            <li>
              <span className="text-lg underline"> מידע אישי </span>
              <ol>
                <li>
                  שם ושם משפחה, כתובת דוא"ל, שם ארגון, תפקיד בארגון (להלן: <strong>"נתוני התקשרות"</strong>).
                </li>
                <li>
                  מידע על המכשיר ממנו מתבצעת הגישה לאתר, למשל סוג ודגם של מכשירך הסלולרי, סוג מערכת ההפעלה וגרסתה, כתובת IP ומזהים ייחודיים נוספים של
                  המכשיר ממנו ביצעת גישה לשירותים, ומאפייניו. בנוסף, החברה עשויה לאסוף מידע אגב מתן הרשאות גישה שתתבקש להעניק באמצעות מכשירך בזמן
                  הגלישה בפלטפורמה (להלן: <strong>"נתוני השימוש"</strong>).
                </li>
                <li>
                  במידה ובחרת לפנות אלינו בכתב באמצעות הפלטפורמה, אנו אוספים את המידע שמסרת לנו לצורך השארת הודעה, לרבות שם, טלפון, כתובת דוא"ל ותוכן
                  ההודעה.
                </li>
              </ol>
            </li>
            <li>
              פרטי חשבון בנק לרבות מספר חשבון, מספר בנק וסניף.
              <br />
              <strong>תשומת ליבך כי לא חלה עליך חובה חוקית למסור את המידע האמור.</strong>
            </li>
            <li>
              אם את/ה מעוניין/ת להוסיף או לתקן מידע אודותיך, פנה/י אלינו בדוא"ל לכתובת: support@smartbull.co.il או בדואר רגיל בכתובת: אשכול לוי 63ג,
              תל אביב.
            </li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline">המקורות שבאמצעותם החברה מקבלת גישה למידע שלך</span>
          <ol>
            <li>החברה מקבלת את נתוני ההתקשרות עמך במועד ההרשמה לשירות וכן במועד הגשת הצעות במכרזים למשקיעים מסווגים..</li>
            <li>מידע שבחרת לשתף אותנו בו כאשר אתה יוצר עמנו קשר.</li>
            <li>באמצעות מידע שהתקבל אצל החברה בקשר עם מתן השירותים.</li>
            <li>
              <p>
                החברה עושה שימוש באמצעים טכנולוגיים על מנת לאסוף את נתוני השימוש שלך בשירותים. למשל, החברה משתמשת בקבצי עוגיות (Cookie) (קבצי טקסט
                קטנים המאוחסנים בדפדפן של המשתמש) וטכנולוגיות דומות אחרות לאיסוף נתונים סטטיסטיים. בכל עת תוכל להגדיר את הדפדפן שלך כך שיחסום קבצים
                אלה, אך חסימה זו עלולה למנוע מאיתנו לספק לך את השירותים או חלק מהם. טכנולוגיות אלה עוזרות לנו לעקוב אחר האופן שבו משתמשים עושים שימוש
                בשירותים שלנו, ואוספות מידע סטטיסטי מצטבר. כמו כן, לצורך איסוף המידע משתמשת החברה ב"משואות רשת" (web beacons) המסייעות לחברה לנטר את
                דפוסי הפעילות של משתמשים בפלטפורמה וכך לבחון את היקף השימוש של תכנים ושירותים שונים בפלטפורמה וביצוע פעולות דומות. המידע הנאסף הוא
                סטטיסטי במהותו, הוא איננו מזהה אותך אישית והוא נועד לצרכי ניתוח, מחקר ובקרה.{' '}
              </p>
              <br />
              <br />
              <p>
                בנוסף, החברה נעזרת בחברת וגופים חיצוניים המספקים לה ניתוחים סטטיסטיים אודות השימוש בפלטפורמה כגון במערכת Google Analytics ו-OpenReplay
                על מנת לאסוף מידע אודות שימושך בפלטפורמה, לרבת בקשר עם תדירות הגלישה בפלטפורמה, אילו עמודים בפלטפורמה נצפו על ידך, באילו אתרים אחרים
                ביקרת קודם לשימוש בפלטפורמה ומידע נוסף על אופי השימוש שלך בפלטפורמה.{' '}
              </p>
              <br />
              <br />
              <p>
                תנאי השימוש של Google Analytics זמינים <a href="https://marketingplatform.google.com/about/analytics/terms/us/">כאן</a> , ומדיניות
                הפרטיות של Google, זמינה <a href="https://policies.google.com/privacy">כאן</a>. תנאי השימוש ומדיניות הפרטיות של OpenReplay זמינים{' '}
                <a href="https://openreplay.com/terms.html">כאן</a>. ככל שאינך מעוניין שהחברה תעשה שימוש בGoogle Analytics כדי לאסוף מידע אודותיך, אתה
                יכול לבחור לחסום את האפשרות בהתאם להנחיות המופיעות <a href="https://tools.google.com/dlpage/gaoptout/">כאן</a>. על מנת ש-OpenReplay
                תסיר מידע שהתקבל אודותיך יש לפנות אליהם בהתאם למפורט בתנאי השימוש שלהם.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline">שימוש שאנו עושים במידע</span>
          <br />
          <p>
            השימוש במידע שנאסף על ידי החברה כפי שמתואר במדיניות פרטיות זו ייעשה רק על פי האמור להלן ו/או על פי הוראות כל דין, וזאת בין היתר בעבור
            המטרות הבאות:
          </p>
          <ol>
            <li>כדי לספק לך את השירותים, לרבות, יצירת אזור אישי ללקוחות רשומים בפלטפורמה.</li>
            <li>על מנת לזהות אותך, וכן על מנת ליצור עמך קשר במידת הצורך.</li>
            <li>
              כדי לאפשר לך לעשות שימוש בשירות ולשפר את חוויית המשתמש, לרבות בקשר עם השימוש בפלטפורמה, ובכלל זה, לשלוח לך סקרים ושאלונים בקשר עם
              השירותים שקיבלת מהחברה.
            </li>
            <li>תפעול, בקרה, שינוי, פיתוח ושיפור השירותים.</li>
            <li>לצורך ביצוע מחקרים, ניתוחים סטטיסטיים, פילוחי שוק וסקרים.</li>
            <li>איתור ומניעה של שימוש אסור בשירות, הונאות, או הפרה אחרת של הוראות הדין.</li>
            <li>לצורך ניהול הליכים משפטיים, לרבות דרישות או טענות של לקוחות או משתמשים, וכן לצרכים ביטוחיים.</li>
            <li>
              במידה ונרשמת לקבלת "שירותי דיוור", על מנת לשלוח לך דוא"ל, על ידי החברה או מטעמה, הכולל מידע על שירותים וכן מידע שיווקי ופרסומי - בין אם
              בקשר לשירותים המוצעים על ידי החברה או על ידי צדדים שלישיים לכתובת הדוא"ל שמסרת לחברה לצורך זה. בכל עת תוכל לבקש לחדול מלקבל את הדיוורים
              כאמור על ידי שליחת בקשת הסרה מרשימת תפוצה כפי שמופיע בתחתית הדוא"ל שקיבלת או באמצעות פניה אלינו באחת מהדרכים המנויות במדיניות פרטיות זו
              תחת לשונית "צור קשר". תשומת ליבך כי לא חלה עליך חובה חוקית למסור את המידע האמור.
            </li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline">מסירת המידע לצדדים שלישיים</span>
          <ol>
            <li>
              מפעם לפעם, החברה עשויה לגלות את המידע כמפורט במדיניות פרטיות זו לצדדים שלישיים, בכלל זה גם שירותי סליקה, שירותי ענן, מחשוב, תפעול
              ותקשורת, לצורך קבלת השירותים, וזאת על מנת לממש את המטרות הלגיטימיות של החברה כמפורט במדיניות פרטיות זו (חברת סליקת אשראי) או בקשר עם
              ניהולה השוטף של החברה (למשל, עורכי דין, רואי חשבון ויועצים נוספים של החברה), וכן, במקרה ונרשמת לקבלת "שירותי דיוור", נתוני ההתקשרות
              עשויים להמסר לגופים המציעים שירותים באמצעות הפלטפורמה. כל שיתוף כאמור יהיה כפוף לחובת סודיות ועמידה בהוראות מדיניות זו של אותם צדדים
              שלישיים בקשר עם השימוש במידע.
            </li>
            <li>
              בנוסף לאמור לעיל, אנו עשויים להעביר לצדדים שלישיים מידע על משתמשים במקרים המפורטים להלן:
              <br />
              <ol>
                <li>אם יתקבל צו שיפוטי המורה לחברה למסור מידע אודות משתמש;</li>
                <li>בכל מקרה בו אנו סבורים שמסירת המידע נחוצה כדי למנוע נזק חמור למשתמש או להחברה או לצד ג';</li>
                <li>במקרים של הפרת הוראות מדיניות פרטיות זו, או פעולות בניגוד לדין ו/או הנחזות כמנוגדות לדין ו/או ניסיון לבצע פעולות כאלה;</li>
                <li>בכל מחלוקת, תביעה, טענה, דרישה או הליכים משפטיים, אם יהיו, שהחברה צד להן;</li>
                <li>
                  לשותפים עסקיים או שותפים עסקיים פוטנציאליים, של החברה, לרבות במסגרת ארגון מחדש, מיזוג, מכירה, מכירת פעילות, מיזם משותף, העברה או כל
                  עסקה אחרת בקשר לעסקי או נכסי החברה, כולם או חלקם.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline">שירותים של צדדים שלישיים</span>
          <br />
          הפלטפורמה עשוי להכיל קישורים (לינקים) לאתרים אחרים המפרסמים ו/או מציעים שירותים על ידי אותם צדדים שלישיים שאינם קשורים לחברה (להלן: "שירותי
          צד ג'"). שירותי צד ג' כאמור אינם בשליטת החברה והם כפופים לתנאי השימוש ומדיניות הפרטיות של אותם צדדים שלישיים. במידה והמשתמש בחר ללחוץ על
          קישור, לוגו או כל סימן אחר, שהעביר אותו לאתר של צד שלישי, על המשתמש לבחון האם עזב את אתר החברה. על מנת לבדוק את מיקומו באינטרנט, על המשתמש
          לבדוק את כתובת ה – URL – המופיעה בראש או בתחתית הדפדפן. החברה ממליצה למשתמש לקרוא את מדיניות הפרטיות של הפלטפורמה האחר אליו גלש.
        </li>
        <li>
          <span className="text-xl font-medium underline"> מימוש זכותי לעיין ולתקן את המידע אודותי</span>
          <ol>
            <li>
              אתה רשאי לעיין במידע אישי אודותיך. תוכל לעשות זאת באמצעות שליחת דוא"ל לכתובת: support@smartbull.co.il או בדואר רגיל בכתובת: אשכול לוי
              63ג, תל אביב.
            </li>
            <li>במידה ומידע אישי אודותיך אינו מדויק או מעודכן, אנא עדכן אותנו על ידי שליחת דוא"ל בנושא.</li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline"> מאגר מידע רשום</span>
          <br />
          המידע שמגיע אלינו באמצעות שימושך בשירותים או ביקורך באתר נשמר במאגר מידע הפועל לפי רישיון הרשות להגנת הפרטיות במשרד המשפטים.
        </li>
        <li>
          <span className="text-xl font-medium underline"> שמירת מידע אישי</span>
          <ol>
            <li>
              אנו שומרים מידע אישי לתקופה הנדרשת על מנת להגשים את מטרות השימוש המתוארות במדיניות זו, להגן או לעמוד על זכויות וטענות משפטיות, לניתוח
              ושיפור השירותים ובהתאם לדין החל.
            </li>
            <li>
              המידע הנ"ל יישמר במאגר מידע שיאוחסן בישראל או בחו"ל כמפורט בסעיף ‏7.3 להלן, וייעשה בו שימוש אך ורק לצורך הפעולות המותרות כפי שצוינו
              במדיניות פרטיות זו.
            </li>
            <li>
              המידע על משתמשים נשמר על ידי החברה בשרתי הענן של חברת Atlas, הממוקמים בגרמניה. בהתאם למדיניות הפרטיות של ספק שירותי ענן הזמינים
              https://www.mongodb.com/legal/privacy-policy.
            </li>
            <li>נתונים לא אישיים ונתונים אנונימיים סטטיסטיים עשויים להישמר על ידינו ללא הגבלה.</li>
          </ol>
        </li>
        <li>
          <span className="text-xl font-medium underline"> אבטחת מידע </span>
          <br />
          החברה מפעילה מערכות ונהלים עדכניים לאבטחת מידע, אשר נועדו לצמצם את הסיכונים לחדירה בלתי-מורשית ולאפשר הגנה על המידע שהחברה אוספת, בהתאם
          לסטנדרטים המקובלים.
          <strong>
            יחד עם זאת, האמצעים שבהם עושה החברה שימוש אינם יכולים להעניק בטחון מוחלט, ובהסכמתך להוראות מדיניות פרטיות זו, אתה מאשר כי אתה מודע לכך
            שהחברה אינה מתחייבת ששירותיה יהיו חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם, ואתה מודע לסכנות הכרוכות בהעברת מידע באופן
            המתואר במדיניות פרטיות זו.
          </strong>
        </li>
        <li>
          <span className="text-xl font-medium underline"> שינויים במדיניות הפרטיות</span>
          <br />
          החברה רשאית, בכל עת, לשנות את הוראות מדיניות פרטיות זו, כולן או חלקן. על כן, אנו ממליצים בחום כי תחזור ותעיין בתנאים אלו בכל פעם שבו אתה
          בוחר לעשות שימוש בשירות, על מנת לוודא שאתה עדיין מסכים לקבל על עצמך את התנאים לרבות השינויים שחלו בהוראותיו. אם תמשיך להשתמש בשירות לאחר
          שינוי בתנאים כאמור, אנחנו נראה אותך כמי שהסכים לקבל על עצמו את אותם השינויים.
        </li>
        <li>
          <span className="text-xl font-medium underline"> צור קשר</span>
          <br />
          לשאלות בנוגע למדיניות זו הנך מוזמן לפנות אלינו באמצעות שליחת דוא"ל לכתובת: support@smartbull.co.il או בדואר רגיל בכתובת: אשכול לוי 63ג, תל
          אביב.
        </li>
        <li>
          <span className="text-xl font-medium underline"> הדין החל וסמכות שיפוט</span>
          <ol>
            <li>הוראות מדיניות פרטיות זו באות בנוסף על כל הסכמה, הסכם או מסמך מול הלקוח, ומהוות חלק בלתי נפרד מכל הסכמה כאמור.</li>
            <li>
              התנאים וההוראות המפורטים במדיניות פרטיות זו לרבות השימוש בשירות המוצע על ידי החברה, יפורשו בהתאם לדין הישראלי. לבתי המשפט שבתל אביב
              -יפו, תהיה סמכות שיפוט בלעדית לדון בכל מחלוקת שתתעורר בקשר עם מדיניות פרטיות זו.
            </li>
            <li>
              במידה ורשות מוסמכת תקבע כי הוראה מהוראות מדיניות פרטיות זו הינה בלתי אכיפה או בלתי חוקית, לא יהיה בכך כדי להשליך או לגרוע מתוקפן של כל
              ההוראות האחרות במדיניות פרטיות זו.
            </li>
          </ol>
        </li>
      </ol>
      <p>מעודכן נכון ליום: 1 במאי, 2023</p>
    </div>
  );
};

export default PrivacyPolicy;
