import dayjs from 'dayjs';
import compact from 'lodash/compact';
import numbro from 'numbro';

import { auctionTypes } from 'shared/const/auctions';
import { buildAuctionTitleGeneric, getLocalizedAuctionType } from 'shared/utils/auctions';
import { calculateLimits } from 'shared/utils/offers';

import ActionCell from 'components/AuctionsTable/ActionCell';
import { AuctionAttachments } from 'components/common/AuctionAttachments';

const getColumns = ({ t, showActionCell }) =>
  compact([
    {
      Header: 'issuerAndSecurity',
      accessor: 'issuedCompanyName',
      Cell: (row) => {
        const { issuedCompanyName, unitStructures } = row;
        const title = buildAuctionTitleGeneric({ issuedCompanyName, unitStructures, t });
        return title;
      },
    },
    {
      Header: 'type',
      accessor: 'type',
      Cell: (row) => getLocalizedAuctionType(row.type, t),
    },
    {
      Header: 'attachments',
      accessor: 'attachments',
      Cell: (row) => {
        return <AuctionAttachments auction={row} />;
      },
      noSort: true,
    },
    {
      Header: 'openingLimit',
      accessor: 'unitStructures.limit',
      Cell: (row) => {
        const { biddingLimitations, unitStructures, type } = row;
        const { maxInterest, minPrice } = calculateLimits(biddingLimitations, unitStructures);

        switch (type) {
          case auctionTypes.INTEREST:
          case auctionTypes.GAP:
            if (!maxInterest) return '-';

            return numbro(maxInterest).formatCurrency({
              thousandSeparated: true,
              currencySymbol: '%',
              currencyPosition: 'postfix',
            });
          case auctionTypes.PRICE:
            if (!minPrice) return '-';

            return numbro(minPrice).format('0,0.00$');
          default:
            return '-';
        }
      },
      noSort: true,
    },
    {
      Header: 'closeTime',
      accessor: 'closeTime',
      Cell: (row) => dayjs(row.closeTime).format('HH:mm'),
    },
    {
      Header: 'commitmentDiscount',
      accessor: 'commitmentDiscount',
      Cell: (row) =>
        numbro(row.commitmentDiscount).formatCurrency({
          thousandSeparated: true,
          currencySymbol: '%',
          currencyPosition: 'postfix',
        }),
      noSort: true,
    },
    {
      Header: 'leadingUnderwriter',
      accessor: 'underwriter.name',
    },
    showActionCell && {
      Header: '',
      accessor: 'actions',
      Cell: (row) => (
        <div className="text-center">
          <ActionCell row={row} />
        </div>
      ),
      noSort: true,
    },
  ]);

export default getColumns;
