import React from 'react';
import { Link } from 'react-router-dom';

const shouldAddCommasSeparator = (flag, idx, attachmentsLinks) => flag && idx !== attachmentsLinks.length - 1;

export const AuctionAttachments = ({ auction, direction = 'col', addCommasSeparator = false, extraClassNames = '' }) => {
  const attachmentsLinks = (auction.attachments.links || []).filter((link) => link.url);

  return (
    <div className={`flex flex-${direction} gap-1 ${extraClassNames}`}>
      {attachmentsLinks.map(({ name, url }, idx) => (
        <Link className="underline" target="_blank" key={idx} to={url}>
          {name}
          {shouldAddCommasSeparator(addCommasSeparator, idx, attachmentsLinks) ? ',' : ''}
        </Link>
      ))}
    </div>
  );
};
