import { useTranslation } from 'react-i18next';

const RelatedDistributerList = () => {
  const { t } = useTranslation();

  //JSX
  return (
    <div>
      <p>{t('screens.company.labels.relatedDistributors')}:</p>
    </div>
  );
};

export default RelatedDistributerList;
